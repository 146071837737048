import React, { FC, useState } from 'react';
import { Link } from 'react-router-dom';

import { makeStyles, useTheme } from '@material-ui/core/styles';
import { Avatar, Box, Divider, SwipeableDrawer } from '@material-ui/core';
import Grid from '@material-ui/core/Grid';
import MenuIcon from '@material-ui/icons/Menu';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import IconButton from '@material-ui/core/IconButton';
import { useTranslation } from 'react-i18next';
import { ProfileShort } from 'types/Profile';
import { Icon } from 'components/atoms';
import { getUserInitials } from 'utils/utils';
import { EsiaLoginContainer } from 'components/molecules';
import { AppNavigation } from 'components/molecules';
import logo from 'assets/images/logo2.svg';
import burger from 'assets/images/burger.svg';

const useStyles = makeStyles(theme => ({
    logo: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '120%',
        display: 'flex',
        alignItems: 'center',
        color: '#000A26',
    },
    menuButton: {
        color: theme.palette.text.primary,
    },
    drawerPaper: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        width: '30%',
        overflowX: 'hidden',
        backgroundColor: theme.palette.common.white,
    },
    divider: {
        width: '100vw',
        left: '50%',
        marginLeft: '-50vw',
        position: 'relative',
    },
    logoImage: {
        marginRight: 8,
    },
    userName: {
        fontWeight: 500,
        fontSize: 17,
        lineHeight: 1.4,
        color: theme.palette.grey[400],
        marginLeft: 16,
    },
    menuHeader: {
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        flexShrink: 0,
    },
    menuTitle: {
        fontWeight: 500,
        fontSize: 28,
        lineHeight: 1.36,
    },
    menuContent: {
        position: 'relative',
        '&::before': {
            content: '""',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: '50%',
            marginLeft: '-50vw',
            position: 'absolute',
            backgroundColor: '#F6FBFF',
            zIndex: -20,
        },
    },
    burger: {},
    pageHeader: {
        position: 'relative',
    },
}));

interface MobileMenuProps {
    isAuthorized?: boolean;
    userName?: ProfileShort;
    logout: VoidFunction;
}

export const MobileMenu: FC<MobileMenuProps> = ({ isAuthorized, logout, userName }) => {
    const classes = useStyles();
    const [drawerIsOpen, setDrawerIsOpen] = useState<boolean>(false);
    const theme = useTheme();
    const { t } = useTranslation();

    const toggleDrawer = (open: boolean, drawer: string) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (drawer === 'menu') {
            setDrawerIsOpen(open);
        }
    };

    return (
        <>
            <Grid
                container
                justify="space-between"
                alignItems="center"
                className={classes.pageHeader}
            >
                <Link className={classes.logo} to="/home">
                    <img
                        src={logo}
                        alt="logo"
                        width="24px"
                        height="24px"
                        className={classes.logoImage}
                    />{' '}
                    {t('zabota')}
                </Link>
                <IconButton
                    edge="end"
                    onClick={toggleDrawer(true, 'menu')}
                    className={classes.menuButton}
                    aria-label={t('com.MobileMenu.openMenu')}
                >
                    <img
                        src={burger}
                        alt="logo"
                        width="16px"
                        height="14px"
                        className={classes.burger}
                    />
                </IconButton>
            </Grid>

            <SwipeableDrawer
                anchor="right"
                open={drawerIsOpen}
                onOpen={toggleDrawer(true, 'menu')}
                disableDiscovery
                onClose={toggleDrawer(false, 'menu')}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Box
                    paddingLeft="20px"
                    paddingRight="20px"
                    paddingBottom="20px"
                    display="flex"
                    flexDirection="column"
                    height="100%"
                >
                    <Box marginY="12px" className={classes.menuHeader}>
                        <Typography className={classes.menuTitle}>
                            {t('com.MobileMenu.menu')}
                        </Typography>
                        <IconButton
                            aria-label={t('com.MobileMenu.closeMenu')}
                            edge="end"
                            size="small"
                            onClick={toggleDrawer(false, 'menu')}
                        >
                            <CloseIcon htmlColor={theme.palette.common.black} />
                        </IconButton>
                    </Box>
                    <Divider variant="fullWidth" className={classes.divider} />
                    <Box
                        height="100%"
                        display="flex"
                        flexDirection="column"
                        className={classes.menuContent}
                    >
                        {isAuthorized && (
                            <>
                                <Box
                                    marginY="26px"
                                    display="flex"
                                    justifyContent="space-between"
                                    alignItems="center"
                                >
                                    <Box display="flex" alignItems="center">
                                        <Avatar>
                                            <Icon name="userpic" />
                                        </Avatar>
                                        <Typography className={classes.userName}>
                                            {getUserInitials(userName)}
                                        </Typography>
                                    </Box>

                                    <IconButton
                                        size="small"
                                        edge="end"
                                        onClick={logout}
                                        aria-label={t('com.MobileMenu.logout')}
                                    >
                                        <Icon name="exitMin" />
                                    </IconButton>
                                </Box>
                                <Divider
                                    variant="fullWidth"
                                    className={classes.divider}
                                />
                            </>
                        )}
                        <AppNavigation mobileMenu />
                        <Box marginTop="auto">
                            {!isAuthorized && (
                                <EsiaLoginContainer
                                    mode="gosuslugi"
                                    buttonProps={{
                                        fullWidth: true,
                                    }}
                                />
                            )}
                        </Box>
                    </Box>
                </Box>
            </SwipeableDrawer>
        </>
    );
};
