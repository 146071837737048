import { createSlice } from '@reduxjs/toolkit';
import { Service, ServiceSubsidy, ServiceDetail, Calculation } from 'types/Services';

interface State {
    serviceDetail: {
        data: ServiceDetail;
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
}

const initialState: State = {
    serviceDetail: {
        data: null,
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
};

const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        getServiceDetailStart(state) {
            state.serviceDetail.loading = true;
            state.serviceDetail.error = null;
        },
        getServiceDetailSuccess(state, { payload }) {
            state.serviceDetail.loading = false;
            state.serviceDetail.error = null;
            state.serviceDetail.success = true;
            state.serviceDetail.loaded = true;
            state.serviceDetail.data = payload;
        },
        getServiceDetailFailure(state, action) {
            state.serviceDetail.loading = false;
            state.serviceDetail.loaded = true;
            state.serviceDetail.error = action.payload.error.error;
            state.serviceDetail.errorCode = action.payload.errorCode;
        },
        getServiceDetailClearErrorCode(state) {
            state.serviceDetail.errorCode = null;
        },
        getServiceDetailClearAll(state) {
            state.serviceDetail.errorCode = null;
            state.serviceDetail.data = null;
        },
    },
});

export const {
    getServiceDetailStart,
    getServiceDetailSuccess,
    getServiceDetailFailure,
    getServiceDetailClearErrorCode,
    getServiceDetailClearAll,
} = services.actions;

export default services.reducer;
