import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Route, Redirect, RouteProps } from 'react-router-dom';
import { RootState } from 'store/store';
import { getBasic } from 'store/actions';
import { useFeatures } from 'hooks/useFeatures';
import { useAuth } from 'hooks/useAuth';

const PublicRoute: React.FC<RouteProps & { featureToggle?: string }> = props => {
    const { component: Component, featureToggle = null, ...rest } = props;
    const findContext = useFeatures();
    useAuth();

    if (featureToggle && !findContext(featureToggle)) return <Redirect to="/home" />;

    return <Route {...rest} component={Component} />;
};

//Авторизация, если не вошел, то все равно идет показ
const PrivateRoute: React.FC<RouteProps & { featureToggle?: string }> = props => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );
    const { component: Component, featureToggle = null, ...rest } = props;
    const findContext = useFeatures();
    useAuth();

    useEffect(() => {
        if (token) {
            dispatch(getBasic());
        }
    }, [dispatch, token]);

    if (featureToggle && !findContext(featureToggle)) return <Redirect to="/home" />;

    return <Route {...rest} component={Component} />;
};

//Авторизация, если не вошел, редирект
const ProtecedRoute: React.FC<RouteProps & { featureToggle?: string }> = props => {
    const dispatch = useDispatch();
    const token = useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );
    const { component: Component, featureToggle = null, ...rest } = props;
    const findContext = useFeatures();
    useAuth();

    useEffect(() => {
        if (token) {
            dispatch(getBasic());
        }
    }, [dispatch, token]);

    if (featureToggle && !findContext(featureToggle)) return <Redirect to="/home" />;

    return (
        <Route {...rest}>
            {token ? <Route {...rest} component={Component} /> : <Redirect to="/home" />}
        </Route>
    );
};

export { PrivateRoute, ProtecedRoute, PublicRoute };
