import React from 'react';
import { useTheme } from '@material-ui/core/styles';
import { AddressAutocompleteFormField } from 'components/atoms';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

export interface AdressProps {
    field: ApplicationField;
    fieldName: string;
    childId?: string;
    isChild?: boolean;
    childIndex?: number | string;
    error: boolean;
    isRequired: boolean;
}

export const AddressRegion: React.FC<AdressProps> = ({
    field,
    fieldName,
    error,
    isRequired,
}) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const formProps = useFormContext();

    return (
        <AddressAutocompleteFormField
            {...formProps}
            key={fieldName}
            label={field.fieldTitle}
            name={fieldName}
            fieldActualId={field.fieldActualId}
            childId={field.childId}
            readOnly={!field.canModify}
            transformValueForBackend={(address: AddressDadata) => {
                if (address?.value) {
                    if (typeof address.value === 'string') {
                        return address.value;
                    }
                    return JSON.stringify(address.value);
                }
                //return address;
            }}
            defaultFormValue={field.currentValue}
            defaultValue={{
                // @ts-ignore
                value: field.currentValue,
                displayValue: field.displayValue,
            }}
            fullWidth
            validationRules={{ required: isRequired }}
            variant="outlined"
            error={error}
            size={isViewXS ? 'small' : 'medium'}
            daDataType={'addressRegion'}
        />
    );
};
