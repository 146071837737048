import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_PASSPORT_REQUESTING,
    GET_PASSPORT_SUCCEED,
    GET_PASSPORT_FAILED,
} from '../../../constants';
import { ApiResponse } from 'types/ApiResponse';
import { ProfilePersonal } from 'types/Profile';
import { parseDateString } from 'utils/utils';

export const getPassport = () => (dispatch, getState) => {
    const dispatchData = (actionType, passport, requestStatus, errors) => {
        dispatch({
            type: actionType,
            payload: {
                passport: passport,
                status: requestStatus,
                errors: errors,
            },
        });
    };

    dispatchData(GET_PASSPORT_REQUESTING, null, null, null);

    axios({
        method: 'GET',
        url: '/api/Profile/personal',
        headers: getCommonHeaders(getState()),
    })
        .then((response: AxiosResponse<ApiResponse<ProfilePersonal>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true && response.data.result !== null) {
                const data = response.data.result;

                const passport = {
                    ...data,
                    // birthOfDate: parseDateString(data.birthOfDate),
                    // passportDate: parseDateString(data.passportDate),
                };

                dispatchData(GET_PASSPORT_SUCCEED, passport, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_PASSPORT_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_PASSPORT_FAILED, null, null, errors);
        });
};
