import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';

import {
    CHILDREN_BIRTH_CERTIFICATE_CATEGORY,
    CHILDREN_BIRTH_PLACE_CATEGORY,
    CHILDREN_INFO_CATEGORY,
    CHILDREN_REGISTRATION_ADDRESS_CATEGORY,
    ProfileGroupsField,
} from 'components/organisms';
import { createChild } from 'store/actions';
import { RootState } from 'store/store';
import { ErrorMessage } from 'components/atoms';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    saveButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        marginTop: 60,
        padding: '13px 28px',
    },
    form: {
        width: '100%',
    },
}));

interface ChildProps {}

export const ChildAdd: FC<ChildProps> = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const childrenState = useSelector((state: RootState) => state.profile.children);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>();

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };

    const submit = React.useCallback(
        formData => {
            dispatch(
                createChild(formData, () => {
                    history.push('/profile/child');
                    enqueueSnackbar(t('com.Child.added'), {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                })
            );
        },
        [dispatch]
    );

    return (
        <>
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
                <ProfileGroupsField
                    fields={CHILDREN_INFO_CATEGORY.fields}
                    groupName={CHILDREN_INFO_CATEGORY.groupId}
                    formProps={formProps}
                    key={CHILDREN_INFO_CATEGORY.groupId}
                />

                <ProfileGroupsField
                    fields={CHILDREN_BIRTH_PLACE_CATEGORY.fields}
                    groupName={CHILDREN_BIRTH_PLACE_CATEGORY.groupId}
                    formProps={formProps}
                    key={CHILDREN_BIRTH_PLACE_CATEGORY.groupId}
                />

                <ProfileGroupsField
                    fields={CHILDREN_BIRTH_CERTIFICATE_CATEGORY.fields}
                    groupName={CHILDREN_BIRTH_CERTIFICATE_CATEGORY.groupId}
                    formProps={formProps}
                    key={CHILDREN_BIRTH_CERTIFICATE_CATEGORY.groupId}
                />

                <ProfileGroupsField
                    fields={CHILDREN_REGISTRATION_ADDRESS_CATEGORY.fields}
                    groupName={CHILDREN_REGISTRATION_ADDRESS_CATEGORY.groupId}
                    formProps={formProps}
                    key={CHILDREN_REGISTRATION_ADDRESS_CATEGORY.groupId}
                />

                {childrenState.request.errors && (
                    <ErrorMessage error={childrenState.request.errors} />
                )}
                <Button
                    className={classes.saveButton}
                    disabled={childrenState.request.isLoading}
                    startIcon={
                        childrenState.request.isLoading && <CircularProgress size={24} />
                    }
                    disableElevation
                    type="submit"
                    variant="contained"
                >
                    {t('com.Child.add')}
                </Button>
            </form>
        </>
    );
};
