import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { errorHandle } from 'utils/utils';
import {
    CardOrder,
    CardOrderDraftFields,
    CardOrderState,
    CardOrderSubmitFields,
    CardOrderSuccesConfig,
} from 'types/CardOrder';

import { AppThunk } from '../store';
import { AxiosResponse } from 'axios';

interface getDraftFieldsSuccess {
    draftFields: CardOrderDraftFields;
}
interface sendCardOrderSuccess {
    applicationId: string;
    externalApplicationId: string;
}

interface setCardOrderSuccesConfig {
    cardOrderSuccesConfig: CardOrderSuccesConfig;
}

interface getCardOrdersSuccess {
    cardOrders: CardOrder[];
}

const draft = {
    firstName: 'string',
    lastName: 'string',
    middleName: 'string',
    gender: 'string',
    snils: 'string',
    birthdayDate: 'string',
    phone: 'string',
    email: 'string',
    passportSeries: 'string',
    passportNumber: 'string',
    passportDate: 'string',
    passportIssuedBy: 'string',
    passportDepartmentCode: 'string',
    registrationAddress: {
        region: 'string',
        city: 'string',
        town: 'string',
        street: 'string',
        house: 'string',
        building: 'string',
        apartment: 'string',
        postIndex: 'string',
    },
    liveAddress: {
        region: 'string',
        city: 'string',
        town: 'string',
        street: 'string',
        house: 'string',
        building: 'string',
        apartment: 'string',
        postIndex: 'string',
    },
};

const initialState: CardOrderState = {
    draftFields: null,
    submitFields: null,
    error: null,
    loading: false,
    submitError: null,
    submitLoading: false,
    applicationId: null,
    externalApplicationId: null,
    submitSuccess: false,
    cardOrderSuccesConfig: null,
    cardOrders: null,
    cardOrdersLoading: false,
    cardOrdersError: null,
};

const cardOrder = createSlice({
    name: 'cardOrder',
    initialState,
    reducers: {
        getDraftFieldsStart(state, action: PayloadAction<string>) {
            state.loading = true;
            state.error = null;
        },
        getDraftFieldsSuccess(state, action: PayloadAction<getDraftFieldsSuccess>) {
            state.loading = false;
            state.error = null;
            state.draftFields = action.payload.draftFields;
        },
        getDraftFieldsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.loading = false;
            state.error = action.payload.error;
        },
        sendCardOrderStart(state, action: PayloadAction<string>) {
            state.submitLoading = true;
            state.submitError = null;
        },
        sendCardOrderSuccess(state, action: PayloadAction<sendCardOrderSuccess>) {
            state.submitLoading = false;
            state.submitError = null;
            state.applicationId = action.payload.applicationId;
            state.externalApplicationId = action.payload.externalApplicationId;
            state.submitSuccess = true;
        },
        sendCardOrderFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.submitLoading = false;
            state.submitError = action.payload.error;
        },
        setCardOrderSuccesConfig(state, action: PayloadAction<setCardOrderSuccesConfig>) {
            state.cardOrderSuccesConfig = action.payload.cardOrderSuccesConfig;
        },
        getCardOrdersStart(state, action: PayloadAction<string>) {
            state.cardOrdersLoading = true;
            state.cardOrdersError = null;
        },
        getCardOrdersSuccess(state, action: PayloadAction<getCardOrdersSuccess>) {
            state.cardOrdersLoading = false;
            state.cardOrdersError = null;
            state.cardOrders = action.payload.cardOrders;
        },
        getCardOrdersFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.cardOrdersLoading = false;
            state.cardOrdersError = action.payload.error;
        },
    },
});

export const {
    getDraftFieldsFailure,
    getDraftFieldsStart,
    getDraftFieldsSuccess,
    sendCardOrderFailure,
    sendCardOrderStart,
    sendCardOrderSuccess,
    setCardOrderSuccesConfig,
    getCardOrdersFailure,
    getCardOrdersStart,
    getCardOrdersSuccess,
} = cardOrder.actions;

export default cardOrder.reducer;

export const getDraftFields = (callback?: Function): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(getDraftFieldsStart());

        const res: AxiosResponse<ApiResponse<CardOrderDraftFields>> = await axios.get(
            '/api/Card/get_request_data',
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            dispatch(
                getDraftFieldsSuccess({
                    draftFields: { ...res.data.result, deliveryBranchOfBank: '' },
                })
            );
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(getDraftFieldsFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(getDraftFieldsFailure(error));
    }
};

export const sendCardOrder = (
    data: CardOrderSubmitFields,
    callback?: Function
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(sendCardOrderStart());
        const res: AxiosResponse<ApiResponse<sendCardOrderSuccess>> = await axios.post(
            '/api/Card/send_create_request',
            data,
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            dispatch(sendCardOrderSuccess(res.data.result));
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(sendCardOrderFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(sendCardOrderFailure(error));
    }
};

export const clearCardOrder = (callback?: Function): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        const userId = getState().account.signInEsiaConfirm.userId;
        // dispatch(sendCardOrderStart());
        const res: AxiosResponse<ApiResponse<null>> = await axios.get(
            `/api/Card/clear/requests/${userId}`,
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            // dispatch(sendCardOrderSuccess(res.data.result));
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            // dispatch(sendCardOrderFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        // dispatch(sendCardOrderFailure(error));
    }
};

export const getCardOrders = (callback?: Function): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(getCardOrdersStart());

        const res: AxiosResponse<ApiResponse<CardOrder[]>> = await axios.get(
            '/api/Card/get_requests',
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            dispatch(getCardOrdersSuccess({ cardOrders: res.data.result }));
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(getCardOrdersFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(getCardOrdersFailure(error));
    }
};
