import {
    SIGN_IN_ANONIM_REQUESTING,
    SIGN_IN_ANONIM_SUCCEED,
    SIGN_IN_ANONIM_FAILED,
    SIGN_IN_ESIA_CONFIRM_SUCCEED,
    SIGN_IN_ESIA_RESET_TOKEN,
} from '../../constants';
import { getAnonimToken } from 'utils/token';

const signinInitState = {
    anonimToken: getAnonimToken() ? getAnonimToken() : null,
    deviceToken: null,
    request: {
        requestStatus: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const signinAnonim = (state = signinInitState, action) => {
    switch (action.type) {
        case SIGN_IN_ANONIM_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case SIGN_IN_ANONIM_SUCCEED:
            return {
                ...state,
                deviceToken: action.deviceToken,
                anonimToken: action.token,
                request: {
                    ...state.request,
                    status: action.requestStatus,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };

        case SIGN_IN_ANONIM_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };
        case SIGN_IN_ESIA_CONFIRM_SUCCEED:
        case SIGN_IN_ESIA_RESET_TOKEN:
            return {
                ...state,
                anonimToken: null,
            };
        default:
            return state;
    }
};
