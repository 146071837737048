import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    DEL_CHILD_REQUESTING,
    DEL_CHILD_SUCCEED,
    DEL_CHILD_FAILED,
} from '../../../constants';
import { errorHandle } from 'utils/utils';
import { getChildrens } from './getChildrens';

export const delChild = (childId?: string, cb?: Function, enqueueSnackbar?: Function) => (
    dispatch,
    getState
) => {
    const dispatchData = (actionType, requestStatus, errors) => {
        dispatch({
            type: actionType,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(DEL_CHILD_REQUESTING, null, null);

    return axios({
        method: 'DELETE',
        url: '/api/Profile/Child/Delete',
        headers: getCommonHeaders(getState()),
        params: {
            childId: childId,
        },
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                dispatch(getChildrens());
                dispatchData(DEL_CHILD_SUCCEED, requestStatus, error);
                cb();
            }
            if (isSuccess === false) {
                dispatchData(DEL_CHILD_FAILED, requestStatus, error);
                enqueueSnackbar();
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);
            dispatchData(DEL_CHILD_FAILED, null, error.error);
            enqueueSnackbar();
        });
};
