import React, { FC, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';
import map from 'lodash/map';
import groupBy from 'lodash/groupBy';
import { useTranslation } from 'react-i18next';
import { Button, CircularProgress } from '@material-ui/core';
import { makeStyles, Theme } from '@material-ui/core/styles';

import { ProfileGroupsField } from 'components/organisms';
import { updateContacts } from 'store/actions';
import { RootState } from 'store/store';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    container: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    saveButton: {
        marginTop: 60,
        padding: '13px 28px',
    },
    form: {
        width: '100%',
    },
}));
interface ContactsProps {
    preparedContacts: any;
}
export const Contacts: FC<ContactsProps> = ({ preparedContacts }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const contactsState = useSelector((state: RootState) => state.profile.contacts);
    const [contactsByGroup, setcontactsByGroup] = useState(null);
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>();

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };

    const submit = React.useCallback(
        formData => {
            dispatch(updateContacts(formData));
        },
        [dispatch]
    );

    useEffect(() => {
        if (preparedContacts) {
            setcontactsByGroup(groupBy(preparedContacts, contact => contact.group));
        }
    }, [preparedContacts]);

    return (
        <>
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
                {contactsByGroup &&
                    map(contactsByGroup, (value, key) => {
                        return (
                            <ProfileGroupsField
                                fields={value}
                                groupName={''}
                                formProps={formProps}
                                key={`contact_${key}`}
                            />
                        );
                    })}

                <Button
                    className={classes.saveButton}
                    startIcon={
                        contactsState.request.isLoading && <CircularProgress size={24} />
                    }
                    disableElevation
                    type="submit"
                    variant="contained"
                >
                    {t('actions.save')}
                </Button>
            </form>
        </>
    );
};
