import { ProfileShortState } from 'types/Profile';
import {
    GET_BASIC_REQUESTING,
    GET_BASIC_SUCCEED,
    GET_BASIC_FAILED,
} from '../../constants';

const initialState: ProfileShortState = {
    info: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const basic = (state = initialState, action) => {
    switch (action.type) {
        case GET_BASIC_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };
        case GET_BASIC_SUCCEED:
            return {
                ...state,
                info: action.info,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case GET_BASIC_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };
        default:
            return state;
    }
};
