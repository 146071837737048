import React from 'react';
import { NavLink, useHistory } from 'react-router-dom';
import { useSelector } from 'react-redux';
import map from 'lodash/map';
import some from 'lodash/some';
import cx from 'classnames';
import { useTranslation } from 'react-i18next';
import { get_services_07_04_2021 } from 'store/features.constants';

import {
    Collapse,
    Divider,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    makeStyles,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { FeatureToggle } from 'components/common/FeatureToggle';
import { Icon } from 'components/atoms';
import { RootState } from 'store/store';

const useStyles = makeStyles(theme => ({
    root: {
        width: '100%',
    },
    nested: {
        borderRadius: 12,
        fontSize: 14,
        lineHeight: 1.71,
        fontWeight: 'normal',
        maxHeight: 48,
        paddingTop: 12,
        paddingBottom: 12,
        paddingLeft: 48,
        '&.activeMenuItem': {
            backgroundColor: '#EBF1FF',
            fontWeight: 'bold',
        },
        '&.activeMenuItem.mobileMenu': {
            backgroundColor: 'transparent',
            fontWeight: 'normal',
            color: theme.palette.primary.main,
        },
        '&.mobileMenu': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    listItem: {
        borderRadius: 12,
        fontSize: 16,
        lineHeight: 1.5,
        fontWeight: 'normal',
        paddingTop: 16,
        paddingBottom: 16,
        maxHeight: 56,
        '&.activeMenuItem': {
            backgroundColor: '#EBF1FF',
            fontWeight: 'bold',
        },
        '&.activeMenuItem.mobileMenu': {
            backgroundColor: 'transparent',
            fontWeight: 'normal',
            color: theme.palette.primary.main,
        },
        '&.mobileMenu': {
            paddingLeft: 0,
            paddingRight: 0,
        },
    },
    listItemIcon: {
        minWidth: 32,
    },
    divider: {
        width: '100vw',
        left: '50%',
        marginLeft: '-50vw',
        position: 'relative',
    },
}));

interface AppNavigationItemProps {
    withIcon?: boolean;
    icon?: React.ReactNode;
    title: string;
    href?: string;
    childRoutes?: any[];
    mobileMenu?: boolean;
}

const AppNavigationItem: React.FC<AppNavigationItemProps> = ({
    withIcon,
    icon,
    title,
    href,
    childRoutes,
    mobileMenu,
}) => {
    let history = useHistory();
    const classes = useStyles();

    const [open, setOpen] = React.useState(false);
    const { t } = useTranslation();
    const handleClick = () => {
        setOpen(!open);
    };

    React.useEffect(() => {
        if (some(childRoutes, ({ href }) => href === history.location.pathname)) {
            setOpen(true);
        }
    }, [history]);
    return (
        <>
            {childRoutes ? (
                <ListItem
                    button
                    onClick={handleClick}
                    className={cx(classes.listItem, { mobileMenu: mobileMenu })}
                >
                    {withIcon && (
                        <ListItemIcon className={classes.listItemIcon}>
                            {icon}
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={t(title)}
                        primaryTypographyProps={{ variant: 'inherit' }}
                    />
                    {open ? <ExpandLess /> : <ExpandMore />}
                </ListItem>
            ) : (
                <ListItem
                    button
                    component={NavLink}
                    to={href}
                    className={cx(classes.listItem, { mobileMenu: mobileMenu })}
                    activeClassName={'activeMenuItem'}
                >
                    {withIcon && (
                        <ListItemIcon className={classes.listItemIcon}>
                            {icon}
                        </ListItemIcon>
                    )}
                    <ListItemText
                        primary={t(title)}
                        primaryTypographyProps={{ variant: 'inherit' }}
                    />
                </ListItem>
            )}

            {mobileMenu && <Divider variant="fullWidth" className={classes.divider} />}
            {childRoutes && (
                <Collapse in={open} timeout="auto" unmountOnExit>
                    <List component="div" disablePadding>
                        {map(childRoutes, ({ title, href }) => (
                            <ListItem
                                key={href}
                                button
                                component={NavLink}
                                to={href}
                                className={cx(classes.nested, { mobileMenu: mobileMenu })}
                                activeClassName="activeMenuItem"
                            >
                                <ListItemText
                                    primary={t(title)}
                                    primaryTypographyProps={{ variant: 'inherit' }}
                                />
                            </ListItem>
                        ))}
                    </List>
                </Collapse>
            )}
        </>
    );
};
const menuItems = [
    {
        title: 'nav.bot',
        href: '/bot',
        privateRoute: false,
        childRoutes: null,
        icon: <Icon name="navigationHeart" />,
        featureToggle: null,
    },
    {
        title: 'nav.home',
        href: '/home',
        privateRoute: false,
        childRoutes: null,
        icon: <Icon name="navigationFile" />,
        featureToggle: null,
    },
    {
        title: 'nav.services',
        href: '/services',
        privateRoute: false,
        childRoutes: null,
        icon: <Icon name="unionBurger" />,
        featureToggle: get_services_07_04_2021,
    },
    {
        title: 'nav.finance',
        href: '/finance',
        privateRoute: true,
        childRoutes: null,
        icon: <Icon name="navigationFinance" />,
        featureToggle: null,
    },
    {
        title: 'nav.profile',
        href: '/profile',
        privateRoute: true,
        icon: <Icon name="navigationProfile" />,
        featureToggle: null,
        childRoutes: [
            {
                title: 'nav.childRoutes.personal',
                href: '/profile/personal',
            },
            {
                title: 'nav.childRoutes.contacts',
                href: '/profile/contacts',
            },
            {
                title: 'nav.childRoutes.child',
                href: '/profile/child',
            },
            {
                title: 'nav.childRoutes.family',
                href: '/profile/family',
            },
        ],
    },
];
interface AppNavigationProps {
    mobileMenu?: boolean;
}
export const AppNavigation: React.FC<AppNavigationProps> = ({ mobileMenu }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const isAuthorized = !!useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );
    return (
        <List
            component="nav"
            aria-labelledby="nested-list-subheader"
            className={classes.root}
            disablePadding
        >
            {map(menuItems, (item, index) => {
                const {
                    title,
                    href,
                    icon,
                    childRoutes,
                    privateRoute,
                    featureToggle,
                } = item;
                if (privateRoute && !isAuthorized) {
                    return null;
                }
                return (
                    <FeatureToggle name={featureToggle} key={href}>
                        <AppNavigationItem
                            title={title}
                            href={href}
                            icon={icon}
                            childRoutes={childRoutes}
                            withIcon={!isViewXS}
                            mobileMenu={mobileMenu}
                        />
                    </FeatureToggle>
                );
            })}
        </List>
    );
};
