import isEmpty from 'lodash/isEmpty';
import XRegExp from 'xregexp';

export default function stringToRegEx(regexIn: string, flags: string = 'g') {
    if (isEmpty(regexIn)) return null;
    if (regexIn.includes('w')) return null;
    let regex;
    try {
        regex = XRegExp(regexIn, flags);
    } catch {
        console.log('Ошибка регулярки stringToRegEx');
    }
    return regex;
}
