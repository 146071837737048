import { OPEN_MODAL, CLOSE_MODAL } from '../../constants';

const modalInitState = {
    visible: false,
    modalName: null,
    title: 'Modal window',
};

export const modal = (state = modalInitState, action) => {
    switch (action.type) {
        case OPEN_MODAL:
            return {
                ...state,
                visible: true,
                modalName: action.modalName,
                title: action.title,
            };
        case CLOSE_MODAL:
            return {
                ...state,
                visible: false,
                modalName: null,
                test: null,
            };
        default:
            return state;
    }
};
