import Fingerprint2 from 'fingerprintjs2';

/** Конфиг */
const options = {
    excludes: {
        userAgent: true,
    },
};

/** Генерирует уникальный клиентский токен устройства */
const fingerPrint = () => {
    return Fingerprint2.getPromise({ options }).then(components => {
        const values = components.map(component => {
            return component.value;
        });
        return Fingerprint2.x64hash128(values.join(''), 31);
    });
};

export default fingerPrint;
