import React from 'react';
import classnames from 'classnames';

import { ReactComponent as arrow } from 'assets/icons/arrow.svg';
import { ReactComponent as chat } from 'assets/icons/chat.svg';
import { ReactComponent as settings } from 'assets/icons/settings.svg';
import { ReactComponent as logout } from 'assets/icons/logout.svg';
import { ReactComponent as link } from 'assets/icons/link.svg';
import { ReactComponent as ministryLogo } from 'assets/icons/ministryLogo.svg';
import { ReactComponent as cctLogo } from 'assets/icons/cctLogo.svg';
import { ReactComponent as send } from 'assets/icons/send.svg';
import { ReactComponent as attach } from 'assets/icons/attach.svg';
import { ReactComponent as mir } from 'assets/icons/mir.svg';
import { ReactComponent as exit } from 'assets/icons/exit.svg';
import { ReactComponent as exitMin } from 'assets/icons/exitMin.svg';
import { ReactComponent as search } from 'assets/icons/search.svg';
import { ReactComponent as personal } from 'assets/icons/personal.svg';
import { ReactComponent as contacts } from 'assets/icons/contacts.svg';
import { ReactComponent as child } from 'assets/icons/child.svg';
import { ReactComponent as info } from 'assets/icons/info.svg';
import { ReactComponent as safety } from 'assets/icons/safety.svg';
import { ReactComponent as intercom } from 'assets/icons/intercom.svg';
import { ReactComponent as home } from 'assets/icons/home.svg';
import { ReactComponent as gas } from 'assets/icons/gas.svg';
import { ReactComponent as electricity } from 'assets/icons/electricity.svg';
import { ReactComponent as calc } from 'assets/icons/calc.svg';
import { ReactComponent as infoGreen } from 'assets/icons/infoGreen.svg';
import { ReactComponent as documents } from 'assets/icons/documents.svg';
import { ReactComponent as user } from 'assets/icons/user.svg';
import { ReactComponent as calendar } from 'assets/icons/calendar.svg';
import { ReactComponent as bank } from 'assets/icons/bank.svg';
import { ReactComponent as close } from 'assets/icons/close.svg';
import { ReactComponent as family } from 'assets/icons/family.svg';
import { ReactComponent as faq } from 'assets/icons/faq.svg';
import { ReactComponent as message } from 'assets/icons/message.svg';
import { ReactComponent as arrowRight } from 'assets/icons/arrowRight.svg';
import { ReactComponent as card } from 'assets/icons/card.svg';
import { ReactComponent as arrowLeft } from 'assets/icons/arrowLeft.svg';
import { ReactComponent as userpic } from 'assets/icons/userpic.svg';
import { ReactComponent as navigationFile } from 'assets/icons/navigationFile.svg';
import { ReactComponent as unionBurger } from 'assets/icons/union.svg';
import { ReactComponent as navigationFinance } from 'assets/icons/navigationFinance.svg';
import { ReactComponent as navigationHeart } from 'assets/icons/navigationHeart.svg';
import { ReactComponent as navigationProfile } from 'assets/icons/navigationProfile.svg';
import { ReactComponent as fileApplication } from 'assets/icons/fileApplication.svg';
import { ReactComponent as houseApplication } from 'assets/icons/houseApplication.svg';
import { ReactComponent as phoneApplication } from 'assets/icons/phoneApplication.svg';
import { ReactComponent as heartApplication } from 'assets/icons/heartApplication.svg';
import { ReactComponent as subsidyCalendar } from 'assets/icons/subsidyCalendar.svg';
import { ReactComponent as subsidyRuble } from 'assets/icons/subsidyRuble.svg';
import { ReactComponent as cardFinance } from 'assets/icons/cardFinance.svg';
import { ReactComponent as plusFinance } from 'assets/icons/plusFinance.svg';
import { ReactComponent as subsidyDetailDocument } from 'assets/icons/subsidyDetailDocument.svg';
import { ReactComponent as subsidyDetailInfo } from 'assets/icons/subsidyDetailInfo.svg';
import { ReactComponent as subsidyDetailDocument1 } from 'assets/icons/subsidyDetailDocument1.svg';
import { ReactComponent as subsidyDetailInfo1 } from 'assets/icons/subsidyDetailInfo1.svg';
import { ReactComponent as fileLoadError } from 'assets/icons/fileLoadError.svg';
import { ReactComponent as fileLoadSuccess } from 'assets/icons/fileLoadSuccess.svg';
import { ReactComponent as reload } from 'assets/icons/reload.svg';
import { ReactComponent as bin } from 'assets/icons/bin.svg';
import { ReactComponent as user2 } from 'assets/icons/user2.svg';
import { ReactComponent as arrowRight2 } from 'assets/icons/arrowRight2.svg';
import { ReactComponent as logout2 } from 'assets/icons/logout2.svg';
import { ReactComponent as anketaChildCircleBtn } from 'assets/icons/anketaChildCircleBtn.svg';
import { ReactComponent as oval } from 'assets/icons/oval.svg';
import { ReactComponent as editPancil } from 'assets/icons/edit_pancil.svg';
import { ReactComponent as crossDel } from 'assets/icons/cross_del.svg';

const icons = {
    arrow,
    chat,
    logout,
    settings,
    link,
    ministryLogo,
    cctLogo,
    send,
    attach,
    mir,
    exit,
    search,
    exitMin,
    personal,
    contacts,
    child,
    info,
    safety,
    intercom,
    home,
    gas,
    electricity,
    calc,
    documents,
    infoGreen,
    user,
    calendar,
    bank,
    close,
    family,
    faq,
    message,
    arrowRight,
    card,
    arrowLeft,
    userpic,
    navigationFile,
    navigationFinance,
    navigationHeart,
    navigationProfile,
    fileApplication,
    houseApplication,
    phoneApplication,
    heartApplication,
    subsidyCalendar,
    subsidyRuble,
    cardFinance,
    plusFinance,
    subsidyDetailInfo,
    subsidyDetailDocument,
    subsidyDetailDocument1,
    subsidyDetailInfo1,
    fileLoadError,
    fileLoadSuccess,
    reload,
    bin,
    user2,
    arrowRight2,
    unionBurger,
    logout2,
    anketaChildCircleBtn,
    oval,
    crossDel,
    editPancil,
};

interface IconProps {
    name: keyof typeof icons;
    onClick?: () => void;
    className?: string;
}

export const Icon: React.FC<IconProps> = React.forwardRef((props, ref) => {
    const FoundIcon = icons[props.name] as any;

    return (
        <FoundIcon
            className={classnames('my-icon', props.className)}
            svgRef={ref}
            onClick={props.onClick}
        />
    );
});
