import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import stringToRegEx from 'utils/stringToRegEx';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
    UploaderFormField,
    TextField,
} from 'components/atoms';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import some from 'lodash/some';
import get from 'lodash/get';
import {
    getMaskByType,
    getRegExByType,
    parseDateString,
    transformDateToServerString,
} from 'utils/utils';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

const validateUploaderFormField = isRequired => value => {
    if (!isRequired) {
        return true;
    }
    const inputValue = JSON.parse(value);
    if (isEmpty(inputValue.files)) {
        return false;
    }
    return true;
};

export const Files: React.FC<{
    field: ApplicationField;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, fieldName, error, isRequired }) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const formProps = useFormContext();

    let currentValue = field.currentValue ? JSON.parse(field.currentValue) : undefined;

    return (
        <UploaderFormField
            {...formProps}
            key={fieldName}
            label={field.fieldTitle}
            name={fieldName}
            buttonProps={{
                fullWidth: true,
                size: isViewXS ? 'small' : 'medium',
            }}
            defaultValue={currentValue}
            defaultFormValue={field.currentValue}
            textFieldProps={{
                fullWidth: true,
                size: isViewXS ? 'small' : 'medium',
            }}
            uploaderProps={{
                accept: '.jpeg, .jpg, .png, .pdf',
            }}
            validationRules={{
                required: isRequired,
                validate: validateUploaderFormField(isRequired),
            }}
            disabled={!field.canModify}
        />
    );
};
