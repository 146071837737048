import { axiosInstanse as axios } from 'infrastructure/RestClient';
import {
    SIGN_IN_ANONIM_REQUESTING,
    SIGN_IN_ANONIM_FAILED,
    SIGN_IN_ANONIM_SUCCEED,
} from '../../constants';
import { setAnonimToken } from 'utils/token';
import fingerPrint from 'utils/fingerPrint';

export const signinAnonim = () => async (dispatch, getState) => {
    const dispatchData = (actionType, deviceToken, requestStatus, token, errors) => {
        dispatch({
            type: actionType,
            deviceToken: deviceToken,
            token: token,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(SIGN_IN_ANONIM_REQUESTING, null, null, null, null);
    const deviceToken = await fingerPrint();
    return axios({
        method: 'GET',
        url: `/api/Auth/loginByDeviceId`,
        params: {
            deviceId: deviceToken,
        },
    })
        .then(response => {
            const {
                status,
                data: { success = false, error, result = {} } = {},
            } = response;
            const { token } = result;
            if (success === true) {
                setAnonimToken(token);
                dispatchData(SIGN_IN_ANONIM_SUCCEED, deviceToken, status, token, error);
            } else {
                dispatchData(SIGN_IN_ANONIM_FAILED, null, null, status, error);
            }
        })
        .catch(error => {
            dispatchData(SIGN_IN_ANONIM_FAILED, null, null, null, error.message);
        });
};
