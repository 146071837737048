// import anchorme from 'anchorme';
// import sanitizeHtml from 'sanitize-html';

/**
 * Экранирует теги в соответствии с настройками.
 * @param {string} text - входящая строка.
 * @returns {string} html;
 */
// const escapeTransform = text => {
//     return sanitizeHtml(text, {
//         allowedTags: [],
//         allowedAttributes: {},
//     });
// };

/**
 * Парсит ссылки из текста.
 * @param {string} text - входящая строка.
 * @returns {string} html с ссылками;
 */
// const linkTransform = (text) => {
//     return anchorme(text || '', {
//         attributes: [
//             function (urlObj) {
//                 if (urlObj.protocol !== 'mailto:') {
//                     return { name: 'target', value: 'blank' };
//                 }
//                 return {};
//             }
//         ]
//     });
// };

export const transforms = [
    // linkTransform,
    // escapeTransform,
];
