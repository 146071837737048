import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, makeStyles, Dialog } from '@material-ui/core';
import { RootState } from 'store/store';
import { ErrorMessage, Loading } from 'components/atoms';
import { getApplications } from 'store/actions/application';
import { useTranslation } from 'react-i18next';
import { ApplicationOrderSuccessForm } from './ApplicationOrderSuccessForm';
import findIndex from 'lodash/findIndex';

const usePageStyles = makeStyles(theme => ({
    root: {},
    pageContent: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        padding: 24,
    },
    pageGrid: {},
    dialogPaper: {
        backgroundColor: theme.palette.common.white,
    },
}));

interface Props {
    applicationSubmitSuccess: boolean;
    currentApplicationTitle: string;
}

export const ApplicationOrderSuccess: React.FC<Props> = ({
    applicationSubmitSuccess,
    currentApplicationTitle,
}) => {
    const classes = usePageStyles();
    const { t } = useTranslation();
    const applicationOrderError = useSelector(
        (state: RootState) => state.application.submitError
    );
    const applicationOrderSuccess = useSelector(
        (state: RootState) => state.application.applicationSent
    );
    const applicationOrderIsLoading = useSelector(
        (state: RootState) => state.application.submitLoading
    );
    const availableApplications = useSelector(
        (state: RootState) => state.application.availableApplications
    );
    const dispatch = useDispatch();
    const history = useHistory();

    const currentApplicationId = useSelector(
        (state: RootState) => state.application.currentApplicationId
    );

    const currentIndex = findIndex(availableApplications, [
        'requestId',
        currentApplicationId,
    ]);

    let nextApplicationId = null;
    if (availableApplications?.length > 1) {
        const nextIndex = (currentIndex + 1) % availableApplications.length;
        nextApplicationId = availableApplications[nextIndex].requestId;
    }

    const handleNextApplication = useCallback(() => {
        dispatch(getApplications());
        history.push('/application/' + nextApplicationId);
    }, []);

    /*if (applicationOrderError || !applicationOrderSuccess) {
        return (
            <>
                <ErrorMessage error={applicationOrderError || t('actions.error')} />
                <Button variant="contained" component={Link} to="/home">
                    {t('common.toHome')}
                </Button>
            </>
        );
    }*/
    return (
        <Dialog
            maxWidth="xs"
            open={applicationSubmitSuccess}
            scroll="body"
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <ApplicationOrderSuccessForm
                availableApplications={availableApplications}
                handleNextApplication={handleNextApplication}
                nextApplicationId={nextApplicationId}
                currentApplicationTitle={currentApplicationTitle}
            />
        </Dialog>
    );
};
