import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import successCardOrder from 'assets/images/successCardOrder.png';

import { MainLayout } from 'components/layouts';
import { RootState } from 'store/store';
import { ErrorMessage, Loading } from 'components/atoms';
import { CardOrderSuccesConfig } from 'types/CardOrder';
import { PageHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
    imageContainer: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    image: {
        width: 200,
        height: 170,
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        marginTop: 56,
        marginRight: 16,
    },
    title: {
        marginTop: 40,
        fontSize: 28,
        lineHeight: 1.29,
        fontWeight: 500,
    },
    paragraph: {
        marginTop: 32,
        color: theme.palette.grey[700],
        fontSize: 18,
        lineHeight: 1.56,
    },
}));

interface CardOrderSuccessFormProps {
    cardOrderSuccesConfig?: CardOrderSuccesConfig;
}

export const CardOrderSuccess: React.FC<CardOrderSuccessFormProps> = ({
    cardOrderSuccesConfig,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Box className={classes.imageContainer}>
                <img
                    alt={t('pages.CardOrder.draftHasSendToBank')}
                    src={successCardOrder}
                    className={classes.image}
                />
            </Box>
            <Typography className={classes.title}>
                {t('pages.CardOrder.applicationWasSubmittedToTheBank')}
            </Typography>
            <Grid item>
                <Typography className={classes.paragraph}>
                    {t('pages.CardOrder.youNeedToContactTheBank')}
                </Typography>
            </Grid>
            <Grid item>
                <Typography className={classes.paragraph}>
                    {t('pages.CardOrder.inCaseOfNonReceiptOfTheCard')}
                </Typography>
            </Grid>
            {cardOrderSuccesConfig ? (
                <>
                    <Button
                        variant="contained"
                        component={Link}
                        to={cardOrderSuccesConfig.url}
                        className={classes.button}
                    >
                        {cardOrderSuccesConfig.text}
                    </Button>
                </>
            ) : (
                <Button
                    variant="contained"
                    component={Link}
                    to="/home"
                    className={classes.button}
                >
                    {t('common.toHome')}
                </Button>
            )}
        </>
    );
};
