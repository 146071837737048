import isString from 'lodash/isString';
import moment from 'moment';
import every from 'lodash/every';
import isEmpty from 'lodash/isEmpty';

import { baseUrl } from 'config';
import { ProfileShort, Gender } from 'types/Profile';
import { ApiErrorConstruction } from 'types/ApiResponse';
import { SubsidyCategory, SubsidyStatus } from 'types/SubsidyManagementModel';
import { CardOrderStatus } from 'types/CardOrder';

export const detectMobile = () => {
    var check = false;
    (function (a) {
        if (
            // @ts-ignore
            /(android|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|mobile.+firefox|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows ce|xda|xiino/i.test(
                a
            ) ||
            // @ts-ignore
            /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(
                a.substr(0, 4)
            )
        )
            check = true;
    })(
        navigator.userAgent ||
            navigator.vendor ||
            // @ts-ignore
            window.opera
    );
    return check;
};

// export const isProd = process.env.NODE_ENV === 'production';

/** Возвращает первую букву названия получателя или null. */
export const getFirstChar = (name: string): string | null => {
    let firstChar = '';

    if (isString(name) && name.length) {
        firstChar = name.slice(0, 1).toUpperCase();
    }

    return firstChar;
};
/** Удаляет из строки все пробелы */
export function deleteSpaces(string: string): string {
    return string.replace(/\s+/gi, '');
}

export const pluralize = (number, one, two, five) => {
    let n = Math.abs(number);
    n %= 100;
    if (n >= 5 && n <= 20) {
        return five;
    }
    n %= 10;
    if (n === 1) {
        return one;
    }
    if (n >= 2 && n <= 4) {
        return two;
    }
    return five;
};

export function decodeSearchParams<T>(searchString): T {
    const searchParams = new URLSearchParams(searchString);
    const result = {};

    // @ts-ignore
    for (const [key, value] of searchParams.entries()) {
        result[key] = value;
    }

    return result as T;
}

export function saveAs(blob: Blob, fileName: string) {
    var url = window.URL.createObjectURL(blob);
    downloadUrl(url, fileName);
    // On Edge, revokeObjectURL should be called only after
    // a.click() has completed, atleast on EdgeHTML 15.15048
    setTimeout(function () {
        window.URL.revokeObjectURL(url);
    }, 1000);
}

export function downloadUrl(url: string, fileName: string) {
    var anchorElem = document.createElement('a');
    anchorElem.style.display = 'none';
    anchorElem.href = url;
    anchorElem.download = fileName;
    anchorElem.target = '_blank';

    document.body.appendChild(anchorElem);
    anchorElem.click();

    document.body.removeChild(anchorElem);
}

export const getIconUrl = relativeUrl => {
    if (!relativeUrl) {
        return '';
    }
    return `${baseUrl}${relativeUrl}`;
};

export const getUserInitials = (user: ProfileShort) => {
    let initials = ``;
    if (!user) return initials;
    if (user.lastName) {
        initials = user.lastName;
    }
    if (user.firstName) {
        initials = `${initials} ${user.firstName}`;
    }
    if (user.middleName) {
        initials = `${initials} ${getFirstChar(user.middleName)}.`;
    }
    return initials;
};

export const DATE_PATTERN = /(\d{2})\.(\d{2})\.(\d{4})/;
export const parseDateString = (date: any) => {
    if (!date) {
        return null;
    }
    if (typeof date === 'string') {
        return moment(new Date(date.replace(DATE_PATTERN, '$3-$2-$1')));
    }
    return date;
};

export const transformDateToServerString = (date: any) => {
    if (!date) {
        return null;
    }

    if (DATE_PATTERN.test(date)) {
        return date;
    }

    if (moment(date).isValid()) {
        return moment(date).format('DD.MM.YYYY');
    }
    return date;
};

export const constructRedirectUrl = () => {
    let redirectUrl = window.location.origin;
    if (window.location.pathname === '/') {
        redirectUrl = `${redirectUrl}/home`;
    } else {
        redirectUrl = `${redirectUrl}${window.location.pathname}`;
    }
    return redirectUrl;
};

export const getSpouseGender = (userGender?: Gender) => {
    if (!userGender) {
        return null;
    }
    if (userGender === 'Male') {
        return 'Female';
    }
    if (userGender === 'Female') {
        return 'Male';
    }
    return null;
};

export const maybeCallFn = (fn: any, ...args: any) => {
    return fn && typeof fn === 'function' && fn.apply(fn, args);
};

export function isExists(value) {
    return value !== undefined && value !== null;
}

export function safeArrayCheck(supposedArray) {
    return isExists(supposedArray) && Array.isArray(supposedArray);
}

export function toSearchParams(params) {
    const formatArray = (key, array) => {
        return array
            .map(value => {
                return key + encodeURIComponent('[]') + '=' + encodeURIComponent(value);
            })
            .join('&');
    };

    const formatObject = (key, object) => {
        return Object.keys(object)
            .map(innerKey => {
                const superInnerKey = `${key}${encodeURIComponent('[' + innerKey + ']')}`;

                if (Array.isArray(object[innerKey])) {
                    return formatArray(superInnerKey, object[innerKey]);
                } else if (typeof object[innerKey] === 'object') {
                    return formatObject(superInnerKey, object[innerKey]);
                } else {
                    return formatString(superInnerKey, object[innerKey]);
                }
            })
            .join('&');
    };

    const formatString = (key, value) => {
        return key + '=' + encodeURIComponent(value);
    };

    return Object.keys(params)
        .map(key => {
            if (Array.isArray(params[key])) {
                return formatArray(encodeURIComponent(key), params[key]);
            } else if (typeof params[key] === 'object') {
                return formatObject(encodeURIComponent(key), params[key]);
            } else {
                return formatString(encodeURIComponent(key), params[key]);
            }
        })
        .join('&');
}

export function cleanObject<T>(obj: object, removeEmptyArray?: boolean): T {
    const cleanedObject = {};

    Object.keys(obj).forEach(key => {
        if (removeEmptyArray === true) {
            if (safeArrayCheck(obj[key]) && obj[key].length === 0) {
                return;
            }
        }

        if (!isExists(obj[key])) {
            return;
        }

        cleanedObject[key] = obj[key];
    });

    return cleanedObject as T;
}

export function errorHandle(
    error: ApiErrorConstruction | any,
    defaultError = 'Внутренняя ошибка'
): ApiErrorConstruction {
    if (error && error.error) {
        return error;
    } else {
        return {
            error: defaultError,
            isHandled: false,
        };
    }
}

export function timeout(asyncFn, timeout = 7000, errorMessage = 'Ошибка ожидания') {
    return Promise.race([
        asyncFn,
        new Promise((_, reject) =>
            setTimeout(
                () =>
                    reject({
                        error: errorMessage,
                    }),
                timeout
            )
        ),
    ]);
}

export function unmaskedPhoneNumber(rawPhoneNumber): string {
    return rawPhoneNumber && rawPhoneNumber.replace(/[\D]+/gi, '');
}

export function getHostName(url: string): string {
    if (!isExists(url)) {
        return null;
    }

    var match = url.match(
        /^(?:https?:)?(?:\/\/)?(?:[^@\n]+@)?(?:www\.)?([^:\/\n\?\=]+)/i
    );

    if (
        match !== null &&
        match.length > 1 &&
        typeof match[1] === 'string' &&
        match[1].length > 0
    ) {
        return match[1];
    } else {
        return null;
    }
}

const russhanChars = new RegExp(/[А-я]/);
const englishChars = new RegExp(/[A-z]/);
const romanChars = new RegExp(/[LlCcDdMmXxIiVv]/);

export const getRegExByType = (type: string, defaultRegEx: string) => {
    switch (type) {
        case 'postIndex':
            break;
        case 'passportCode':
            break;
        case 'passportNumber':
            break;
        case 'passportSeries':
            break;
        case 'mobilePhone':

        case 'snils':
            break;
        case 'kidCertificateSeries':
            return '(?i)M{0,3}(D?C{0,3}|C[DM])(L?X{0,3}|X[LC])(V?I{0,3}|I[VX])[-]?[А-Я]{2}';

            break;
        case 'kidCertificateNumber':
            break;
        default:
            break;
    }
    return defaultRegEx;
};

export const getMaskByType = type => {
    let mask;
    switch (type) {
        case 'postIndex':
            mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            break;
        case 'passportCode':
            mask = [/\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];
            break;
        case 'passportNumber':
            mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            break;
        case 'passportSeries':
            mask = [/\d/, /\d/, /\d/, /\d/];
            break;
        case 'mobilePhone':
            mask = [
                '+',
                /[7]/,
                '(',
                /[1-9]/,
                /\d/,
                /\d/,
                ')',
                ' ',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                /\d/,
            ];
            break;
        case 'snils':
            mask = [
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                '-',
                /\d/,
                /\d/,
                /\d/,
                ' ',
                /\d/,
                /\d/,
            ];
            break;
        case 'kidCertificateSeries':
            mask = value => {
                // console.log(value);
                if (value && value.length) {
                    const firstGroup = [];
                    const secondGroup = [];
                    let tire = false;
                    for (let i = 0; i < value.length; i++) {
                        const char = value.charAt(i);
                        if (romanChars.test(char) && !tire) {
                            firstGroup.push(romanChars);
                        }
                        if (char == '-' && value.match(/[-]/g).length == 1) {
                            firstGroup.push('-');
                            tire = true;
                        }

                        if (russhanChars.test(char)) {
                            if (!tire) {
                                firstGroup.push('-');
                                tire = true;
                            }
                            secondGroup.push(russhanChars);
                        }
                    }
                    if (!isEmpty(secondGroup)) {
                        return [...firstGroup.slice(0, 7), ...secondGroup.slice(0, 2)];
                    }
                    return [...firstGroup.slice(0, 7)];
                }
                return [/[a-zA-Z]/, /[a-zA-Z]/, '-', /[А-Яа-я]/, /[А-Яа-я]/];
            };
            break;
        case 'kidCertificateNumber':
            mask = [/\d/, /\d/, /\d/, /\d/, /\d/, /\d/];
            break;
        default:
            break;
    }
    return mask;
};

export const getColorBySubsidyCategory = (category: SubsidyCategory) => {
    let backgroundColor = '';
    let borderColor = '';
    switch (category) {
        // Транспорт
        case 0:
            backgroundColor = '#FCF6ED';
            borderColor = '#FFC976';

            break;
        // Дом
        case 1:
            backgroundColor = '#EDFCEE';
            borderColor = '#82DF94';

            break;
        // Медицина
        case 2:
            backgroundColor = '#F0F9FF';
            borderColor = '#8CC9FF';

            break;
        // Дети
        case 3:
            backgroundColor = '#FFF1FA';
            borderColor = '#FF8AB1';

            break;
        // Образование
        case 4:
            backgroundColor = '#F8F2FF';
            borderColor = '#BA89FF';

            break;
        // Информационные
        case 5:
            backgroundColor = '#FFEFEA';
            borderColor = '#FF9A85';

            break;
        // Радиация
        case 6:
            backgroundColor = '#E9FBF6';
            borderColor = '#7ADEC4';

            break;
        // ??
        case 7:
            backgroundColor = '#F5F8DF';
            borderColor = '#BDDB48';

            break;

        default:
            break;
    }
    return {
        backgroundColor,
        borderColor,
    };
};

export const getColorBySubsidyStatus = (status: SubsidyStatus) => {
    let backgroundColor = '';
    let tagTextColor = '';
    let textColor = '#222222';
    let amountColor = '#474D5F';
    let iconUser2Color = '#9f9f9f';
    switch (status) {
        // Черновик
        case 0:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#1690EB';

            break;
        // Сформировано
        case 1:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#1690EB';

            break;
        // Отправлено
        case 2:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#00AA7E';

            break;
        // Не зарегистрировано
        case 3:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#EB4916';

            break;
        // Заявление поступило
        case 4:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#1690EB';

            break;
        // Принято к рассмотрению
        case 5:
            backgroundColor = `radial-gradient(29.9% 70.94% at 44.25% 86.96%, rgba(166, 229, 106, 0.5) 0%, rgba(128, 216, 44, 0) 100%),
            linear-gradient(225.68deg, rgba(0, 199, 51, 0) -5.8%, rgba(38, 190, 76, 0.71) 41.7%, rgba(82, 207, 114, 0) 89.02%),
            radial-gradient(89.88% 210.61% at 77.85% -98.27%, #A6E56A 0%, #A6E56A 34.46%, #A6E56A 36.2%, #7CFF00 69.5%, rgba(166, 229, 106, 0) 100%), #25BE4C`;
            tagTextColor = '#1690EB';
            textColor = '#FFFFFF';
            amountColor = '#FFFFFF';
            iconUser2Color = '#FFFFFF';
            break;
        // Решение \"Отказать\" сформировано
        case 6:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#EB4916';

            break;
        // Решение \"Отказать\" подписано
        case 7:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#EB4916';

            break;
        // Решение \"Предоставить\" сформировано
        case 8:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#00AA7E';

            break;
        // Решение \"Предоставить\" подписано
        case 9:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#00AA7E';

            break;
        // Неполные данные
        case 10:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#1690EB';

            break;
        // Решение принято
        case 11:
            backgroundColor = '#F5F5F5';
            tagTextColor = '#00AA7E';

            break;

        default:
            break;
    }
    return {
        backgroundColor,
        textColor,
        tagTextColor,
        amountColor,
        iconUser2Color,
    };
};

export const getColorByGender = (gender: Gender) => {
    let backgroundColor = '#F5F5F5';
    switch (gender) {
        case 'Male':
            backgroundColor = '#EEF7FF';

            break;
        case 'Female':
            backgroundColor = '#FFF2F7';

            break;
        default:
            break;
    }
    return {
        backgroundColor,
    };
};

export const getTextByCardOrderStatus = (status: CardOrderStatus): string => {
    let statusText = '';
    switch (status) {
        case 1:
            statusText = 'Черновик';
            break;
        case 2:
            statusText = 'Заявка сформирована';

            break;
        case 4:
            statusText = 'Заявка принята';

            break;
        case 5:
            statusText = 'Заявка обрабатывается';

            break;
        case 6:
            statusText = 'Заявка отклонена';

            break;
        case 7:
            statusText = 'Заявка принята';

            break;
        case 8:
            statusText = 'Данные карты получены';

            break;
        case 17:
            statusText = 'Карта ожидает получения';

            break;
        case 18:
            statusText = 'Требуется корректировка данных';

            break;

        default:
            break;
    }
    return statusText;
};

export const canCardOrder = (cardOrders, HandledByZabota = true) => {
    return (
        isEmpty(cardOrders) ||
        every(cardOrders, cardOrder => {
            const status = cardOrder.status.status;
            if (CardOrderStatus.Rejected === status) return true;
            if (CardOrderStatus.HandledByZabota === status && HandledByZabota)
                return true;
        })
    );
};
