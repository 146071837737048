import React, { FC } from 'react';

import moment from 'moment';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';

import { Bubble } from 'components/atoms';
import { Message } from 'types/Chat';

import { useCommonStyles } from '../.././Chat.styles';
import IncomingMessage from './IncomingMessage';

const useStylesMessageComponent = makeStyles(() => ({
    messageContainer: {
        display: 'flex',
    },
    chatAvatar: {
        width: 32,
        height: 32,
        marginBottom: 6,
    },
    chatTime: {
        fontSize: 11,
        lineHeight: '13px',
        color: '#474D5F',
    },
    bubbleContainer: {
        // marginLeft: 12,
        display: 'flex',
        flexWrap: 'wrap',
        flexDirection: 'column',
        width: '100%',
    },
    clientMessage: {
        wordBreak: 'break-word',
    },
}));

interface MessageComponentProps {
    message: Message;
    lastMessage?: Message;
}

const MessageComponent: FC<MessageComponentProps> = ({ message, lastMessage }) => {
    const classes = useStylesMessageComponent();
    const commonClasses = useCommonStyles();

    return (
        <Box className={classes.messageContainer}>
            <Box className={classes.bubbleContainer}>
                {message.isClientMessage ? (
                    <Bubble
                        variant="outgoing"
                        time={moment(message.createDateTime).format('HH:mm')}
                        key={`client_message_${message.id}`}
                        className={commonClasses.bubble}
                    >
                        <p className={classes.clientMessage}>{message.message.Value}</p>
                    </Bubble>
                ) : (
                    <IncomingMessage
                        message={message}
                        lastMessage={lastMessage}
                        key={`incoming_message_${message.id}`}
                    />
                )}
            </Box>
        </Box>
    );
};

export default MessageComponent;
