import { hot } from 'react-hot-loader';
import React, { Suspense, useEffect, useState } from 'react';
import { SnackbarProvider } from 'notistack';
import { ParallaxProvider } from 'react-scroll-parallax';
import { LoadingElement } from 'components/atoms';

import { detectMobile } from 'utils/utils';
import { AddCardDialodProvider } from 'hooks/useAddCardDialog';
import { SubsidyCalculatorDrawerProvider } from 'hooks/useSubsidyCalculatorDrawer';
import { DialogProvider } from 'hooks/useDialog';
import { CardOrderDialogProvider } from 'hooks/useCardOrderDialog';
import { FeatureTogglesProvider } from 'hooks/useFeatures';
import { MessagesProvider } from 'components/common/Messages';
import { Dialog } from 'components/molecules/Dialog';
import { Routes } from '../routing/routes';
require('utils/fakeLogin');

const App = () => {
    const [isMobile, setIsMobile] = useState(false);

    useEffect(() => {
        setIsMobile(detectMobile());
    }, []);

    return (
        <Suspense fallback={LoadingElement}>
            <ParallaxProvider>
                <FeatureTogglesProvider>
                    <DialogProvider>
                        <SnackbarProvider
                            maxSnack={3}
                            anchorOrigin={
                                isMobile
                                    ? {
                                          vertical: 'bottom',
                                          horizontal: 'center',
                                      }
                                    : { vertical: 'bottom', horizontal: 'left' }
                            }
                        >
                            <CardOrderDialogProvider>
                                <AddCardDialodProvider>
                                    <SubsidyCalculatorDrawerProvider>
                                        <MessagesProvider>
                                            <Routes />
                                        </MessagesProvider>
                                    </SubsidyCalculatorDrawerProvider>
                                </AddCardDialodProvider>
                            </CardOrderDialogProvider>
                        </SnackbarProvider>
                    </DialogProvider>
                </FeatureTogglesProvider>
            </ParallaxProvider>
        </Suspense>
    );
};

export default hot(module)(App);
