import React, { FC, useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import every from 'lodash/every';

import { setCardOrderSuccesConfig } from 'store/slices/cardOrder';
import { CardOrderStatus, CardOrderSuccesConfig } from 'types/CardOrder';
import { AlertDialog } from 'components/molecules/AlertDialog';
import { ConfirmDialog } from 'components/molecules/ConfirmDialog';
import { RootState } from 'store/store';
import { useFeatures } from 'hooks/useFeatures';
import { msp_select_cards_18_05_2021 } from 'store/features.constants';

interface CardOrderDialogProps {
    unallowedCardOrder: boolean;
    hasLinkedCard: boolean;
    open: boolean;
    onClickOpen: VoidFunction;
    onClose: VoidFunction;
    onCardOrder: VoidFunction;
}

export const CardOrderDialog: FC<CardOrderDialogProps> = ({
    unallowedCardOrder,
    hasLinkedCard,
    open,
    onClickOpen,
    onClose,
    onCardOrder,
}) => {
    if (unallowedCardOrder) {
        return (
            <AlertDialog
                open={open}
                onClickOpen={onClickOpen}
                onClose={onClose}
                dialogText="У вас уже есть активная заявка. Для отправки новой необходимо закрыть текущий договор. Обратитесь в банк"
            />
        );
    }

    if (hasLinkedCard) {
        return (
            <ConfirmDialog
                open={open}
                onClickOpen={onClickOpen}
                onClose={onClose}
                onConfirm={onCardOrder}
                dialogText="Добавить можно только одну карту МИР. Хотите удалить карту и заказать новую?"
            />
        );
    }

    return null;
};

const CardOrderDialogContext = React.createContext({
    onOpen: (cardOrderSuccesConfig: CardOrderSuccesConfig) => {},
    onClose: () => {},
});

export const useCardOrderDialog = () => {
    return React.useContext(CardOrderDialogContext);
};

export const CardOrderDialogProvider: FC = ({ children }) => {
    const dispatch = useDispatch();
    const history = useHistory();

    const cardInfoState = useSelector((state: RootState) => state.wallet.cardInfo);
    const cardOrders = useSelector((state: RootState) => state.cardOrder.cardOrders);

    const [open, setOpen] = React.useState(false);

    const hasLinkedCard = useMemo(() => !isEmpty(cardInfoState.cardInfo), [
        cardInfoState.cardInfo,
    ]);
    const unallowedCardOrder = useMemo(() => {
        return (
            !isEmpty(cardOrders) &&
            every(
                cardOrders,
                cardOrder => cardOrder.status.status === CardOrderStatus.HandledByZabota
            )
        );
    }, [cardOrders]);

    const findContext = useFeatures();
    const mspSelectCardsFeatureAvailable = useMemo(
        () => findContext(msp_select_cards_18_05_2021),
        [findContext]
    );

    const handleOpenDialog = useCallback(() => {
        setOpen(true);
    }, []);

    const handleCloseDialog = useCallback(() => {
        setOpen(false);
    }, []);

    const onClose = useCallback(() => {
        handleCloseDialog();
    }, [handleCloseDialog]);

    const handleOrderCard = useCallback(() => {
        handleCloseDialog();
        history.push('/cardOrder');
    }, [handleCloseDialog, history]);

    const onOpen = useCallback(
        (cardOrderSuccesConfig: CardOrderSuccesConfig) => {
            dispatch(setCardOrderSuccesConfig({ cardOrderSuccesConfig }));
            if (
                !mspSelectCardsFeatureAvailable &&
                (hasLinkedCard || unallowedCardOrder)
            ) {
                handleOpenDialog();
            } else {
                handleOrderCard();
            }
        },
        [
            dispatch,
            mspSelectCardsFeatureAvailable,
            hasLinkedCard,
            unallowedCardOrder,
            handleOpenDialog,
            handleOrderCard,
        ]
    );

    return (
        <>
            <CardOrderDialog
                open={open}
                onClickOpen={handleOpenDialog}
                onClose={handleCloseDialog}
                onCardOrder={handleOrderCard}
                unallowedCardOrder={unallowedCardOrder}
                hasLinkedCard={hasLinkedCard}
            />
            <CardOrderDialogContext.Provider
                value={{
                    onClose,
                    onOpen,
                }}
            >
                {children}
            </CardOrderDialogContext.Provider>
        </>
    );
};
