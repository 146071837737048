import React from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/find';
import cx from 'classnames';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';
import { Box, makeStyles, Theme, Typography, Grid } from '@material-ui/core';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import { useTranslation } from 'react-i18next';
import { DynamicField } from './DynamicField';
import { Icon } from 'components/atoms';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';

const useStyles = makeStyles((theme: Theme) => ({
    group: {
        '& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 32,
        },
    },
    groupTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        },
        fontWeight: 500,
        fontSize: 20,
    },
    groupSubtitle: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 8,
            fontSize: 15,
        },
        color: theme.palette.grey[600],
        marginTop: 16,
        fontSize: 18,
        flex: 1,
    },
    fieldGroup: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 24,
            marginRight: 0,
        },
        marginBottom: 24,
        marginRight: -32,
    },
    groupIcon: {
        marginRight: 16,
    },
    groupHeader: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
        display: 'flex',
        alignItems: 'flex-start',
        flexDirection: 'column',
        marginBottom: 24,
        position: 'relative',
    },
    superCategoryHeader: {
        fontWeight: 'bold',
    },
    superCategoryIcon: {
        position: 'absolute',
        left: '-27.5px',
        top: '6px',
    },
}));

interface ApplicationGroupIconProps {
    categoryType?: string;
}

const ApplicationGroupIcon: React.FC<ApplicationGroupIconProps> = ({ categoryType }) => {
    const classes = useStyles();
    const getGroupIcon = () => {
        let icon = null;
        switch (categoryType) {
            case 'passport':
            case 'birth_certificate':
                icon = <Icon name="fileApplication" className={classes.groupIcon} />;
                break;
            case 'registationAddress':
            case 'legal_address':
            case 'child_birth_place':
                icon = <Icon name="houseApplication" className={classes.groupIcon} />;
                break;
            case 'contacts':
                icon = <Icon name="phoneApplication" className={classes.groupIcon} />;
                break;
            case 'spouse':
                icon = <Icon name="heartApplication" className={classes.groupIcon} />;
                break;
            default:
                break;
        }
        return icon;
    };
    return <>{getGroupIcon()}</>;
};

interface ApplicationGroupSubtitleProps {
    categoryType?: string;
}

export const ApplicationGroupSubtitle: React.FC<ApplicationGroupSubtitleProps> = ({
    categoryType,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const getGroupSubtitle = () => {
        let subtitle = '';
        switch (categoryType) {
            case 'common':
                subtitle = t('com.ApplicationGroupSubtitle.common');
                break;
            // case 'passport':
            // case 'birth_certificate':
            //     subtitle = <Icon name="fileApplication" className={classes.groupIcon} />;
            //     break;
            // case 'registationAddress':
            // case 'legal_address':
            case 'contacts':
                subtitle = t('com.ApplicationGroupSubtitle.contacts');
                break;
            case 'guardianship_address':
                //   subtitle = t('com.ApplicationGroupSubtitle.guardianshipAddress');
                break;
            //  case 'employer_information':
            //     subtitle = t('com.ApplicationGroupSubtitle.employerInformation');
            //    break;
            // case 'spouse':
            //     subtitle = <Icon name="heartApplication" className={classes.groupIcon} />;
            //     break;
            default:
                break;
        }
        return subtitle;
    };
    return (
        <>
            {!!getGroupSubtitle() && (
                <Typography variant="body1" className={classes.groupSubtitle}>
                    {getGroupSubtitle()}
                </Typography>
            )}
        </>
    );
};

interface ApplicationGroupHeaderProps {
    categoryName: string;
    categoryType?: string;
    className?: string;
    isSuperCategory: boolean;
}

export const ApplicationGroupHeader: React.FC<ApplicationGroupHeaderProps> = ({
    categoryName,
    categoryType,
    className,
    isSuperCategory,
}) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.groupHeader, className)}>
            <Box display="flex" alignItems="center">
                {isSuperCategory ? (
                    <Icon name="oval" className={classes.superCategoryIcon} />
                ) : (
                    <ApplicationGroupIcon categoryType={categoryType} />
                )}
                <Typography
                    className={cx(classes.groupTitle, {
                        [classes.superCategoryHeader]: isSuperCategory,
                    })}
                >
                    {categoryName}
                </Typography>
            </Box>
            <ApplicationGroupSubtitle categoryType={categoryType} />
        </Box>
    );
};

interface ApplicationGroupsFieldProps {
    categoryName: string;
    categoryType?: string;
    categoryId?: number;
    fields: ApplicationField[];
    filedsPath: string; //Имя для правильной структуры submitData в форме
    isSuperCategory: boolean;
}

export const ApplicationGroupsField: React.FC<ApplicationGroupsFieldProps> = ({
    categoryName,
    categoryType,
    categoryId,
    fields,
    isSuperCategory,
    filedsPath,
}) => {
    const classes = useStyles();

    const formProps = useFormContext();
    const { errors, register, watch } = formProps;
    const categoryFields = fields.filter(c => c.criterionFieldsCategoryId === categoryId);

    const applicationSubmitFields = useSelector(
        (state: RootState) => state.application.submitData
    );

    if (isEmpty(categoryFields)) return null;
    //Было нужно спросить зачем
    /*const sortedFields =
        categoryType === 'documents'
            ? sortBy(categoryFields, 'fieldType')
            : categoryFields;*/

    //Мапились изначально sortedFields
    const renderVisibleFields = map(categoryFields, (field, index) => {
        let fieldName = `${filedsPath}.${field.fieldId}`;

        //Редактирование уже имеющегося значения (при возврате шага)
        const cv = get(applicationSubmitFields, fieldName);
        if (cv) {
            field = cloneDeep(field);
            field.currentValue = cv;
        }

        if (checkBindingVisibleValues(field, watch, filedsPath)) return null;

        const isRequired =
            checkBindingRequiredValues(field, watch, filedsPath) || field.isRequired;

        const error = Boolean(get(errors, fieldName, false));
        return (
            <DynamicField
                field={field}
                isRequired={isRequired}
                fieldName={fieldName}
                error={error}
                key={`DynamicField-${filedsPath}-${index}-${categoryType}-${fieldName}`}
            />
        );
    });

    //Чтобы пустых категорий, где все поля скрыты, не было
    if (renderVisibleFields.every(item => item === null)) return null;

    return (
        <Box className={classes.group}>
            {categoryName && (
                <ApplicationGroupHeader
                    categoryName={categoryName}
                    categoryType={categoryType}
                    isSuperCategory={isSuperCategory}
                />
            )}
            <Grid container className={classes.fieldGroup}>
                {renderVisibleFields}
            </Grid>
        </Box>
    );
};
