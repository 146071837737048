import React, { useMemo } from 'react';
import { SelectFormField } from 'components/atoms';
import { SelectionOption } from 'components/atoms/Select/Select';
import Typography from '@material-ui/core/Typography';
import { Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import get from 'lodash/get';
import { FormFieldProps } from 'types/FormField';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        paddingBottom: 24,
    },
}));

interface Props {
    userCards: any;
    formProps: FormFieldProps;
}

const SelectCard: React.FC<Props> = ({ userCards, formProps }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const userCardsOptions: SelectionOption[] = useMemo(() => {
        return userCards.map(card => ({
            name: card.maskPan,
            value: card.id,
        }));
    }, [userCards]);
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Box>
            <Typography variant="body1" className={classes.title}>
                {t('pages.Application.chooseCard')}
            </Typography>
            <SelectFormField
                {...formProps}
                label={'Выберите карту'}
                name={'data.selected_card'}
                options={userCardsOptions}
                autoComplete="off"
                defaultValue={
                    formProps.getValues('data.selected_card') ||
                    userCardsOptions[0]?.value
                }
                fullWidth={isViewXS}
                validationRules={{ required: true }}
                variant="outlined"
                error={Boolean(get(formProps.errors, 'data.selected_card', false))}
                formControlProps={{
                    size: isViewXS ? 'small' : 'medium',
                }}
            />
        </Box>
    );
};

export default SelectCard;
