import React from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldProps, SubmitFieldValidationRules } from 'types/FormField';

import Autocomplete, { AutocompleteProps } from './Autocomplete';

type Props = {
    label: string;
    validationRules?: SubmitFieldValidationRules;
} & FormFieldProps &
    AutocompleteProps;

const AutocompleteFormField: React.FC<Props> = React.memo(props => {
    const {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        defaultValue,
        value,
        onChange,
        ...other
    } = props;

    return (
        <Controller
            control={control}
            name={name}
            rules={validationRules}
            render={({ value: RHFValue, onChange: RHFOnChange }) => (
                <Autocomplete
                    {...other}
                    onChange={(event, data, reason, details) => {
                        if (onChange) {
                            onChange(event, data, reason, details);
                        }
                        //@ts-ignore
                        RHFOnChange(data?.value || undefined);
                    }}
                    // value={RHFValue}
                    error={Boolean(errors[name])}
                />
            )}
        />
    );
});

export default AutocompleteFormField;
