import React from 'react';
import map from 'lodash/map';

import { makeStyles, Theme, Typography } from '@material-ui/core';

import { FormFieldProps, SubmitField } from 'types/FormField';
import { ProfileSubmitField } from './ProfileSubmitField';
import { EMPTY, GROUPS } from './fields';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    groupTitle: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'normal',
        },
        color: theme.palette.grey[400],
        fontWeight: 500,
        fontSize: 15,
        marginBottom: 24,
        '&:not(:first-child)': {
            marginTop: 24,
        },
    },
}));

interface RenderGroupsFieldProps {
    groupName: string;
    fields: SubmitField[];
    formProps: FormFieldProps;
}

export const ProfileGroupsField: React.FC<RenderGroupsFieldProps> = ({
    groupName,
    fields,
    formProps,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            {groupName !== EMPTY && (
                <Typography className={classes.groupTitle}>
                    {t(GROUPS[groupName])}
                </Typography>
            )}
            {map(fields, (field, index) => (
                <ProfileSubmitField
                    field={field}
                    formProps={formProps}
                    key={`child_${groupName}_${index}`}
                />
            ))}
        </>
    );
};
