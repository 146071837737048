import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import find from 'lodash/find';

export const profileChildrenToSelectOptions = (
    currentChildId,
    profileChildren,
    childrenBlock
) => {
    let options = [];
    options.push({
        name: 'Добавить ребенка',
        value: 0,
    });
    if (isEmpty(profileChildren)) return options;

    profileChildren.forEach(function (child) {
        const id = child.childId;

        //Ребенок уже есть в childrenBlock?
        const findInChildrenBlock = find(childrenBlock, { childId: id });
        if (findInChildrenBlock && currentChildId !== id) return;

        const fildFirstName = find(child?.childFields, { fieldId: 'kid_first_name' });
        let fildLastName = find(child?.childFields, { fieldId: 'kid_last_name' });

        // Если нет имени - нет и ребенка
        if (!fildFirstName || !fildFirstName?.currentValue) return;

        // Если нет фамилии
        if (!fildLastName || !fildLastName?.currentValue)
            fildLastName = { currentValue: '' };

        options.push({
            name: fildFirstName.currentValue + ' ' + fildLastName?.currentValue,
            value: id,
        });
    });

    return options;
};
