import { createSlice } from '@reduxjs/toolkit';
import { Service, ServiceSubsidy, ServiceDetail, Calculation } from 'types/Services';

interface ServiceState {
    allServices: {
        data: Service[];
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
}

const initialState: ServiceState = {
    allServices: {
        data: [],
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
};

//TODO: сделать конструкторы, чтобы уменьшить код
const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        //Все
        getAllServicesStart(state) {
            state.allServices.loading = true;
            state.allServices.error = null;
        },
        getAllServicesSuccess(state, { payload }) {
            state.allServices.loading = false;
            state.allServices.error = null;
            state.allServices.success = true;
            state.allServices.loaded = true;
            state.allServices.data = payload;
        },
        getAllServicesFailure(state, { payload }) {
            state.allServices.loading = false;
            state.allServices.loaded = true;
            state.allServices.error = payload.error.error;
            state.allServices.errorCode = payload.errorCode;
        },
        getAllServicesClearErrorCode(state) {
            state.allServices.errorCode = null;
        },
    },
});

export const {
    getAllServicesStart,
    getAllServicesSuccess,
    getAllServicesFailure,
    getAllServicesClearErrorCode,
} = services.actions;

export default services.reducer;
