declare global {
    interface Window {
        projectEnv: any;
    }
}

// backend api url
export const baseUrl =
    process.env.NODE_ENV === 'production'
        ? process.env.REACT_APP_API_URL || ''
        : process.env.REACT_APP_API_URL || 'https://zabota.mspp.akbars.ru';

// front url for redirect from esia
export const frontUrl = `${window.location.origin}/home`;

// signalR url
export const chatHub = '/chat';
