import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialCheckbox, {
    CheckboxProps as MaterialCheckboxProps,
} from '@material-ui/core/Checkbox';
import { colors } from 'constants/component.constants';

const useStyles = makeStyles(theme => {
    const { gray0, gray250, gray300, gray450, gray50 } = colors;

    return {
        root: {
            margin: 0,
            background: 'none !important',
        },
        icon: {
            borderRadius: 4,
            border: `1px solid ${gray250}`,
            backgroundColor: gray50,
            transition: 'border-color 0.25s',
            boxSizing: 'border-box',

            'input:hover ~ &': {
                borderColor: gray450,
            },
            'input:active ~ &': {
                border: 'none',
                '&:after': {
                    content: '""',
                    position: 'absolute',
                    border: `2px solid ${theme.palette.primary.main}`,
                    borderRadius: 4,
                    boxSizing: 'border-box',
                    left: '50%',
                    top: '50%',
                },
            },
            'input:disabled:hover ~ &': {
                borderColor: gray300,
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                border: `1px solid ${gray300}`,
                background: gray300,
                '&:after': {
                    display: 'none',
                },
            },
        },
        iconL: {
            width: 20,
            height: 20,
            'input:active ~ &': {
                border: 'none',
                '&:after': {
                    marginTop: -11,
                    marginLeft: -11,
                    width: 22,
                    height: 22,
                },
            },
        },
        iconM: {
            width: 18,
            height: 18,
            'input:active ~ &': {
                border: 'none',
                '&:after': {
                    marginTop: -10,
                    marginLeft: -10,
                    width: 20,
                    height: 20,
                },
            },
        },
        iconS: {
            width: 16,
            height: 16,
            'input:active ~ &': {
                border: 'none',
                '&:after': {
                    marginTop: -9,
                    marginLeft: -9,
                    width: 18,
                    height: 18,
                },
            },
        },
        // checkedIcon
        checkedIcon: {
            backgroundColor: theme.palette.primary.main,
            borderRadius: 4,
            position: 'relative',
            '&:before': {
                display: 'block',
                content: '""',
                boxSizing: 'content-box',
            },
            '&:after': {
                position: 'absolute',
                width: 5,
                height: 10,
                border: `solid ${gray0}`,
                borderWidth: '0 2px 2px 0',
                transform: 'rotate(45deg)',
                content: '""',
                boxSizing: 'content-box',
            },
            'input:hover ~ &': {
                boxShadow: 'none',
            },
            'input:disabled ~ &': {
                boxShadow: 'none',
                background: gray300,
                '&:before': {
                    display: 'none',
                },
                '&:after': {
                    opacity: '.5',
                },
            },
            'input:active ~ &': {
                '&:before': {
                    borderRadius: 7,
                    content: '""',
                    position: 'absolute',
                    border: `2px solid ${theme.palette.primary.main}`,
                    boxSizing: 'border-box',
                    left: '50%',
                    top: '50%',
                },
            },
        },
        checkedIconL: {
            width: 20,
            height: 20,
            '&:before': {},
            '&:after': {
                left: 7,
                top: 2,
            },
            'input:active ~ &': {
                '&:before': {
                    marginTop: -13,
                    marginLeft: -13,
                    width: 26,
                    height: 26,
                },
            },
        },
        checkedIconM: {
            width: 18,
            height: 18,
            '&:before': {},
            '&:after': {
                left: 6,
                top: 1,
            },
            'input:active ~ &': {
                '&:before': {
                    marginTop: -12,
                    marginLeft: -12,
                    width: 24,
                    height: 24,
                },
            },
        },
        checkedIconS: {
            width: 16,
            height: 16,
            '&:before': {},
            '&:after': {
                width: 4,
                height: 9,
                left: 5,
                top: 1,
            },
            'input:active ~ &': {
                '&:before': {
                    marginTop: -11,
                    marginLeft: -11,
                    width: 22,
                    height: 22,
                },
            },
        },
    };
});

export type CheckboxSize = 'l' | 'm' | 's';

export interface CheckboxProps extends Omit<MaterialCheckboxProps, 'size'> {
    size?: CheckboxSize;
}

export const Checkbox: React.FC<CheckboxProps> = ({ size, ...props }) => {
    const s = useStyles();

    let checkedIconSizeClass: string;
    let iconSizeClass: string;

    switch (size) {
        case 'l':
            checkedIconSizeClass = s.checkedIconL;
            iconSizeClass = s.iconL;
            break;
        case 'm':
            checkedIconSizeClass = s.checkedIconM;
            iconSizeClass = s.iconM;
            break;
        case 's':
            checkedIconSizeClass = s.checkedIconS;
            iconSizeClass = s.iconS;
            break;
        default:
            checkedIconSizeClass = s.checkedIconL;
            iconSizeClass = s.iconL;
    }

    return (
        <MaterialCheckbox
            {...props}
            checkedIcon={<span className={`${s.checkedIcon} ${checkedIconSizeClass}`} />}
            disableRipple
            icon={<span className={`${s.icon} ${iconSizeClass}`} />}
            classes={{ root: s.root }}
        />
    );
};

Checkbox.defaultProps = {
    size: 'l',
};

export default Checkbox;
