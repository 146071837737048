import React from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Box, Dialog, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    dialogButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        marginTop: 56,
        marginRight: 16,
    },
    dialogPaper: {
        padding: 60,
    },
    dialogTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        },
        fontSize: 20,
        fontWeight: 500,
        marginBottom: 32,
    },
    termName: {
        fontWeight: 500,
    },
}));

interface DialogGoToOfficeProps {
    modalOpen: boolean;
    currentApplicationTitle: string;
    goHome: VoidFunction;
    nextApplicationId: string;
    onClose: VoidFunction;
}

export const DialogGoToOffice: React.FC<DialogGoToOfficeProps> = ({
    modalOpen,
    currentApplicationTitle,
    goHome,
    nextApplicationId,
    onClose,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Dialog
            open={modalOpen}
            onClose={onClose}
            disableBackdropClick
            disableEscapeKeyDown
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <Box>
                <Typography className={classes.dialogTitle}>
                    {t('pages.Application.forRegistration')}{' '}
                    <span className={classes.termName}>{currentApplicationTitle}</span>{' '}
                    {t('pages.Application.goToOffice')}
                </Typography>
                <Box>
                    {nextApplicationId && (
                        <Link to={'/application/' + nextApplicationId}>
                            <Button variant="contained" className={classes.dialogButton}>
                                {t('pages.Application.nextSubsidy')}
                            </Button>
                        </Link>
                    )}
                    <Button
                        variant="outlined"
                        className={classes.dialogButton}
                        onClick={goHome}
                        component={Link}
                        to="/bot"
                    >
                        {t('pages.Application.backToChat')}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};

export default DialogGoToOffice;
