import { useContext } from 'react';
import { FeatureTogglesContext } from './context';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

export const showFindFeature = (featuresContext, checkFeatureName) => {
    const { list, userId } = featuresContext;

    if (isEmpty(list)) return false;

    const finded = find(list, feature => {
        return feature.name === checkFeatureName;
    });

    if (!isEmpty(finded)) {
        const { value, users } = finded;
        if (value === 'on' && isEmpty(users)) return true;
        if (value === 'off' && isEmpty(users)) return false;

        if (users) {
            const findedUser = find(users, user => {
                return user === userId;
            });

            if (value === 'on' && findedUser) return true;
            if (value === 'off' && findedUser) return false;
        }
    }
};

export const useFeatures = () => {
    const context = useContext(FeatureTogglesContext);
    return (name: string) => showFindFeature(context, name);
};
