import {
    GET_FAQS_CATEGORIES_REQUESTING,
    GET_FAQS_CATEGORIES_SUCCEED,
    GET_FAQS_CATEGORIES_FAILED,
    SET_FITER_CATEGORY_IDS,
} from '../../constants';
import { FaqCategory } from 'types/Faq';

interface state {
    data: any[];
    selectedFilterCategories: {
        id: string;
    }[];
    request: {
        status: any;
        isLoading: boolean;
        isSuccess: boolean;
        errors: any;
        isLoaded: boolean;
    };
}

const initialState: state = {
    data: null,
    selectedFilterCategories: [],
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
        isLoaded: false,
    },
};

export const faqCategories = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQS_CATEGORIES_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case GET_FAQS_CATEGORIES_SUCCEED:
            return {
                ...state,
                data: action.payload.faqs,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_FAQS_CATEGORIES_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case SET_FITER_CATEGORY_IDS:
            return {
                ...state,
                selectedFilterCategories: action.payload.ids,
            };
        default:
            return state;
    }
};
