import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';

import bannerGif from 'assets/images/bannerGif.png';
import bannerMain from 'assets/images/bannerMain.png';
import bannerLandingMobile from 'assets/images/bannerLandingMobile.png';

import { Icon } from 'components/atoms';
import { RootState } from 'store/store';
import { bannerToggle } from 'store/actions';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    card: {
        color: theme.palette.common.white,
        position: 'relative',
        border: 'none',
    },
    cardMain: {
        [theme.breakpoints.down('xs')]: {
            padding: '0 64px',
            height: 173,
        },
        display: 'flex',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            background: 'linear-gradient(90deg, #5D73C8 0%, #4FBCC9 100%);',
            width: '100vw',
            left: '50%',
            marginLeft: '-50vw',
            zIndex: -1,
        },
    },
    cardChat: {
        [theme.breakpoints.down('xs')]: {
            padding: 20,
        },
        background: 'linear-gradient(90deg, #5D73C8 0%, #4FBCC9 100%);',
        width: '100%',
        borderRadius: 16,
        padding: 30,
    },
    cardLarge: {
        [theme.breakpoints.down('sm')]: {
            height: 369,
            flexDirection: 'column',
        },
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        '&::before': {
            content: '""',
            position: 'absolute',
            top: 0,
            bottom: 0,
            background: 'linear-gradient(90deg, #5D73C8 0%, #4FBCC9 100%);',
            width: '100vw',
            left: '50%',
            marginLeft: '-50vw',
            zIndex: -1,
        },
    },
    cardTitleContaierMain: {
        [theme.breakpoints.down('xs')]: {
            padding: '20px 0',
        },
        padding: '41px 0',
    },
    cardTitle: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
        fontFamily: 'Roboto',
    },
    cardTitleMain: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 1.33,
            marginBottom: 8,
        },
        fontSize: 40,
        lineHeight: 1.2,
        marginBottom: 16,
        textAlign: 'center',
        letterSpacing: '0.02em',
    },
    cardTitleChat: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 24,
            lineHeight: 1.33,
            fontWeight: 500,
            marginBottom: 8,
        },
        textAlign: 'center',
        fontWeight: 'bold',
        fontSize: 27,
        lineHeight: 1.2,
        marginBottom: theme.spacing(2),
    },
    cardTitleLarge: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 30,
            lineHeight: 1.07,
            width: 'auto',
            marginBottom: 20,
        },
        fontSize: 40,
        lineHeight: 1.2,
        width: 378,
        marginBottom: 20,
        letterSpacing: '0.01em',
    },
    cardDicription: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
        },
    },
    cardDicriptionMain: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            lineHeight: 1.5,
        },
        fontSize: 18,
        lineHeight: 1.4,
        textAlign: 'center',
    },
    cardDicriptionChat: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 12,
            lineHeight: 1.5,
        },
        fontSize: 16,
        lineHeight: 1.4,
        textAlign: 'center',
    },
    cardDicriptionLarge: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 14,
            lineHeight: 1.29,
            width: 'auto',
        },
        fontSize: 20,
        lineHeight: 1.4,
        letterSpacing: '0.01em',
    },
    cardImage: {
        [theme.breakpoints.down('xs')]: {
            position: 'unset',
        },
        position: 'absolute',
    },
    cardImageMain: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
            visible: 'hidden',
        },
        position: 'unset',
        right: 100,
        top: 0,
        width: 256,
        height: 200,
        marginLeft: 49,
    },
    cardImageSmall: {
        [theme.breakpoints.down('xs')]: {
            width: 94,
            height: 153,
            right: 0,
            bottom: 0,
        },
        height: 204,
        width: 126,
        right: 0,
        bottom: 0,
    },
    cardImageLargeContainer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 30,
        },
        [theme.breakpoints.down('xs')]: {
            marginRight: 'auto',
        },
    },
    cardImageLarge: {
        [theme.breakpoints.down('sm')]: {
            width: 246,
            height: 103,
        },
        position: 'relative',
        width: 404,
        height: 404,
    },
    cardButton: {
        position: 'absolute',
        right: 0,
        top: 20,
    },
}));

interface Props {
    className?: string;
    type?: 'landing' | 'main' | 'chat';
}

export const HelpBanner: FC<Props> = ({ className, type }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const bannerState = useSelector((state: RootState) => state.common.banner);
    const theme = useTheme();
    const isViewSM = useMediaQuery(theme.breakpoints.down('sm'));
    const closeBanner = () => {
        dispatch(bannerToggle(false));
    };
    const { t } = useTranslation();
    let content = null;
    switch (type) {
        case 'landing':
            content = (
                <Box className={classNames(classes.card, classes.cardLarge, className)}>
                    <Box>
                        <Typography
                            className={classNames(
                                classes.cardTitle,
                                classes.cardTitleLarge
                            )}
                        >
                            <Trans i18nKey="com.HelpBanner.helpIsHere" />
                        </Typography>
                        <Typography
                            className={classNames(
                                classes.cardDicription,
                                classes.cardDicriptionLarge,
                                'cardDescription'
                            )}
                        >
                            <Trans i18nKey="com.HelpBanner.multiline" />
                        </Typography>
                    </Box>
                    <Box textAlign="center" className={classes.cardImageLargeContainer}>
                        <img
                            src={isViewSM ? bannerLandingMobile : bannerGif}
                            className={classNames(
                                classes.cardImage,
                                classes.cardImageLarge
                            )}
                        ></img>
                    </Box>
                </Box>
            );
            break;
        case 'main':
            if (bannerState.visible) {
                content = (
                    <Box
                        className={classNames(classes.card, classes.cardMain, className)}
                    >
                        <Box className={classNames(classes.cardTitleContaierMain)}>
                            <Typography
                                className={classNames(
                                    classes.cardTitle,
                                    classes.cardTitleMain
                                )}
                            >
                                <Trans i18nKey="com.HelpBanner.helpIsHere" />
                            </Typography>
                            <Typography
                                className={classNames(
                                    classes.cardDicription,
                                    classes.cardDicriptionMain,
                                    'cardDescription'
                                )}
                            >
                                {t('com.HelpBanner.obrat')}
                                <br className="hideOnMobile" />{' '}
                                {t('com.HelpBanner.inBlagFond')}
                            </Typography>
                        </Box>
                        <Box textAlign="center">
                            <img
                                src={bannerMain}
                                className={classNames(
                                    classes.cardImage,
                                    classes.cardImageMain
                                )}
                            ></img>
                        </Box>
                        <Box>
                            <IconButton
                                size="small"
                                className={classes.cardButton}
                                onClick={closeBanner}
                                aria-label={t('com.HelpBanner.closeBanner')}
                            >
                                <Icon name="close" />
                            </IconButton>
                        </Box>
                    </Box>
                );
            } else {
                content = null;
            }

            break;
        case 'chat':
            content = (
                <Box className={classNames(classes.card, classes.cardChat, className)}>
                    <Typography
                        className={classNames(classes.cardTitle, classes.cardTitleChat)}
                    >
                        «Ярдәм янәшә! <br /> {t('com.HelpBanner.helpIsHere')}»
                    </Typography>
                    <Typography
                        className={classNames(
                            classes.cardDicription,
                            classes.cardDicriptionChat,
                            'cardDescription'
                        )}
                    >
                        <Trans i18nKey="com.HelpBanner.multiline" />
                    </Typography>
                </Box>
            );
            break;

        default:
            content = null;
            break;
    }

    return <>{content}</>;
};
