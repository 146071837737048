import store from 'store/store';
import {
    SIGN_IN_ANONIM_REQUESTING,
    SIGN_IN_ANONIM_SUCCEED,
    SIGN_IN_ANONIM_FAILED,
    SIGN_IN_ESIA_CONFIRM_SUCCEED,
    SIGN_IN_ESIA_RESET_TOKEN,
} from 'store/constants';

import { deleteAnonimToken, setAuthToken } from 'utils/token';
import { setJson, deleteJson } from 'utils/localStorage';
import { LocalStorageKeys } from 'types/LocalStorageKeys';

import {
    axiosInstanse as axios,
    getAnonimCommonHeaders,
} from 'infrastructure/RestClient';

/*
mspp:
e2949ebf-a859-4de5-85a1-96cc2bee0ae7 Валерия Киселева +7(927)4930557
a1013ae0-8f84-4dc6-bff8-50d049bccbe0 Иван Иван 79841707971
c60eae1f-1a1a-4a18-bfd3-12701f18ce81 Тест1 Тестеров 79279078283

/** Фейковый логин. Только для тестовых стендов */
//TODO: узнать айдишники для других стендов (пока есть только mspp)
window.fakeLogin = (userId = null) => {
    if (!userId) {
        switch (process.env.REACT_APP_API_URL) {
            case 'https://zabota.mspp.akbars.ru':
                userId = 'e2949ebf-a859-4de5-85a1-96cc2bee0ae7';
                break;

            default:
                userId = 'e2949ebf-a859-4de5-85a1-96cc2bee0ae7';
                break;
        }
    }
    return axios
        .get(`/api/auth/${userId}/fakeLogin`, {
            headers: getAnonimCommonHeaders(store.getState()),
        })
        .then(({ data }) => {
            if (data.success) {
                console.log('success', data);
                const res = data.result;

                setAuthToken(res.token);
                // deleteAnonimToken()
                setJson(LocalStorageKeys.UserId, res.userId);
                setJson(LocalStorageKeys.UserBanner, true);
                store.dispatch({
                    type: SIGN_IN_ESIA_CONFIRM_SUCCEED,
                    userId,
                    token: res.token,
                    requestStatus: null,
                });
            } else {
                console.log('error', data.error);
            }
        })
        .catch(response => {
            console.log('catch', response);
        });
};
