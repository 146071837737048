import React, { FC } from 'react';
import map from 'lodash/map';

import MessagesGroupByDayComponent from './MessagesGroupByDayComponent';
import ChatActions from './ChatActions';

import { Message, MessagesGroupByDay } from 'types/Chat';

interface ChatContentProps {
    messagesByDay?: MessagesGroupByDay;
    fakeMessageRef: any;
    onMessage: (message: string) => void;
    lastMessage?: Message;
}

const ChatContent: FC<ChatContentProps> = ({
    messagesByDay,
    fakeMessageRef,
    onMessage,
    lastMessage,
}) => {
    return (
        <>
            {messagesByDay &&
                map(messagesByDay, (dayMessages, key) => (
                    <MessagesGroupByDayComponent
                        dayMessages={dayMessages}
                        lastMessage={lastMessage}
                        day={key}
                        key={key}
                    />
                ))}
            <ChatActions onMessage={onMessage} lastMessage={lastMessage} />
            <div
                style={{
                    float: 'left',
                    clear: 'both',
                    visibility: 'hidden',
                }}
                ref={fakeMessageRef}
            ></div>
        </>
    );
};

export default ChatContent;
