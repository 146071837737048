import React, { FC, Fragment } from 'react';
import cx from 'classnames';
import moment from 'moment';
import { Typography, Box, useTheme } from '@material-ui/core';
import map from 'lodash/map';
import { useTranslation } from 'react-i18next';

import { Bubble } from 'components/atoms';
import { Message, MessageType } from 'types/Chat';
import { useCommonStyles } from '../.././Chat.styles';

import { Link } from 'react-router-dom';

import CheckBoxesMessage from './CheckBoxesMessage';
import CheckListMessage from './CheckListMessage';

interface IncomingMessageProps {
    message: Message;
    lastMessage?: Message;
}
const IncomingMessage: FC<IncomingMessageProps> = ({ message, lastMessage }) => {
    const classes = useCommonStyles();
    const theme = useTheme();
    const { t } = useTranslation();

    const renderView = () => {
        let content = null;

        switch (message.messageType) {
            case MessageType.Text:
            case MessageType.ShowCard:
                content = (
                    <Bubble
                        variant="incoming"
                        time={moment(message.createDateTime).format('HH:mm')}
                        className={cx(classes.bubble, classes.incomingBubble)}
                    >
                        {message.message?.Value}
                        {message.detailsLink && (
                            <Box className={classes.detailsLink}>
                                <Link
                                    to={message.detailsLink.replace('app://', '/')}
                                    className={classes.link}
                                >
                                    {t('common.more')}
                                </Link>
                            </Box>
                        )}
                    </Bubble>
                );
                break;
            case MessageType.CheckList:
                content = (
                    <>
                        <Bubble
                            variant="incoming"
                            time={moment(message.createDateTime).format('HH:mm')}
                            className={cx(classes.bubble, classes.incomingBubble)}
                        >
                            {message.message?.Title}
                        </Bubble>
                        {map(message.message.Items, (value, index) => {
                            return (
                                <CheckListMessage
                                    item={value}
                                    key={value.Id}
                                    disabled={lastMessage?.id !== message.id}
                                />
                            );
                        })}
                    </>
                );
                break;
            case MessageType.CheckBoxes:
                content = (
                    <>
                        <Bubble
                            variant="incoming"
                            className={cx(classes.bubble, classes.incomingBubble)}
                        >
                            {message.message?.Value}
                        </Bubble>
                        <Box className={classes.checkboxexMessageContainer}>
                            {map(message.message.Checkboxes, (value, index) => {
                                return (
                                    <CheckBoxesMessage
                                        item={value}
                                        key={value.Text}
                                        disabled={lastMessage?.id !== message.id}
                                    />
                                );
                            })}
                        </Box>
                    </>
                );
                break;

            case MessageType.List:
                content = (
                    <>
                        <Bubble
                            variant="incoming"
                            time={moment(message.createDateTime).format('HH:mm')}
                            className={cx(classes.bubble, classes.incomingBubble)}
                        >
                            {message.message?.Title}
                        </Bubble>
                        <Bubble
                            variant="incoming"
                            className={classes.checkListMessageChildTag}
                        >
                            {map(message.message.Items, ({ Label, Value }, index) => {
                                return (
                                    <Fragment key={`message_list_item_${index}`}>
                                        <Typography
                                            style={{
                                                color: theme.palette.grey[400],
                                                marginBottom: 4,
                                            }}
                                        >
                                            {Label}
                                        </Typography>
                                        <Typography style={{ marginBottom: 16 }}>
                                            {Value}
                                        </Typography>
                                    </Fragment>
                                );
                            })}
                        </Bubble>
                    </>
                );
                break;
            default:
                break;
        }
        return content;
    };
    return renderView();
};

export default IncomingMessage;
