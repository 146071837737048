import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import map from 'lodash/map';

import { ProfileSubmitField } from 'components/organisms';

interface PersonalProps {
    preparedPassport: any;
}

export const Personal: FC<PersonalProps> = ({ preparedPassport }) => {
    const {
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>();

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };

    return (
        <>
            {preparedPassport &&
                map(preparedPassport, (field, index) => {
                    return (
                        <ProfileSubmitField
                            field={field}
                            formProps={formProps}
                            key={`personal_${index}`}
                        />
                    );
                })}
        </>
    );
};
