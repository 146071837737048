import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // marginBottom: theme.spacing(3),
    },
    grid: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            paddingTop: 20,
        },
        flexDirection: 'column',
        padding: 24,
    },
    nonAuthorizedInfo: {
        fontSize: '14px',
        color: '#474D5F',
    },
    collapseContainer: {
        position: 'relative',
        '&:first-child': {
            marginBottom: 20,
        },
    },
    collapseContainers: {
        marginTop: 56,
    },
    input: {
        color: theme.palette.grey[600],
    },
    collapseTitleContainer: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 0,
        },
        '& p': {
            [theme.breakpoints.down('xs')]: {
                fontSize: '16px',
            },
            fontSize: '18px',
        },
        marginLeft: 16,
        width: '100%',
    },
    collapse: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
            border: 0,
            boxShadow: '0px 0px 24px rgba(0, 0, 0, 0.06)',
        },
        background: theme.palette.common.white,
        borderRadius: 12,
        border: '1px solid #DFE1E4',
        padding: '10px 20px',
        transition: 'border-radius 300ms ease-in-out',
        // border: '1px solid #DFE1E4',
    },
    collapseOpen: {
        borderRadius: '12px 12px 0 0',
        borderBottom: 'none',
    },
    collapseContent: {
        [theme.breakpoints.down('xs')]: {
            border: 0,
        },
        transition: 'all 300ms ease-in-out',
        backgroundColor: theme.palette.common.white,
        borderRadius: '0 0 12px 12px',
        //boxShadow: '0px 20px 24px rgba(0, 0, 0, 0.06)',
        border: '1px solid #DFE1E4',
        borderTop: 'none',
    },
    collapseImage: {
        borderRadius: 12,
    },
    expand: {
        [theme.breakpoints.down('xs')]: {
            transform: 'rotate(-90deg)',
        },
        '& svg': {
            fontSize: '2rem',
        },
        transform: 'rotate(0deg)',
        marginLeft: 'auto',
        transition: theme.transitions.create('transform', {
            duration: theme.transitions.duration.shortest,
        }),
    },
    expandOpen: {
        [theme.breakpoints.down('xs')]: {
            transform: 'rotate(0deg)',
        },
        transform: 'rotate(180deg)',
    },
    sybsidyButtonContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 24,
        },
        marginTop: 32,
        display: 'flex',
        alignItems: 'center',
    },
    subsidyApplyButton: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 500,
            textTransform: 'uppercase',
            padding: 16,
        },
        fontWeight: 'normal',
        marginRight: 20,
        padding: '13px 28px 15px 27px',
    },
    subsidyCalcButton: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 500,
            textTransform: 'uppercase',
            padding: 16,
            width: '100%',
        },
        fontWeight: 'normal',
        padding: '13px 28px 15px 27px',
    },
    subsidyCalcLink: {
        fontSize: 14,
        lineHeight: 1.43,
        textDecoration: 'underline',
        color: theme.palette.primary.main,
    },
    subsidyAmount: {
        fontWeight: 500,
    },
    subsidyInformationContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
        },
        marginTop: 40,
    },
    subsidyInformation: {
        display: 'flex',
        marginBottom: 23,
        alignItems: 'center',
        '&:last-child': {
            marginBottom: 0,
        },
    },
    subsidyInformationImage: {
        marginRight: 16,
        backgroundColor: 'unset',
    },
    subsidyGrantedContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
        },
        marginTop: 56,
    },
    subsidyGranted: {
        marginTop: 20,
        color: theme.palette.grey[400],
    },
    button: {
        [theme.breakpoints.down('xs')]: {
            boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.24)',
        },
    },
    dot: {
        width: 8,
        height: 8,
        display: 'inline-block',
        backgroundColor: '#474D5F',
        borderRadius: '100%',
        marginRight: 8,
    },
    listItemIconRoot: {
        minWidth: 'unset',
        marginTop: 10,
    },
    term: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 13,
            lineHeight: 1.4,
        },
        fontSize: 14,
        fontWeight: 'normal',
        lineHeight: 1.43,
        color: theme.palette.grey[600],
    },
    subsidyHint: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 17,
            lineHeight: 1.4,
        },
        fontSize: 15,
        lineHeight: 1.2,
        '& + &': {
            marginTop: 16,
        },
    },
    subsidyGrantedTitle: {
        [theme.breakpoints.down('xs')]: {
            lineHeight: 1.4,
        },
        fontSize: 18,
        lineHeight: 1.56,
        marginBottom: 24,
    },
}));
