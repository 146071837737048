import React from 'react';

import { Box, makeStyles, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import ruStoreBadge from 'assets/images/rustore-badge.svg';
import ruStoreBadgeMobile from 'assets/images/rustore-badge-mobile.svg';
import misRt from 'assets/images/mis-rt.svg';
import gosuslugi from 'assets/images/gosuslugi.svg';
import cct from 'assets/images/cct.svg';
import { IconLink } from 'components/atoms/IconLink';
import cx from 'classnames';
import { useMediaQuery, useTheme } from '@material-ui/core';
import { gosuslugi_link_06_05_2021 } from 'store/features.constants';
import { cct_link_06_05_2021 } from 'store/features.constants';
import { mis_rt_link_06_05_2021 } from 'store/features.constants';
import { rate_quality_link_06_05_2021 } from 'store/features.constants';
import { complaint_link_06_05_2021 } from 'store/features.constants';
import { FeatureToggle } from 'components/common/FeatureToggle';

const useStyles = makeStyles(theme => ({
    footer: {
        [theme.breakpoints.down('xs')]: {
            // position: 'relative',
            // width: '100vw',
            // left: '50%',
            // marginLeft: '-50vw',
            // padding: '30px 0 40px',
            // background: 'transparent',
            padding: '24px 0',
            position: 'relative',
        },
        padding: '38px 0',
        background: '#fff',
        position: 'sticky',
        '&::before': {
            [theme.breakpoints.down('xs')]: {
                background: '#F6FBFF',
            },
            content: '""',
            position: 'absolute',
            width: '100vw',
            top: 0,
            bottom: 0,
            left: '50%',
            marginLeft: '-50vw',
            background: theme.palette.common.white,
            zIndex: -10,
        },
    },
    link: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginTop: 12,
            marginRight: '10px',
        },
        '&:first-child': {
            [theme.breakpoints.down('xs')]: {
                marginRight: '10px',
            },
        },
        '&.phone': {
            color: 'grey',
        },
        color: theme.palette.primary.main,
        fontSize: 14,
        lineHeight: 2,
    },
    lined: {
        textDecoration: 'underline',
    },
    footerContainer: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
        },
        [theme.breakpoints.down('md')]: {
            textAlign: 'center',
        },
    },
    iconLinksContainer: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'unset',
            flexWrap: 'wrap',
            justifyContent: 'center',
            marginTop: 0,
        },
        marginTop: 8,
        display: 'flex',
        flexDirection: 'column',
    },
    iconLink: {
        [theme.breakpoints.down('xs')]: {
            padding: 6,
            marginBottom: 0,
        },
        marginBottom: 16,
    },
    boxCol: {
        display: 'flex',
        flexDirection: 'column',
        '&:nth-child(1)': {
            order: 0,
        },
        '&:nth-child(2)': {
            order: 1,
        },
        '&:nth-child(3)': {
            order: 2,
            [theme.breakpoints.down('xs')]: {
                order: 3,
            },
        },
        '&:nth-child(4)': {
            order: 3,
            [theme.breakpoints.down('xs')]: {
                order: 2,
            },
        },
    },
    apps: {
        [theme.breakpoints.down('xs')]: {
            display: 'block',
        },
    },
    phone: {
        fontStyle: 'normal',
        fontWeight: 700,
        fontSize: '26px',
        lineHeight: '28px',
        color: '#474D5F',
        marginBottom: 8,
    },
    contacts: {
        marginBottom: theme.spacing(3),
        display: 'grid',
    },
    socProtection: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '140%',
        color: '#000A26',
        marginBottom: 8,
    },
    email: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '26px',
        lineHeight: '28px',
        color: '#878B97',
    },
    appBage: {
        marginBottom: 4.47,
        [theme.breakpoints.down('xs')]: {
            padding: '0 8px',
        },
    },
}));

export const Footer: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    return (
        <footer className={classes.footer}>
            <Grid
                container
                justify="space-between"
                spacing={5}
                className={classes.footerContainer}
            >
                <Grid sm={6} md={6} lg={'auto'} item className={classes.boxCol}>
                    <Box className={classes.contacts}>
                        <Typography
                            component="a"
                            href="tel:+78435257061"
                            className={classes.phone}
                        >
                            +7 (843) 525-70-61
                        </Typography>
                        <Typography
                            component="a"
                            href="mailto:zabota.help@tatar.ru"
                            className={classes.email}
                        >
                            zabota.help@tatar.ru
                        </Typography>
                    </Box>
                    <Typography
                        component="a"
                        href={`${process.env.PUBLIC_URL}/docs/Operator_Policy_in_confidentiality_and_processing_personal.pdf`}
                        className={classes.link}
                        target="_blank"
                    >
                        {t('com.Footer.policy')}
                    </Typography>
                    <Typography
                        component="a"
                        href={`${process.env.PUBLIC_URL}/docs/User_Agreement_Care_Offer.pdf`}
                        className={classes.link}
                        target="_blank"
                    >
                        {t('com.Footer.agreement')}
                    </Typography>
                </Grid>
                <Grid sm={6} md={6} lg={'auto'} item className={classes.boxCol}>
                    <Typography variant="h5" className={classes.socProtection}>
                        Социальная защита
                    </Typography>
                    <FeatureToggle name={rate_quality_link_06_05_2021}>
                        <Typography
                            component="a"
                            href={`https://uslugi.tatarstan.ru/services-rating/user`}
                            className={cx(classes.link, classes.lined)}
                            target="_blank"
                        >
                            Оценить качество оказания услуг
                        </Typography>
                    </FeatureToggle>
                    <FeatureToggle name={complaint_link_06_05_2021}>
                        <Typography
                            component="a"
                            href={`https://do.gosuslugi.ru/`}
                            className={cx(classes.link, classes.lined)}
                            target="_blank"
                        >
                            Жалоба на портале Досудебного обжалования
                        </Typography>
                    </FeatureToggle>
                    <Typography
                        component="a"
                        href={`/faq`}
                        className={cx(classes.link, classes.lined)}
                        target="_blank"
                    >
                        Часто задаваемые вопросы
                    </Typography>
                </Grid>
                <Grid sm={6} md={6} lg={'auto'} item className={classes.boxCol}>
                    <Grid item className={classes.iconLinksContainer}>
                        <FeatureToggle name={gosuslugi_link_06_05_2021}>
                            <IconLink
                                to="https://uslugi.tatarstan.ru/"
                                target="_blank"
                                className={classes.iconLink}
                                img={gosuslugi}
                                title="Госуслуги"
                            />
                        </FeatureToggle>
                        <FeatureToggle name={cct_link_06_05_2021}>
                            <IconLink
                                to="https://digital.tatarstan.ru/gku-tsentr-tsifrovoy-transformatsii-respubliki.htm"
                                target="_blank"
                                className={classes.iconLink}
                                img={cct}
                                title="ЦЦТ"
                            />
                        </FeatureToggle>
                        <FeatureToggle name={mis_rt_link_06_05_2021}>
                            <IconLink
                                to="https://digital.tatarstan.ru/"
                                target="_blank"
                                className={classes.iconLink}
                                img={misRt}
                                title="МИС РТ"
                            />
                        </FeatureToggle>
                    </Grid>
                </Grid>
                <Grid
                    sm={6}
                    md={6}
                    lg={'auto'}
                    item
                    className={cx(classes.boxCol, classes.apps)}
                >
                    <a
                        href="https://apps.rustore.ru/app/ru.tatarstan.care"
                        className={classes.appBage}
                    >
                        <img
                            src={isViewXS ? ruStoreBadgeMobile : ruStoreBadge}
                            alt="RuStore"
                        />
                    </a>
                </Grid>
            </Grid>
        </footer>
    );
};
