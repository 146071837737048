import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_CHECK_CARD_REQUESTING,
    WALLET_CHECK_CARD_SUCCEED,
    WALLET_CHECK_CARD_FAILED,
    COMMON_ERROR,
} from '../../constants';

export const checkCard = operationId => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_CHECK_CARD_REQUESTING, null, null, null);

    axios
        .get(`/api/Requisites/${operationId}`, {
            headers: getCommonHeaders(getState()),
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const result = response.data?.result?.value;
            const error = response.data?.error;

            if (isSuccess === true) {
                dispatchData(WALLET_CHECK_CARD_SUCCEED, result, requestStatus, error);
            }

            if (isSuccess === false) {
                dispatchData(WALLET_CHECK_CARD_FAILED, null, requestStatus, error);
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);
            dispatchData(WALLET_CHECK_CARD_FAILED, null, null, error.error);
        });
};
