import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import stringToRegEx from 'utils/stringToRegEx';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
    UploaderFormField,
    TextField,
} from 'components/atoms';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import some from 'lodash/some';
import get from 'lodash/get';
import {
    getMaskByType,
    getRegExByType,
    parseDateString,
    transformDateToServerString,
} from 'utils/utils';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

const useStylesField = makeStyles((theme: Theme) => ({
    comment: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            lineHeight: '140%',
        },
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#474D5F',
    },
}));

export const Comment: React.FC<{
    field: ApplicationField;
    fieldName: string;
}> = ({ field }) => {
    const classes = useStylesField();
    //const theme = useTheme();
    //const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    return <Box className={classes.comment}>{field.fieldTitle}</Box>;
};
