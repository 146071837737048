import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { getMessages } from 'store/actions/messages/getMessages';

interface Props {
    children?: React.ReactNode;
}

export const MessagesProvider: React.FC<Props> = ({ children }) => {
    const list = useSelector((state: RootState) => state.featureToggles.list);

    const isLoading = useSelector((state: RootState) => state.messages.isLoading);
    const isLoaded = useSelector((state: RootState) => state.messages.isLoaded);

    const dispatch = useDispatch();

    useEffect(() => {
        if (!isLoading && !isLoaded) {
            dispatch(getMessages());
        }
    }, []);

    if (!isLoaded) return null;

    return <>{children}</>;
};
