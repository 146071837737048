import React from 'react';
import Stepper from '@material-ui/core/Stepper';
import { useTranslation } from 'react-i18next';
import { Card } from 'types/Card';
import { Application } from 'types/Application';
import StepSubsidyName from './Steps/StepSubsidyName';
import { StepBlocks } from './Steps/StepBlocks';
import StepAddCard from './Steps/StepAddCard';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    stepper: {
        backgroundColor: 'transparent',
        padding: 0,
    },
}));

interface ApplicationFormProps {
    activeStep: any;
    currentApplication: Application;
    formProps: any;
    applicationSubmitError?: string;
    applicationSubmitIsLoading: boolean;
    userCards: Card[];
    addCardIsLoading: boolean;
    showButtonCardOrder: boolean;
    showCardOrderCheckbox: boolean;
    handleSubmit: any; //useForm
    onSubmit: any;
    handleAddCard: VoidFunction;
    handleOrderCard?: VoidFunction;
    openModal: VoidFunction;
    profileChildren: any;
    handleBack: Function;
    nextApplicationId: string;
}

export const ApplicationForm: React.FC<ApplicationFormProps> = ({
    activeStep,
    currentApplication,
    formProps,
    applicationSubmitError,
    applicationSubmitIsLoading,
    userCards,
    addCardIsLoading,
    showButtonCardOrder,
    showCardOrderCheckbox,
    handleSubmit,
    profileChildren,
    onSubmit,
    handleAddCard,
    handleOrderCard,
    openModal,
    handleBack,
    nextApplicationId,
}) => {
    const classes = useStyles();
    return (
        <Stepper
            activeStep={activeStep}
            orientation="vertical"
            className={classes.stepper}
            connector={null}
        >
            <StepSubsidyName
                key="subsidyName"
                open={openModal}
                currentApplication={currentApplication}
                handleSubmit={handleSubmit}
                nextApplicationId={nextApplicationId}
                onSubmit={onSubmit}
                handleBack={handleBack}
            />
            {currentApplication.blocks.map((block, blockIndex) => (
                <StepBlocks
                    key={block.blockType}
                    block={block}
                    blockIndex={blockIndex}
                    formProps={formProps}
                    profileChildren={profileChildren}
                    currentApplication={currentApplication}
                    canAppendChildData={currentApplication.canAppendChildData}
                    applicationSubmitError={applicationSubmitError}
                    applicationSubmitIsLoading={applicationSubmitIsLoading}
                    handleSubmit={handleSubmit}
                    onSubmit={onSubmit}
                    handleBack={handleBack}
                />
            ))}
            <StepAddCard
                key="addCard"
                handleSubmit={handleSubmit}
                formProps={formProps}
                onSubmit={onSubmit}
                applicationSubmitError={applicationSubmitError}
                applicationSubmitIsLoading={applicationSubmitIsLoading}
                userCards={userCards}
                addCardIsLoading={addCardIsLoading}
                handleAddCard={handleAddCard}
                showButtonCardOrder={showButtonCardOrder}
                showCardOrderCheckbox={showCardOrderCheckbox}
                handleOrderCard={handleOrderCard}
            />
        </Stepper>
    );
};

export default ApplicationForm;
