import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        marginBottom: 8,
        display: 'flex',
    },
    link: {
        textDecoration: 'unset',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '140%',
        color: '#5780EA',
        cursor: 'pointer',
    },
}));

interface Props {
    item: any;
    itemIndex: number;
    downloadFile: (fileId: string) => void;
}

export const ListItem: FC<Props> = ({ item, itemIndex, downloadFile }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <li key={'services.subsidyListItem-' + itemIndex} className={classes.listItem}>
            <Typography
                className={classes.link}
                key={item.id}
                onClick={() => downloadFile(item.fileId)}
            >
                {item.filename}
            </Typography>
        </li>
    );
};
