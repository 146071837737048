import React, { useCallback, useMemo, useState } from 'react';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import { ErrorMessage, Icon } from 'components/atoms';
import Typography from '@material-ui/core/Typography';
import { Avatar, Box, CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { FeatureToggle } from 'components/common/FeatureToggle';
import {
    card_request_10_02_2021,
    msp_select_cards_18_05_2021,
} from 'store/features.constants';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { FormProvider, useFormContext } from 'react-hook-form';

import SelectCardOrders from './SelectCardOrders.component';
import SelectCard from './SelectCard.component';
import { useFeatures } from 'hooks/useFeatures';

export const useStyles = makeStyles((theme: Theme) => ({
    dialogButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    stepLabel: {
        '& .MuiStepLabel-label, & .MuiStepLabel-active, & .MuiStepLabel-completed': {
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1.4,
            },
            fontWeight: 'bold',
            color: theme.palette.grey[900],
            fontSize: 24,
            lineHeight: 1.33,
        },
    },
    stepDecription: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            lineHeight: 1.4,
        },
        fontSize: 18,
        lineHeight: 1.56,
        color: theme.palette.grey[700],
    },
    collapse: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        padding: 4,
        borderRadius: 4,
        background: theme.palette.common.white,
        boxShadow: '0px 4px 8px rgba(16, 35, 83, 0.14)',
        justifyContent: 'space-between',
        width: 350,
        marginTop: 16,
    },
    cardIcon: {
        backgroundColor: 'transparent',
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40,
        },
        width: 62,
        height: 40,
    },
    cardNumber: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 16,
            lineHeight: 1.5,
            marginLeft: 0,
        },
        fontWeight: 'normal',
        marginLeft: 30,
    },
    stepContent: {
        marginTop: 16,
    },
    addCardButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    hint: {
        color: '#474D5F',
    },
}));

interface StepAddCardProps {
    handleSubmit: any;
    onSubmit: any;
    applicationSubmitError: any;
    applicationSubmitIsLoading: any;
    userCards: any;
    addCardIsLoading: any;
    handleAddCard: any;
    showButtonCardOrder: any;
    showCardOrderCheckbox: boolean;
    handleOrderCard: any;
    formProps: any;
}

const StepAddCard: React.FC<StepAddCardProps> = ({
    handleSubmit,
    onSubmit,
    applicationSubmitError,
    applicationSubmitIsLoading,
    userCards,
    addCardIsLoading,
    handleAddCard,
    showButtonCardOrder,
    showCardOrderCheckbox,
    handleOrderCard,
    formProps,
    ...props
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const findContext = useFeatures();
    const mspSelectCardsFeatureAvailable = useMemo(
        () => findContext(msp_select_cards_18_05_2021),
        [findContext]
    );
    const [useCardOrder, setUseCardOrder] = useState<boolean>(false);
    const handleChangeUseCardOrder = useCallback(() => {
        // если используем заявку на карту то чистим поле selected_card
        if (!useCardOrder) {
            formProps?.setValue('data.selected_card', '');
        }
        setUseCardOrder(!useCardOrder);
    }, [useCardOrder, formProps]);

    return (
        <Step key="addCard" {...props}>
            <StepLabel className={classes.stepLabel}>
                {t('pages.Application.bankDetails')}
            </StepLabel>
            <StepContent className={classes.stepContent}>
                <FormProvider {...formProps}>
                    <form onSubmit={handleSubmit(onSubmit('addCard'))}>
                        <Grid container direction="column" spacing={4}>
                            {mspSelectCardsFeatureAvailable ? (
                                <>
                                    {showCardOrderCheckbox && (
                                        <Grid item>
                                            <SelectCardOrders
                                                useCardOrderValue={useCardOrder}
                                                onChangeUseCardOrder={
                                                    handleChangeUseCardOrder
                                                }
                                            />
                                        </Grid>
                                    )}

                                    {!useCardOrder && !isEmpty(userCards) && (
                                        <Grid item>
                                            <SelectCard
                                                userCards={userCards}
                                                formProps={formProps}
                                            />
                                        </Grid>
                                    )}

                                    {!showCardOrderCheckbox &&
                                        !useCardOrder &&
                                        isEmpty(userCards) && (
                                            <Grid item>
                                                <Typography
                                                    variant="body1"
                                                    className={classes.hint}
                                                >
                                                    {t(
                                                        'pages.Application.noCardAndNoCardOrders'
                                                    )}
                                                </Typography>
                                            </Grid>
                                        )}

                                    <Grid item container spacing={4}>
                                        <Grid item xs={12} sm="auto">
                                            <Button
                                                startIcon={
                                                    addCardIsLoading && (
                                                        <CircularProgress size={24} />
                                                    )
                                                }
                                                disabled={
                                                    addCardIsLoading || useCardOrder
                                                }
                                                onClick={handleAddCard}
                                                variant="outlined"
                                                className={classes.addCardButton}
                                            >
                                                {t('pages.AddCard.addCard')}
                                            </Button>
                                        </Grid>

                                        {showButtonCardOrder && (
                                            <FeatureToggle name={card_request_10_02_2021}>
                                                <Grid item xs={12} sm="auto">
                                                    <Button
                                                        variant="outlined"
                                                        className={classes.addCardButton}
                                                        onClick={handleOrderCard}
                                                    >
                                                        {t('pages.Application.getMir')}
                                                    </Button>
                                                </Grid>
                                            </FeatureToggle>
                                        )}
                                    </Grid>
                                </>
                            ) : (
                                <>
                                    {!isEmpty(userCards) && (
                                        <Grid item>
                                            <Typography
                                                className={classes.stepDecription}
                                            >
                                                {t('pages.Application.cardPayment')}
                                            </Typography>
                                            <Box className={classes.collapse}>
                                                <Box display="flex" alignItems="center">
                                                    <Avatar
                                                        variant="square"
                                                        className={classes.cardIcon}
                                                    >
                                                        <Icon name="card" />
                                                    </Avatar>
                                                    <Typography
                                                        variant="h5"
                                                        component="p"
                                                        className={classes.cardNumber}
                                                    >
                                                        {userCards[0].maskPan}
                                                    </Typography>
                                                </Box>
                                            </Box>
                                        </Grid>
                                    )}

                                    {isEmpty(userCards) && (
                                        <Grid item container spacing={4}>
                                            <Grid item xs={12} sm="auto">
                                                <Button
                                                    startIcon={
                                                        addCardIsLoading && (
                                                            <CircularProgress size={24} />
                                                        )
                                                    }
                                                    disabled={
                                                        addCardIsLoading || useCardOrder
                                                    }
                                                    onClick={handleAddCard}
                                                    variant="outlined"
                                                    className={classes.addCardButton}
                                                >
                                                    {t('pages.AddCard.addCard')}
                                                </Button>
                                            </Grid>

                                            {showButtonCardOrder && (
                                                <FeatureToggle
                                                    name={card_request_10_02_2021}
                                                >
                                                    <Grid item xs={12} sm="auto">
                                                        <Button
                                                            variant="outlined"
                                                            className={
                                                                classes.addCardButton
                                                            }
                                                            onClick={handleOrderCard}
                                                        >
                                                            {t(
                                                                'pages.Application.getMir'
                                                            )}
                                                        </Button>
                                                    </Grid>
                                                </FeatureToggle>
                                            )}
                                        </Grid>
                                    )}
                                </>
                            )}

                            {!!applicationSubmitError && (
                                <Grid item>
                                    <ErrorMessage error={applicationSubmitError} />
                                </Grid>
                            )}

                            <Grid item />
                            <Grid item>
                                <Button
                                    type="submit"
                                    variant="contained"
                                    startIcon={
                                        applicationSubmitIsLoading && (
                                            <CircularProgress size={24} />
                                        )
                                    }
                                    disabled={
                                        isEmpty(userCards) || applicationSubmitIsLoading
                                    }
                                    className={classes.dialogButton}
                                >
                                    {t('actions.send')}
                                </Button>
                            </Grid>
                        </Grid>
                    </form>
                </FormProvider>
            </StepContent>
        </Step>
    );
};

export default StepAddCard;
