import React, { FC } from 'react';
import { useForm } from 'react-hook-form';
import { useSelector, useDispatch } from 'react-redux';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Button, CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { ProfileGroupsField } from 'components/organisms';
import { updateFamily } from 'store/actions';
import { RootState } from 'store/store';
import { ProfileRelations } from 'types/Profile';
import { ErrorMessage } from 'components/atoms';

import { FAMILY_MARITAL_STATUS_CATEGORY, FAMILY_SPOUSE_CATEGORY } from '../Form';
import { useSnackbar } from 'notistack';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    tabs: {
        borderRight: `1px solid ${theme.palette.divider}`,
    },
    container: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    saveButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        marginTop: 60,
        padding: '13px 28px',
    },
    form: {
        width: '100%',
    },
}));
interface FamilyProps {
    preparedFamily: ProfileRelations;
}

export const Family: FC<FamilyProps> = ({ preparedFamily }) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const familytState = useSelector((state: RootState) => state.profile.family);
    const { enqueueSnackbar } = useSnackbar();
    const { t } = useTranslation();
    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>({
        defaultValues: preparedFamily,
    });

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };

    const watchMaritalStatus = watch('maritalStatus');

    const submit = React.useCallback(
        formData => {
            dispatch(
                updateFamily(formData, () => {
                    enqueueSnackbar(t('com.Family.update'), {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                })
            );
        },
        [dispatch]
    );

    return (
        <>
            <form onSubmit={handleSubmit(submit)} className={classes.form}>
                <ProfileGroupsField
                    fields={FAMILY_MARITAL_STATUS_CATEGORY.fields}
                    groupName={FAMILY_MARITAL_STATUS_CATEGORY.groupId}
                    formProps={formProps}
                    key={FAMILY_MARITAL_STATUS_CATEGORY.groupId}
                />
                {(watchMaritalStatus === 1 || watchMaritalStatus === 5) && (
                    <ProfileGroupsField
                        fields={FAMILY_SPOUSE_CATEGORY.fields}
                        groupName={FAMILY_SPOUSE_CATEGORY.groupId}
                        formProps={formProps}
                        key={FAMILY_SPOUSE_CATEGORY.groupId}
                    />
                )}

                {familytState.updateRequest.errors && (
                    <ErrorMessage error={familytState.updateRequest.errors} />
                )}

                <Button
                    className={classes.saveButton}
                    disabled={familytState.updateRequest.isLoading}
                    startIcon={
                        familytState.updateRequest.isLoading && (
                            <CircularProgress size={24} />
                        )
                    }
                    disableElevation
                    type="submit"
                    variant="contained"
                >
                    {t('actions.save')}
                </Button>
            </form>
        </>
    );
};
