import * as React from 'react';
import { FC } from 'react';
import {
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
} from '@material-ui/lab';
import TextField from '../TextField';
import { useTranslation } from 'react-i18next';

export interface AutocompleteProps
    extends Omit<MUIAutocompleteProps<SelectionOption, true, true, true>, 'renderInput'> {
    /**
     * Массив опций
     */
    options: {
        name: string;
        value?: string | ReadonlyArray<string> | number;
    }[];
    /**
     * Подпись/метка к элементу
     */
    label?: string;
    /**
     * Имена классов для возможности дополнительной стилизации
     */
    classes?: object;
    /**
     * Визуализация элемента ввода
     */
    renderInput?: (params: object) => React.ReactNode;
    /**
     * Ссылка ref
     */
    ref?: ((instance: unknown) => void) | React.RefObject<unknown> | null;
    helperText?: string;
    variant?: 'outlined' | 'standard';
    error?: boolean;
    required?: boolean;
}

interface SelectionOption {
    /**
     * Название варианта
     */
    name: string;
    /**
     * Что выбираем
     */
    value?: string | ReadonlyArray<string> | number;
}

const Autocomplete: FC<AutocompleteProps> = ({
    label,
    options,
    placeholder,
    variant,
    size,
    className,
    disabled,
    error,
    fullWidth,
    helperText,
    required,
    ...other
}) => {
    const { t } = useTranslation();
    return (
        <MUIAutocomplete
            noOptionsText={t('actions.noSearchResult')}
            loadingText={t('actions.loadingDots')}
            limitTags={5}
            {...other}
            options={options}
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    required={required}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    variant={variant}
                    label={label}
                    helperText={helperText}
                    placeholder={placeholder}
                />
            )}
        />
    );
};

export default Autocomplete;
