import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    UPDATE_CHILD_REQUESTING,
    UPDATE_CHILD_SUCCEED,
    UPDATE_CHILD_FAILED,
} from '../../../constants';
import { ProfileChild } from 'types/Profile';

import { getChild } from './getChild';
import { getChildrens } from './getChildrens';
import { errorHandle, transformDateToServerString } from 'utils/utils';

export const updateChild = (child: ProfileChild, childId?: string, cb?: Function) => (
    dispatch,
    getState
) => {
    const dispatchData = (actionType, requestStatus, errors) => {
        dispatch({
            type: actionType,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(UPDATE_CHILD_REQUESTING, null, null);

    return axios({
        method: 'POST',
        url: '/api/Profile/child/upsert',
        headers: getCommonHeaders(getState()),
        data: {
            ...child,
            id: childId,
            birthOfDate: transformDateToServerString(child.birthOfDate),
            certificateIssueDate: transformDateToServerString(child.certificateIssueDate),
        },
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;

            if (isSuccess === true) {
                if (child?.id) {
                    dispatch(getChild(child?.id));
                } else {
                    dispatch(getChildrens());
                }
                cb && cb();
                dispatchData(UPDATE_CHILD_SUCCEED, requestStatus, null);
            }
            if (isSuccess === false) {
                const error = errorHandle(response.data);
                dispatchData(UPDATE_CHILD_FAILED, requestStatus, error.error);
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);
            dispatchData(UPDATE_CHILD_FAILED, null, error.error);
        });
};
