import React from 'react';
import { useHistory } from 'react-router-dom';

import { Dialog, makeStyles, Theme, useMediaQuery, useTheme } from '@material-ui/core';

import { AuthError } from './AuthError';

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        maxWidth: 480,
        padding: 40,
        backgroundColor: theme.palette.common.white,
    },
    closeButton: {
        position: 'absolute',
        right: 60 - 12,
        top: 60 - 12,
        color: theme.palette.grey[500],
    },
}));

const DialogContext = React.createContext({
    openDialog: type => {},
    closeDialog: () => {},
});

export const useDialog = () => {
    return React.useContext(DialogContext);
};

export const DialogProvider = React.memo(props => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const [open, setOpen] = React.useState(false);
    const [type, setType] = React.useState(null);

    const openDialog = type => {
        setType(type);
        setOpen(true);
    };

    const closeDialog = () => {
        setOpen(false);
    };

    const clear = () => {
        setTimeout(() => {
            setType(null);
        }, 500);
    };

    return (
        <>
            <Dialog
                classes={{
                    paper: classes.dialog,
                }}
                fullScreen={isViewXS}
                open={open}
                onClose={closeDialog}
                fullWidth
                onExit={clear}
            >
                {type === 'AuthError' && <AuthError onClose={closeDialog} />}
            </Dialog>
            <DialogContext.Provider
                value={{
                    openDialog,
                    closeDialog,
                }}
            >
                {props.children}
            </DialogContext.Provider>
        </>
    );
});
