import { startChatBot } from '../chat';

import subscribeToSignalR from 'utils/connectToSignalR';
import { connectToSignalR, transformChatMessage } from './chat.actions';

export const subscribeToChatEvents = () => {
    return (dispatch, getState) => {
        const handlers = [];
        handlers.push({
            event: 'answerMessageEvent',
            handler: message => {
                if (message && message?.isClientMessage) {
                    dispatch(transformChatMessage(message));
                } else {
                    setTimeout(() => {
                        dispatch(transformChatMessage(message));
                    }, 1000);
                }
            },
        });
        const token =
            getState().account.signInEsiaConfirm.token ||
            getState().account.signinAnonim.anonimToken;
        return subscribeToSignalR(handlers, token);
    };
};

/**
 * Initializes support chat.
 *
 */
export const initializeChat = () => {
    return (dispatch, getState) => {
        if (
            getState().account.signInEsiaConfirm.token ||
            getState().account.signinAnonim.anonimToken
        ) {
            dispatch(connectToSignalR());
            dispatch(startChatBot());
        }
    };
};
