import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        // maxWidth: 1216,
    },
    pageTitle: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            alignItems: 'flex-start',
        },
        position: 'relative',
    },
    subsidyCardCarousel: {
        [theme.breakpoints.down('xs')]: {
            flexDirection: 'column',
            padding: 0,
            marginRight: 0,
            WebkitOverflowScrolling: 'touch',
        },
        display: 'flex',
        flexWrap: 'wrap',
        marginRight: -32,
        padding: 24,
    },
    subsidyCard: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: 16,
            marginRight: 0,
            marginBottom: 16,
            minHeight: 'auto',
            height: 'auto',
        },
        textDecoration: 'none',
        color: 'inherit',
        width: 197,
        minWidth: 197,
        minHeight: 168,
        marginRight: 24,
        marginBottom: 24,
        padding: 24,
        borderRadius: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
    },
    subsidyCardTitle: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'bold',
            fontSize: 15,
            maxHeight: 15 * 1.4 * 4,
        },
        fontWeight: 500,
        fontSize: 14,
        lineHeight: 1.4,
        maxHeight: 14 * 1.4 * 4,
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    subsidyCardSubtitleContainer: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
            marginTop: 8,
        },
        display: 'flex',
        alignItems: 'flex-start',
    },
    subsidyAmount: {
        fontWeight: 'normal',
        color: 'rgba(0, 0, 0, 0.35)',
        fontSize: 13,
        lineHeight: 1.62,
        whiteSpace: 'nowrap',
        overflow: 'hidden',
        textOverflow: 'ellipsis',
    },
    search: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        position: 'relative',
    },
    iconButton: {
        background: 'rgba(255, 255, 255, 0.5)',
        borderRadius: 8,
        color: theme.palette.primary.main,
        padding: '3px 6px',
        fontSize: 14,
        lineHeight: 1.43,
    },
    subsidyCalcLink: {
        fontSize: 14,
        lineHeight: 1.43,
        color: theme.palette.primary.main,
        textDecoration: 'none',
    },
    linkTitle: {
        color: '#222',
        '&:hover': {
            color: '#222',
        },
    },
}));
