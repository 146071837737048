import * as constants from 'constants/chat.constants';
import { SIGN_IN_ESIA_CONFIRM_SUCCEED } from '../constants';
import {
    updateMessage,
    updateMessages,
    getMessagesWithParsedTime,
    getMessageWithParsedTime,
    getMessagesByDay,
} from '../actions/chat';
import { transforms } from '../actions/chat/messageTransforms';
import { ChatState, Message } from 'types/Chat';
import { generateKey } from 'store/actions/chat/chatUtils';

const INITIAL_STATE: ChatState = {
    currentChat: null,
    messages: [],
    messagesByDay: {},
    chatBotLoaded: false,
    chats: [],
    initialLoading: true,
    messagesLoading: false,
    chatsLoading: false,
    chatIsLoading: false,
    sendMessageLoading: false,
    sendMessageText: null,
};

export default function (state = INITIAL_STATE, action) {
    switch (action.type) {
        case constants.START_BOT_START:
            return {
                ...state,
                error: '',
            };
        case constants.START_BOT_FAILURE:
            return {
                ...state,
                error: action.payload.error,
            };
        case constants.START_BOT_SUCCESS:
            return {
                ...state,
                chatBotLoaded: true,
            };
        case constants.GET_CHAT_HISTORY_START:
            return {
                ...state,
                messagesLoading: true,
                error: '',
            };
        case constants.GET_CHAT_HISTORY_FAILURE:
            return {
                ...state,
                messagesLoading: false,
                initialLoading: false,
                error: action.payload.error,
            };
        case constants.GET_CHAT_HISTORY_SUCCESS:
            const { messages } = action.payload;
            const messagesReversed = messages.reverse();
            // const messagesWithTime = getMessagesWithParsedTime(messagesReversed);
            const messagesEscaped = updateMessages(messagesReversed);
            const messagesByDay = getMessagesByDay(messagesEscaped);

            return {
                ...state,
                messagesLoading: false,
                initialLoading: false,
                messagesByDay: messagesByDay,
                messages: messagesEscaped,
            };
        case constants.CLEAR_CHAT_HISTORY_START:
            return {
                ...state,
                messagesLoading: true,
                error: '',
            };
        case constants.CLEAR_CHAT_HISTORY_FAILURE:
            return {
                ...state,
                messagesLoading: false,
                error: action.payload.error,
            };
        case constants.CLEAR_CHAT_HISTORY_SUCCESS:
            return {
                ...state,
                messagesLoading: false,
                messagesByDay: {},
                messages: [],
            };
        case constants.SEND_MESSAGE_START: {
            return {
                ...state,
                sendMessageLoading: true,
                error: null,
                sendMessageText: action.payload.sendMessageText,
            };
        }
        case constants.SEND_MESSAGE_FAILURE: {
            return {
                ...state,
                sendMessageLoading: false,
                error: action.payload.error,
                sendMessageError: action.payload.error,
                sendMessageText: null,
            };
        }
        case constants.SEND_MESSAGE_SUCCESS: {
            return {
                ...state,
                sendMessageLoading: false,
                sendMessageError: null,
                sendMessageText: null,
            };
        }
        case constants.NEW_WEBSOCKET_MESSAGE: {
            const {
                data: { message },
            } = action.payload;
            if (!message) return state;
            // const messageWithTime = getMessageWithParsedTime(message);
            const messageEscaped = updateMessage(message, transforms);
            const key = generateKey(messageEscaped.createDateTime);
            let messagesI = [];
            let messagesByDayI = {};
            const duplicateIndex = messagesI.findIndex(
                (msg: Message) => msg.id === message.id
            );
            if (duplicateIndex < 0) {
                messagesI = [...state.messages, messageEscaped];
                if (state.messagesByDay[key]) {
                    messagesByDayI[key] = [...state.messagesByDay[key], messageEscaped];
                } else {
                    messagesByDayI[key] = [messageEscaped];
                }
            } else {
                messagesI = [...state.messages];
                messagesI.splice(duplicateIndex, 1, messageEscaped);
                messagesByDayI = { [key]: [...state.messagesByDay[key]] };
                messagesByDayI[key].splice(duplicateIndex, 1, messageEscaped);
            }
            return {
                ...state,
                messagesByDay: { ...state.messagesByDay, [key]: messagesByDayI[key] },
                messages: messagesI,
            };
        }

        case constants.RESET: {
            return INITIAL_STATE;
        }

        case SIGN_IN_ESIA_CONFIRM_SUCCEED: {
            return INITIAL_STATE;
        }

        default:
            return state;
    }
}
