import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import cx from 'classnames';

export const useStyles = makeStyles((theme: Theme) => ({
    active: {
        [theme.breakpoints.down('xs')]: {
            paddingLeft: 0,
            borderLeft: 'none',
            marginLeft: 0,
        },
    },
    stepLabel: {
        '& .MuiStepLabel-label, & .MuiStepLabel-active, & .MuiStepLabel-completed': {
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1.4,
            },
            fontWeight: 'bold',
            color: theme.palette.grey[900],
            fontSize: 24,
            lineHeight: 1.33,
        },
        '& .MuiStepLabel-completed': {
            cursor: 'pointer',
            '&:hover': {
                color: '#5d5d5d',
            },
        },
    },
    stepContent: {
        marginTop: 16,
    },
}));

interface Props {
    children: React.ReactNode;
    active?: boolean;
    stepLabel?: string;
    handleBack: Function;
    index?: number;
}

export const CustomStep: React.FC<Props> = ({
    children,
    stepLabel,
    handleBack,
    ...props
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <Step {...props}>
                <StepLabel
                    className={classes.stepLabel}
                    onClick={() => handleBack(props.index)}
                >
                    {stepLabel}
                </StepLabel>
                <StepContent
                    className={cx(classes.stepContent, {
                        [classes.active]: props.active,
                    })}
                >
                    {children}
                </StepContent>
            </Step>
        </>
    );
};
