import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    root: {
        marginBottom: theme.spacing(3),
    },
    pageContent: {},
    pageGrid: {},
    pageLoadingContent: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: theme.palette.common.white,
            padding: 0,
        },
        padding: 24,
    },
    pageLoadingGrid: {
        [theme.breakpoints.down('xs')]: {
            '&::before': {
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    grid: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        flexDirection: 'column',
        padding: 24,
    },
    sybsidyButtonContainer: {
        marginTop: 40,
    },
    subsidyDownloadButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
    subsidyInformationContainer: {
        flexDirection: 'column',
    },
    subsidyInformationTitle: {
        [theme.breakpoints.down('xs')]: {},
        marginRight: 5,
        color: theme.palette.grey[400],
    },
    subsidyInformation: {
        '& + &': {
            marginTop: 16,
        },
        display: 'flex',
    },
    subsidyCommentContainer: {
        marginTop: 40,
    },
    delButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            fontWeight: 'normal',
            fontSize: 16,
            lineHeight: 1.4,
            marginTop: 20,
        },
        color: '#5780EA',
        padding: '13px 28px',
    },
    subsidyComment: {
        marginTop: 20,
        color: theme.palette.grey[400],
    },
    issueApplicationButton: {
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
            width: '100%',
            marginBottom: 16,
        },
        marginRight: 16,
    },
    subsidies: {
        marginTop: theme.spacing(3),
    },
}));
