import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_CHILD_REQUESTING,
    GET_CHILD_SUCCEED,
    GET_CHILD_FAILED,
} from '../../../constants';
import { ApiResponse } from 'types/ApiResponse';
import { ProfileChild } from 'types/Profile';
import { parseDateString } from 'utils/utils';

export const getChild = childId => (dispatch, getState) => {
    const dispatchData = (actionType, children, requestStatus, errors) => {
        dispatch({
            type: actionType,
            children: children,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(GET_CHILD_REQUESTING, null, null, null);

    axios
        .get(`/api/Profile/child/${childId}`, {
            method: 'GET',
            url: '/api/Profile/get',
            headers: getCommonHeaders(getState()),
        })
        .then((response: AxiosResponse<ApiResponse<ProfileChild>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                const data = response.data.result;

                dispatchData(GET_CHILD_SUCCEED, data, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_CHILD_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_CHILD_FAILED, null, null, errors);
        });
};
