import React, { FC } from 'react';
import classNames from 'classnames';
import { useTranslation } from 'react-i18next';

import { Typography, Button } from '@material-ui/core';

import { Icon } from 'components/atoms';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { useStyles } from './Subsidies.styles';

interface SubsidyAmountProps {
    subsidy: SubsidyManagementModel;
    handleClickCalculator: (event: React.MouseEvent<HTMLButtonElement>) => void;
}
export const SubsidyAmount: FC<SubsidyAmountProps> = ({
    subsidy,
    handleClickCalculator,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const handleClickCalculatorUrl = () => {
        window.open(subsidy?.calculatorButton.externalCalculatorUrl, '_blank');
    };

    const checkWebView = url =>
        !url.includes(
            process.env.NODE_ENV === 'production' ? window.location.host : 'zabota'
        );
    if (subsidy?.amount && subsidy?.amountDescription) {
        return (
            <Typography
                variant="body1"
                className={classNames(classes.subsidyAmount, 'subsidyAmount')}
            >
                <Icon name="subsidyRuble" />{' '}
                {`${subsidy.amount} ${subsidy.amountDescription}`}
            </Typography>
        );
    }

    if (subsidy?.amount) {
        return (
            <Typography
                variant="body1"
                className={classNames(classes.subsidyAmount, 'subsidyAmount')}
            >
                <Icon name="subsidyRuble" /> {subsidy.amount}
            </Typography>
        );
    }

    if (subsidy?.amountDescription) {
        return (
            <Typography
                variant="body1"
                className={classNames(classes.subsidyAmount, 'subsidyAmount')}
            >
                {subsidy.amountDescription}
            </Typography>
        );
    }

    if (subsidy?.calculatorButton.isCalculatorActive) {
        if (
            subsidy?.calculatorButton.buttonName &&
            checkWebView(subsidy?.calculatorButton.externalCalculatorUrl)
        ) {
            return (
                <Typography
                    className={classes.subsidyCalcLink}
                    onClick={handleClickCalculatorUrl}
                >
                    <Button
                        className={classNames(classes.iconButton, 'iconButton')}
                        variant="text"
                        endIcon={<Icon name="arrowRight2" />}
                    >
                        {t('actions.calculate')}
                    </Button>
                </Typography>
            );
        }
        return (
            <Button
                className={classNames(classes.iconButton, 'iconButton')}
                onClick={handleClickCalculator}
                variant="text"
                endIcon={<Icon name="arrowRight2" />}
            >
                {t('actions.calculate')}
            </Button>
        );
    }

    return null;
};
