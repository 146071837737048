import React from 'react';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { ApplicationGroupsField } from 'components/organisms';
import { ErrorMessage } from 'components/atoms';
import { CircularProgress, Typography, Grid, Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { ApplicationGroupSubtitle } from 'components/organisms';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from 'components/atoms';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { Select } from 'components/atoms';
import { profileChildrenToSelectOptions } from './helper';
import { FormControl, InputLabel } from '@abdt/ornament';
import { selectProfileChild } from 'store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import { find } from 'lodash';

export const useStyles = makeStyles((theme: Theme) => ({
    child: {
        position: 'relative',
        marginBottom: 32,
    },
    title: {
        color: '#10002B',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: 1.33,
    },
    anketaChildCircleBtn: {
        position: 'absolute',
        left: '-30px',
        top: '6px',
    },
    buttonsContainer: {
        marginTop: 10,
    },
    field: {
        width: 270,
        marginRight: 16,
    },
}));

interface Props {
    profileChildren: any;
    childIndex: any;
    currentChildId: any;
    childrenBlock: any;
}

export const ChildSelectField: React.FC<Props> = ({
    profileChildren,
    childIndex,
    childrenBlock,
    currentChildId,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const options = profileChildrenToSelectOptions(
        currentChildId,
        profileChildren,
        childrenBlock
    );
    const dispatch = useDispatch();
    let value = find(profileChildren, { childId: currentChildId });
    if (value) {
        value = value.childId;
    } else {
        value = 0;
    }

    const handleChange = e => {
        const selectedChild = find(profileChildren, { childId: e.target.value });
        dispatch(selectProfileChild(childIndex, selectedChild));
    };

    return (
        <Box m={theme.spacing(2, 0)} className={classes.field}>
            <FormControl fullWidth={true} variant="outlined">
                <InputLabel>Выберите ребенка</InputLabel>
                <Select
                    name={'childselect'}
                    defaultValue={value}
                    options={options}
                    onChange={handleChange}
                />
            </FormControl>
        </Box>
    );
};
