import * as React from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTabs, { TabsProps as MaterialTabsProps } from '@material-ui/core/Tabs';
import { FC } from 'react';

const useStyles = makeStyles(theme => ({
    root: {
        minHeight: 40,
    },
    indicator: {
        backgroundColor: '#00b2a9',
    },
    hidden: {
        display: 'none',
    },
    inverse: {
        backgroundColor: '#fff',
    },
    scrollButtons: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
    },
}));

interface TabsProps extends MaterialTabsProps {
    /**
     * Вариант внешнего вида
     */
    appearance: 'tabs' | 'tabs-inverse' | 'switcher';
}

const Tabs: FC<TabsProps> = ({ classes, appearance, ...props }) => {
    const s = useStyles();

    return (
        <MaterialTabs
            {...props}
            classes={{
                scrollButtons: s.scrollButtons,
                root: s.root,
                indicator: cx(s.indicator, {
                    [s.hidden]: appearance === 'switcher',
                    [s.inverse]: appearance === 'tabs-inverse',
                }),
            }}
        />
    );
};

Tabs.defaultProps = {
    appearance: 'tabs',
};

export default Tabs;
