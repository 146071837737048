import filter from 'lodash/filter';
import isEmpty from 'lodash/isEmpty';
import findIndex from 'lodash/findIndex';
import cloneDeep from 'lodash/cloneDeep';
import { errorHandle } from 'utils/utils';
import imageCompression from 'browser-image-compression';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';

import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { AppThunk } from 'store/store';
import {
    UploaderFiles,
    UploaderSetFileStatusLoaded,
    UploaderSetFileErrorNotLoaded,
    UploaderFile,
} from 'components/atoms';
import { AxiosResponse } from 'axios';
import * as AppSlice from 'store/slices/application.slice';
import { normalizeFields, normalizeApplication } from './helper';
import replace from 'lodash/replace';

/** 
К следующей анкете (если есть)
@store currentApplicationId
*/
export const getNextApplication = (
    deleteCurrentApplication?: boolean
): AppThunk => async (dispatch, getState) => {
    try {
        const availableApplications = getState().application.availableApplications;
        const oldApplicationId = getState().application.currentApplicationId;
        const currentIndex = findIndex(availableApplications, [
            'requestId',
            oldApplicationId,
        ]);
        const nextIndex = (currentIndex + 1) % availableApplications.length;
        let currentApplicationId = availableApplications[nextIndex].requestId;
        // if (nextIndex === 0) {
        //     currentApplicationId = null
        // }

        // if (deleteCurrentApplication) {
        //     availableApplications.splice(currentIndex, 1);
        // }
        dispatch(AppSlice.nextApplication({ currentApplicationId }));
    } catch (rawError) {
        // const error = errorHandle(rawError);
        // dispatch(getApplicationsFailure(error));
    }
};

/** 
Все субсидии где statusId === 0 (availableApplications)
@store availableApplications
@store currentApplicationId
@store allApplications
*/
export const getApplications = (
    showNotification?: Function,
    callback?: Function
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(AppSlice.getApplicationsStart());

        const res = await axios.get('/api/Subsidies/get', {
            headers: getCommonHeaders(getState()),
        });
        if (res.data.success) {
            const availableApplications = filter(
                res.data.result,
                application => application.statusId === 0
            );

            if (isEmpty(availableApplications)) {
                dispatch(
                    AppSlice.getApplicationsSuccess({
                        availableApplications: null,
                        allApplications: res.data.result,
                    })
                );
                showNotification && showNotification();
            } else {
                dispatch(
                    AppSlice.getApplicationsSuccess({
                        availableApplications,
                        allApplications: res.data.result,
                    })
                );
                callback && callback(availableApplications[0].requestId);
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(AppSlice.getApplicationsFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(AppSlice.getApplicationsFailure(error));
    }
};

/** 
Получает application по currentApplicationId
@store application
@store preparedApplication
@store currentApplicationId
*/
export const getApplicationById = (id: string): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(AppSlice.getApplicationByIdStart());

        const res: AxiosResponse<ApiResponse<any>> = await axios.get(
            `/api/Allowance/${id}/fields`,
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            dispatch(
                AppSlice.getApplicationByIdSuccess({
                    //preparedApplication,
                    application: res.data.result,
                    currentApplicationId: id,
                })
            );
        } else {
            const error = errorHandle(res.data);
            dispatch(AppSlice.getApplicationByIdFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(AppSlice.getApplicationByIdFailure(error));
    }
};

/** 
Сохраняет Application
@store applicationSent
@store submitData
*/
export const saveApplication = (
    id: string,
    data,
    send?: boolean,
    cb?: Function
): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(AppSlice.saveApplicationStart());

        const res: AxiosResponse<ApiResponse<any>> = await axios.post(
            '/api/Subsidies/save',
            {
                data: data.data,
                childrenData: data.childrenData ? data.childrenData : [],
                familyMembers: data.familyMembers ? data.familyMembers : [],
                requestId: id,
                send,
            },
            { headers: getCommonHeaders(getState()) }
        );
        if (res.data.success) {
            dispatch(
                AppSlice.saveApplicationSuccess({
                    applicationSent: send,
                    submitData: data,
                })
            );
            if (cb && typeof cb === 'function') {
                cb();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(AppSlice.saveApplicationFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(AppSlice.saveApplicationFailure(error));
    }
};

const imageCompressionOptions = {
    maxSizeMB: 5,
    // maxWidthOrHeight: 1920,
    useWebWorker: true,
};

export const uploadFile = (
    setFileStatusLoaded: UploaderSetFileStatusLoaded,
    setFileErrorNotLoaded: UploaderSetFileErrorNotLoaded,
    file: UploaderFile,
    fieldTypeId: string,
    setFilesData: (file) => void
): AppThunk => async (dispatch, getState) => {
    if (!file) return;

    try {
        let compressedFile: any = file;
        if (file.type && file.type.split('/')[0] === 'image') {
            try {
                compressedFile = await imageCompression(file, imageCompressionOptions);
            } catch (error) {
                compressedFile = file;
            }
        }
        const formData = new FormData();

        // formData.append('FileTypeId', fieldTypeId);
        // formData.append('DocumentVersionId', '');
        // formData.append('SourceDate', '');
        formData.append('files', compressedFile, compressedFile.name);

        const response: AxiosResponse<ApiResponse<any>> = await axios.post(
            `/api/files/byFileType/${fieldTypeId}`,
            formData,
            {
                headers: {
                    ...getCommonHeaders(getState()),
                    'Content-Type': 'multipart/form-data',
                },
            }
        );

        setFileStatusLoaded(file.dropId);
        const newFile = Object.assign(file, {
            serverData: response.data.result[0],
        });
        setFilesData(newFile);
    } catch (rawError) {
        const tempError = rawError.response?.data || rawError;
        const error = errorHandle(tempError, 'Ошибка загрузки файла.');
        setFileErrorNotLoaded(file.dropId, error.error);
    }
};

/** 
Добавление ребенка к текущему application, используя шаблон
*/
export const addChild = (): AppThunk => async (dispatch, getState) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const childDataBlockIndex = findIndex(blocks, { blockType: 'child_data' });

    if (!childDataBlockIndex) {
        console.log('Ошибка. childDataBlock - пустой');
        return;
    }
    const childFieldsTemplate = blocks[childDataBlockIndex]?.childFieldsTemplate;

    if (isEmpty(childFieldsTemplate)) {
        console.log('Ошибка. childFieldsTemplate - пустой');
        return;
    }

    dispatch(
        AppSlice.addChild({
            childDataBlockIndex,
            childFieldsTemplate,
        })
    );
};

/** 
Удаление ребенка
*/
export const delChild = (childIndex): AppThunk => async (dispatch, getState) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const childDataBlockIndex = findIndex(blocks, { blockType: 'child_data' });

    if (!childDataBlockIndex) {
        console.log('Ошибка. childDataBlock - пустой');
        return;
    }

    dispatch(
        AppSlice.delChild({
            childDataBlockIndex,
            childIndex,
        })
    );
};

/** 
Выбор селекта с детьми и замена на текущего
*/
export const selectProfileChild = (childIndex, selectedChild): AppThunk => async (
    dispatch,
    getState
) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const childDataBlockIndex = findIndex(blocks, { blockType: 'child_data' });

    if (!childDataBlockIndex) {
        console.log('Ошибка. childDataBlock - пустой');
        return;
    }

    const childFieldsTemplate = blocks[childDataBlockIndex].childFieldsTemplate;

    if (!selectedChild) {
        selectedChild = {
            childId: null,
            childFields: childFieldsTemplate,
        };
    }

    dispatch(
        AppSlice.replaceChild({
            childDataBlockIndex,
            childIndex,
            selectedChild,
        })
    );
};

/** 
Добавление Члена семьи к текущему application, используя шаблон
*/
export const addFamilyMember = (): AppThunk => async (dispatch, getState) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const familyDataBlockIndex = findIndex(blocks, { blockType: 'family' });

    if (!familyDataBlockIndex) {
        console.log('Ошибка. familyDataBlockIndex - пустой');
        return;
    }
    const familyMembersTemplate = blocks[familyDataBlockIndex]?.familyMembersTemplate;

    if (isEmpty(familyMembersTemplate)) {
        console.log('Ошибка. familyMembersTemplate - пустой');
        return;
    }

    dispatch(
        AppSlice.addFamilyMember({
            familyDataBlockIndex,
            familyMembersTemplate,
        })
    );
};

/** 
Удаление Члена семьи
*/
export const delFamilyMember = (familyMemberIndex): AppThunk => async (
    dispatch,
    getState
) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const familyDataBlockIndex = findIndex(blocks, { blockType: 'family' });

    if (!familyDataBlockIndex) {
        console.log('Ошибка. familyDataBlockIndex - пустой');
        return;
    }

    dispatch(
        AppSlice.delFamilyMember({
            familyDataBlockIndex,
            familyMemberIndex,
        })
    );
};

/** 
Добавление Родственника к текущему Члену семьи, используя шаблон
*/
export const addRelativeMember = (familyMemberIndex): AppThunk => async (
    dispatch,
    getState
) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const familyDataBlockIndex = findIndex(blocks, { blockType: 'family' });

    if (!familyDataBlockIndex) {
        console.log('Ошибка. familyDataBlockIndex - пустой');
        return;
    }
    const relativeTemplate = blocks[familyDataBlockIndex]?.relativeTemplate;

    if (isEmpty(relativeTemplate)) {
        console.log('Ошибка. familyMembersTemplate - пустой');
        return;
    }

    dispatch(
        AppSlice.addRelativeMember({
            familyDataBlockIndex,
            familyMemberIndex,
            relativeTemplate,
        })
    );
};

/** 
Удаление Родственника у Члена семьи
*/
export const delRelativeMember = (
    relativeMemberIndex,
    familyMemberIndex
): AppThunk => async (dispatch, getState) => {
    let application = cloneDeep(getState().application.application);
    const blocks = application.blocks;
    const familyDataBlockIndex = findIndex(blocks, { blockType: 'family' });

    if (!familyDataBlockIndex) {
        console.log('Ошибка. familyDataBlockIndex - пустой');
        return;
    }

    dispatch(
        AppSlice.delRelativeMember({
            familyDataBlockIndex,
            relativeMemberIndex,
            familyMemberIndex,
        })
    );
};

/** 
Замена блока (динамического) новым
*/
//TODO: разобраться с useBlockPreloading, бек его другим присылает почему-то
export const setBlock = (block, blockIndex): AppThunk => async (dispatch, getState) => {
    dispatch(
        AppSlice.setBlock({
            block: { ...block, useBlockPreloading: true },
            blockIndex,
        })
    );
};

/** 
Получение детей из профиля
*/
export const getProfileChildren = (currentApplicationId: string): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(AppSlice.getProfileChildrenStart());
        const res: any = await axios.get(
            `/api/Allowance/user/${currentApplicationId}/childs`,
            {
                headers: getCommonHeaders(getState()),
            }
        );
        if (res.data.success) {
            dispatch(
                AppSlice.getProfileChildrenSuccess({
                    profileChildren: res.data.result?.blocks[0]?.childrenBlock,
                })
            );
        } else {
            const error = errorHandle(res.data);
            dispatch(AppSlice.getProfileChildrenFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(AppSlice.getProfileChildrenFailure(error));
    }
};

export const saveBlock = (id: string, data, callback?: Function): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(AppSlice.saveApplicationStart());

        const res: AxiosResponse<ApiResponse<any>> = await axios.post(
            '/api/Subsidies/block/save',
            {
                data: data.data,
                requestId: id,
            },
            { headers: getCommonHeaders(getState()) }
        );
        if (res.data.success) {
            dispatch(AppSlice.saveApplicationBlockSuccess());
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(AppSlice.saveApplicationFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(AppSlice.saveApplicationFailure(error));
    }
};

export const clearApplication = (): AppThunk => async (dispatch, getState) => {
    dispatch(AppSlice.clearApplication());
};
