import React from 'react';
import cx from 'classnames';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
    Box,
    Container as MaterialUiContainer,
    Grid,
    Hidden,
    useMediaQuery,
} from '@material-ui/core';

import { StatementList, AppNavigation, NewsBanner } from 'components/molecules';
import { Footer } from 'components/common/Footer';
import { Header } from 'components/common/Header';
import { useQuery } from 'hooks/useQuery';
import { Messages } from 'components/common/Messages';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            // appBar height
            paddingTop: 62,
        },
        // appBar height
        paddingTop: 72,
        width: '100%',
        flex: '1 0 auto',
    },
    wrapper: {
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column',
        position: 'relative',
        height: '100vh',
    },
    '@global': {
        '*': {
            'scrollbar-color':
                '#e3e3e3 #fff0' /* FF «цвет ползунка» «цвет полосы скроллбара» */,
            scrollbarWidth: 'thin' /* FF толщина */,
        },
        '*::-webkit-scrollbar': {
            width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
    },
    rootWithFooter: {
        [theme.breakpoints.down('sm')]: {
            // 100vh - footer height
            //  minHeight: 'calc(100% - 186px)',
        },
        // 100vh - footer height
        //minHeight: 'calc(100% - 104px)',
        paddingBottom: 40,
    },
    container: {
        // height: '100vh',
    },
    footerContainer: {
        //position: 'absolute',
        width: '100%',
        bottom: 0,
        background: '#fff',
        flex: '0 0 auto',
    },
    grid: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'column-reverse',
            marginTop: 40,
        },
        '&::before': {
            content: '""',
            position: 'fixed',
            top: 0,
            bottom: 0,
            backgroundColor: '#EAF6FF',
            // width: '100vw',
            right: 0,
            left: 0,
            zIndex: -20,
        },
        display: 'flex',
        flexDirection: 'row',
        marginTop: 56,
        flexWrap: 'nowrap',
        boxSizing: 'content-box',
    },
    leftColumn: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: 'auto',
            marginRight: 0,
        },
        width: 232,
        minWidth: 232,
        marginRight: 32,
    },
    rightColumn: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: 'auto',
        },
        width: 232,
        minWidth: 232,
    },
    contentColumn: {
        [theme.breakpoints.down('sm')]: {
            minWidth: 'auto',
            width: (props: any) => (props.fullContentWidth ? '100%' : 'auto'),
            marginRight: 0,
        },
        minWidth: 688,
        width: (props: any) => (props.fullContentWidth ? '100%' : 688),
        marginRight: 32,
    },
    pageContent: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: '#eaf6ff',
        },
        borderRadius: 8,
        backgroundColor: theme.palette.common.white,
        width: '100%',
    },
    pageHeader: {
        marginBottom: 32,
    },
    webViewContainer: {
        padding: 16,
    },
}));

interface Props {
    contentClassName?: string;
    containerClassName?: string;
    gridClassName?: string;
    pageContentClassName?: string;
    pageHeaderClassName?: string;
    pageHeader?: React.ReactNode;
    // pageContent?: React.ReactNode
    withFooter?: boolean;
    withRightColumn?: boolean;
    withBanner?: boolean;
    contentColumnClassName?: string;
    fullContentWidth?: boolean;
}

export const MainLayout: React.FC<Props> = props => {
    const {
        children,
        pageHeader,
        // pageContent,
        withFooter = true,
        withBanner,
        withRightColumn = true,
        containerClassName,
        contentClassName,
        gridClassName,
        pageContentClassName,
        pageHeaderClassName,
        contentColumnClassName,
        fullContentWidth,
    } = props;

    const classes = useStyles({ fullContentWidth });
    let query = useQuery();
    if (query.get('webView') === 'true') {
        return (
            <Grid container className={classes.webViewContainer}>
                <Grid item className={cx(classes.contentColumn, contentColumnClassName)}>
                    {pageHeader && (
                        <Box className={cx(classes.pageHeader, pageHeaderClassName)}>
                            {pageHeader}
                        </Box>
                    )}
                    <Box className={cx(classes.pageContent, pageContentClassName)}>
                        {children}
                    </Box>
                </Grid>
            </Grid>
        );
    }
    return (
        <>
            <MaterialUiContainer
                maxWidth="lg"
                className={cx(classes.container, containerClassName)}
            >
                <Header />
            </MaterialUiContainer>
            <div className={classes.wrapper}>
                <div
                    className={cx(classes.root, contentClassName, {
                        [classes.rootWithFooter]: withFooter,
                    })}
                >
                    {withBanner && <NewsBanner />}
                    <Messages />
                    <MaterialUiContainer
                        maxWidth="lg"
                        className={cx(classes.container, containerClassName)}
                    >
                        <Grid
                            container
                            spacing={0}
                            className={cx(classes.grid, gridClassName)}
                        >
                            <Hidden smDown>
                                <Grid item className={classes.leftColumn}>
                                    <AppNavigation />
                                </Grid>
                            </Hidden>
                            <Grid
                                item
                                className={cx(
                                    classes.contentColumn,
                                    contentColumnClassName
                                )}
                            >
                                {pageHeader && (
                                    <Box
                                        className={cx(
                                            classes.pageHeader,
                                            pageHeaderClassName
                                        )}
                                    >
                                        {pageHeader}
                                    </Box>
                                )}
                                <Box
                                    className={cx(
                                        classes.pageContent,
                                        pageContentClassName
                                    )}
                                >
                                    {children}
                                </Box>
                            </Grid>
                            {withRightColumn && (
                                <Grid item className={classes.rightColumn}>
                                    <StatementList />
                                </Grid>
                            )}
                        </Grid>
                    </MaterialUiContainer>
                </div>
                {withFooter && (
                    <div className={classes.footerContainer}>
                        <MaterialUiContainer
                            maxWidth="lg"
                            className={cx(classes.container, containerClassName)}
                        >
                            <Footer />
                        </MaterialUiContainer>
                    </div>
                )}
            </div>
        </>
    );
};
