import React, { useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import { Button, makeStyles, Typography, Box } from '@material-ui/core';
import { MainLayout } from 'components/layouts';
import { RootState } from 'store/store';
import { ErrorMessage, Loading } from 'components/atoms';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { getApplications, getNextApplication } from 'store/actions/application';
import { PageHeader } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import starSuccessImage from 'assets/images/star_success.png';
import authErrorImage from 'assets/images/authError.png';

const useStyles = makeStyles(theme => ({
    /* button: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        marginRight: 16,
    },*/
    title: {
        marginTop: 40,
        fontSize: 28,
        lineHeight: 1.29,
        fontWeight: 500,
    },
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
    },
    image: {
        marginBottom: 40,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            textTransform: 'uppercase',
        },
        width: '100%',
        padding: '13px 28px 15px 27px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        flexDirection: 'column',
        padding: 60,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    text: {
        color: theme.palette.grey[500],
    },
    dialogPaper: {
        backgroundColor: theme.palette.common.white,
    },
    backLink: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '18px',
        lineHeight: '28px',
        textAlign: 'center',
        color: '#5780EA',
        textDecoration: 'none',
    },
    headerText: {
        fontWeight: 600,
    },
}));

interface ApplicationOrderSuccessFormProps {
    availableApplications?: SubsidyManagementModel[];
    handleNextApplication: VoidFunction;
    nextApplicationId: string;
    currentApplicationTitle: string;
}

export const ApplicationOrderSuccessForm: React.FC<ApplicationOrderSuccessFormProps> = ({
    availableApplications,
    handleNextApplication,
    nextApplicationId,
    currentApplicationTitle,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    //TODO: вывод детей как в макете
    return (
        <>
            <Box className={classes.dialogActions}>
                <img
                    src={starSuccessImage}
                    alt=""
                    width="200px"
                    height="170px"
                    className={classes.image}
                />
                <Box marginBottom="24px">
                    <Typography variant="h3" className={classes.headerText}>
                        Заявление оформлено!
                    </Typography>
                </Box>
                <Box marginBottom="16px">
                    <Typography className={classes.text}>
                        {currentApplicationTitle}
                    </Typography>
                </Box>
                {false && (
                    <Box marginBottom="40px">
                        <Typography className={classes.text}></Typography>
                    </Box>
                )}
                {true && (
                    <Box marginBottom="32px">
                        <Link to="/home" className={classes.backLink}>
                            Вернуться в каталог
                        </Link>{' '}
                    </Box>
                )}
                {false && (
                    <Box marginBottom="32px">
                        <Link to="/bot" className={classes.backLink}>
                            Вернуться в чат
                        </Link>
                    </Box>
                )}
                {nextApplicationId && (
                    <Button
                        variant="contained"
                        className={classes.button}
                        onClick={handleNextApplication}
                    >
                        {t('pages.Application.nextSubsidy')}
                    </Button>
                )}
            </Box>

            {/* <Typography className={classes.title}>Заявление оформлено!</Typography> */}
            {/** 
            {availableApplications?.length - 1 !== 0 && (
                <Button
                    variant="contained"
                    className={classes.button}
                    onClick={handleNextApplication}
                >
                    {t('pages.Application.nextSubsidy')}
                </Button>
            )}
            <Button
                variant="outlined"
                className={classes.button}
                component={Link}
                to="/bot"
            >
                {t('pages.Application.backToChat')}
            </Button>*/}
        </>
    );
};
