import { SelectionOption } from 'components/atoms/Select/Select';

export enum AnswerType {
    Text = 'Text',
    PhoneNumber = 'PhoneNumber',
    Button = 'Button',
    Email = 'Email',
    Date = 'Date',
    Snils = 'Snils',
    PassportSeries = 'PassportSeries',
    PassportNumber = 'PassportNumber',
    PassportCode = 'PassportCode',
    CertificateSerNumber = 'CertificateSerNumber',
    CertificateSeries = 'CertificateSeries',
    CertificateNumber = 'CertificateNumber',
}

export enum MessageType {
    Text = 'Text',
    CheckList = 'CheckList',
    List = 'List',
    ShowCard = 'ShowCard',
    Widget = 'Widget',
    CheckBoxes = 'CheckBoxes',
}

type Button = {
    Text?: string;
    Action?: string;
};

export type TextMessageType = {
    value: string;
    Value: string;
    buttons: Array<Button>;
    Buttons: Array<Button>;
};

export type CheckListItem = {
    Id?: string;
    Description?: string;
    Title?: string;
    AllowanceId?: string;
    Category?: number;
    SubsidyId?: string;
    ActionLink?: string;
    ActionLinkTitle?: string;
};

export type CheckListMessageType = {
    title: string;
    Title: string;
    items: {
        id?: string;
        description?: string;
        title?: string;
        type?: number;
    }[];
    Items: CheckListItem[];
    buttons: Array<Button>;
    Buttons: Array<Button>;
};

export type ListMessageType = {
    title: string;
    Title: string;
    items: {
        label?: string;
        value?: string;
    }[];
    Items: {
        Label?: string;
        Value?: string;
    }[];
    buttons: Array<Button>;
    Buttons: Array<Button>;
};

export type WidgetFieldsType =
    | 'text'
    | 'combobox'
    | 'date'
    | 'checkbox'
    | 'dadata'
    | 'radioGroup'
    | 'selectGroup';

export type WidgetField = {
    can_modify: boolean;
    current_value: any;
    field_items?: SelectionOption[]; //преобразуется на фронте src/Zabota.Subsidies.Web/ClientApp/src/store/actions/chat/chatUtils.ts
    field_mask?: any;
    field_name: string;
    field_title: string;
    field_type: WidgetFieldsType;
    dadata_url: string;
    block_name: string;
    required: boolean;
};

export type WidgetSection = {
    section: string;
    urlPiecture: string;
    fields: WidgetField[];
};

export type WidgetBlock = {
    block_id: string;
    block_name: string;
    description: string;
    saveUrl: string;
    sections: WidgetSection[];
};

export type WidgetMessageType = {
    id?: string;
    subsidyName: string;
    blocks: WidgetBlock[];
};

export type CheckBoxes = {
    Text?: string;
};

export type CheckBoxesMessageType = {
    Checkboxes: CheckBoxes[];
    Value: string;
    Buttons: Button[];
};

export type MessageUI =
    | Partial<TextMessageType>
    | Partial<CheckListMessageType>
    | Partial<ListMessageType>
    // | Partial<WidgetMessageType>
    | Partial<CheckBoxesMessageType>;

export interface Message {
    id: string;
    message: any;
    answerType: AnswerType;
    messageType: MessageType;
    isClientMessage: boolean;
    createDateTime: string;
    detailsLink?: string;
}

export interface MessagesGroupByDay {
    [key: string]: Array<Message>;
}

export interface ChatState {
    currentChat: {
        id: string;
    };
    chatBotLoaded: boolean;
    messages: Array<Message>;
    messagesByDay: MessagesGroupByDay;
    chats: any;
    initialLoading: boolean;
    messagesLoading: boolean;
    chatsLoading: boolean;
    chatIsLoading: boolean;
    sendMessageLoading: boolean;
    sendMessageText?: string;
}
