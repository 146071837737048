import React, { FC } from 'react';
import {
    Typography,
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
} from '@material-ui/core';

import { useStyles } from './Subsidy.styles';

interface SubsidyGrantedProps {
    list: any;
}
export const SubsidyGranted: FC<SubsidyGrantedProps> = ({ list = [] }) => {
    const classes = useStyles();

    return (
        <List dense disablePadding>
            {list.map((item, index) => {
                if (item == '') return '';
                return (
                    <ListItem
                        key={`subsidy_granted_${index}`}
                        alignItems="flex-start"
                        dense
                        disableGutters
                    >
                        <ListItemIcon
                            classes={{
                                root: classes.listItemIconRoot,
                            }}
                        >
                            <span className={classes.dot} />
                        </ListItemIcon>
                        <ListItemText className={classes.term} disableTypography>
                            <Typography>{item.trim()}</Typography>
                        </ListItemText>
                    </ListItem>
                );
            })}
        </List>
    );
};
