import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_MESSAGES_START,
    GET_MESSAGES_FAILURE,
    GET_MESSAGES_SUCCESS,
} from '../../constants';

export const getMessages = () => (dispatch, getState) => {
    dispatch({ type: GET_MESSAGES_START });
    axios({
        method: 'GET',
        params: {
            //platform: 'web',
        },
        url: '/api/Messages',
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const error = response.data?.error;
            if (isSuccess === true) {
                dispatch({
                    type: GET_MESSAGES_SUCCESS,
                    payload: { data: response.data?.result },
                });
            } else {
                dispatch({ type: GET_MESSAGES_FAILURE, payload: { error } });
            }
        })
        .catch(response => {
            dispatch({
                type: GET_MESSAGES_FAILURE,
                payload: { error: response.data?.error },
            });
        });
};
