import React, { FC, Fragment } from 'react';
import cx from 'classnames';
import makeStyles from '@material-ui/core/styles/makeStyles';
import MaterialTab, { TabProps as MaterialTabProps } from '@material-ui/core/Tab';
import { theme } from 'utils/theme';

const styles = () => ({
    root: {
        borderBottom: '2px solid #d3d3d3',
        borderTop: '2px solid transparent',
        color: '#6c6c6c',
        fontSize: '14px',
        fontWeight: 400,
        lineHeight: '20px',
        minWidth: 'auto',
        opacity: 1,
        textTransform: 'none' as const,
        '&:hover': {
            borderBottom: '2px solid #989898',
        },
        '&:hover, &:active': {
            color: '#10002b',
        },
    },
    selected: {
        color: '#10002b',
    },
    disabled: {
        color: '#b6b6b6',
        opacity: 1,
        borderBottom: '2px solid #d3d3d3',
    },
    inverse: {
        borderBottom: '2px solid #e1e1e1',
        color: '#b6b6b6',
        '&:hover': {
            borderBottom: '2px solid #d3d3d3',
        },
        '&:hover, &:active': {
            color: '#fff',
        },
        '&$selected': {
            color: '#fff',
        },
        '&$disabled': {
            color: '#6c6c6c',
            borderBottom: '2px solid #d3d3d3',
        },
    },
});

const useStyles = makeStyles(styles);

const switcherStyles = () => ({
    root: {
        border: `1px solid ${theme.palette.primary.main}`,
        color: theme.palette.primary.main,
        fontSize: '16px',
        fontWeight: 400,
        lineHeight: '24px',
        height: '40px',
        marginRight: '-1px',
        minWidth: 'auto',
        minHeight: 40,
        opacity: 1,
        overflow: 'visible',
        padding: '0 24px',
        textTransform: 'none' as const,

        '&:first-child': {
            borderTopLeftRadius: '4px',
            borderBottomLeftRadius: '4px',
            '& $outline': {
                borderTopLeftRadius: '6px',
                borderBottomLeftRadius: '6px',
            },
        },
        '&:last-child': {
            borderTopRightRadius: '4px',
            borderBottomRightRadius: '4px',
            marginRight: 0,
            '& $outline': {
                borderTopRightRadius: '6px',
                borderBottomRightRadius: '6px',
            },
        },
    },
    selected: {
        backgroundColor: theme.palette.primary.main,
        color: `${theme.palette.common.white} !important`,
        '& $outline': {
            top: '-4px',
            bottom: '-4px',
            left: '-4px',
            right: '-4px',
        },
    },
    disabled: {
        color: '#b6b6b6',
        opacity: 1,
        border: '1px solid #d3d3d3',
    },
    wrapper: {
        '& > *:first-child': {
            marginBottom: '0 !important',
        },
    },
});

const useSwitcherStyles = makeStyles(switcherStyles);

const useCommonStyles = makeStyles(() => ({
    focus: {
        '& $outline': {
            display: 'block',
        },
    },
    outline: {
        border: '2px solid #40decd',
        display: 'none',
        position: 'absolute' as const,
        top: '-3px',
        bottom: '-3px',
        left: '-3px',
        right: '-3px',
    },
}));

interface TabProps extends MaterialTabProps {
    /**
     * Вариант внешнего вида
     */
    appearance: 'tabs' | 'tabs-inverse' | 'switcher';
}

const Tab: FC<TabProps> = ({ appearance, label, ...props }) => {
    const s = useStyles();
    const switcherS = useSwitcherStyles();
    const commonStyles = useCommonStyles();

    let classes;
    let commonClasses;

    switch (appearance) {
        case 'tabs':
            classes = s;
            break;
        case 'tabs-inverse':
            classes = s;
            break;
        case 'switcher':
            classes = switcherS;
            commonClasses = commonStyles;
            break;
        default:
            break;
    }

    return (
        <MaterialTab
            {...props}
            focusVisibleClassName={commonClasses?.focus}
            classes={{
                ...classes,
                root: cx(classes.root, {
                    [classes.inverse]: appearance === 'tabs-inverse',
                }),
                selected: classes.selected,
            }}
            label={
                <Fragment>
                    {label}
                    <div
                        className={
                            commonClasses?.outline ? commonClasses?.outline : undefined
                        }
                    />
                </Fragment>
            }
            disableRipple
        />
    );
};

Tab.defaultProps = {
    appearance: 'tabs',
};

export default Tab;
