import { frontUrl } from 'config';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    SIGN_IN_ESIA_REQUESTING,
    SIGN_IN_ESIA_SUCCEED,
    SIGN_IN_ESIA_FAILED,
} from '../../constants';
import { LocalStorageKeys } from 'types/LocalStorageKeys';
import { deleteAnonimToken } from 'utils/token';
import { setJson } from 'utils/localStorage';

export const signinEsia = (redirectUrl: string, errorCb: VoidFunction) => dispatch => {
    const dispatchData = (actionType, redirectLink, requestId, requestStatus, errors) => {
        dispatch({
            type: actionType,
            redirectLink,
            confirmRequestId: requestId,
            requestStatus,
            errors,
        });
    };

    dispatchData(SIGN_IN_ESIA_REQUESTING, null, null, null, null);

    axios
        .get('/api/v2/auth/esiaAuth', {
            params: { callback: redirectUrl || frontUrl },
            headers: getCommonHeaders(),
        })
        .then(async response => {
            const {
                status,
                data: { success = false, error = undefined, result: esiaUrl },
            } = response;

            if (success === true && esiaUrl) {
                const requestId = new URL(esiaUrl).searchParams.get('state');
                dispatchData(SIGN_IN_ESIA_SUCCEED, esiaUrl, requestId, status, error);
                if (requestId) {
                    setJson(LocalStorageKeys.ConfirmRequestId, requestId);
                }
                deleteAnonimToken();
                window.location.href = esiaUrl;
            } else {
                errorCb();
                dispatchData(SIGN_IN_ESIA_FAILED, null, null, status, error);
            }
        })
        .catch(error => {
            errorCb();
            dispatchData(SIGN_IN_ESIA_FAILED, null, null, null, error.message);
        });
};
