import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { RootState } from 'store/store';
import { Provider } from './context';
import { getAllFeatures } from 'store/actions/featureToggles/getAllFeatures';

interface FeatureTogglesProviderProps {
    children?: React.ReactNode;
}

export const FeatureTogglesProvider: React.FC<FeatureTogglesProviderProps> = ({
    children,
}) => {
    const list = useSelector((state: RootState) => state.featureToggles.list);
    const featuresLoading = useSelector(
        (state: RootState) => state.featureToggles.featuresLoading
    );
    const featuresAreLoaded = useSelector(
        (state: RootState) => state.featureToggles.featuresAreLoaded
    );
    const userId = useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.userId
    );
    const dispatch = useDispatch();

    useEffect(() => {
        if (!featuresLoading && !featuresAreLoaded) {
            dispatch(getAllFeatures());
        }
    }, []);

    if (!featuresAreLoaded) return null;

    const value: any = { list, userId };
    return <Provider value={value}> {children} </Provider>;
};
