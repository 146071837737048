import isEmpty from 'lodash/isEmpty';

// true - поле скрывать
export const checkBindingVisibleValues = (field, watch, fieldsPath) => {
    if (field.visible?.value === true) return false;
    if (field.visible?.value === false) return true;
    if (isEmpty(field.visible?.binding)) return false;
    let { fieldId, values } = field.visible?.binding;
    if (isEmpty(fieldId)) return false;
    if (isEmpty(values)) return false;

    let fieldName = `${fieldsPath}.${fieldId}`;
    // if (field.childId) fieldId = `${field.childId}[${fieldId}]`;
    if (!values.includes(String(watch(fieldName)))) return true;
    return false;
};

//true - поле обязательно
export const checkBindingRequiredValues = (field, watch, fieldsPath) => {
    if (field.required?.value === true) return true;
    if (field.required?.value === false) return false;
    if (isEmpty(field.required?.binding)) return false;
    let { fieldId, values } = field.required?.binding;
    if (isEmpty(fieldId)) return false;
    if (isEmpty(values)) return false;

    let fieldName = `${fieldsPath}.${fieldId}`;
    //if (field.childId) fieldId = `${field.childId}[${fieldId}]`;
    if (!values.includes(String(watch(fieldName)))) return false;
    return true;
};
