import {
    GET_ALL_SUBSIDIES_REQUESTING,
    GET_ALL_SUBSIDIES_FAILED,
    GET_ALL_SUBSIDIES_SUCCEED,
    SET_FITER_SUBSIDY_ID,
    SET_FITER_SUBSIDY_CATEGORY_IDS,
} from '../../constants';

type filterCategory = {
    filterId: string;
    categoryId: string;
};

interface allSubsidiesState {
    data: any[];
    selectedSubsidy: {};
    selectedFilterCategories: filterCategory[];
    selectedFilterId: string;
    request: {
        status: any;
        isLoading: boolean;
        isSuccess: boolean;
        errors: any;
        isLoaded: boolean;
    };
}

const initialState: allSubsidiesState = {
    data: [],
    selectedSubsidy: {},
    selectedFilterCategories: [],
    selectedFilterId: '',
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
        isLoaded: false,
    },
};

export const allSubsidies = (state = initialState, action) => {
    switch (action.type) {
        case GET_ALL_SUBSIDIES_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    error: null,
                    isLoaded: false,
                },
            };

        case GET_ALL_SUBSIDIES_SUCCEED:
            return {
                ...state,
                data: action.payload.subsidies,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                    isLoaded: true,
                },
            };
        case GET_ALL_SUBSIDIES_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                    isLoaded: true,
                },
            };
        case SET_FITER_SUBSIDY_ID:
            return {
                ...state,
                selectedFilterId: action.payload,
            };
        case SET_FITER_SUBSIDY_CATEGORY_IDS:
            return {
                ...state,
                selectedFilterCategories: action.payload,
            };
        default:
            return state;
    }
};
