import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { deleteJson } from 'utils/localStorage';
import { deleteAuthToken, deleteAnonimToken } from 'utils/token';
import { LocalStorageKeys } from 'types/LocalStorageKeys';

import { signinEsiaResetToken } from './signinEsiaConfirm';

export const logout = () => (dispatch, getState) => {
    axios
        .post(
            `/api/auth/logOut`,
            {
                redirectUrl: window.location.href,
            },
            {
                headers: getCommonHeaders(getState()),
            }
        )
        .then(response => {})
        .catch(error => {})
        .finally(() => {
            deleteJson(LocalStorageKeys.UserBanner);
            deleteJson(LocalStorageKeys.UserId);
            deleteAuthToken();
            deleteAnonimToken();
            dispatch(signinEsiaResetToken());
            window.location.href = '/home';
        });
};
