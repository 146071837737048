import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_CARD_INFO_REQUESTING,
    WALLET_CARD_INFO_SUCCEED,
    WALLET_CARD_INFO_FAILED,
} from '../../constants';

export const getCardInfo = () => (dispatch, getState) => {
    const dispatchData = (actionType, cardInfo, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                cardInfo: cardInfo,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_CARD_INFO_REQUESTING, null, null, null);

    axios({
        method: 'GET',
        url: '/api/Card',
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                const result = response.data?.result;

                dispatchData(WALLET_CARD_INFO_SUCCEED, result, requestStatus, error);
            }

            if (isSuccess === false) {
                dispatchData(WALLET_CARD_INFO_FAILED, null, requestStatus, error);
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);

            dispatchData(WALLET_CARD_INFO_FAILED, null, null, error.error);
        });
};
