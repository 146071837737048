import React from 'react';

import { InputBase, TextFieldProps } from '@material-ui/core';
import {
    Grid,
    IconButton,
    CircularProgress,
    InputAdornment,
    Divider,
    Paper,
    Box,
} from '@abdt/ornament';
import { Icon, TextField } from 'components/atoms';
import useStyles from './AcceptedFile.style';
import sliceFileName from '../../utils/slice-file-name';
import { UploaderFile } from '../../Uploader.types';

interface AcceptedFileProps {
    file: UploaderFile;
    deleteFile: (file: UploaderFile) => void;
    textFieldProps: TextFieldProps;
}

const AcceptedFile: React.FC<AcceptedFileProps> = ({
    file,
    deleteFile,
    textFieldProps,
}) => {
    const { name, status } = file;
    const classes = useStyles();
    const fileName = sliceFileName(name);
    const isLoadingStatus = status && status === 'loading';

    const handleDelete = () => {
        deleteFile(file);
    };

    return (
        <Grid
            container
            wrap="nowrap"
            direction="row"
            alignItems="center"
            className={classes.acceptedFileItem}
        >
            <TextField
                {...textFieldProps}
                className={classes.acceptedFileInput}
                value={fileName}
                InputProps={{
                    endAdornment: (
                        <Box
                            display="flex"
                            alignItems="center"
                            flexWrap="nowrap"
                            position="relative"
                            className={classes.acceptedFileControls}
                        >
                            {isLoadingStatus ? (
                                <CircularProgress
                                    size={20}
                                    className={classes.acceptedFileProgress}
                                />
                            ) : (
                                <Icon
                                    name="fileLoadSuccess"
                                    className={classes.acceptedFileIcon}
                                />
                            )}
                            <Divider
                                orientation="vertical"
                                variant="fullWidth"
                                className={classes.acceptedFileDivider}
                            />
                            {isLoadingStatus ? (
                                <IconButton
                                    onClick={handleDelete}
                                    className={classes.binButton}
                                >
                                    <Icon name="close" />
                                </IconButton>
                            ) : (
                                <IconButton
                                    onClick={handleDelete}
                                    className={classes.binButton}
                                >
                                    <Icon name="bin" />
                                </IconButton>
                            )}
                        </Box>
                    ),
                }}
            />
        </Grid>
    );
};

export default AcceptedFile;
