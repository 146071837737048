import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_RESTART_CHECK_CARD_REQUESTING,
    WALLET_RESTART_CHECK_CARD_FAILED,
    WALLET_RESTART_CHECK_CARD_SUCCEED,
    COMMON_ERROR,
} from '../../constants';

export const restartCheckCard = (operationId: string) => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_RESTART_CHECK_CARD_REQUESTING, null, null, null);

    return axios
        .get(`/api/Requisites/restart/${operationId}`, {
            headers: getCommonHeaders(getState()),
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const result = response.data?.result;
            const error = response.data?.error;
            if (isSuccess === true) {
                dispatchData(
                    WALLET_RESTART_CHECK_CARD_SUCCEED,
                    result,
                    requestStatus,
                    error
                );
            }

            if (isSuccess === false) {
                dispatchData(
                    WALLET_RESTART_CHECK_CARD_FAILED,
                    null,
                    requestStatus,
                    error
                );
            }
            return result;
        })
        .catch(rawError => {
            const error = errorHandle(rawError);

            dispatchData(WALLET_RESTART_CHECK_CARD_FAILED, null, null, error.error);
        });
};
