//common
export const OPEN_MODAL = 'OPEN_MODAL';
export const CLOSE_MODAL = 'CLOSE_MODAL';

export const OPEN_BANNER = 'OPEN_BANNER';
export const CLOSE_BANNER = 'CLOSE_BANNER';

//sgin in
export const SIGN_IN_ESIA_REQUESTING = 'SIGN_IN_ESIA_REQUESTING';
export const SIGN_IN_ESIA_SUCCEED = 'SIGN_IN_ESIA_SUCCEED';
export const SIGN_IN_ESIA_FAILED = 'SIGN_IN_ESIA_FAILED';

export const SIGN_IN_ESIA_CONFIRM_REQUESTING = 'SIGN_IN_ESIA_CONFIRM_REQUESTING';
export const SIGN_IN_ESIA_CONFIRM_SUCCEED = 'SIGN_IN_ESIA_CONFIRM_SUCCEED';
export const SIGN_IN_ESIA_CONFIRM_FAILED = 'SIGN_IN_ESIA_CONFIRM_FAILED';

export const SIGN_IN_ANONIM_REQUESTING = 'SIGN_IN_ANONIM_REQUESTING';
export const SIGN_IN_ANONIM_SUCCEED = 'SIGN_IN_ANONIM_SUCCEED';
export const SIGN_IN_ANONIM_FAILED = 'SIGN_IN_ANONIM_FAILED';

export const SIGN_IN_ESIA_RESET_TOKEN = 'SIGN_IN_ESIA_RESET_TOKEN';

//profile-basic
export const GET_BASIC_REQUESTING = 'GET_BASIC_REQUESTING';
export const GET_BASIC_SUCCEED = 'GET_BASIC_SUCCEED';
export const GET_BASIC_FAILED = 'GET_BASIC_FAILED';

//profile-contacts
export const GET_CONTACTS_REQUESTING = 'GET_CONTACTS_REQUESTING';
export const GET_CONTACTS_SUCCEED = 'GET_CONTACTS_SUCCEED';
export const GET_CONTACTS_FAILED = 'GET_CONTACTS_FAILED';

export const UPDATE_CONTACTS_REQUESTING = 'UPDATE_CONTACTS_REQUESTING';
export const UPDATE_CONTACTS_SUCCEED = 'UPDATE_CONTACTS_SUCCEED';
export const UPDATE_CONTACTS_FAILED = 'UPDATE_CONTACTS_FAILED';

//profile-passport
export const GET_PASSPORT_REQUESTING = 'GET_PASSPORT_REQUESTING';
export const GET_PASSPORT_SUCCEED = 'GET_PASSPORT_SUCCEED';
export const GET_PASSPORT_FAILED = 'GET_PASSPORT_FAILED';

//profile-children
export const GET_CHILDRENS_REQUESTING = 'GET_CHILDRENS_REQUESTING';
export const GET_CHILDRENS_SUCCEED = 'GET_CHILDRENS_SUCCEED';
export const GET_CHILDRENS_FAILED = 'GET_CHILDRENS_FAILED';

export const GET_CHILD_REQUESTING = 'GET_CHILD_REQUESTING';
export const GET_CHILD_SUCCEED = 'GET_CHILD_SUCCEED';
export const GET_CHILD_FAILED = 'GET_CHILD_FAILED';

export const CREATE_CHILD_REQUESTING = 'CREATE_CHILD_REQUESTING';
export const CREATE_CHILD_SUCCEED = 'CREATE_CHILD_SUCCEED';
export const CREATE_CHILD_FAILED = 'CREATE_CHILD_FAILED';

export const SELECT_CHILD = 'SELECT_CHILD';
export const DESELECT_CHILD = 'DESELECT_CHILD';

export const UPDATE_CHILD_REQUESTING = 'UPDATE_CHILD_REQUESTING';
export const UPDATE_CHILD_SUCCEED = 'UPDATE_CHILD_SUCCEED';
export const UPDATE_CHILD_FAILED = 'UPDATE_CHILD_FAILED';

export const DEL_CHILD_REQUESTING = 'DEL_CHILD_REQUESTING';
export const DEL_CHILD_SUCCEED = 'DEL_CHILD_SUCCEED';
export const DEL_CHILD_FAILED = 'DEL_CHILD_FAILED';

//profile-family
export const GET_FAMILY_REQUESTING = 'GET_FAMILY_REQUESTING';
export const GET_FAMILY_SUCCEED = 'GET_FAMILY_SUCCEED';
export const GET_FAMILY_FAILED = 'GET_FAMILY_FAILED';

export const UPDATE_FAMILY_REQUESTING = 'UPDATE_FAMILY_REQUESTING';
export const UPDATE_FAMILY_SUCCEED = 'UPDATE_FAMILY_SUCCEED';
export const UPDATE_FAMILY_FAILED = 'UPDATE_FAMILY_FAILED';

// esia
export const PROFILE_USE_ESIA_REQUESTING = 'PROFILE_USE_ESIA_REQUESTING';
export const PROFILE_USE_ESIA_SUCCEED = 'PROFILE_USE_ESIA_SUCCEED';
export const PROFILE_USE_ESIA_FAILED = 'PROFILE_USE_ESIA_FAILED';

//subsidies
export const GET_MY_SUBSIDIES_REQUESTING = 'subsidies/GET_MY_SUBSIDIES_REQUESTING';
export const GET_MY_SUBSIDIES_SUCCEED = 'subsidies/GET_MY_SUBSIDIES_SUCCEED';
export const GET_MY_SUBSIDIES_FAILED = 'subsidies/GET_MY_SUBSIDIES_FAILED';

export const GET_MY_SUBSIDY_REQUESTING = 'subsidies/GET_MY_SUBSIDY_REQUESTING';
export const GET_MY_SUBSIDY_SUCCEED = 'subsidies/GET_MY_SUBSIDY_SUCCEED';
export const GET_MY_SUBSIDY_FAILED = 'subsidies/GET_MY_SUBSIDY_FAILED';

export const GET_APPLICATION_FILE_REQUESTING =
    'subsidies/GET_APPLICATION_FILE_REQUESTING';
export const GET_APPLICATION_FILE_SUCCEED = 'subsidies/GET_APPLICATION_FILE_SUCCEED';
export const GET_APPLICATION_FILE_FAILED = 'subsidies/GET_APPLICATION_FILE_FAILED';

export const GET_ALL_SUBSIDIES_REQUESTING = 'subsidies/GET_ALL_SUBSIDIES_REQUESTING';
export const GET_ALL_SUBSIDIES_SUCCEED = 'subsidies/GET_ALL_SUBSIDIES_SUCCEED';
export const GET_ALL_SUBSIDIES_FAILED = 'subsidies/GET_ALL_SUBSIDIES_FAILED';
export const SET_FITER_SUBSIDY_ID = 'subsidies/SET_FITER_SUBSIDY_ID';
export const SET_FITER_SUBSIDY_CATEGORY_IDS = 'subsidies/SET_FITER_SUBSIDY_CATEGORY_IDS';

export const GET_SUBSIDY_REQUESTING = 'subsidies/GET_SUBSIDY_REQUESTING';
export const GET_SUBSIDY_SUCCEED = 'subsidies/GET_SUBSIDY_SUCCEED';
export const GET_SUBSIDY_FAILED = 'subsidies/GET_SUBSIDY_FAILED';
export const GET_SUBSIDY_RESET = 'subsidies/GET_SUBSIDY_RESET';

export const CALCULATE_SUBSIDY_REQUESTING = 'subsidies/CALCULATE_SUBSIDY_REQUESTING';
export const CALCULATE_SUBSIDY_SUCCEED = 'subsidies/CALCULATE_SUBSIDY_SUCCEED';
export const CALCULATE_SUBSIDY_FAILED = 'subsidies/CALCULATE_SUBSIDY_FAILED';

export const RESET_CALCULATE_SUBSIDY = 'subsidies/RESET_CALCULATE_SUBSIDY';

export const GET_CALCULATE_RESULT_REQUESTING =
    'subsidies/GET_CALCULATE_RESULT_REQUESTING';
export const GET_CALCULATE_RESULT_SUCCEED = 'subsidies/GET_CALCULATE_RESULT_SUCCEED';
export const GET_CALCULATE_RESULT_FAILED = 'subsidies/GET_CALCULATE_RESULT_FAILED';

//wallet

export const WALLET_CARD_INFO_REQUESTING = 'WALLET_CARD_INFO_REQUESTING';
export const WALLET_CARD_INFO_SUCCEED = 'WALLET_CARD_INFO_SUCCEED';
export const WALLET_CARD_INFO_FAILED = 'WALLET_CARD_INFO_FAILED';

export const WALLET_INIT_CHECK_CARD_REQUESTING = 'WALLET_INIT_CHECK_CARD_REQUESTING';
export const WALLET_INIT_CHECK_CARD_SUCCEED = 'WALLET_INIT_CHECK_CARD_SUCCEED';
export const WALLET_INIT_CHECK_CARD_FAILED = 'WALLET_INIT_CHECK_CARD_FAILED';
export const WALLET_INIT_CHECK_CARD_RESET = 'WALLET_INIT_CHECK_CARD_RESET';

export const WALLET_RESTART_CHECK_CARD_REQUESTING =
    'WALLET_RESTART_CHECK_CARD_REQUESTING';
export const WALLET_RESTART_CHECK_CARD_SUCCEED = 'WALLET_RESTART_CHECK_CARD_SUCCEED';
export const WALLET_RESTART_CHECK_CARD_FAILED = 'WALLET_RESTART_CHECK_CARD_FAILED';

export const WALLET_CHECK_CARD_REQUESTING = 'WALLET_CHECK_CARD_REQUESTING';
export const WALLET_CHECK_CARD_SUCCEED = 'WALLET_CHECK_CARD_SUCCEED';
export const WALLET_CHECK_CARD_FAILED = 'WALLET_CHECK_CARD_FAILED';

export const WALLET_DELETE_CARD_REQUESTING = 'WALLET_DELETE_CARD_REQUESTING';
export const WALLET_DELETE_CARD_SUCCEED = 'WALLET_DELETE_CARD_SUCCEED';
export const WALLET_DELETE_CARD_FAILED = 'WALLET_DELETE_CARD_FAILED';
export const WALLET_DELETE_CARD_RESET = 'WALLET_DELETE_CARD_RESET';

export const WALLET_SAVE_REQUISITES_REQUESTING = 'WALLET_SAVE_REQUISITES_REQUESTING';
export const WALLET_SAVE_REQUISITES_SUCCEED = 'WALLET_SAVE_REQUISITES_SUCCEED';
export const WALLET_SAVE_REQUISITES_FAILED = 'WALLET_SAVE_REQUISITES_FAILED';

export const WALLET_GET_BANK_NAME_REQUESTING = 'WALLET_GET_BANK_NAME_REQUESTING';
export const WALLET_GET_BANK_NAME_SUCCEED = 'WALLET_GET_BANK_NAME_SUCCEED';
export const WALLET_GET_BANK_NAME_FAILED = 'WALLET_GET_BANK_NAME_FAILED';

export const COMMON_ERROR = 'Что-то пошло не так(';

// faq
export const GET_FAQS_REQUESTING = 'GET_FAQS_REQUESTING';
export const GET_FAQS_SUCCEED = 'GET_FAQS_SUCCEED';
export const GET_FAQS_FAILED = 'GET_FAQS_FAILED';

export const GET_FAQS_CATEGORIES_REQUESTING = 'GET_FAQS_CATEGORIES_REQUESTING';
export const GET_FAQS_CATEGORIES_SUCCEED = 'GET_FAQS_CATEGORIES_SUCCEED';
export const GET_FAQS_CATEGORIES_FAILED = 'GET_FAQS_CATEGORIES_FAILED';
export const SET_FITER_CATEGORY_IDS = 'SET_FITER_CATEGORY_IDS';

// address
export const FIND_ADDRESS_START = 'creditCardApplication/FIND_ADDRESS_START';
export const FIND_ADDRESS_SUCCESS = 'creditCardApplication/FIND_ADDRESS_SUCCESS';
export const FIND_ADDRESS_FAILURE = 'creditCardApplication/FIND_ADDRESS_FAILURE';

//featureToggles
export const GET_FEATURES_LIST_START = 'GET_FEATURES_LIST_START';
export const GET_FEATURES_LIST_FAILURE = 'GET_FEATURES_LIST_FAILURE';
export const GET_FEATURES_LIST_SUCCESS = 'GET_FEATURES_LIST_SUCCESS';

//Messages
export const GET_MESSAGES_START = 'GET_MESSAGES_START';
export const GET_MESSAGES_FAILURE = 'GET_MESSAGES_FAILURE';
export const GET_MESSAGES_SUCCESS = 'GET_MESSAGES_SUCCESS';
