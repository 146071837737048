import React from 'react';
import map from 'lodash/map';
import cx from 'classnames';

import { Box, makeStyles, Theme, Typography } from '@material-ui/core';

import { FormFieldProps, SubmitField } from 'types/FormField';
import { CardOrderSubmitField } from './CardOrderSubmitField';
import { Icon } from 'components/atoms';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    group: {
        '& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },
    },
    groupTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
        },
        fontWeight: 500,
        fontSize: 20,
    },
    fieldGroup: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 24,
            marginRight: 0,
        },
        marginTop: 40,
        marginRight: -32,
    },
    groupIcon: {
        marginRight: 16,
    },
    groupHeader: {
        [theme.breakpoints.down('xs')]: {
            marginBottom: 16,
        },
        display: 'flex',
        alignItems: 'center',
        marginBottom: 24,
    },
}));
interface CardOrderGroupCellProps {
    className?: string;
}

export const CardOrderGroupCell: React.FC<CardOrderGroupCellProps> = ({
    children,
    className,
}) => {
    const classes = useStyles();

    return (
        <Box display="flex" flexWrap="wrap" className={cx(classes.fieldGroup, className)}>
            {children}
        </Box>
    );
};

interface CardOrderGroupIconProps {
    groupId?: string;
}

const CardOrderGroupIcon: React.FC<CardOrderGroupIconProps> = ({ groupId }) => {
    const classes = useStyles();
    const getGroupIcon = () => {
        let icon = null;
        switch (groupId) {
            case 'passport':
                icon = <Icon name="fileApplication" className={classes.groupIcon} />;
                break;
            case 'registationAddress':
                icon = <Icon name="houseApplication" className={classes.groupIcon} />;
                break;

            default:
                break;
        }
        return icon;
    };
    return <>{getGroupIcon()}</>;
};

interface CardOrderGroupHeaderProps {
    groupName: string;
    groupId?: string;
    className?: string;
}

export const CardOrderGroupHeader: React.FC<CardOrderGroupHeaderProps> = ({
    groupName,
    groupId,
    className,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box className={cx(classes.groupHeader, className)}>
            <CardOrderGroupIcon groupId={groupId} />
            <Typography className={classes.groupTitle}>{t(groupName)}</Typography>
        </Box>
    );
};

interface CardOrderGroupsFieldProps {
    groupName: string;
    groupId?: string;
    fields: SubmitField[];
    formProps: FormFieldProps;
    className?: string;
}

export const CardOrderGroupsField: React.FC<CardOrderGroupsFieldProps> = ({
    groupName,
    fields,
    formProps,
    groupId,
    className,
}) => {
    const classes = useStyles();

    return (
        <Box className={cx(classes.group, className)}>
            {groupName && (
                <CardOrderGroupHeader groupName={groupName} groupId={groupId} />
            )}
            <CardOrderGroupCell>
                {map(fields, (field, index) => (
                    <CardOrderSubmitField
                        field={field}
                        formProps={formProps}
                        key={`cardOrder_${groupId || 'empty'}_${index}`}
                    />
                ))}
            </CardOrderGroupCell>
        </Box>
    );
};
