import React, { useState, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import { Typography, Box, useMediaQuery, useTheme } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'components/layouts';
import { getAllSubsidies } from 'store/actions';
import { RootState } from 'store/store';
import { useStyles } from './Subsidies.styles';
import { AdaptedPageHeader } from './AdaptedPageHeader';
import { CardSubsidy } from './CardSubsidy';
import { Loading } from 'components/atoms';
import some from 'lodash/some';

export const Subsidies: FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const { t } = useTranslation();
    const allSubsidiesState = useSelector(
        (state: RootState) => state.subsidies.allSubsidies
    );
    const [allSubsidiesFiltered, setallSubsidiesFiltered] = useState([]);
    const [searchInput, setSearchInput] = useState('');
    const [notFound, setnotFound] = useState(false);
    const selectFilterCategories = allSubsidiesState.selectedFilterCategories;
    const selectedFilterId = allSubsidiesState.selectedFilterId;
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    useEffect(() => {
        if (!allSubsidiesState.request.isLoaded && !allSubsidiesState.request.isLoading) {
            dispatch(getAllSubsidies());
        }
    }, []);
    useEffect(() => {
        if (
            !isEmpty(selectFilterCategories) &&
            allSubsidiesState.data &&
            !isEmpty(allSubsidiesState.data)
        ) {
            const tempAllSubsidies = allSubsidiesState.data.filter(sub => {
                const filterCategoriesSubsidy = [];

                //Формирование массива схожего по структуре с selectFilterCategories
                sub.filterCategories.forEach(function (filter) {
                    filter.categoryIds.forEach(categoryId => {
                        filterCategoriesSubsidy.push({
                            filterId: filter.filterId,
                            categoryId: categoryId,
                        });
                    });
                });

                //Сравнение выбранных фильтров с формированными фильтрами субсидии
                return selectFilterCategories.some(item => {
                    return some(filterCategoriesSubsidy, item);
                });
            });
            setallSubsidiesFiltered(tempAllSubsidies);
            if (isEmpty(tempAllSubsidies)) {
                setnotFound(true);
            } else {
                setnotFound(false);
            }
        } else if (
            !searchInput &&
            allSubsidiesState.data &&
            !isEmpty(allSubsidiesState.data)
        ) {
            setallSubsidiesFiltered(allSubsidiesState.data);
            setnotFound(false);
        } else {
            setallSubsidiesFiltered([]);
        }
    }, [searchInput, selectFilterCategories, allSubsidiesState.data]);

    return (
        <MainLayout
            contentClassName={classes.root}
            pageHeader={
                <AdaptedPageHeader
                    title={t('common.subsidies')}
                    setSearch={setSearchInput}
                />
            }
            withRightColumn={!isViewXS}
        >
            <Box className={classes.subsidyCardCarousel}>
                {allSubsidiesState.request.isLoading ? (
                    <Loading />
                ) : notFound ? (
                    <Typography>{t('pages.Subsidies.nothingWasFound')}</Typography>
                ) : (
                    allSubsidiesFiltered.map(value => (
                        <CardSubsidy
                            subsidy={value}
                            withTag
                            withCalc
                            withIcon
                            key={value.id}
                            detailUrl={`/subsidies/${value.id}`}
                        />
                    ))
                )}
            </Box>
        </MainLayout>
    );
};
