import React, { FC, ReactNode } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { makeStyles } from '@material-ui/core/styles';
import Toolbar from '@material-ui/core/Toolbar';
import AppBar from '@material-ui/core/AppBar/AppBar';

import { Container, Hidden } from '@material-ui/core';

import { RootState } from 'store/store';

import { MobileMenu } from './MobileMenu';
import { DesktopMenu } from './DesktopMenu';
import { logout } from 'store/actions';

const useStyles = makeStyles(theme => ({
    toolbar: {
        [theme.breakpoints.down('sm')]: {
            height: 62,
            minHeight: 62,
            padding: '12px 0',
        },
        padding: '16px 0',
        height: 72,
        minHeight: 72,
    },
    appBar: {
        [theme.breakpoints.down('sm')]: {
            height: 62,
        },
        backgroundColor: theme.palette.common.white,
        boxShadow: 'none',
        height: 72,
    },
}));

interface HeaderProps {
    shouldRenderMobile?: boolean;
    actionButton?: ReactNode;
}

export const Header: FC<HeaderProps> = ({ shouldRenderMobile, actionButton }) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const userName = useSelector((state: RootState) => state.profile.basic.info);
    const token = useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );

    const logoutHandle = () => {
        dispatch(logout());
    };

    return (
        <AppBar className={classes.appBar} position="fixed">
            <Container maxWidth="lg">
                <Toolbar disableGutters className={classes.toolbar}>
                    {shouldRenderMobile && (
                        <Hidden mdUp>
                            <MobileMenu
                                userName={userName}
                                isAuthorized={!!token}
                                logout={logoutHandle}
                            />
                        </Hidden>
                    )}

                    {/* Если не нужна мобильная версия,
                        то отображать при любой ширине
                        (нужно для лендинга, у него другие breakpoints) */}
                    <Hidden smDown={shouldRenderMobile}>
                        <DesktopMenu
                            userName={userName}
                            isAuthorized={!!token}
                            logout={logoutHandle}
                            actionButton={actionButton}
                        />
                    </Hidden>
                </Toolbar>
            </Container>
        </AppBar>
    );
};

Header.defaultProps = {
    shouldRenderMobile: true,
};
