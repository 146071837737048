import { OPEN_BANNER, CLOSE_BANNER } from '../../constants';

const modalInitState = {
    visible: true,
};

export const banner = (state = modalInitState, action) => {
    switch (action.type) {
        case OPEN_BANNER:
            return {
                ...state,
                visible: true,
            };
        case CLOSE_BANNER:
            return {
                ...state,
                visible: false,
            };
        default:
            return state;
    }
};
