import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { errorHandle } from 'utils/utils';
import { AxiosResponse } from 'axios';
import { RootState } from 'store/store';
import { ApplicationGroupsField } from 'components/organisms';
import { setBlock } from 'store/actions/application/application.actions';
import * as AppSlice from 'store/slices/application.slice';

const useStyles = makeStyles((theme: Theme) => ({}));

interface Props {
    blockType: any;
    blockIndex: any;
    useBlockPreloading: boolean;
    categories: any;
    fields: any;
}

export const DynamicBlock: React.FC<Props> = ({
    blockType,
    blockIndex,
    useBlockPreloading,
    fields,
    categories,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const [loading, setLoading] = useState(false);
    const [blockLoaded, setBlockLoaded] = useState(false);
    const [error, setError] = useState(false);
    const state = useSelector((state: RootState) => state);
    const currentApplicationId = state.application.currentApplicationId;

    /*  const handleAddFamilyMember = useCallback(() => {
        // dispatch(addFamilyMember());
    }, []);
*/
    const getPreloadingBlock = async () => {
        try {
            dispatch(AppSlice.saveApplicationStart());
            const res: AxiosResponse<ApiResponse<any>> = await axios.get(
                `/api/Allowance/block/${currentApplicationId}/`,
                {
                    headers: getCommonHeaders(state),
                    params: {
                        blockType,
                    },
                }
            );

            if (res.data.success) {
                const data = res.data;
                dispatch(setBlock(data.result, blockIndex));
                setBlockLoaded(true);
                dispatch(AppSlice.saveApplicationBlockSuccess());
            } else {
                const error = errorHandle(res.data);
                setLoading(false);
                setError(true);
            }
        } catch (rawError) {
            const error = errorHandle(rawError);
            console.log('createApplication', error);
            setError(true);
            dispatch(AppSlice.saveApplicationFailure(error));
        }
    };

    useEffect(() => {
        if (useBlockPreloading) getPreloadingBlock();
        else setBlockLoaded(true);
    }, [blockType]);

    const formProps = useFormContext();
    const { watch } = formProps;

    if (error) return <div>Ошибка загрузки блока</div>;
    if (blockLoaded)
        return (
            <>
                {/*#Филды*/}
                {/*Пустая категория. Когда criterionFieldsCategoryId == null*/}
                {fields && !isEmpty(fields) && (
                    <ApplicationGroupsField
                        categoryName={null}
                        categoryType="common"
                        categoryId={null}
                        isSuperCategory={false}
                        fields={fields}
                        key={`fields_category_common}`}
                        filedsPath={`data`}
                    />
                )}
                {/*По категориям*/}
                {!isEmpty(fields) &&
                    !isEmpty(categories) &&
                    map(categories, (category, key) => {
                        return (
                            <ApplicationGroupsField
                                categoryName={category.displayName}
                                categoryType={category.categoryType}
                                categoryId={category.id}
                                isSuperCategory={category.isSuperCategory}
                                fields={fields}
                                filedsPath={`data`}
                                key={`fields_category_${key}`}
                            />
                        );
                    })}
            </>
        );
    return null;
};
