import { createMuiTheme, withStyles } from '@material-ui/core/styles';
import type {} from '@material-ui/lab/themeAugmentation';

import omit from 'lodash/omit';

import { themes } from '@abdt/ornament';
import { colors, zIndex } from '@abdt/design-tokens';

const font = '"Golos", "sans-serif"';

const pickersOverrides = [
    'overrides.MuiPickersBasePicker',
    'overrides.MuiPickersDateRangePickerInput',
    'overrides.MuiPickersDesktopDateRangeCalendar',
    'overrides.MuiPickersCalendarHeader',
    'overrides.MuiPickersCalendar',
    'overrides.MuiPickersDateRangeDay',
    'overrides.MuiPickersDay',
    'overrides.MuiPickersArrowSwitcher',
    'overrides.MuiPickersYear',
];
// TODO. Базовая тема ломает пикеры 3 версии.
// Пока что выпиливаем пропсы которые мешают работе
const adaptetBase = omit(themes.base, pickersOverrides);

const theme = createMuiTheme(adaptetBase, {
    palette: {
        common: {
            black: '#000A26',
            white: colors.white,
        },
        primary: {
            main: '#5780EA',
            light: '#4E73D3',
            dark: '#3E5BC9',
            contrastText: colors.white,
        },
        error: {
            main: '#FF4545',
            dark: '#DC2730',
            light: '#FFF6F6',
        },
        success: {
            main: '#70D468',
            dark: '#65BF5E',
            light: '#F1FBF0',
        },
        background: {
            default: '#EAF6FF',
            // paper: '#F7F9FF',
        },
        grey: {
            100: '#DFE1E4',
            400: '#878B97',
            600: '#474D5F',
            700: '#6A6A6A',
            900: '#10002B',
        },
        text: {
            primary: '#10002B',
            secondary: 'rgba(16, 0, 43, 0.65)',
            disabled: 'rgba(0, 0, 0, 0.38)',
            hint: 'rgba(16, 0, 43, 0.33)',
        },
    },
    zIndex: {},
    typography: {
        fontWeightRegular: 400,
        fontWeightMedium: 500,
        fontWeightBold: 700,
        fontFamily: font,
        h1: {
            fontWeight: 500,
            fontSize: 28,
            lineHeight: 1.36,
            fontFamily: font,
        },
        h2: {
            fontWeight: 600,
            fontSize: 24,
            lineHeight: 1.5,
            fontFamily: font,
        },
        h3: {
            fontWeight: 500,
            fontSize: 24,
            lineHeight: 1.5,
            fontFamily: font,
        },
        h4: {
            fontWeight: 500,
            fontSize: 20,
            lineHeight: 1.5,
            fontFamily: font,
        },
        h5: {
            fontWeight: 'normal',
            fontSize: 18,
            lineHeight: 1.56,
            fontFamily: font,
        },
        // text regular
        body1: {
            fontWeight: 'normal',
            fontSize: 14,
            lineHeight: 1.43,
            fontFamily: font,
        },
        // text bold
        body2: {
            fontWeight: 600,
            fontSize: 14,
            lineHeight: 1.43,
            fontFamily: font,
        },
        // title regular
        subtitle1: {
            fontWeight: 'normal',
            fontSize: 16,
            lineHeight: 1.5,
            fontFamily: font,
        },
        // title bold
        subtitle2: {
            fontWeight: 600,
            fontSize: 16,
            lineHeight: 1.5,
            fontFamily: font,
        },
    },
    breakpoints: {
        values: {
            xs: 0,
            sm: 600,
            md: 960,
            lg: 1280,
            xl: 1920,
        },
    },
});

theme.props = {
    // ...themes.base.props,
    MuiSelect: {
        ...theme.props.MuiSelect,
    },
    MuiButton: {
        ...theme.props.MuiButton,
        size: 'large',
    },
    MuiCircularProgress: {
        ...theme.props.MuiCircularProgress,
    },
};

theme.overrides = {
    ...theme.overrides,
    MuiStepContent: {
        ...theme.overrides.MuiStepContent,
        root: {
            ...theme.overrides.MuiStepContent?.root,
            marginBottom: 8,
            //paddingBottom: 16,
            minHeight: 20,
        },
    },
    MuiTextField: {
        ...theme.overrides.MuiTextField,
        root: {
            ...theme.overrides.MuiTextField?.root,
        },
    },
    MuiTooltip: {
        ...theme.overrides.MuiTooltip,
        popper: {
            ...theme.overrides.MuiTooltip?.popper,
            zIndex: 200000,
        },
        tooltip: {
            ...theme.overrides.MuiTooltip?.tooltip,
            padding: 16,
            backgroundColor: '#FFFFFF',
            borderRadius: 8,
            fontFamily: 'Roboto',
            fontStyle: 'normal',
            fontWeight: 'normal',
            fontSize: '13px',
            color: '#10002b',
            boxShadow: '0 0 4px #0000001f',
        },
        arrow: {
            ...theme.overrides.MuiTooltip?.arrow,
            color: '#FFFFFF',
        },
    },
    MuiInput: {
        ...theme.overrides.MuiInput,
        underline: {
            ...theme.overrides.MuiInput.underline,
            '&:after': {
                borderBottomColor: theme.palette.primary.main,
            },
        },
    },
    MuiInputBase: {
        ...theme.overrides.MuiInputBase,
    },
    MuiOutlinedInput: {
        ...theme.overrides.MuiOutlinedInput,
        root: {
            ...theme.overrides.MuiOutlinedInput.root,
            '&$focused $notchedOutline': {
                borderColor: theme.palette.primary.main,
            },
        },
    },
    MuiInputLabel: {
        ...theme.overrides.MuiInputLabel,
        root: {
            ...theme.overrides.MuiInputLabel.root,
            overflow: 'hidden',
            textOverflow: 'ellipsis',
            whiteSpace: 'nowrap',
            right: '30px',
            bottom: '0px',
        },
        disabled: {
            color: '#878B97',
        },
        shrink: {
            ...theme.overrides.MuiInputLabel.shrink,
            right: 'unset',
        },
    },
    MuiFilledInput: {
        ...theme.overrides.MuiFilledInput,
    },
    MuiFormHelperText: {
        ...theme.overrides.MuiFormHelperText,
    },
    MuiFormLabel: {
        ...theme.overrides.MuiFormLabel,
        root: {
            ...theme.overrides.MuiFormLabel.root,
            color: '#878B97',
        },
    },
    MuiButton: {
        ...theme.overrides.MuiButton,
        sizeSmall: {
            ...theme.overrides.MuiButton.sizeSmall,
            fontFamily: font,
        },
        sizeLarge: {
            ...theme.overrides.MuiButton.sizeLarge,
            fontFamily: font,
        },
        text: {
            ...theme.overrides.MuiButton.text,
            color: theme.palette.text.primary,
            '&:hover': {},
            '&:active': {},
        },
        textPrimary: {
            ...theme.overrides.MuiButton.textPrimary,
            color: theme.palette.primary.main,
            '&:hover': {},
            '&:active': {},
        },
        contained: {
            ...theme.overrides.MuiButton.contained,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {},
            '&:active': {},
        },
        containedPrimary: {
            ...theme.overrides.MuiButton.containedPrimary,
            backgroundColor: theme.palette.primary.main,
            '&:hover': {},
            '&:active': {},
        },

        outlined: {
            // padding: '12px 24px',
            ...theme.overrides.MuiButton.outlined,
            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {},
            '&:active': {},
            '&.Mui-disabled': {
                // border: 'none',
            },
        },
        outlinedPrimary: {
            ...theme.overrides.MuiButton.outlinedPrimary,

            color: theme.palette.primary.main,
            border: `1px solid ${theme.palette.primary.main}`,
            '&:hover': {},
            '&:active': {},
            '&.Mui-disabled': {
                // border: 'none',
            },
        },
    },
    MuiContainer: {
        maxWidthLg: {
            [theme.breakpoints.down('xs')]: {
                paddingLeft: 16,
                paddingRight: 16,
            },
            paddingLeft: 32,
            paddingRight: 32,
        },
    },
    MuiAvatar: {
        colorDefault: {
            backgroundColor: 'transparent',
        },
    },
    MuiAutocomplete: {
        ...theme.overrides.MuiAutocomplete,
    },
    MuiAppBar: {
        root: {
            zIndex: zIndex.header,
        },
    },
    MuiTypography: {
        h1: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h1[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        h2: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h2[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        h3: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h3[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        h4: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h4[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        h5: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h5[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        h6: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.h6[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        body1: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.body1[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        body2: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.body2[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        caption: {
            [theme.breakpoints.down('sm')]: {
                ...theme.overrides.MuiTypography.caption[theme.breakpoints.down('sm')],
                fontFamily: font,
            },
        },
        subtitle1: {},
    },
    MuiCircularProgress: {
        colorPrimary: {
            color: theme.palette.primary.main,
        },
    },
    MuiSelect: {
        ...theme.overrides.MuiSelect,
    },
};

const GlobalCss = withStyles(theme => ({
    // @global is handled by jss-plugin-global.
    '@global': {},
}))(() => null);

export { theme, GlobalCss };
