import { SubsidyManagementModel } from './SubsidyManagementModel';

export interface CurrentSubsidyState {
    subsidy?: SubsidyManagementModel;
    request: {
        status: any;
        isLoading: boolean;
        isSuccess: boolean;
        errors: string;
    };
}

export enum OptionsType {
    Select = 'Select',
    Input = 'Input',
    NumericInput = 'NumericInput',
    Calculation = 'Calculation',
    Radio = 'Radio',
    Link = 'Link',
}
export enum TypeVariant {
    Value = 1,
    Answers = 0,
    Options = 2,
}

export interface OptionsFields {
    name: string;
    optionsType: OptionsType;
    optionsValueses?: {
        name: string;
        value: string;
    }[];
    id: string | number;
    description?: string;
    nameLinks?:
        | {
              mask: string;
              link: string;
          }[]
        | null;
}

export interface CalculateSubsidyState {
    result?: {
        typeSubsidy: string | number;
        typeVariant: TypeVariant;
        value?: string;
        question?: string;
        answers?: {
            name: string;
            value: string;
            id: string;
        }[];
        name: string;
        description?: string;
        options?: OptionsFields[];
    };
    calculateResult?: {
        isFinish: boolean;
        result: string;
    };
    request: {
        status: any;
        isLoading: boolean;
        isSuccess: boolean;
        errors: string;
    };
    calculateRequest: {
        status: any;
        isLoading: boolean;
        isSuccess: boolean;
        errors: string;
    };
}
