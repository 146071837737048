import React, { useEffect, useState } from 'react';
import classNames from 'classnames';
import { useDispatch, useSelector } from 'react-redux';
import map from 'lodash/map';
import filter from 'lodash/filter';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
    Avatar,
    Box,
    Grid,
    IconButton,
    Typography,
    useMediaQuery,
} from '@material-ui/core';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';

import { MainLayout } from 'components/layouts';
import {
    Childrens,
    Contacts,
    contactsFields,
    Family,
    Personal,
    personalFields,
} from 'components/organisms';
import { Icon } from 'components/atoms';
import { getChildrens, getContacts, getFamily, getPassport, logout } from 'store/actions';
import { RootState } from 'store/store';
import { getUserInitials } from 'utils/utils';
import { PageHeader } from 'components/molecules';

const useStyles = makeStyles((theme: Theme) => ({
    root: {},
    grid: {},
    container: {
        flexGrow: 1,
        backgroundColor: theme.palette.background.paper,
        display: 'flex',
        height: 224,
    },
    titleContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        marginTop: 40,
    },
    gridItem: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 0,
        },
        marginTop: 40,
    },
    collapseTitleContainer: {
        [theme.breakpoints.down('sm')]: {
            display: 'flex',
            justifyContent: 'space-between',
            marginLeft: 0,
        },
        marginLeft: 16,
        width: '100%',
    },
    collapse: {
        cursor: 'pointer',
    },
    input: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        width: 350,
        marginBottom: 44,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    menu: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            marginTop: 45,
        },
        marginRight: 32,
    },
    menuContent: {
        [theme.breakpoints.down('sm')]: {
            display: 'none',
        },
        padding: 40,
        borderRadius: 12,
        backgroundColor: theme.palette.common.white,
    },
    paper: {
        backgroundColor: theme.palette.common.white,
    },
    menuItem: {
        [theme.breakpoints.down('sm')]: {
            boxShadow: '0px 4px 8px rgba(16, 35, 83, 0.14)',
            padding: 4,
            borderRadius: 4,
        },
        background: theme.palette.common.white,
        borderRadius: 12,
        '&:not(:last-child)': {
            marginBottom: 16,
        },
        padding: 16,
        '&:hover': {
            cursor: 'pointer',
        },
    },
    activeMenuItem: {
        color: theme.palette.common.white,
        backgroundColor: theme.palette.primary.main,

        '& svg': {
            fill: theme.palette.primary.main,
        },
        '& circle': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.common.white,
        },
        '& path': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.common.white,
        },
        '& rect': {
            fill: theme.palette.primary.main,
            stroke: theme.palette.common.white,
        },
        '& .MuiAvatar-root': {
            backgroundColor: 'transparent !important',
            border: `1px solid ${theme.palette.common.white}`,
        },
    },
    avatar: {
        [theme.breakpoints.down('sm')]: {
            backgroundColor: 'transparent !important',
        },
        backgroundColor: '#F1F1F1 !important',
        borderRadius: 12,
    },
    menuTitle: {
        padding: '0 40px',
        color: theme.palette.grey[400],
        marginBottom: 30,
    },
}));

export const Profile = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const [activeMenuItem, setActiveMenuItem] = useState('personal');
    const [preparedPassport, setpreparedPassport] = useState(null);
    const [preparedContacts, setpreparedContacts] = useState(null);

    const passportState = useSelector((state: RootState) => state.profile.passport);
    const contactsState = useSelector((state: RootState) => state.profile.contacts);
    const familytState = useSelector((state: RootState) => state.profile.family);
    const childrenState = useSelector((state: RootState) => state.profile.children);
    const basicUserState = useSelector((state: RootState) => state.profile.basic);

    const dispatch = useDispatch();

    /* useEffect(() => {
        if (!isViewXS) {
            dispatch(getContacts());
            dispatch(getPassport());
            dispatch(getChildrens());
            dispatch(getFamily());
        }
    }, []);*/
    //TODO: этот компонент остался, похоже, с предыдущей версии Заботы. Пока не выпилил, но нужно уточнить что с ним делать
    history.replace(`/profile/personal`);
    return null;

    const toggleMenu = (item: string) => (
        event: React.KeyboardEvent | React.MouseEvent
    ) => {
        if (
            event &&
            event.type === 'keydown' &&
            ((event as React.KeyboardEvent).key === 'Tab' ||
                (event as React.KeyboardEvent).key === 'Shift')
        ) {
            return;
        }
        if (isViewXS) {
            history.push(`/profile/${item}`);
        } else {
            setActiveMenuItem(item);
        }
    };

    const logoutHandle = () => {
        dispatch(logout());
    };

    useEffect(() => {
        if (passportState.details) {
            let tempFields = map(passportState.details, (value, key) => {
                if (personalFields[key]) {
                    return {
                        ...(personalFields[key] || {}),
                        value: personalFields[key].valueFormatter
                            ? personalFields[key].valueFormatter(value)
                            : value,
                        name: key,
                    };
                }
                return null;
            });
            tempFields = filter(tempFields, Boolean);
            setpreparedPassport(tempFields);
        }
    }, [passportState]);

    useEffect(() => {
        if (contactsState.contacts) {
            let tempFields = map(contactsState.contacts, (value, key) => {
                if (contactsFields[key]) {
                    return {
                        ...(contactsFields[key] || {}),
                        value,
                        name: key,
                    };
                }
                return null;
            });
            tempFields = filter(tempFields, Boolean);
            setpreparedContacts(tempFields);
        }
    }, [contactsState]);

    return (
        <MainLayout
            contentClassName={classes.root}
            pageHeader={<PageHeader title={'pages.Profile.profile'} />}
            withRightColumn={false}
        >
            <Grid container className={classes.grid}>
                <Grid xs={12} item className={classes.titleContainer}>
                    <Typography variant={isViewXS ? 'h4' : 'h1'} component="p">
                        {getUserInitials(basicUserState.info)}
                    </Typography>
                    {isViewXS && (
                        <IconButton size="small" edge="end" onClick={logoutHandle}>
                            <Icon name="exitMin" />
                        </IconButton>
                    )}
                </Grid>
                <Grid item container wrap="nowrap" xs={12}>
                    <Grid
                        sm={4}
                        xs={12}
                        item
                        className={classNames(classes.gridItem, classes.menu)}
                    >
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={toggleMenu('personal')}
                            className={classNames(classes.menuItem, {
                                [classes.activeMenuItem]:
                                    !isViewXS && activeMenuItem === 'personal',
                            })}
                        >
                            <Avatar className={classes.avatar}>
                                <Icon name="personal" />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography
                                    variant={isViewXS ? 'subtitle1' : 'h5'}
                                    component="p"
                                >
                                    {t('pages.Profile.personalData')}
                                </Typography>
                                {isViewXS && <Icon name="arrowRight" />}
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={toggleMenu('contacts')}
                            className={classNames(classes.menuItem, {
                                [classes.activeMenuItem]:
                                    !isViewXS && activeMenuItem === 'contacts',
                            })}
                        >
                            <Avatar className={classes.avatar}>
                                <Icon name="contacts" />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography
                                    variant={isViewXS ? 'subtitle1' : 'h5'}
                                    component="p"
                                >
                                    {t('pages.Profile.contactInfo')}
                                </Typography>
                                {isViewXS && <Icon name="arrowRight" />}
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={toggleMenu('child')}
                            className={classNames(classes.menuItem, {
                                [classes.activeMenuItem]:
                                    !isViewXS && activeMenuItem === 'child',
                            })}
                        >
                            <Avatar className={classes.avatar}>
                                <Icon name="child" />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography
                                    variant={isViewXS ? 'subtitle1' : 'h5'}
                                    component="p"
                                >
                                    Дети
                                </Typography>
                                {isViewXS && <Icon name="arrowRight" />}
                            </Box>
                        </Box>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={toggleMenu('family')}
                            className={classNames(classes.menuItem, {
                                [classes.activeMenuItem]:
                                    !isViewXS && activeMenuItem === 'family',
                            })}
                        >
                            <Avatar className={classes.avatar}>
                                <Icon name="family" />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography
                                    variant={isViewXS ? 'subtitle1' : 'h5'}
                                    component="p"
                                >
                                    {t('pages.Profile.familyStatus')}
                                </Typography>
                                {isViewXS && <Icon name="arrowRight" />}
                            </Box>
                        </Box>
                    </Grid>

                    <Grid
                        sm={8}
                        item
                        className={classNames(classes.gridItem, classes.menuContent)}
                    >
                        {activeMenuItem === 'personal' && preparedPassport && (
                            <Personal preparedPassport={preparedPassport} />
                        )}
                        {activeMenuItem === 'contacts' && preparedContacts && (
                            <Contacts preparedContacts={preparedContacts} />
                        )}
                        {activeMenuItem === 'child' && childrenState.data && (
                            <Childrens preparedChildren={childrenState.data} />
                        )}
                        {activeMenuItem === 'family' && familytState.spouse && (
                            <Family preparedFamily={familytState.spouse} />
                        )}
                    </Grid>
                </Grid>
            </Grid>
        </MainLayout>
    );
};
