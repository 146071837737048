import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_CONTACTS_REQUESTING,
    GET_CONTACTS_FAILED,
    GET_CONTACTS_SUCCEED,
} from '../../../constants';
import { ApiResponse } from 'types/ApiResponse';
import { ProfileContact } from 'types/Profile';

export const getContacts = () => (dispatch, getState) => {
    const dispatchData = (actionType, contacts, requestStatus, errors) => {
        dispatch({
            type: actionType,
            payload: {
                contacts: contacts,
                status: requestStatus,
                errors: errors,
            },
        });
    };

    dispatchData(GET_CONTACTS_REQUESTING, null, null, null);

    axios({
        method: 'GET',
        url: '/api/Profile/contact',
        headers: getCommonHeaders(getState()),
    })
        .then((response: AxiosResponse<ApiResponse<ProfileContact>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                const data = response.data.result;

                dispatchData(GET_CONTACTS_SUCCEED, data, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_CONTACTS_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_CONTACTS_FAILED, null, null, errors);
        });
};
