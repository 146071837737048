import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import cx from 'classnames';
import {
    Box,
    makeStyles,
    Theme,
    Typography,
    Grid,
    Button,
    Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Selector } from 'components/organisms/Application/ApplicationForm/Steps/StepBlocks/Selector';
import { Relatives } from 'components/organisms/Application/ApplicationForm/Steps/StepBlocks/Relatives';
import { ApplicationGroupsField } from 'components/organisms';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useFormContext } from 'react-hook-form';
import isEmpty from 'lodash/isEmpty';
import { useDispatch, useSelector } from 'react-redux';
import { delFamilyMember } from 'store/actions/application';
import { RootState } from 'store/store';
import { useTheme, useMediaQuery } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    family: {
        /*'& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },*/
    },
    familyMembers: {},
    dialogBox: {
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
        },
        padding: '42px 42px',
    },
    dialogTitle: {
        marginBottom: 32,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            right: '5px',
        },
        right: '30px',
        color: theme.palette.grey[500],
    },
    relatives: {},
    buttons: {},
    iconNumber: {
        marginRight: '16px',
        background: '#5780EA',
        width: '24px',
        height: '24px',
        borderRadius: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    number: {
        color: 'white',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '0px 0px 0px 1px',
    },
    iconTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

interface Props {
    memberIndex: any;
    member: any;
    familyMembersLength: number;
    blockIndex: any;
}

export const FamilyMember: React.FC<Props> = ({
    member,
    memberIndex,
    familyMembersLength,
    blockIndex,
}) => {
    const classes = useStyles();
    const [selectedFamilyMemberIndex, setSelectedFamilyMemberIndex] = useState(null);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const formProps = useFormContext();
    const { watch, trigger, register, errors } = formProps;

    const handleClose = () => {
        setSelectedFamilyMemberIndex(null);
        // trigger(`familyMembers[${memberIndex}]`);
    };
    const handleOpen = () => {
        setSelectedFamilyMemberIndex(memberIndex);
    };
    const handleDel = useCallback(() => {
        dispatch(delFamilyMember(memberIndex));
    }, []);

    const application = useSelector((state: RootState) => state.application.application);
    const categories = application.blocks[blockIndex].categories;

    //Имя
    const first_name = watch(
        `familyMembers[${memberIndex}].data.family_member_first_name`
    );
    //Отчество
    const middle_name = watch(
        `familyMembers[${memberIndex}].data.family_member_middle_name`
    );

    const relation_degree_items = find(member.memberFields, {
        fieldId: 'family_member_relation_degree',
    })?.fieldItems;

    //Степень родства (value)
    let relation_degree;
    if (!isEmpty(relation_degree_items)) {
        let relation_degreeWatch = watch(
            `familyMembers[${memberIndex}].data.family_member_relation_degree`
        );
        relation_degree = find(relation_degree_items, {
            key: String(relation_degreeWatch),
        })?.value;
    }
    //Имя + Отчество + (Степень родства)
    let label = first_name;
    label = middle_name ? label + ' ' + middle_name : label;
    label = relation_degree ? label + ' (' + relation_degree + ')' : label;
    if (!label) label = 'Новый член семьи';

    const has_relatives = watch(`familyMembers[${memberIndex}].data.has_relatives`);
    return (
        <>
            <Selector
                label={label}
                onEdit={handleOpen}
                onDelete={handleDel}
                index={memberIndex}
                error={Boolean(errors?.familyMembers?.[memberIndex])}
            />
            <Dialog
                open={selectedFamilyMemberIndex === memberIndex}
                onClose={handleClose}
                keepMounted
                fullWidth={true}
                maxWidth={'md'}
                scroll="body"
                fullScreen={isViewXS}
            >
                <Box className={classes.dialogBox}>
                    <DialogTitle disableTypography className={classes.dialogTitle}>
                        <Box className={classes.iconTitle}>
                            <div className={classes.iconNumber}>
                                <span className={classes.number}>{memberIndex + 1}</span>
                            </div>
                            <Typography variant="h3">Член семьи</Typography>
                        </Box>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    {/*id*/}
                    <input
                        type="hidden"
                        value={member.memberId}
                        name={`familyMembers[${memberIndex}].memberId`}
                        ref={register}
                    />

                    <ApplicationGroupsField
                        categoryName={null}
                        categoryType={null}
                        categoryId={null}
                        fields={member.memberFields}
                        isSuperCategory={false}
                        filedsPath={`familyMembers[${memberIndex}].data`}
                    />
                    {categories.map((category, categoryIndex) => (
                        <ApplicationGroupsField
                            categoryName={category.displayName}
                            categoryType={category.categoryType}
                            categoryId={category.id}
                            fields={member.memberFields}
                            isSuperCategory={false}
                            filedsPath={`familyMembers[${memberIndex}].data`}
                            key={`familyMemberGroup-${categoryIndex}`}
                        />
                    ))}
                    {has_relatives && (
                        <Relatives
                            familyMemberIndex={memberIndex}
                            familyMemberId={member.memberId}
                            blockIndex={blockIndex}
                            relatives={member.relatives}
                            setSelectedFamilyMemberIndex={setSelectedFamilyMemberIndex}
                        />
                    )}

                    <Grid container className={classes.buttons} spacing={3}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Сохранить данные
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Отмена
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};
