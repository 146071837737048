import React from 'react';
import { Checkbox } from 'components/atoms';
import Typography from '@material-ui/core/Typography';
import { FormControlLabel, Link } from '@material-ui/core';
import { Trans, useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Link as RouterLink } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    title: {
        color: '#474D5F',
        paddingBottom: '24px',
    },
    hint: {
        color: '#878B97',
        fontSize: '13px',
        paddingTop: '16px',
    },
    link: {
        color: theme.palette.primary.main,
    },
}));

interface Props {
    useCardOrderValue: boolean;
    onChangeUseCardOrder: (event: React.ChangeEvent<HTMLInputElement>) => void;
}

const SelectCardOrders: React.FC<Props> = ({
    onChangeUseCardOrder,
    useCardOrderValue,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    return (
        <>
            <Typography variant="body1" className={classes.title}>
                <Trans i18nKey="pages.Application.cardOrder" />{' '}
                <Link to={'/finance'} component={RouterLink} className={classes.link}>
                    {t('nav.finance').toLowerCase()}
                </Link>
            </Typography>
            <FormControlLabel
                control={
                    <Checkbox
                        value={useCardOrderValue}
                        checked={useCardOrderValue}
                        onChange={onChangeUseCardOrder}
                        color="primary"
                    />
                }
                labelPlacement="end"
                label={
                    <Typography variant="h5" component="p">
                        {t('pages.Application.cardOrderLabel')}
                    </Typography>
                }
            />
            <Typography variant="body1" className={classes.hint}>
                {t('pages.Application.cardOrderRefused')}
            </Typography>
        </>
    );
};

export default SelectCardOrders;
