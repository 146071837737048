import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { ApiResponse } from 'types/ApiResponse';
import { ProfileRelations } from 'types/Profile';
import {
    GET_FAMILY_REQUESTING,
    GET_FAMILY_SUCCEED,
    GET_FAMILY_FAILED,
} from '../../../constants';
import { getSpouseGender, parseDateString } from 'utils/utils';

export const getFamily = () => (dispatch, getState) => {
    const dispatchData = (actionType, spouse, requestStatus, errors) => {
        dispatch({
            type: actionType,
            payload: {
                spouse: spouse,
                requestStatus: requestStatus,
                errors: errors,
            },
        });
    };

    dispatchData(GET_FAMILY_REQUESTING, null, null, null);

    axios
        .get('/api/Profile/relation', {
            method: 'GET',
            url: '/api/Profile/get',
            headers: getCommonHeaders(getState()),
        })
        .then((response: AxiosResponse<ApiResponse<ProfileRelations>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true && response.data.result !== null) {
                const data = response.data.result;
                const userGender = getState().profile?.passport?.details?.gender;

                const spouse = {
                    ...data,
                    gender: getSpouseGender(userGender),
                    // birthOfDate: parseDateString(data?.birthOfDate),
                    // marriageOfDate: parseDateString(data?.marriageOfDate),
                };

                dispatchData(GET_FAMILY_SUCCEED, spouse, requestStatus, error);
            }
            if (isSuccess === true && response.data.result === null) {
                dispatchData(GET_FAMILY_SUCCEED, null, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_FAMILY_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_FAMILY_FAILED, null, null, errors);
        });
};
