import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { SubsidyLi } from './lis/SubsidyLi';

const useStyles = makeStyles((theme: Theme) => ({
    ul: {
        marginBottom: 24,
    },
}));

interface Props {
    links: any[];
    className?: any;
}

export const LinkList: FC<Props> = ({ links, className }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <>
            <ul className={className ? className : classes.ul}>
                {links.map((item, itemIndex) => (
                    <SubsidyLi item={item} itemIndex={itemIndex} />
                ))}
            </ul>
        </>
    );
};
