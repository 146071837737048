import React, { FC, useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import classNames from 'classnames';
import { isEmpty } from 'lodash';
import { useDispatch, useSelector } from 'react-redux';

import {
    Box,
    Button,
    Dialog,
    IconButton,
    makeStyles,
    Grid,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { AxiosResponse } from 'axios';
import { RootState } from 'store/store';
import { useStyles } from '../Subsidy.styles';
import { errorHandle } from 'utils/utils';
import { useHistory } from 'react-router-dom';
import { EsiaLoginContainer } from 'components/molecules/EsiaLoginContainer';
import CloseIcon from '@material-ui/icons/Close';
import { MakeApplicationForm } from './MakeApplication.form';

const useDialogStyles = makeStyles(theme => ({
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            textTransform: 'uppercase',
        },
        padding: '13px 28px 15px 27px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        flexDirection: 'column',
        padding: 60,
    },
    dialogPaper: {
        backgroundColor: theme.palette.common.white,
    },
}));

interface Props {
    subsidyId: string;
    actionLink: string;
}

export const MakeApplication: FC<Props> = ({ subsidyId, actionLink }) => {
    const classes = useStyles();
    const dialogClases = useDialogStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const [open, setOpen] = useState(false);
    const [preconditionData, setPrecondData] = useState(null);
    const [isLoading, setLoading] = useState(false);
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const state = useSelector((state: RootState) => state);
    const isAuthorized = !!useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );

    const handleClose = () => {
        setOpen(false);
    };

    const handleClickMakeAnApplication = () => {
        if (actionLink) {
            window.open(actionLink, '_blank');
            return;
        }
        loadPrecondition();
    };

    const loadPrecondition = async () => {
        if (preconditionData) {
            setOpen(true);
            return;
        }
        try {
            setLoading(true);
            const res = await axios.get(`/api/subsidies/${subsidyId}/precondition/`, {
                headers: getCommonHeaders(state),
            });
            if (!isEmpty(res.data?.result?.blocks)) {
                //Есть поля - показать форму
                setLoading(false);
                setPrecondData(res.data?.result);
                setOpen(true);
            } else {
                //Нет полей, сразу createApplication
                setLoading(false);
                createApplication();
            }
        } catch (rawError) {
            const error = errorHandle(rawError);
            console.log('loadPrecondition', error);
            setLoading(false);
        }
    };

    const createApplication = async (data = null) => {
        try {
            setLoading(true);
            //TODO: отрефакторить
            const res: AxiosResponse<ApiResponse<any>> = await axios.post(
                `/api/Subsidies/create/${subsidyId}`,
                data // Бек требует data, только, если есть precondition. В противном случае, даже пустая data не будет создавать черновик
                    ? {
                          data: data.data,
                      }
                    : {},
                {
                    headers: getCommonHeaders(state),
                }
            );

            if (res.data.success) {
                setLoading(false);
                const data = res.data;
                history.push('/application/' + data.result.id);
            } else {
                const error = errorHandle(res.data);
                setLoading(false);
            }
        } catch (rawError) {
            const error = errorHandle(rawError);
            console.log('createApplication', error);
            setLoading(false);
        }
    };

    return (
        <>
            <Grid item xs={12} sm={'auto'}>
                {!isAuthorized ? (
                    <EsiaLoginContainer
                        mode="gosuslugi"
                        className={classNames(classes.subsidyCalcButton, classes.button)}
                    />
                ) : (
                    <Button
                        onClick={handleClickMakeAnApplication}
                        className={classNames(classes.subsidyCalcButton, classes.button)}
                        fullWidth={isViewXS}
                        variant={'contained'}
                    >
                        Оформить заявление
                    </Button>
                )}
            </Grid>
            <Dialog
                maxWidth="xs"
                open={open}
                onClose={handleClose}
                scroll="body"
                classes={{
                    paper: dialogClases.dialogPaper,
                }}
            >
                <IconButton
                    size="small"
                    className={dialogClases.closeButton}
                    onClick={handleClose}
                >
                    <CloseIcon />
                </IconButton>
                <Box className={dialogClases.dialogActions}>
                    {preconditionData && (
                        <MakeApplicationForm
                            isLoading={isLoading}
                            preconditionData={preconditionData}
                            createApplication={createApplication}
                        />
                    )}
                </Box>
            </Dialog>
        </>
    );
};
