import React from 'react';
import get from 'lodash/get';
import cx from 'classnames';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';

import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
} from 'components/atoms';
import { SubmitField, FormFieldProps } from 'types/FormField';
import { parseDateString, transformDateToServerString } from 'utils/utils';

const useStylesSubmitField = makeStyles((theme: Theme) => ({
    field: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 20,
            },
        },
        '&:nth-child(n+4)': {
            [theme.breakpoints.up('lg')]: {
                marginTop: 40,
            },
        },
        '&:nth-child(n+3)': {
            [theme.breakpoints.only('md')]: {
                marginTop: 40,
            },
        },
        width: 274,
        marginRight: 16,
    },
}));
interface CardOrderFieldCellProps {
    className?: string;
}
export const CardOrderFieldCell: React.FC<CardOrderFieldCellProps> = ({
    children,
    className,
}) => {
    const classes = useStylesSubmitField();
    return <div className={cx(classes.field, className, 'field')}>{children}</div>;
};

export const CardOrderSubmitField: React.FC<{
    field: SubmitField;
    formProps: FormFieldProps;
}> = ({ field, formProps }) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { errors, register, defaultValues } = formProps;
    const { t } = useTranslation();

    let component;

    if (field.component === null) {
        return null;
    }
    if (field.component === 'address') {
        component = (
            <AddressAutocompleteFormField
                {...formProps}
                fullWidth
                name={field.name}
                label={t(field.label)}
                error={Boolean(errors[field.name])}
                readOnly={field.readOnly}
                defaultFormValue={get(defaultValues, field.name, '')}
                defaultValue={get(defaultValues, field.name, '')}
                // readOnly
                helperText={Boolean(errors[field.name]) && field.errorText}
                validationRules={field.validationRules}
                variant="outlined"
                size={isViewXS ? 'small' : 'medium'}
            />
        );
    } else if (field.component === 'select') {
        component = (
            <SelectFormField
                {...formProps}
                label={t(field.label)}
                name={field.name}
                options={field.options}
                readOnly={field.readOnly}
                // readOnly
                autoComplete="off"
                defaultValue={field.value || get(defaultValues, field.name, '')}
                fullWidth
                validationRules={field.validationRules}
                variant="outlined"
                error={Boolean(get(errors, field.name, false))}
                helperText={Boolean(get(errors, field.name, false)) && field.errorText}
                formControlProps={{
                    size: isViewXS ? 'small' : 'medium',
                }}
            />
        );
    } else if (field.component === 'datepicker') {
        component = (
            <DatePickerFormFIeld
                {...formProps}
                fullWidth
                name={field.name}
                validationRules={field.validationRules}
                format="DD.MM.YYYY"
                label={t(field.label)}
                error={Boolean(get(errors, field.name, false))}
                helperText={Boolean(get(errors, field.name, false)) && field.errorText}
                size={isViewXS ? 'small' : 'medium'}
                autoComplete="off"
                transformValueForBackend={transformDateToServerString}
                defaultFormValue={transformDateToServerString(
                    field.value || get(defaultValues, field.name, '')
                )}
                readOnly={field.readOnly}
                InputProps={{
                    readOnly: field.readOnly,
                }}
                disableFuture
            />
        );
    } else if (field.component === 'checkbox') {
        component = (
            <CheckboxFormField
                {...formProps}
                name={field.name}
                label={t(field.label)}
                readOnly={field.readOnly}
                validationRules={{ required: field.validationRules }}
                defaultValue={field.value || get(defaultValues, field.name, '')}
                color="primary"
                inputProps={{ 'aria-label': t(field.label) }}
            />
        );
    } else {
        component = (
            <MaskedTextField
                fullWidth
                type="text"
                name={field.name}
                label={t(field.label)}
                maskProps={
                    field.mask
                        ? {
                              guide: false,
                              mask: field.mask || undefined,
                          }
                        : undefined
                }
                InputProps={{
                    readOnly: field.readOnly,
                    // readOnly: true,
                    autoComplete: 'off',
                }}
                placeholder={field.placeholder}
                autoComplete="off"
                defaultValue={field.value || get(defaultValues, field.name, '')}
                variant="outlined"
                error={Boolean(get(errors, field.name, false))}
                helperText={Boolean(get(errors, field.name, false)) && field.errorText}
                inputRef={register(field.validationRules)}
                size={isViewXS ? 'small' : 'medium'}
            />
        );
    }

    return <CardOrderFieldCell>{component}</CardOrderFieldCell>;
};
