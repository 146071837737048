import React, { useState, useCallback, useRef, useEffect, FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Grid,
    Typography,
    Box,
    Paper,
    IconButton,
    useTheme,
    useMediaQuery,
    Button,
    CircularProgress,
    FormControlLabel,
} from '@material-ui/core';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';

import { Icon, Bubble, Checkbox, TextField } from 'components/atoms';
import { MainLayout } from 'components/layouts';
import { RootState } from 'store/store';
import {
    getChatHistory,
    initializeChat,
    sendChatMessage,
    clearChatHistory,
    disconnectSignalR,
} from 'store/actions/chat';
import {
    CheckBoxes,
    CheckListItem,
    Message,
    MessagesGroupByDay,
    MessageType,
} from 'types/Chat';

import {
    SelectedMessageProvider,
    useSelectedMessages,
} from '../.././useSelectedMessageContext';
import { getApplications } from 'store/actions/application';
import { useSnackbar } from 'notistack';
import { Link, useHistory } from 'react-router-dom';
import { signinEsia } from 'store/actions';
import { PageHeader } from 'components/molecules';
import { constructRedirectUrl, getColorBySubsidyCategory } from 'utils/utils';
import { useDialog } from 'hooks/useDialog';

const useStylesChatActions = makeStyles(() => ({
    chatButtonsContainer: {
        display: 'flex',
        flexWrap: 'wrap',
        marginLeft: 'auto',
        justifyContent: 'flex-end',
    },
    chatButton: {
        marginLeft: 16,
        marginBottom: 8,
    },
}));

interface ChatActionsProps {
    onMessage: (message: string) => void;
    lastMessage: Message;
}

const ChatActions: FC<ChatActionsProps> = ({ onMessage, lastMessage }) => {
    const classes = useStylesChatActions();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const application = useSelector((state: RootState) => state.application);
    const chatState = useSelector((state: RootState) => state.chat);
    const { enqueueSnackbar } = useSnackbar();
    const history = useHistory();
    const { openDialog } = useDialog();
    useEffect(() => {
        if (application.getApplications.error) {
            enqueueSnackbar(t(application.getApplications.error), {
                variant: 'error',
                autoHideDuration: 3000,
            });
        }
    }, [application]);

    const showNotification = () => {
        enqueueSnackbar(t('pages.Chat.noMSP'), {
            variant: 'warning',
            autoHideDuration: 3000,
        });
    };

    const handleMessage = (buttonText: string, buttonAction?: string) => (
        event: React.MouseEvent<HTMLButtonElement>
    ) => {
        if (buttonAction) {
            if (buttonAction === 'processSubsidies' || buttonAction === 'wantIssue') {
                dispatch(
                    getApplications(showNotification, id =>
                        history.push(`/application/${id}`)
                    )
                );
            } else if (buttonAction === 'authEsia' || buttonAction === 'login') {
                const redirectUrl = constructRedirectUrl();
                dispatch(
                    signinEsia(redirectUrl, () => {
                        openDialog('AuthError');
                    })
                );
            } else {
                onMessage(buttonText);
            }
        } else {
            // TODO: выпилить когда будут приходить экшены
            if (buttonText === t('pages.Chat.wantToRegister')) {
                dispatch(
                    getApplications(showNotification, id =>
                        history.push(`/application/${id}`)
                    )
                );
            } else {
                onMessage(buttonText);
            }
        }
    };

    const needStartIcon = (buttonText: string, buttonAction?: string) => {
        if (buttonText === chatState.sendMessageText && chatState.sendMessageLoading) {
            return true;
        }
        if (
            (buttonAction === 'processSubsidies' || buttonAction === 'wantIssue') &&
            application.getApplications.loading
        ) {
            return true;
        }
        return false;
    };
    if (lastMessage && !isEmpty(lastMessage?.message.Buttons)) {
        const buttons = lastMessage?.message.Buttons;
        return (
            <Box className={classes.chatButtonsContainer}>
                {map(buttons, ({ Text, Action }, index) => {
                    return (
                        <Button
                            disabled={
                                chatState.sendMessageLoading ||
                                application.getApplications.loading
                            }
                            startIcon={
                                needStartIcon(Text, Action) && (
                                    <CircularProgress size={16} />
                                )
                            }
                            variant="contained"
                            size="small"
                            onClick={handleMessage(Text, Action)}
                            key={`button_${index}`}
                            className={classes.chatButton}
                        >
                            {Text}
                        </Button>
                    );
                })}
            </Box>
        );
    }
    return null;
};

export default ChatActions;
