import {
    PROFILE_USE_ESIA_REQUESTING,
    PROFILE_USE_ESIA_SUCCEED,
    PROFILE_USE_ESIA_FAILED,
} from '../../constants';

const signinInitState = {
    redirectLink: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const esia = (state = signinInitState, action) => {
    switch (action.type) {
        case PROFILE_USE_ESIA_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };
        case PROFILE_USE_ESIA_SUCCEED:
            return {
                ...state,
                redirectLink: action.redirectLink,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case PROFILE_USE_ESIA_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };

        default:
            return state;
    }
};
