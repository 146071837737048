import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import { Typography, Box, Button, useTheme, useMediaQuery } from '@material-ui/core';

import { Icon, Tag } from 'components/atoms';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { useStyles } from './Subsidy.styles';

interface SubsidyAmountProps {
    subsidy: SubsidyManagementModel;
    handleClickCalculator: VoidFunction;
}
export const SubsidyAmount: FC<SubsidyAmountProps> = ({
    subsidy,
    handleClickCalculator,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const checkWebView = url =>
        !url.includes(
            process.env.NODE_ENV === 'production' ? window.location.host : 'zabota'
        );

    if (subsidy?.amount && subsidy?.amountDescription) {
        return (
            <Box>
                <Typography
                    variant={isViewXS ? 'body1' : 'h4'}
                    component="p"
                    className={classes.subsidyAmount}
                >
                    <Icon name="subsidyRuble" />{' '}
                    {`${subsidy.amount.toLocaleString()} ${subsidy.amountDescription}`}
                </Typography>
            </Box>
        );
    }

    if (subsidy?.amount) {
        return (
            <Box>
                <Typography
                    variant={isViewXS ? 'body1' : 'h4'}
                    component="p"
                    className={classes.subsidyAmount}
                >
                    <Icon name="subsidyRuble" /> {subsidy.amount.toLocaleString()}
                </Typography>
            </Box>
        );
    }

    if (subsidy?.amountDescription) {
        return (
            <Box>
                <Typography
                    variant={isViewXS ? 'body1' : 'h4'}
                    component="p"
                    className={classes.subsidyAmount}
                >
                    {subsidy.amountDescription}
                </Typography>
            </Box>
        );
    }

    if (subsidy?.calculatorButton.isCalculatorActive) {
        if (
            subsidy?.calculatorButton.buttonName &&
            checkWebView(subsidy?.calculatorButton.externalCalculatorUrl)
        ) {
            return (
                <Typography
                    component="a"
                    href={subsidy?.calculatorButton.externalCalculatorUrl}
                    target="_blank"
                    className={classes.subsidyCalcLink}
                >
                    {subsidy?.calculatorButton.buttonName}
                </Typography>
            );
        }
        return (
            <Button
                onClick={handleClickCalculator}
                className={classNames(classes.subsidyCalcButton, classes.button)}
                fullWidth={isViewXS}
                variant={isViewXS ? 'contained' : 'outlined'}
            >
                {isViewXS
                    ? t('pages.SubsidyDetail.calculateThePayoutAmount')
                    : t('pages.SubsidyDetail.calculateThePayout')}
            </Button>
        );
    }

    return null;
};
