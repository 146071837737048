import { SELECT_CHILD, DESELECT_CHILD } from '../../../constants';

export const selectChild = id => dispatch => {
    dispatch({
        type: SELECT_CHILD,
        id: id,
    });
};

export const deselectChild = () => dispatch => {
    dispatch({
        type: DESELECT_CHILD,
    });
};
