import React from 'react';

import { Box, makeStyles, Typography } from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import cx from 'classnames';

const useStyles = makeStyles(theme => ({
    link: {
        color: '#000A26',
        fontSize: '14px',
        fontWeight: 'normal',
    },
    img: {
        marginRight: 4,
    },
}));

interface Props {
    img: any;
    to: string;
    title: string;
    target?: string;
    className?: any;
}

export const IconLink: React.FC<Props> = ({ img, to, title, className, target }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <a
            href={to}
            className={className ? cx(classes.link, className) : classes.link}
            target={target}
        >
            <img
                className={classes.img}
                src={img}
                alt="gosuslugi"
                width="24px"
                height="24px"
            />{' '}
            {title}
        </a>
    );
};
