import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_GET_BANK_NAME_FAILED,
    WALLET_GET_BANK_NAME_REQUESTING,
    WALLET_GET_BANK_NAME_SUCCEED,
    COMMON_ERROR,
} from '../../constants';

export const getBankName = bic => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error, _bic) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
                bic: _bic,
            },
        });
    };

    dispatchData(WALLET_GET_BANK_NAME_REQUESTING, null, null, null, bic);

    axios
        .get(`/api/Card/getbankname/${bic}`, {
            headers: getCommonHeaders(getState()),
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const result = response.data.result;

            if (isSuccess === true) {
                dispatchData(
                    WALLET_GET_BANK_NAME_SUCCEED,
                    result,
                    requestStatus,
                    null,
                    null
                );
            }

            if (isSuccess === false) {
                const error = errorHandle(response.data);
                dispatchData(
                    WALLET_GET_BANK_NAME_FAILED,
                    null,
                    requestStatus,
                    error.error,
                    null
                );
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError.response.data || rawError);
            dispatchData(WALLET_GET_BANK_NAME_FAILED, null, null, error.error, null);
        });
};
