import React from 'react';
import { Link } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    error: {
        marginTop: 30,
    },
}));

export const Empty: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <Box>
            <Typography className={classes.error}>
                {t('pages.Application.noMSP')}
            </Typography>
            <Button
                style={{ marginTop: 30 }}
                component={Link}
                to="/home"
                variant="contained"
            >
                {t('common.toHome')}
            </Button>
        </Box>
    );
};
