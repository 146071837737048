import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import {
    Box,
    makeStyles,
    Theme,
    Typography,
    Grid,
    Button,
    Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Selector } from 'components/organisms/Application/ApplicationForm/Steps/StepBlocks/Selector';
import { ApplicationGroupsField } from 'components/organisms';
import { TransitionProps } from '@material-ui/core/transitions';
import Slide from '@material-ui/core/Slide';
import { FamilyMember } from './FamilyMember';
import { useDispatch, useSelector } from 'react-redux';
import { useFormContext } from 'react-hook-form';
import { addFamilyMember } from 'store/actions/application';

const useStyles = makeStyles((theme: Theme) => ({
    family: {
        /*'& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },*/
        marginTop: 20,
    },
    familyMembers: {},
    btn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

interface Props {
    familyMembersTemplate: any;
    familyMembers: any;
    blockIndex: any;
}

export const Family: React.FC<Props> = ({
    familyMembers,
    familyMembersTemplate,
    blockIndex,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleAddFamilyMember = useCallback(() => {
        dispatch(addFamilyMember());
    }, []);

    const formProps = useFormContext();
    const { watch } = formProps;

    return (
        <Grid container className={classes.family} direction="column" spacing={3}>
            {!isEmpty(familyMembers) && (
                <Grid item>
                    <Box className={classes.familyMembers}>
                        {familyMembers.map((member, itemIndex) => (
                            <FamilyMember
                                key={`familyMember-${itemIndex}-${member.memberId}`}
                                memberIndex={itemIndex}
                                member={member}
                                familyMembersLength={familyMembers.length}
                                blockIndex={blockIndex}
                            />
                        ))}
                    </Box>
                </Grid>
            )}
            <Grid item>
                <Button
                    variant="outlined"
                    onClick={handleAddFamilyMember}
                    className={classes.btn}
                >
                    Добавить члена семьи
                </Button>
            </Grid>
        </Grid>
    );
};
