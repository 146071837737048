import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { MainLayout } from 'components/layouts';
import { PageHeader } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import { ApplicationContainer } from '../../organisms/Application';

const usePageStyles = makeStyles(theme => ({
    root: {},
    pageContent: {
        [theme.breakpoints.down('xs')]: {
            backgroundColor: theme.palette.common.white,
            padding: 0,
        },
        padding: 24,
    },
    pageGrid: {
        [theme.breakpoints.down('xs')]: {
            '&::before': {
                backgroundColor: theme.palette.common.white,
            },
        },
    },
    contentColumn: {
        width: 'auto',
        marginRight: 0,
    },
}));

const ApplicationPage: React.FC = () => {
    const classes = usePageStyles();
    const { t } = useTranslation();

    return (
        <MainLayout
            contentClassName={classes.root}
            pageHeader={
                <PageHeader title={t('pages.Application.makingAnOnline')} hasBackLink />
            }
            pageContentClassName={classes.pageContent}
            withFooter
            withRightColumn={false}
            gridClassName={classes.pageGrid}
            contentColumnClassName={classes.contentColumn}
        >
            <ApplicationContainer />
        </MainLayout>
    );
};

export default ApplicationPage;
