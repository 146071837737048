import React, { useState, useCallback, useRef, useEffect, FC, Fragment } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import cx from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Paper, IconButton } from '@material-ui/core';

import { Icon, TextField } from 'components/atoms';
import { Message } from 'types/Chat';

const useStylesChatInput = makeStyles((theme: Theme) => ({
    input: {
        flex: 1,
        '& .MuiOutlinedInput-multiline': {
            padding: '0 14px',
        },
        '& .MuiInputBase-multiline.MuiInputBase-marginDense .MuiInputBase-inputMultiline': {
            paddingBottom: 0,
        },
    },
    paper: {
        [theme.breakpoints.down('xs')]: {
            margin: '0 0 16px',
        },
        background: '#FFFFFF',
        position: 'absolute',
        right: 0,
        left: 0,
        bottom: 0,
        margin: '0 24px 24px',
    },
    iconButton: {
        // padding: 10,
    },
}));

interface ChatInputProps {
    onMessage: (message: string) => void;
    lastMessage?: Message;
}

const ChatInput: FC<ChatInputProps> = ({ onMessage, lastMessage }) => {
    const classes = useStylesChatInput();
    const { t } = useTranslation();

    const [message, setmessage] = useState('');

    const onChange = useCallback(
        (event: React.ChangeEvent<HTMLInputElement>) => {
            let value = event.target.value;
            // убирает перенос строки, пробелы и табы если строка пустая
            if (/^[\s]+$/.test(value)) {
                value = '';
            }

            setmessage(value);
        },
        [setmessage]
    );

    const handleOnMessage = useCallback(
        event => {
            const trimmedMessage = message.trim();
            if (trimmedMessage) {
                onMessage(message);
                setmessage('');
            }
        },
        [onMessage, setmessage, message]
    );

    const handleKeyPress = useCallback(
        (event: React.KeyboardEvent<HTMLDivElement>) => {
            if (event.key === 'Enter') {
                const trimmedMessage = message.trim();
                if (trimmedMessage) {
                    onMessage(message);
                    setmessage('');
                }
            }
        },
        [onMessage, setmessage, message]
    );

    return (
        <Paper className={classes.paper}>
            <TextField
                // autoFocus
                size="small"
                value={message}
                className={classes.input}
                placeholder={t('pages.Chat.writeMessage')}
                fullWidth
                inputProps={{ 'aria-label': t('pages.Chat.writeMessage') }}
                onKeyPress={handleKeyPress}
                onChange={onChange}
                variant="outlined"
                InputProps={{
                    endAdornment: (
                        <IconButton
                            color="primary"
                            size="small"
                            className={classes.iconButton}
                            aria-label={t('pages.Chat.sendMessage')}
                            onClick={handleOnMessage}
                        >
                            <Icon name="send"></Icon>
                        </IconButton>
                    ),
                }}
                multiline
                rowsMax={2}
            />
            {/* <IconButton
                type="submit"
                className={classes.iconButton}
                aria-label="Прикрепить файл"
            >
                <Icon name="attach"></Icon>
            </IconButton> */}
        </Paper>
    );
};

export default ChatInput;
