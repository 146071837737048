/**
 * Список названий фич.
 * @type {string}
 */

/*Возможность заказать карту*/
export const card_request_10_02_2021 = 'card_request_10_02_2021';

/*Услуги*/
export const get_services_07_04_2021 = 'get_services_07_04_2021';

/*Услуга ЖКУ*/
export const get_services_zku_07_04_2021 = 'get_services_zku_07_04_2021';

/**Ссылка на госулсуги в футере и хеадере*/
export const gosuslugi_link_06_05_2021 = 'gosuslugi_link_06_05_2021';

/**Ссылка на соц. защиту в хеадере*/
export const soc_protection_link_06_05_2021 = 'soc_protection_link_06_05_2021';

/**Ссылка на соц. защиту в футере*/
export const cct_link_06_05_2021 = 'cct_link_06_05_2021';

/**Ссылка на МИС РТ в футере*/
export const mis_rt_link_06_05_2021 = 'mis_rt_link_06_05_2021';

/** Ссылка на "Оценить качество" в футере */
export const rate_quality_link_06_05_2021 = 'rate_quality_link_06_05_2021';

/** Ссылка на "Жалобу" в футере */
export const complaint_link_06_05_2021 = 'complaint_link_06_05_2021';

/** Выбор карты в заявке на МСП */
export const msp_select_cards_18_05_2021 = 'msp_select_cards_18_05_2021';

/** МСП без подбора + кнопка */
export const allowance_request_18_05_2021 = 'allowance_request_18_05_2021';
