import React, { FC } from 'react';
import { useSelector } from 'react-redux';

import {
    Box,
    Button,
    Dialog,
    IconButton,
    makeStyles,
    SwipeableDrawer,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import authErrorImage from 'assets/images/authError.png';
import { Link } from 'react-router-dom';
import { RootState } from 'store/store';

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
    },
    image: {
        marginBottom: 40,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            textTransform: 'uppercase',
        },
        padding: '13px 28px 15px 27px',
        width: '100%',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        flexDirection: 'column',
        padding: 16,
        display: 'flex',
        alignItems: 'center',
        textAlign: 'center',
    },
    text: {
        fontSize: 18,
    },
}));

interface Props {
    onClose: VoidFunction;
}

export const AuthError: FC<Props> = ({ onClose }) => {
    const classes = useStyles();
    const theme = useTheme();
    const error = useSelector(
        (state: RootState) => state.account.signInEsia.request.errors
    );

    return (
        <>
            <IconButton size="small" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Box className={classes.dialogActions}>
                <img
                    src={authErrorImage}
                    alt=""
                    width="200px"
                    height="170px"
                    className={classes.image}
                />
                <Box marginBottom="40px">
                    <Typography className={classes.text}>{error}</Typography>
                </Box>
                <Button
                    className={classes.button}
                    onClick={onClose}
                    color="primary"
                    variant="contained"
                    fullWidth
                >
                    Закрыть
                </Button>
            </Box>
        </>
    );
};
