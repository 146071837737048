import {
    WALLET_CHECK_CARD_REQUESTING,
    WALLET_CHECK_CARD_SUCCEED,
    WALLET_CHECK_CARD_FAILED,
} from '../../constants';

const initState = {
    result: undefined,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        error: null,
    },
};

export const checkCard = (state = initState, action) => {
    switch (action.type) {
        case WALLET_CHECK_CARD_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    error: null,
                },
            };
        case WALLET_CHECK_CARD_SUCCEED:
            return {
                ...state,
                result: action.payload.result,
                request: {
                    ...state.request,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: true,
                    error: null,
                },
            };
        case WALLET_CHECK_CARD_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};
