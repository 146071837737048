import React, { FC, useCallback, useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import classNames from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { AxiosResponse } from 'axios';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { errorHandle } from 'utils/utils';

import { Loading, Tag } from 'components/atoms';
import { MainLayout } from 'components/layouts';
import { downloadStatementFile, getMySubsidy } from 'store/actions';
import { RootState } from 'store/store';
import { PageHeader } from 'components/molecules';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { setCurrentApplicationId } from 'store/slices/application.slice';

import { useStyles } from './styles';
import { StatementForm } from './StatementForm';

interface SubsidyProps {
    // id?: string;
}

export const Statement: FC<SubsidyProps> = () => {
    const params: { id?: string } = useParams();
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector((state: RootState) => state);

    const [solutionFiles, setSolutionFiles] = useState([]);
    const [isLoadingFiles, setLoadingFiles] = useState(false);

    const currentSubsidyState = useSelector(
        (state: RootState) => state.subsidies.mySubsidies
    );

    const requestId = currentSubsidyState.selectedSubsidy?.requestId;

    const downloadFile = useCallback(
        fileId => {
            dispatch(downloadStatementFile(fileId));
        },
        [dispatch, currentSubsidyState]
    );

    const getSolutionFiles = async () => {
        try {
            setLoadingFiles(true);
            const res: AxiosResponse<ApiResponse<any>> = await axios.get(
                `/api/Subsidies/getFiles`,
                {
                    headers: getCommonHeaders(state),
                    params: {
                        requestId,
                    },
                }
            );
            if (res.data.success) {
                setLoadingFiles(false);
                setSolutionFiles(res.data.result);
            } else {
                const error = errorHandle(res.data);
                setLoadingFiles(false);
            }
        } catch (rawError) {
            const error = errorHandle(rawError);
            setLoadingFiles(false);
        }
    };

    useEffect(() => {
        getSolutionFiles();
        return () => {
            setSolutionFiles([]);
        };
    }, [requestId]);

    useEffect(() => {
        if (params.id) {
            dispatch(getMySubsidy(params.id));
        }
    }, [params.id, dispatch]);
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);

    if (currentSubsidyState.request.isLoading) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={
                    <PageHeader
                        title={currentSubsidyState.selectedSubsidy?.name}
                        hasBackLink={!isViewXS}
                    />
                }
                pageContentClassName={classes.pageLoadingContent}
                withFooter
                withRightColumn={!isViewXS}
                gridClassName={classes.pageLoadingGrid}
            >
                <Loading />
            </MainLayout>
        );
    }
    if (currentSubsidyState.selectedSubsidy) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={
                    <PageHeader
                        title={currentSubsidyState.selectedSubsidy?.name}
                        hasBackLink={!isViewXS}
                        status={currentSubsidyState.selectedSubsidy?.status}
                        statusId={currentSubsidyState.selectedSubsidy?.statusId}
                    />
                }
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={!isViewXS}
                gridClassName={classes.pageGrid}
            >
                <StatementForm
                    requestId={requestId}
                    downloadFile={downloadFile}
                    solutionFiles={solutionFiles}
                    application={currentSubsidyState.selectedSubsidy}
                    fileIsLoading={currentSubsidyState.file.isLoading}
                />
            </MainLayout>
        );
    }
    return null;
};
