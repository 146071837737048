import React from 'react';

export const useCollapse = (defaultValue?: boolean) => {
    const [expanded, setExpanded] = React.useState(defaultValue);

    const handleExpandClick = () => {
        setExpanded(!expanded);
    };

    return {
        expanded,
        handleExpandClick,
    };
};
