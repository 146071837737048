import React, { useState } from 'react';
import { Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import get from 'lodash/get';
import filter from 'lodash/filter';
import find from 'lodash/find';
import map from 'lodash/map';

import { FormControl } from '@abdt/ornament';
import {
    ButtonProps,
    createStyles,
    FormHelperText,
    FormLabel,
    makeStyles,
    TextFieldProps,
    Theme,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import { uploadFile } from 'store/actions/application';
import { FormFieldProps, SubmitField } from 'types/FormField';

import { UploaderNotification } from './UploaderNotification';
import {
    UploaderOnDropFiles,
    UploaderFile,
    UploaderOnReloadFile,
    UploaderOnDeleteFile,
    UploaderProps,
} from './Uploader.types';
import Uploader from './Uploader';

const useStyles = makeStyles((theme: Theme) =>
    createStyles({
        uploaderLabel: {
            fontSize: 18,
            lineHeight: 1.43,
            marginBottom: 16,
            color: theme.palette.text.primary,
        },
        uploaderFormControl: {
            height: '100%',
        },
        button: {},
    })
);
type Props = {
    textFieldProps?: TextFieldProps;
    uploaderProps?: UploaderProps;
    buttonProps?: ButtonProps;
} & FormFieldProps &
    SubmitField;

export const UploaderFormField: React.FC<Props> = props => {
    const {
        control,
        errors,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        label,
        defaultValue,
        defaultFormValue,
        disabled,
        textFieldProps,
        uploaderProps,
        buttonProps,
        helperText,
    } = props;
    const fieldId = name;
    const [filesDataFull, setFilesDataFull] = useState([]);
    const dispatch = useDispatch();
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const initialFiles = map(defaultValue ? defaultValue?.files : [], file => ({
        ...file,
        name: file?.filename,
    }));
    const setFilesData = file => {
        if (!file) {
            return;
        }
        setFilesDataFull(prevFiles => {
            return [...prevFiles, file];
        });
        const files = get(JSON.parse(watch(name)), 'files', []);
        const filesData = {
            fileTypeId: defaultValue?.fileTypeId,
            files: [...files, file.serverData],
        };
        const filesDataString = JSON.stringify(filesData);
        setValue(name, filesDataString);
    };

    const addFileToFilesData = (setFileStatusLoaded, setFileErrorNotLoaded, file) => {
        dispatch(
            uploadFile(
                setFileStatusLoaded,
                setFileErrorNotLoaded,
                file,
                defaultValue?.fileTypeId,
                setFilesData
            )
        );
    };

    const dropHandler: UploaderOnDropFiles = (
        setFileStatusLoaded,
        setFileErrorNotLoaded,
        acceptedFiles
    ) => {
        acceptedFiles.forEach((file: UploaderFile) => {
            addFileToFilesData(setFileStatusLoaded, setFileErrorNotLoaded, file);
        });
    };

    const reloadFileHandler: UploaderOnReloadFile = (
        setFileStatusLoaded,
        setFileErrorNotLoaded,
        file
    ) => {
        addFileToFilesData(setFileStatusLoaded, setFileErrorNotLoaded, file);
    };

    const deleteFileHandler: UploaderOnDeleteFile = (deletedFile: UploaderFile) => {
        let files = get(JSON.parse(watch(name)), 'files', []);
        let filesData;
        if (deletedFile.fileId) {
            files = filter(
                files,
                (file: UploaderFile) => file.fileId !== deletedFile.fileId
            );
            filesData = {
                fileTypeId: defaultValue?.fileTypeId,
                files: files,
            };
            setFilesDataFull(prevFiles => {
                return prevFiles.filter(file => file.fileId !== deletedFile.fileId);
            });
        } else {
            const deletedFileFull = find(
                filesDataFull,
                file => file.dropId === deletedFile.dropId
            );
            files = filter(
                files,
                (file: UploaderFile) => file.fileId !== deletedFileFull.serverData.fileId
            );
            filesData = {
                fileTypeId: defaultValue?.fileTypeId,
                files: files,
            };
            setFilesDataFull(prevFiles => {
                return prevFiles.filter(
                    file => file.fileId !== deletedFileFull.serverData.fileId
                );
            });
        }
        const filesDataString = JSON.stringify(filesData);
        setValue(name, filesDataString);
    };
    return (
        <Controller
            control={control}
            name={name}
            rules={validationRules}
            defaultValue={defaultFormValue}
            render={() => (
                <FormControl
                    disabled={disabled}
                    error={Boolean(get(errors, name, false))}
                    required={validationRules?.required || undefined}
                    variant="outlined"
                    fullWidth
                    className={classes.uploaderFormControl}
                >
                    <FormLabel className={classes.uploaderLabel}>{label}</FormLabel>
                    <UploaderNotification fieldId={fieldId} />
                    <Uploader
                        onDrop={dropHandler}
                        onReloadFile={reloadFileHandler}
                        onDeleteFile={deleteFileHandler}
                        initialFiles={initialFiles}
                        textFieldProps={{
                            ...textFieldProps,
                            error: Boolean(get(errors, name, false)),
                            disabled: disabled,
                            variant: 'outlined',
                        }}
                        buttonProps={{
                            ...buttonProps,
                            className: classes.button,
                            disabled: disabled,
                            variant: 'outlined',
                            fullWidth: isViewXS ? true : false,
                        }}
                        {...uploaderProps}
                        error={Boolean(get(errors, name, false))}
                    />
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            )}
        />
    );
};
