import React, { useEffect, FC } from 'react';

import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, FormControlLabel } from '@material-ui/core';

import { Checkbox } from 'components/atoms';

import { CheckBoxes } from 'types/Chat';

import { useSelectedMessages } from '../.././useSelectedMessageContext';

const useStylesCheckBoxesMessage = makeStyles((theme: Theme) => ({
    checkBoxexMessageContainer: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: -30,
        },
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    checkBoxesMessageContentContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    checkBoxesMessageChildTag: {
        fontSize: 14,
        lineHeight: 1.43,
        fontWeight: 'normal',
        marginBottom: 10,
    },
    checkBoxesMessageCheckboxControlLabel: {
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'flex-start',
    },
    checkBoxesMessageCheckbox: {
        padding: 0,
        marginRight: 12,
    },
}));

interface CheckBoxesMessageProps {
    item: CheckBoxes;
    disabled: boolean;
}

const CheckBoxesMessage: FC<CheckBoxesMessageProps> = ({ item, disabled }) => {
    const classes = useStylesCheckBoxesMessage();
    const { onChangeSelectedMessages } = useSelectedMessages();
    const [checked, setChecked] = React.useState(false);

    useEffect(() => {
        if (disabled) {
            onChangeSelectedMessages(item.Text, false);
            setChecked(false);
        }
    }, [disabled]);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        onChangeSelectedMessages(item.Text, event.target.checked);
        setChecked(event.target.checked);
    };

    return (
        <>
            <Box className={classes.checkBoxexMessageContainer}>
                <Box className={classes.checkBoxesMessageContentContainer}>
                    <FormControlLabel
                        className={classes.checkBoxesMessageCheckboxControlLabel}
                        control={
                            <Checkbox
                                disabled={disabled}
                                className={classes.checkBoxesMessageCheckbox}
                                value={checked}
                                checked={checked}
                                onChange={onChange}
                                color="primary"
                                inputProps={{ 'aria-label': item.Text }}
                            />
                        }
                        labelPlacement="end"
                        label={
                            <Box>
                                <Typography variant="body1" component="p">
                                    {item.Text}
                                </Typography>
                            </Box>
                        }
                    />
                </Box>
            </Box>
        </>
    );
};

export default CheckBoxesMessage;
