import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    SIGN_IN_ESIA_CONFIRM_REQUESTING,
    SIGN_IN_ESIA_CONFIRM_SUCCEED,
    SIGN_IN_ESIA_CONFIRM_FAILED,
    SIGN_IN_ESIA_RESET_TOKEN,
} from '../../constants';
import { setAuthToken } from 'utils/token';
import { setJson, deleteJson } from 'utils/localStorage';
import { LocalStorageKeys } from 'types/LocalStorageKeys';
import { frontUrl } from 'config';

export const signinEsiaConfirm = (requestId, token) => (dispatch, getState) => {
    const dispatchData = (actionType, userId, requestStatus, token, errors) => {
        dispatch({
            type: actionType,
            userId,
            token,
            requestStatus,
            errors,
        });
    };
    deleteJson(LocalStorageKeys.ConfirmRequestId);
    deleteJson(LocalStorageKeys.ConfirmToken);

    dispatchData(SIGN_IN_ESIA_CONFIRM_REQUESTING, null, null, null, null);

    return axios
        .post(
            '/api/v2/auth/esiaPersonal',
            { requestId, token, redirectUrl: frontUrl },
            { headers: getCommonHeaders() }
        )
        .then(response => {
            const { status, data: { success = false, error, result = {} } = {} } =
                response;
            const { token, refreshToken, userId } = result;

            if (success === true) {
                setAuthToken(token);
                // deleteAnonimToken()
                setJson(LocalStorageKeys.UserId, userId);
                setJson(LocalStorageKeys.UserBanner, true);
                dispatchData(SIGN_IN_ESIA_CONFIRM_SUCCEED, userId, status, token, error);
            } else {
                dispatchData(SIGN_IN_ESIA_CONFIRM_FAILED, null, null, status, error);
            }
        })
        .catch(error => {
            dispatchData(SIGN_IN_ESIA_CONFIRM_FAILED, null, null, null, error.message);
        });
};

export const signinEsiaResetToken = () => ({
    type: SIGN_IN_ESIA_RESET_TOKEN,
});
