import { ProfilePassportState } from 'types/Profile';
import {
    GET_PASSPORT_REQUESTING,
    GET_PASSPORT_SUCCEED,
    GET_PASSPORT_FAILED,
} from '../../constants';

const initialState: ProfilePassportState = {
    details: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const passport = (state = initialState, action) => {
    switch (action.type) {
        case GET_PASSPORT_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case GET_PASSPORT_SUCCEED:
            return {
                ...state,
                details: action.payload.passport,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.payload.errors,
                },
            };
        case GET_PASSPORT_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload.errors,
                },
            };
        default:
            return state;
    }
};
