import React from 'react';
import { useFeatures } from 'hooks/useFeatures';
import isEmpty from 'lodash/isEmpty';

interface Props {
    name: string;
    activeComponent?: React.ReactNode;
    inactiveComponent?: React.ReactNode;
    children?: React.ReactNode;
}

export const FeatureToggle: React.FC<Props> = ({
    name,
    children,
    inactiveComponent = null,
    activeComponent,
}): any => {
    const findContext = useFeatures();

    if (isEmpty(name)) return activeComponent ? activeComponent : children;

    return findContext(name)
        ? activeComponent
            ? activeComponent
            : children
        : inactiveComponent;
};
