import React from 'react';

import { makeStyles, Typography } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    pageTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            lineHeight: 1.4,
            fontWeight: 500,
        },
        fontSize: 28,
        lineHeight: 1.14,
        fontWeight: 600,
    },
}));
interface PageTitleProps {
    title: string;
}
export const PageTitle: React.FC<PageTitleProps> = ({ title }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return <Typography className={classes.pageTitle}>{t(title)}</Typography>;
};
