import React from 'react';

import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Container as MaterialUiContainer } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        width: '100%',
        minHeight: '100%',
    },
    container: {},
}));

interface Props {
    contentClassName?: string;
    containerClassName?: string;
}

export const EmptyLayout: React.FC<Props> = props => {
    const classes = useStyles();
    const { children, contentClassName, containerClassName } = props;

    return (
        <MaterialUiContainer
            maxWidth="lg"
            className={cx(classes.container, containerClassName)}
        >
            <div className={cx(classes.root, contentClassName)}>{children}</div>
        </MaterialUiContainer>
    );
};
