import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import { BrowserRouter } from 'react-router-dom';
import moment from 'moment';

import { MuiPickersUtilsProvider } from '@material-ui/pickers';
import MomentUtils from '@date-io/moment';

import 'moment/locale/ru';
import 'flexboxgrid/dist/flexboxgrid.css';

import { ThemeLayout } from './components/ThemeLayout';
import { App } from './components';
import store from './store/store';
import registerServiceWorker, { unregister } from './registerServiceWorker';

import './styles/App.scss';
import './utils/i18n';

moment.locale('ru');

const render = () => {
    ReactDOM.render(
        <Provider store={store}>
            <BrowserRouter>
                <ThemeLayout>
                    <MuiPickersUtilsProvider utils={MomentUtils} locale="ru">
                        <App />
                    </MuiPickersUtilsProvider>
                </ThemeLayout>
            </BrowserRouter>
        </Provider>,
        document.getElementById('root')
    );
};

render();

// registerServiceWorker();
unregister();
