import { AxiosError, AxiosResponse } from 'axios';
import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    FIND_ADDRESS_FAILURE,
    FIND_ADDRESS_START,
    FIND_ADDRESS_SUCCESS,
} from 'store/constants';

/**
 * Ищет адреса по строке
 * @function
 * @param input {string}
 * @param cb {Function}
 */
export const findAddress = (input: string, cb: Function, daDataType = 'address') => {
    return (dispatch, getState) => {
        dispatch(findAddressStart());
        axios
            .get('/api/DaData/suggestAddress', {
                headers: getCommonHeaders(getState()),
                params: {
                    query: input,
                    type: daDataType,
                },
            })
            .then((response: AxiosResponse) => {
                const requestStatus = response.status;
                const isSuccess = response.data?.success;
                const error = response.data?.error;
                const data = response.data?.result;

                if (isSuccess && data) {
                    cb && cb(data);
                    dispatch(findAddressSuccess());
                } else {
                    dispatch(findAddressFailure());
                }
            })
            .catch((err: AxiosError) => {
                dispatch(findAddressFailure());
            });
    };
};

const findAddressStart = () => ({
    type: FIND_ADDRESS_START,
});

const findAddressSuccess = () => ({
    type: FIND_ADDRESS_SUCCESS,
});

const findAddressFailure = () => ({
    type: FIND_ADDRESS_FAILURE,
});
