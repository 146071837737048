import { combineReducers } from 'redux';

import application from './slices/application.slice';
import cardOrder from './slices/cardOrder';
import atm from './slices/atm';
import subsidiesFilter from './slices/subsidiesFilter';

import { signinEsia, signinEsiaConfirm, signinAnonim } from './reducers/account';
import {
    basic,
    passport,
    childrenList,
    family,
    esia,
    contacts,
} from './reducers/profile';
import {
    mySubsidies,
    allSubsidies,
    currentSubsidy,
    calculateSubsidy,
} from './reducers/subsidies';
import { modal, banner } from './reducers/common';
import {
    cardInfo,
    checkCard,
    saveRequsites,
    initCheckCard,
    getBankName,
    restartCheckCard,
} from './reducers/wallet';
import chatReducer from './reducers/chat.reducer';
import featureTogglesReducer from './reducers/featureToggles.reducer';
import messagesReducer from './reducers/messages.reducer';
import { allFaqs } from './reducers/faq';
import { faqCategories } from './reducers/faq';
import servicesAll from './slices/servicesAll.slice';
import ServiceDetSad from './slices/ServiceDetSad.slice';
import serviceGku from './slices/serviceGku.slice';
import serviceSocUnemployment from './slices/serviceSocUnemployment.slice';

const rootReducer = combineReducers({
    application: application,
    chat: chatReducer,
    cardOrder: cardOrder,
    atm: atm,
    services: combineReducers({
        all: servicesAll,
        gku: serviceGku,
        socUnemployment: serviceSocUnemployment,
        detSad: ServiceDetSad,
    }),
    account: combineReducers({
        signInEsia: signinEsia,
        signInEsiaConfirm: signinEsiaConfirm,
        signinAnonim: signinAnonim,
    }),
    profile: combineReducers({
        esia: esia,
        basic: basic,
        passport: passport,
        children: childrenList,
        family: family,
        contacts: contacts,
    }),
    subsidies: combineReducers({
        mySubsidies: mySubsidies,
        allSubsidies: allSubsidies,
        currentSubsidy: currentSubsidy,
        calculateSubsidy: calculateSubsidy,
        filter: subsidiesFilter,
    }),
    wallet: combineReducers({
        cardInfo: cardInfo,
        checkCard: checkCard,
        saveRequsites: saveRequsites,
        initCheckCard: initCheckCard,
        getBankName: getBankName,
        restartCheckCard: restartCheckCard,
    }),
    common: combineReducers({
        modal: modal,
        banner: banner,
    }),
    faq: combineReducers({
        allFaqs: allFaqs,
        categories: faqCategories,
    }),
    featureToggles: featureTogglesReducer,
    messages: messagesReducer,
});

export default rootReducer;
