import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { useAddCardDialog } from 'hooks/useAddCardDialog';
import { Application } from 'types/Application';
import { DialogGoToOffice } from './DialogGoToOffice';
import { ApplicationForm } from './ApplicationForm.component';
import { RootState } from 'store/store';
import { canCardOrder } from 'utils/utils';
import { saveApplication, saveBlock } from 'store/actions/application';
import { useDispatch, useSelector } from 'react-redux';
import { every, isEmpty } from 'lodash';
import merge from 'lodash/merge';
import { CardOrderStatus } from 'types/CardOrder';
import { useFeatures } from 'hooks/useFeatures';
import { msp_select_cards_18_05_2021 } from 'store/features.constants';
import { TestPanel } from './TestPanel';

export const canShowCardOrderCheckbox = cardOrders => {
    return (
        !isEmpty(cardOrders) &&
        every(cardOrders, cardOrder => {
            const status = cardOrder.status.status;
            if (CardOrderStatus.Rejected !== status) return true;
        })
    );
};

interface ApplicationFormContainerProps {
    currentApplicationId: string;
    currentApplication: Application;
    nextApplicationId: string;
    handleOrderCard?: VoidFunction;
    handleSetActiveStep: (step: number) => void;
    profileChildren: any;
}

const ApplicationFormContainer: React.FC<ApplicationFormContainerProps> = ({
    currentApplicationId,
    currentApplication,
    nextApplicationId,
    handleOrderCard,
    handleSetActiveStep,
    profileChildren,
}) => {
    const { initAddCard, addCardIsLoading } = useAddCardDialog();
    const history = useHistory();
    const dispatch = useDispatch();
    const [modalOpen, setmodalOpen] = useState(false);

    //Текущий шаг
    const currentStep = useSelector((state: RootState) => state.application.step);
    const cardOrders = useSelector((state: RootState) => state.cardOrder.cardOrders);
    const userCards = useSelector((state: RootState) => state.wallet.cardInfo.cardInfo);

    const findContext = useFeatures();
    const mspSelectCardsFeatureAvailable = useMemo(
        () => findContext(msp_select_cards_18_05_2021),
        [findContext]
    );

    const showButtonCardOrder = useMemo(
        () => canCardOrder(cardOrders, !mspSelectCardsFeatureAvailable),
        [cardOrders, mspSelectCardsFeatureAvailable]
    );

    const showCardOrderCheckbox = useMemo(
        () => canShowCardOrderCheckbox(cardOrders),
        [cardOrders]
    );

    const applicationSubmitIsLoading = useSelector(
        (state: RootState) => state.application.submitLoading
    );
    const applicationSubmitError = useSelector(
        (state: RootState) => state.application.submitError
    );

    const applicationSubmitFields = useSelector(
        (state: RootState) => state.application.submitData
    );
    const state = useSelector((state: RootState) => state);

    // данные для отправки на бэк
    const [submitData, setsubmitData] = useState(applicationSubmitFields);

    const [activeStep, setActiveStep] = useState<any>(currentStep);

    const sendApplication = useCallback(
        (formData, send: boolean, callback?: Function) => {
            dispatch(saveApplication(currentApplicationId, formData, send, callback));
        },
        [currentApplicationId]
    );

    const sendBlock = useCallback(
        (formData, callback?: Function) => {
            dispatch(saveBlock(currentApplicationId, formData, callback));
        },
        [currentApplicationId]
    );

    useEffect(() => {
        // ложим текущий шаг в стор чтобы можно было начать с него если пользователь ушел с экрана
        handleSetActiveStep(activeStep);
    }, [activeStep]);

    const open = () => {
        setmodalOpen(true);
    };

    const close = () => {
        setmodalOpen(false);
    };

    const handleAddCard = () => {
        initAddCard();
    };

    const goHome = () => {
        close();
        history.push('/application/' + nextApplicationId);
    };

    const handleNext = (): void => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = (index): void => {
        if (activeStep === 0 || activeStep <= index) return;
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = (): void => {
        setActiveStep(0);
    };

    const onSubmit = useCallback(
        (blockType, useBlockPreloading) => blockData => {
            // Первый шаг
            if (blockType === 'subsidyName') {
                handleNext();
                // Последний шаг после привязки карт - сохраняем данные и отправляем заявку
            } else if (blockType === 'addCard') {
                let tempSubmitData = merge([], submitData, blockData);
                sendApplication(tempSubmitData, true, handleNext); //saveApplication
                // Остальные шаги - приходят с бэка
            } else if (useBlockPreloading) {
                sendBlock(blockData, handleNext); //saveBlock
                // Остальные шаги - приходят с бэка
            } else {
                let tempSubmitData = merge([], submitData, blockData);
                console.log(tempSubmitData?.['childrenData']?.[0]);

                sendApplication(tempSubmitData, false, handleNext);
                setsubmitData(tempSubmitData);
            }
        },
        [submitData]
    );

    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>({
        defaultValues: {},
    });

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };

    return (
        <>
            {process.env.NODE_ENV === 'development' && (
                <TestPanel formProps={formProps} submitData={submitData} />
            )}
            <DialogGoToOffice
                modalOpen={modalOpen}
                goHome={goHome}
                currentApplicationTitle={currentApplication.title}
                nextApplicationId={nextApplicationId}
                onClose={close}
            />
            <ApplicationForm
                activeStep={activeStep}
                profileChildren={profileChildren}
                currentApplication={currentApplication}
                formProps={formProps}
                applicationSubmitError={applicationSubmitError}
                applicationSubmitIsLoading={applicationSubmitIsLoading}
                userCards={userCards}
                addCardIsLoading={addCardIsLoading}
                showButtonCardOrder={showButtonCardOrder}
                showCardOrderCheckbox={showCardOrderCheckbox}
                handleSubmit={handleSubmit}
                nextApplicationId={nextApplicationId}
                onSubmit={onSubmit}
                handleAddCard={handleAddCard}
                handleOrderCard={handleOrderCard}
                openModal={open}
                handleBack={handleBack}
            />
        </>
    );
};

export default ApplicationFormContainer;
