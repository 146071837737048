export enum ChannelType {
    ZabotaIOS = 0,
    ZabotaAndroid = 1,
    ZabotaWeb = 2,
    ZabotaPgmyWeb = 3,
}

export interface CardOrderDraftFields {
    firstName: string;
    lastName: string;
    middleName: string;
    gender: string;
    snils: string;
    birthdayDate: string;
    phone: string;
    email: string;
    passportSeries: string;
    passportNumber: string;
    passportDate: string;
    passportIssuedBy: string;
    passportDepartmentCode: string;
    registrationAddress: {
        region: string;
        city: string;
        town: string;
        street: string;
        house: string;
        building: string;
        apartment: string;
        postIndex: string;
    };
    liveAddress: {
        region: string;
        city: string;
        town: string;
        street: string;
        house: string;
        building: string;
        apartment: string;
        postIndex: string;
    };
    deliveryBranchOfBank?: string; // Добавляется на фронте /src/store/slices/cardOrder.ts
    channelTypeId: ChannelType;
}

export interface CardOrderSubmitFields extends CardOrderDraftFields {
    codeWord: string;
    deliveryBranchOfBank: string;
}

export interface CardOrderSuccesConfig {
    url: string;
    text: string;
}
export enum CardOrderStatus {
    Draft = 1,
    FormGenerated = 2,
    FormSaved = 4,
    InProcess = 5,
    Rejected = 6,
    Approved = 7,
    CardIssued = 8,
    CardInDelivery = 9,
    CardNotInDemand = 10,
    CardMustBeRecycled = 11,
    CardGranted = 12,
    CardActivated = 13,
    CardBlocked = 14,
    Withdrawn = 15,
    Closed = 16,
    AwaitingReceipt = 17,
    ValidationFailed = 18,
    HandledByZabota = 255,
}

export interface CardOrder {
    applicationId: string;
    contract: {
        cardId: string;
        accountNumber: string;
        cardNumber: string;
        openDate: string;
    };
    status: {
        status: CardOrderStatus;
        receivedAt: string;
    };
}
export interface CardOrderState {
    draftFields?: CardOrderDraftFields;
    submitFields?: CardOrderSubmitFields;
    error?: string;
    loading: boolean;
    submitError?: string;
    submitLoading: boolean;
    submitSuccess?: boolean;
    applicationId?: string;
    externalApplicationId?: string;
    cardOrderSuccesConfig?: CardOrderSuccesConfig;
    cardOrders?: CardOrder[];
    cardOrdersLoading: boolean;
    cardOrdersError?: string;
}
