import React, { useState, useCallback, useEffect, FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { Box, useTheme, useMediaQuery, CircularProgress } from '@material-ui/core';
import isEmpty from 'lodash/isEmpty';

import { MainLayout } from 'components/layouts';
import { RootState } from 'store/store';
import {
    getChatHistory,
    initializeChat,
    sendChatMessage,
    clearChatHistory,
    disconnectSignalR,
} from 'store/actions/chat';
import { Message, MessageType } from 'types/Chat';

import { SelectedMessageProvider } from './useSelectedMessageContext';
import { signinAnonim } from 'store/actions';
import { PageHeader } from 'components/molecules';

import { useStylesChatComponent } from './Chat.styles';
import { ChatComponent } from './ChatComponent';

const LoadingChat: FC = () => {
    return (
        <Box
            display="flex"
            alignItems="center"
            justifyContent="center"
            margin="auto"
            padding="24px"
        >
            <CircularProgress size={40} />
        </Box>
    );
};

export const Chat = () => {
    const classes = useStylesChatComponent();
    const { t } = useTranslation();
    const chat = useSelector((state: RootState) => state.chat);
    const esiaState = useSelector((state: RootState) => state.account.signInEsiaConfirm);
    const anonimState = useSelector((state: RootState) => state.account.signinAnonim);
    const [lastMessage, setlastMessage] = useState<Message | null>(null);
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    const dispatch = useDispatch();

    useEffect(() => {
        if ((esiaState.token || anonimState.anonimToken) && !chat.chatBotLoaded) {
            dispatch(initializeChat());
        } else if (
            !esiaState.request.isLoading &&
            !anonimState.request.isLoading &&
            !esiaState.token &&
            !anonimState.anonimToken
        ) {
            dispatch(signinAnonim());
        }
    }, [esiaState, anonimState, chat.chatBotLoaded]);

    useEffect(() => {
        if (chat.chatBotLoaded && chat.initialLoading) {
            dispatch(getChatHistory());
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [chat.chatBotLoaded]);

    useEffect(() => {
        if (chat.messages && !isEmpty(chat.messages)) {
            setlastMessage(chat.messages[chat.messages.length - 1]);
        }
    }, [chat.messages]);

    const handleMessage = useCallback(
        (message: string, selectedMessages: { [key: string]: any }) => {
            let messageObj: any = {
                MessageId: lastMessage?.id,
                Text: message,
            };
            if (lastMessage && lastMessage.messageType === MessageType.CheckList) {
                const selectedMessagesArray = Object.keys(selectedMessages).filter(
                    k => selectedMessages[k]
                );
                messageObj = {
                    ...messageObj,
                    SelectedItems: selectedMessagesArray,
                };
            }
            if (lastMessage && lastMessage.messageType === MessageType.CheckBoxes) {
                const selectedMessagesString = Object.keys(selectedMessages)
                    .filter(k => selectedMessages[k])
                    .join(';');
                messageObj = {
                    ...messageObj,
                    UI: {
                        MessageText: selectedMessagesString,
                        Message: {
                            AnswerText: selectedMessagesString,
                        },
                    },
                };
            }
            dispatch(sendChatMessage(messageObj));
        },
        [dispatch, lastMessage]
    );

    if (chat.initialLoading) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={isViewXS ? null : <PageHeader title={'nav.bot'} />}
                pageContentClassName={classes.chatPage}
                withFooter={!isViewXS}
                withRightColumn={!isViewXS}
                gridClassName={classes.pageGrid}
            >
                <LoadingChat />
            </MainLayout>
        );
    }

    return (
        <SelectedMessageProvider>
            <MainLayout
                contentClassName={classes.root}
                pageHeader={isViewXS ? null : <PageHeader title={'nav.bot'} />}
                pageContentClassName={classes.chatPage}
                withFooter={!isViewXS}
                withRightColumn={!isViewXS}
                gridClassName={classes.pageGrid}
            >
                <ChatComponent
                    handleMessage={handleMessage}
                    messagesByDay={chat.messagesByDay}
                    lastMessage={lastMessage}
                />
            </MainLayout>
        </SelectedMessageProvider>
    );
};
