import React, { useLayoutEffect } from 'react';
import Grid from '@material-ui/core/Grid';
import { makeStyles } from '@material-ui/core';
import { Container as MaterialUiContainer } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useQuery } from 'hooks/useQuery';

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            fontSize: '11px',
        },
        position: 'fixed',
        bottom: 0,
        width: '100%',
        background: '#ACE2B8',
        color: 'white',
        padding: '24px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    closeBtn: {
        color: 'white',
        padding: 0,
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

const OfferMess: React.FC = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [showOffer, setShowOffer] = React.useState(true);
    let history = useHistory();
    let query = useQuery();
    if (history.location.pathname.match(/addCard/)) return null;
    if (query.get('webView') === 'true') return null;

    let LShideOfferMess = localStorage.getItem('hideOfferMess');

    const setOffer = () => {
        localStorage.setItem('hideOfferMess', 'true');
        setShowOffer(false);
    };

    return (
        <Slide
            direction="up"
            in={showOffer && LShideOfferMess === null}
            mountOnEnter
            unmountOnExit
        >
            <div className={`OfferMess ${classes.root}`} onClick={setOffer}>
                <MaterialUiContainer className={`OfferMess ${classes.container}`}>
                    <span>{t('com.OfferMess.cookieAgree')}</span>
                    <IconButton
                        className={`OfferMess__close-btn ${classes.closeBtn}`}
                        edge="end"
                    >
                        <CloseIcon />
                    </IconButton>
                </MaterialUiContainer>
            </div>
        </Slide>
    );
};

export default OfferMess;
