import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import stringToRegEx from 'utils/stringToRegEx';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
    UploaderFormField,
    TextField,
} from 'components/atoms';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import some from 'lodash/some';
import get from 'lodash/get';
import {
    getMaskByType,
    getRegExByType,
    parseDateString,
    transformDateToServerString,
} from 'utils/utils';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

export const Checkbox: React.FC<{
    field: ApplicationField;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, fieldName, error, isRequired }) => {
    const formProps = useFormContext();
    return (
        <CheckboxFormField
            {...formProps}
            key={fieldName}
            name={fieldName}
            label={field.fieldTitle + (isRequired ? '*' : '')}
            readOnly={!field.canModify}
            validationRules={{ required: isRequired }}
            defaultValue={
                typeof field.currentValue === 'string'
                    ? field.currentValue.toLowerCase() === 'true'
                    : field.currentValue
            }
            error={error}
            inputProps={{ 'aria-label': field.fieldTitle }}
        />
    );
};
