import React, { FC } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

import CloseIcon from '@material-ui/icons/Close';
import {
    Dialog,
    IconButton,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import { RootState } from 'store/store';
import { calculateSubsidy } from 'store/actions';
import { SubsidyCalculator } from 'components/organisms';
import { Loading } from 'components/atoms';

const SubsidyCalculatorDrawerContext = React.createContext({
    openDrawer: () => {},
    closeDrawer: () => {},
    initCalculateSubsidy: subsidyId => {},
});

export const useSubsidyCalculatorDrawer = () => {
    return React.useContext(SubsidyCalculatorDrawerContext);
};

const useStyles = makeStyles(theme => ({
    paper: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
        padding: 56,
        maxWidth: 660,
    },
    closeButton: {
        position: 'absolute',
        right: 56 - 12,
        top: 56 - 12,
        color: theme.palette.grey[500],
    },
    dialogTitle: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 22,
            lineHeight: 1.4,
        },
        fontSize: 28,
        lineHeight: 1.14,
        fontWeight: 500,
        marginBottom: 40,
    },
}));

export const SubsidyCalculatorDrawerProvider: FC = ({ children }) => {
    const classes = useStyles();
    const [open, setOpen] = React.useState(false);
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const calculateSubsidyState = useSelector(
        (state: RootState) => state.subsidies.calculateSubsidy
    );
    const dispatch = useDispatch();
    const [subsidyId, setsubsidyId] = React.useState('');

    const openDrawer = () => {
        setOpen(true);
    };

    const closeDrawer = () => {
        setsubsidyId('');
        setOpen(false);
    };

    const initCalculateSubsidy = _subsidyId => {
        setsubsidyId(_subsidyId);
        if (isViewXS) {
            history.push(`/calculator/${_subsidyId}`);
        } else {
            dispatch(calculateSubsidy(_subsidyId));
            openDrawer();
        }
    };

    return (
        <>
            <Dialog
                fullWidth
                aria-labelledby="Модальное окно калькультора"
                open={open}
                scroll="body"
                onClose={closeDrawer}
                classes={{
                    paper: classes.paper,
                }}
            >
                <Typography className={classes.dialogTitle}>Калькулятор</Typography>
                <IconButton
                    aria-label="Закрыть модальное окно"
                    className={classes.closeButton}
                    onClick={closeDrawer}
                >
                    <CloseIcon />
                </IconButton>
                {open && calculateSubsidyState.request.isLoading ? (
                    <Loading />
                ) : (
                    open && (
                        <SubsidyCalculator
                            subsidyId={subsidyId}
                            calculateSubsidyState={calculateSubsidyState}
                        />
                    )
                )}
            </Dialog>
            <SubsidyCalculatorDrawerContext.Provider
                value={{
                    openDrawer,
                    closeDrawer,
                    initCalculateSubsidy,
                }}
            >
                {children}
            </SubsidyCalculatorDrawerContext.Provider>
        </>
    );
};
