import { useEffect } from 'react';
import { useHistory } from 'react-router-dom';

import { useQuery } from './useQuery';
import { setJson } from 'utils/localStorage';
import { LocalStorageKeys } from 'types/LocalStorageKeys';

export const useAuth = () => {
    const query = useQuery();
    const history = useHistory();

    useEffect(() => {
        const code = query.get('code');
        const state = query.get('state');
        if (code && state) {
            setJson(LocalStorageKeys.ConfirmToken, code);
            setJson(LocalStorageKeys.ConfirmRequestId, state);
            history.push(history.location.pathname);
        }
    }, [query, history]);
};
