import React, { FC, useState, useEffect, useCallback } from 'react';
import classNames from 'classnames';
import { Typography, Box, useMediaQuery, useTheme, Button } from '@material-ui/core';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { RootState } from 'store/store';
import { getFilter } from 'store/actions/subsidies/filter';
import isEmpty from 'lodash/isEmpty';
import { Menu, MenuItem } from '@material-ui/core';
import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import find from 'lodash/find';
import findIndex from 'lodash/findIndex';
import {
    setFilterSubsidyId,
    setFilterSubsidyCategoryIds,
} from 'store/actions/subsidies/filter';

const useStyles = makeStyles((theme: Theme) => ({
    root: {
        /* [theme.breakpoints.down('xs')]: {
       '&::before': {
         backgroundColor: theme.palette.common.white,
       },
     },*/
        margin: '33px 0',
    },
    buttonCategory: {
        marginRight: '8px',
        marginBottom: '8px',
        padding: '4px 12px',
        background: '#FFFFFF',
        borderRadius: '4px',
        fontSize: '13px',
        lineHeight: '16px',
        color: '#5780EA',
        border: '1px solid white',
        '&:hover': {
            border: '1px solid #5780EA',
            background: '#FFFFFF',
        },
    },
    showFiltersBy: {
        fontSize: '14px',
        marginRight: 8,
    },
    buttonCategoryActive: {
        background: '#5780EA',
        color: 'white',
        '&:hover': {
            background: '#5780EA',
        },
    },
    filterSelect: {
        fontSize: '14px',
        lineHeight: 'normal',
        color: '#5780EA',
        padding: 0,
        '&:hover': {
            background: 'none',
            textDecoration: 'underline',
        },
    },
    top: {
        display: 'flex',
        alignItems: 'center',
        marginBottom: 26,
    },
    menuAppbar: {
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.08)',
        borderRadius: 8,
        marginTop: 6,
    },
}));

interface FilterProps {}
export const Filter: FC<FilterProps> = ({}) => {
    const classes = useStyles();
    const theme = useTheme();
    const dispatch = useDispatch();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const filterState = useSelector((state: RootState) => state.subsidies.filter);
    const selectedFilterId = useSelector(
        (state: RootState) => state.subsidies.allSubsidies.selectedFilterId
    );

    const selectedFilterCategories = useSelector(
        (state: RootState) => state.subsidies.allSubsidies.selectedFilterCategories
    );

    useEffect(() => {
        if (!filterState.success && !filterState.error) {
            dispatch(getFilter());
        }
    }, [filterState]);

    if (!filterState.success || isEmpty(filterState.filter)) return null;

    const selectedFilter = find(filterState.filter, function (item) {
        return item.id === selectedFilterId;
    });

    const handleCategoryClick = catId => {
        const find = findIndex(selectedFilterCategories, function (item: any) {
            return item.filterId === selectedFilterId && item.categoryId === catId;
        });

        let newsFilterSubsidyCategoryIds = [...selectedFilterCategories];
        if (find !== -1) {
            newsFilterSubsidyCategoryIds.splice(find, 1);
        } else {
            newsFilterSubsidyCategoryIds.push({
                filterId: selectedFilterId,
                categoryId: catId,
            });
        }
        dispatch(setFilterSubsidyCategoryIds(newsFilterSubsidyCategoryIds));
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const handleSelect = filterId => {
        dispatch(setFilterSubsidyId(filterId));
        dispatch(setFilterSubsidyCategoryIds([]));
        setAnchorEl(null);
    };
    const handleMenu = (event: React.MouseEvent<HTMLElement>) => {
        setAnchorEl(event.currentTarget);
    };

    const findCard = catId => {
        return find(selectedFilterCategories, function (item: any) {
            return item.filterId === selectedFilterId && item.categoryId === catId;
        });
    };

    return (
        <Box className={classes.root}>
            <Box className={classes.top}>
                <Typography className={classes.showFiltersBy}>
                    {isViewXS ? 'Фильтры по:' : 'Показать фильтры по:'}
                </Typography>
                <Button
                    size="medium"
                    color="default"
                    variant="text"
                    endIcon={open ? <ExpandLess /> : <ExpandMore />}
                    aria-label="account of current user"
                    aria-controls="menu-appbar"
                    aria-haspopup="true"
                    className={classes.filterSelect}
                    onClick={handleMenu}
                >
                    {selectedFilter?.name}
                </Button>
                <Menu
                    id="menu-subsidiesFilter"
                    anchorEl={anchorEl}
                    elevation={0}
                    getContentAnchorEl={null}
                    anchorOrigin={{
                        vertical: 'bottom',
                        horizontal: 'center',
                    }}
                    keepMounted
                    transformOrigin={{
                        vertical: 'top',
                        horizontal: 'center',
                    }}
                    open={open}
                    onClose={handleClose}
                    classes={{
                        paper: classes.menuAppbar,
                    }}
                >
                    {filterState.filter.map(filterItem => (
                        <MenuItem
                            button
                            onClick={() => handleSelect(filterItem.id)}
                            key={'menu' + filterItem.id}
                        >
                            {filterItem.name}
                        </MenuItem>
                    ))}
                </Menu>
            </Box>
            <Box>
                {selectedFilter.categories.map(category => (
                    <Button
                        className={classNames(
                            classes.buttonCategory,
                            findCard(category.categoryId) && classes.buttonCategoryActive
                        )}
                        key={'filCat' + selectedFilter.id + category.categoryId}
                        onClick={() => handleCategoryClick(category.categoryId)}
                    >
                        {category.name}
                    </Button>
                ))}
            </Box>
        </Box>
    );
};
