import React from 'react';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { FormProvider, useFormContext } from 'react-hook-form';
import { ErrorMessage } from 'components/atoms';
import { CircularProgress } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Application } from 'types/Application';
import { ApplicationGroupSubtitle } from 'components/organisms';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Children } from './Children';
import { Family } from './Family';
import { DynamicBlock } from './DynamicBlock';
import { CustomStep } from 'components/organisms/Application/ApplicationForm/Steps/CustomStep';

export const useStyles = makeStyles((theme: Theme) => ({
    actionsContainer: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 32,
        },
        marginTop: 32,
        marginBottom: theme.spacing(2),
    },
    stepLabel: {
        '& .MuiStepLabel-label, & .MuiStepLabel-active, & .MuiStepLabel-completed': {
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1.4,
            },
            fontWeight: 'bold',
            color: theme.palette.grey[900],
            fontSize: 24,
            lineHeight: 1.33,
        },
    },
    stepContent: {
        //  marginTop: 16,
    },
    submit: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

interface StepBlocksProps {
    handleSubmit: any;
    onSubmit: any;
    canAppendChildData: boolean;
    currentApplication: Application;
    profileChildren: any;
    block: any;
    blockIndex: any;
    formProps: any;
    applicationSubmitError: any;
    applicationSubmitIsLoading: any;
    handleBack: Function;
}

const StepBlocks: React.FC<StepBlocksProps> = ({
    handleSubmit,
    onSubmit,
    block,
    blockIndex,
    formProps,
    applicationSubmitError,
    applicationSubmitIsLoading,
    canAppendChildData,
    profileChildren,
    currentApplication,
    handleBack,
    ...props
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const {
        blockType,
        categories,
        childFieldsTemplate,
        childrenBlock,
        displayName,
        fields,
        id,
        familyMembers,
        familyMembersTemplate,
        useBlockPreloading,
    } = block;

    //TODO: убрать хардкод русского языка. Бек у себя должен менять
    const customStepLabel = label => {
        if (blockType === 'child_data') return 'О детях';
        return label;
    };

    const { watch, errors } = formProps;
    return (
        <CustomStep
            key={blockType}
            stepLabel={customStepLabel(displayName)}
            handleBack={handleBack}
            {...props}
        >
            {!isEmpty(fields) && blockIndex === 0 && (
                <ApplicationGroupSubtitle categoryType="common" />
            )}
            <FormProvider {...formProps}>
                <form onSubmit={handleSubmit(onSubmit(blockType, useBlockPreloading))}>
                    {/*Динамический блок с филдами*/}
                    <DynamicBlock
                        blockIndex={blockIndex}
                        blockType={blockType}
                        fields={fields}
                        categories={categories}
                        useBlockPreloading={useBlockPreloading}
                    />

                    {/*#Дети*/}
                    {blockType === 'child_data' && (
                        <Children
                            canAppendChildData={canAppendChildData}
                            categories={categories}
                            childFieldsTemplate={childFieldsTemplate}
                            childrenBlock={childrenBlock}
                            profileChildren={profileChildren}
                            applicationSubmitIsLoading={applicationSubmitIsLoading}
                        />
                    )}

                    {/*#Члены семьи*/}
                    {blockType === 'family' && (
                        <Family
                            familyMembers={familyMembers}
                            familyMembersTemplate={familyMembersTemplate}
                            blockIndex={blockIndex}
                        />
                    )}

                    {!!applicationSubmitError && (
                        <ErrorMessage error={applicationSubmitError} />
                    )}
                    {blockType !== 'child_data' && blockType !== 'allowance_owners' && (
                        <div className={classes.actionsContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.submit}
                                startIcon={
                                    applicationSubmitIsLoading && (
                                        <CircularProgress size={24} />
                                    )
                                }
                                disabled={applicationSubmitIsLoading}
                            >
                                {t('actions.dalee')}
                            </Button>
                        </div>
                    )}
                    {blockType === 'allowance_owners' && (
                        <div className={classes.actionsContainer}>
                            <Button
                                variant="contained"
                                color="primary"
                                type="submit"
                                className={classes.submit}
                                startIcon={
                                    applicationSubmitIsLoading && (
                                        <CircularProgress size={24} />
                                    )
                                }
                                disabled={applicationSubmitIsLoading || !isEmpty(errors)}
                            >
                                Готово
                            </Button>
                        </div>
                    )}
                </form>
            </FormProvider>
        </CustomStep>
    );
};

export default StepBlocks;
