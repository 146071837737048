import { createSlice, PayloadAction } from '@reduxjs/toolkit';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import map from 'lodash/map';
import { ApiErrorConstruction } from 'types/ApiResponse';
import { AtmState, DataAsOptions, BranchModel, LocalityModel } from 'types/Atm';
import { errorHandle } from 'utils/utils';
import { AppThunk } from '../store';

interface getLocalitiesSuccess {
    localities: Array<LocalityModel>;
    localitiesAsOptions: Array<DataAsOptions>;
}
interface getBranchesSuccess {
    branches: Array<BranchModel>;
    branchesAsOptions: Array<DataAsOptions>;
}

const initialState: AtmState = {
    // Точки обслуживания
    branches: null,
    branchesAsOptions: [],
    branchesLoading: false,
    branchesError: null,
    // Населенные пункты
    localities: null,
    localitiesAsOptions: [],
    localitiesLoading: false,
    localitiesError: null,
    // Выбранная точка обслуживания
    branch: null,
    branchLoading: false,
    branchError: null,
    // Выбранный в селекте город
    selectedCity: null,
};

const atm = createSlice({
    name: 'atm',
    initialState,
    reducers: {
        getLocalitiesStart(state, action: PayloadAction<string>) {
            state.localitiesLoading = true;
            state.localitiesError = null;
        },
        getLocalitiesSuccess(state, action: PayloadAction<getLocalitiesSuccess>) {
            state.localitiesLoading = false;
            state.localitiesError = null;
            state.localities = action.payload.localities;
            state.localitiesAsOptions = action.payload.localitiesAsOptions;
        },
        getLocalitiesFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.localitiesLoading = false;
            state.localitiesError = action.payload.error;
        },
        getBranchesStart(state, action: PayloadAction<string>) {
            state.branchesLoading = true;
            state.branchesError = null;
        },
        getBranchesSuccess(state, action: PayloadAction<getBranchesSuccess>) {
            state.branchesLoading = false;
            state.branchesError = null;
            state.branches = action.payload.branches;
            state.branchesAsOptions = action.payload.branchesAsOptions;
        },
        getBranchesFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.branchesLoading = false;
            state.branchesError = action.payload.error;
        },
        setSelectedCity(state, action: PayloadAction<LocalityModel>) {
            state.selectedCity = action.payload.name;
        },
    },
});

export const {
    getBranchesFailure,
    getBranchesStart,
    getBranchesSuccess,
    getLocalitiesFailure,
    getLocalitiesStart,
    getLocalitiesSuccess,
    setSelectedCity,
} = atm.actions;

export default atm.reducer;

export const getBranches = (locality, callback?: Function): AppThunk => async (
    dispatch,
    getState
) => {
    try {
        dispatch(getBranchesStart());

        const res = await axios.get('/api/Endpoint', {
            headers: getCommonHeaders(getState()),
            params: {
                locality,
            },
        });
        if (res.data.success) {
            const branchesAsOptions = map(res.data.result, branch => ({
                name: `${branch.address} - ${branch.name}`,
                value: branch.code,
            }));
            dispatch(
                getBranchesSuccess({ branches: res.data.result, branchesAsOptions })
            );
            if (callback && typeof callback === 'function') {
                callback();
            }
        } else {
            const error = errorHandle(res.data);
            dispatch(getBranchesFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(getBranchesFailure(error));
    }
};

export const getLocalities = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getLocalitiesStart());
        const res = await axios.get('/api/Endpoint/getalllocalities', {
            headers: getCommonHeaders(getState()),
        });
        if (res.data.success) {
            const localitiesAsOptions = map(res.data.result, city => ({
                name: city.name,
                value: city.name,
            }));
            dispatch(
                getLocalitiesSuccess({ localities: res.data.result, localitiesAsOptions })
            );
        } else {
            const error = errorHandle(res.data);
            dispatch(getLocalitiesFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(getLocalitiesFailure(error));
    }
};
