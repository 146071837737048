import React from 'react';
import get from 'lodash/get';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import { useTranslation } from 'react-i18next';
import { AddressDadata } from 'types/Address';
import {
    AmountFormField,
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    AddressAutocompleteFormField,
} from 'components/atoms';
import { SubmitField, FormFieldProps } from 'types/FormField';
import { parseDateString, transformDateToServerString } from 'utils/utils';

const useStylesSubmitField = makeStyles((theme: Theme) => ({
    field: {
        [theme.breakpoints.down('sm')]: {
            width: '100%',
        },
        width: 350,

        '& + &': {
            marginTop: 24,
        },
    },
}));

export const ProfileSubmitField: React.FC<{
    field: SubmitField;
    formProps: FormFieldProps;
}> = ({ field, formProps }) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const classes = useStylesSubmitField();
    const { errors, register, defaultValues, setValue } = formProps;
    const { t } = useTranslation();
    let component;

    if (field.component === null) {
        return null;
    }

    if (field.component === 'amount') {
        component = (
            <AmountFormField
                {...formProps}
                fullWidth
                type="text"
                name={field.name}
                label={t(field.label)}
                error={Boolean(errors[field.name])}
                InputProps={{
                    readOnly: field.readOnly,
                    disabled: field.disabled,
                    autoComplete: 'off',
                }}
                helperText={errors[field.name]?.message || field.errorText}
                validationRules={field.validationRules}
                variant="outlined"
                autoComplete="off"
                size={isViewXS ? 'small' : 'medium'}
            />
        );
    } else if (field.component === 'address') {
        component = (
            <AddressAutocompleteFormField
                {...formProps}
                fullWidth
                name={field.name}
                label={t(field.label)}
                error={Boolean(errors[field.name])}
                readOnly={field.readOnly}
                disabled={field.disabled}
                defaultFormValue={field.value || get(defaultValues, field.name, '')}
                defaultValue={field.value || get(defaultValues, field.name, '')}
                transformValueForBackend={(address: AddressDadata) => {
                    if (address?.value) {
                        return address;
                    }
                }}
                // readOnly
                helperText={errors[field.name]?.message || field.errorText}
                validationRules={field.validationRules}
                variant="outlined"
                size={isViewXS ? 'small' : 'medium'}
            />
        );
    } else if (field.component === 'select') {
        const options = field.options.map(item => ({
            ...item,
            name: t(String(item.name)),
        }));

        component = (
            <SelectFormField
                {...formProps}
                label={t(field.label)}
                name={field.name}
                options={options}
                readOnly={field.readOnly}
                disabled={field.disabled}
                autoComplete="off"
                defaultValue={field.value}
                fullWidth
                validationRules={field.validationRules}
                variant="outlined"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message || field.errorText}
                formControlProps={{
                    size: isViewXS ? 'small' : 'medium',
                }}
            />
        );
    } else if (field.component === 'textfield') {
        component = (
            <MaskedTextField
                fullWidth
                type="text"
                name={field.name}
                label={t(field.label)}
                maskProps={
                    field.mask
                        ? {
                              guide: true,
                              mask: field.mask || undefined,
                          }
                        : undefined
                }
                InputProps={{
                    readOnly: field.readOnly,
                    disabled: field.disabled,
                    autoComplete: 'off',
                }}
                placeholder={field.placeholder}
                autoComplete="off"
                defaultValue={field.value || get(defaultValues, field.name, '')}
                variant="outlined"
                error={Boolean(errors[field.name])}
                helperText={errors[field.name]?.message || field.errorText}
                inputRef={register(field.validationRules)}
                size={isViewXS ? 'small' : 'medium'}
                onChange={e => {
                    if (!e.target.value.trim()) setValue(field.name, '');
                    if (field.toUpperCase)
                        setValue(field.name, e.target.value.toUpperCase());
                }}
            />
        );
    } else if (field.component === 'datepicker') {
        component = (
            <DatePickerFormFIeld
                {...formProps}
                fullWidth
                name={field.name}
                validationRules={field.validationRules}
                label={t(field.label)}
                error={Boolean(get(errors, field.name, false))}
                size={isViewXS ? 'small' : 'medium'}
                autoComplete="off"
                transformValueForBackend={transformDateToServerString}
                defaultFormValue={field.value || get(defaultValues, field.name, '')}
                readOnly={field.readOnly}
                disableFuture
                InputProps={{
                    readOnly: field.readOnly,
                    disabled: field.disabled,
                }}
            />
        );
    }

    return <div className={classes.field}>{component}</div>;
};
