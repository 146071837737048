import React from 'react';

export const useInput = (initialValue?: string) => {
    const [value, setvalue] = React.useState(initialValue);

    const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setvalue(event.target.value);
    };

    const clear = () => setvalue('');

    return {
        bind: {
            value,
            onChange,
        },
        clear,
    };
};
