import moment from 'moment';

import { windowScroll } from 'utils/DOMUtils';
import { Message } from 'types/Chat';

import { transforms } from './messageTransforms';
import map from 'lodash/map';

/**
 * Управление скроллом на мобильных устройствах.
 * @param {boolean} scroll - быть или не быть.
 */
export const mobileEnableScroll = scroll => {
    const { innerWidth, innerHeight } = window || {};
    if (!innerWidth || !innerHeight) return;
    const d = Math.min(innerWidth, innerHeight);

    if (d < 640 && !scroll) {
        windowScroll(false);
        return;
    }
    windowScroll(true);
};

const processMessageText = (text, textTransforms = []) => {
    let res = text;
    // textTransforms.forEach(t => {
    //     res = t(res);
    // });

    return res;
};

export const updateMessage = (message, textTransforms) => {
    const Text = processMessageText(message.Text, textTransforms);
    return { ...message, Text };
};

export const updateMessages = messages => {
    return messages.map(m => updateMessage(m, transforms));
};

export const parseMessage = async message => {
    let messageUI = message.message;
    try {
        if (messageUI && typeof messageUI === 'string') {
            while (typeof messageUI === 'string') {
                messageUI = await JSON.parse(messageUI);
            }
        }
    } catch (error) {
        console.warn(error);
    }
    if (messageUI && typeof messageUI !== 'string') {
        messageUI = {
            ...messageUI,
            Value: messageUI.value || messageUI.Value || undefined,
            Buttons: messageUI.buttons || messageUI.Buttons || undefined,
            Items: messageUI.items || messageUI.Items || undefined,
            Title: messageUI.title || messageUI.Title || undefined,
        };
        if (messageUI.Buttons) {
            messageUI.Buttons = map(messageUI.Buttons, value => ({
                ...value,
                Action: value.action || value.Action || undefined,
                Text: value.text || value.Text || undefined,
            }));
        }
        if (messageUI.Items) {
            messageUI.Items = map(messageUI.Items, value => ({
                ...value,
                Id: value.id || value.Id || undefined,
                Title: value.title || value.Title || undefined,
                Description: value.description || value.Description || undefined,
            }));
        }
    }

    return { ...message, message: messageUI };
};

export const parseMessages = messages => {
    return messages.map(m => parseMessage(m));
};

/** Modify object instanciating date fields.
 * @param {object} obj - object to modify.
 * @param {string} fieldName - date field name.
 * @returns {object} modified object.
 * */
const instanciateDateField = (fieldName, obj) => {
    return { ...obj, [fieldName]: moment(obj[fieldName]) };
};

/** Get chat with parsed LastUnreadMessageTime time.
 * @param {object} chat - chat.
 * @returns {object} chat with parsed LastUnreadMessageTime time.
 * */
export const getChatWithParsedTime = chat => {
    return instanciateDateField('LastUnreadMessageTime', chat);
};

/** Get string key from day.
 * @param {object} date - date.
 * @returns {string} key.
 * */
export function generateKey(date) {
    if (moment().isSame(date, 'day')) {
        return 'common.today';
    }
    if (moment().subtract(1, 'days').isSame(date, 'day')) {
        return 'common.yesterday';
    }
    const format = moment(date).year() === moment().year() ? 'DD MMMM' : 'DD MMMM YYYY';
    const key = moment(date).format(format);
    return key;
}

/** Get message grouped by day.
 * @param {object} message - message.
 * @returns {object} messages grouped by day.
 * */
export const getMessagesByDay = (messages: Array<Message>) => {
    return messages.reduce((accumulator, currentValue, index, array) => {
        const key = generateKey(currentValue.createDateTime);
        if (accumulator[key]) {
            accumulator[key] = [...accumulator[key], currentValue];
        } else {
            accumulator[key] = [currentValue];
        }
        return accumulator;
    }, {});
};

/** Get message with parsed create time.
 * @param {object} message - message.
 * @returns {object} message with parsed create time.
 * */
export const getMessageWithParsedTime = message => {
    return instanciateDateField('createDateTime', message);
};

/** Get array of messages with parsed create time.
 * @param {array} messages - array of messages.
 * @returns {array} array of messages with parsed create time.
 * */
export const getMessagesWithParsedTime = messages => {
    return messages.map(m => getMessageWithParsedTime(m));
};

/** Get array of chats with parsed LastUnreadMessageTime time.
 * @param {array} chats - array of chats.
 * @returns {array} array of chats with parsed LastUnreadMessageTime time.
 * */
export const getChatsWithParsedTime = chats => {
    return chats.map(c => getChatWithParsedTime(c));
};
