import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import stringToRegEx from 'utils/stringToRegEx';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
    UploaderFormField,
    TextField,
} from 'components/atoms';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import some from 'lodash/some';
import get from 'lodash/get';
import {
    getMaskByType,
    getRegExByType,
    parseDateString,
    transformDateToServerString,
} from 'utils/utils';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

export const Mask: React.FC<{
    field: ApplicationField;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, fieldName, error, isRequired }) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const formProps = useFormContext();
    const { register, watch, setValue } = formProps;

    return (
        <MaskedTextField
            key={fieldName}
            fullWidth
            type="text"
            name={fieldName}
            label={field.fieldTitle}
            maskProps={{
                guide: true,
                mask: getMaskByType(field.fieldType),
            }}
            InputProps={{
                readOnly: !field.canModify,
                // readOnly: true,
                autoComplete: 'off',
            }}
            autoComplete="off"
            defaultValue={field.currentValue}
            variant="outlined"
            error={error}
            inputRef={register({
                required: isRequired,
                pattern: stringToRegEx(getRegExByType(field.fieldType, field.regex)),
            })}
            size={isViewXS ? 'small' : 'medium'}
            onChange={e => {
                if (field.fieldType === 'kidCertificateSeries')
                    setValue(field.fieldId, e.target.value.toUpperCase());
            }}
        />
    );
};
