import React, { FC, useEffect } from 'react';
import { connect } from 'react-redux';

import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import Button, { ButtonProps } from '@material-ui/core/Button';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { CircularProgress } from '@material-ui/core';

import { signinEsia, signinEsiaConfirm } from 'store/actions';
import { useTranslation } from 'react-i18next';
import { IconContainer } from './IconContainer';
import { GosuslugiIcon } from '../atoms/CustomIcons/Gosuslugi';
import { Notice } from './Notice';
import { LocalStorageKeys } from 'types/LocalStorageKeys';
import { constructRedirectUrl } from 'utils/utils';
import { useDialog } from 'hooks/useDialog';
import { getJson } from 'utils/localStorage';

const mapStateToProps = state => {
    return {
        signInEsia: state.account.signInEsia,
        signInEsiaConfirm: state.account.signInEsiaConfirm,
    };
};

const mapDispatchToProps = dispatch => {
    return {
        startEsiaSignIn: (redirectUrl, errorCb) => {
            return dispatch(signinEsia(redirectUrl, errorCb));
        },
        setSigninEsiaConfirm: (confirmRequestId, token) => {
            return dispatch(signinEsiaConfirm(confirmRequestId, token));
        },
    };
};

interface SignInEsiaState {
    redirectLink: string | undefined;
    confirmRequestId: string | undefined;
    request: {
        status: string;
        isLoading: boolean;
        isSuccess: boolean;
        errors: string;
    };
}

const useStyles = makeStyles(theme => ({
    navButton: {
        textTransform: 'none',
        padding: theme.spacing(2),
        height: theme.mixins.toolbar.minHeight,
        borderRadius: 0,
        fontWeight: 500,
        fontSize: '16px',
    },
}));

interface EsiaLoginProps {
    signInEsia: SignInEsiaState;
    setSigninEsiaConfirm(requestId: string, token: string): any;
    startEsiaSignIn(redirectUrl: string, errorCb: VoidFunction): void;
    mode?: 'notice' | 'menuButton' | 'esiaButton' | 'gosuslugi';
    className?: string;
    signInEsiaConfirm?: any;
    buttonProps?: ButtonProps;
    text?: string;
}

const Component: FC<EsiaLoginProps> = ({
    startEsiaSignIn,
    mode,
    className,
    setSigninEsiaConfirm,
    signInEsiaConfirm,
    signInEsia,
    buttonProps,
    text,
}) => {
    const s = useStyles();
    const { t } = useTranslation();
    const { openDialog } = useDialog();
    useEffect(() => {
        const requestId = getJson(LocalStorageKeys.ConfirmRequestId);
        const token = getJson(LocalStorageKeys.ConfirmToken);
        if ((signInEsia.confirmRequestId || requestId) && token) {
            if (!signInEsia.request.isLoading && !signInEsiaConfirm.request.isLoading) {
                setSigninEsiaConfirm(requestId, token);
            }
        }
    }, []);

    const handleLoginClick = () => {
        const redirectUrl = constructRedirectUrl();
        startEsiaSignIn(redirectUrl, () => {
            openDialog('AuthError');
        });
    };

    const renderComponent = () => {
        let res = <></>;

        switch (mode) {
            case 'menuButton':
                res = (
                    <Button className={s.navButton} onClick={handleLoginClick}>
                        <Typography variant="body1" style={{ fontWeight: 500 }}>
                            {t('com.EsiaLoginContainer.cabinet')}
                        </Typography>
                    </Button>
                );
                break;
            case 'notice':
                res = (
                    <Notice
                        preTitleBlock={
                            <IconContainer>
                                <GosuslugiIcon />
                            </IconContainer>
                        }
                        title={t('com.EsiaLoginContainer.noticeTitle')}
                        content={
                            <>
                                <Typography variant="body2" color="textSecondary">
                                    {t('com.EsiaLoginContainer.info')}
                                </Typography>
                                <Box paddingBottom={3.5}></Box>
                                <Button
                                    onClick={handleLoginClick}
                                    variant="contained"
                                    fullWidth
                                    color="primary"
                                >
                                    {t('com.EsiaLoginContainer.enter')}
                                </Button>
                            </>
                        }
                        variant="white"
                    />
                );
                break;
            case 'esiaButton':
                res = (
                    <Button
                        onClick={handleLoginClick}
                        variant="contained"
                        fullWidth
                        size="large"
                    >
                        {t('com.EsiaLoginContainer.enterEsia')}
                    </Button>
                );
                break;
            case 'gosuslugi':
                res = (
                    <Button
                        {...buttonProps}
                        startIcon={
                            signInEsia.request.isLoading && <CircularProgress size={24} />
                        }
                        disabled={signInEsia.request.isLoading}
                        onClick={handleLoginClick}
                        variant="contained"
                        className={className}
                    >
                        {text || t('com.EsiaLoginContainer.enterGU')}
                    </Button>
                );
                break;
            default:
                break;
        }

        return res;
    };

    return renderComponent();
};

export const EsiaLoginContainer = connect(mapStateToProps, mapDispatchToProps)(Component);
