import {
    GET_MESSAGES_SUCCESS,
    GET_MESSAGES_FAILURE,
    GET_MESSAGES_START,
} from '../constants';

import { MessagesState } from 'types/Messages.type';

const INITIAL_STATE: MessagesState = {
    isLoading: false,
    isLoaded: false,
    error: undefined,
    data: [],
};

export default function (state = INITIAL_STATE, { type, payload }) {
    let nextState;
    switch (type) {
        case GET_MESSAGES_START: {
            nextState = {
                ...state,
                isLoading: true,
                error: undefined,
            };
            break;
        }
        case GET_MESSAGES_FAILURE: {
            nextState = {
                ...state,
                isLoading: false,
                error: payload.error,
                isLoaded: true,
            };
            break;
        }
        case GET_MESSAGES_SUCCESS: {
            nextState = {
                ...state,
                isLoading: false,
                data: payload.data,
                isLoaded: true,
            };
            break;
        }

        default:
            nextState = state;
    }

    return nextState;
}
