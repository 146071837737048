import React, { FC } from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Box from '@material-ui/core/Box';

const useStyles = makeStyles(theme => ({
    root: {
        alignItems: 'center',
        backgroundColor: theme.palette.grey[100],
        borderRadius: '100%',
        display: 'flex',
        height: theme.spacing(7),
        justifyContent: 'center',
        width: theme.spacing(7),
    },
}));

export const IconContainer: FC = ({ children }) => {
    const s = useStyles();

    return <Box className={s.root}>{children}</Box>;
};
