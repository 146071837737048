import * as React from 'react';
import cx from 'classnames';
import Chip, { ChipProps } from '@material-ui/core/Chip';
import makeStyles from '@material-ui/core/styles/makeStyles';
import { FC } from 'react';

const styles = theme => ({
    root: {
        borderRadius: 8,
        fontSize: '14px',
        padding: '4px 10px',
        height: 'auto',
    },
    label: {
        paddingLeft: 0,
        paddingRight: 0,
    },
    rootDefault: {
        backgroundColor: '#def1ff',
    },
    rootSuccess: {
        backgroundColor: '#dffbe4',
    },
    rootError: {
        backgroundColor: '#fee7e0',
    },
    rootFaded: {
        backgroundColor: '#f0f0f0',
    },
    indicator: {
        borderRadius: '100%',
        height: theme.spacing(1),
        marginLeft: 12,
        marginRight: -4,
        width: theme.spacing(1),
    },
    indicatorBlue: {
        backgroundColor: '#0044d8',
    },
    indicatorGrey: {
        backgroundColor: '#6c6c6c',
    },
    indicatorGreen: {
        backgroundColor: '#009b3a',
    },
    indicatorRed: {
        backgroundColor: '#cd1f13',
    },
});

const useStyles = makeStyles(styles);

interface TagProps extends Omit<ChipProps, 'color'> {
    /**
     * Цвет тэга
     */
    color?: string;
    /**
     * Цвет индикатора
     */
    indicatorColor?: 'blue' | 'grey' | 'green' | 'red';
    /**
     * Наличие индикатора
     */
    withIndicator?: boolean;
}

const Tag: FC<TagProps> = props => {
    const s = useStyles();

    const { color, indicatorColor, withIndicator, ...other } = props;

    return (
        <Chip
            {...other}
            icon={
                withIndicator ? (
                    <div
                        className={cx(s.indicator, {
                            [s.indicatorBlue]: indicatorColor === 'blue',
                            [s.indicatorGrey]: indicatorColor === 'grey',
                            [s.indicatorGreen]: indicatorColor === 'green',
                            [s.indicatorRed]: indicatorColor === 'red',
                        })}
                    />
                ) : undefined
            }
            classes={{
                root: s.root,
                icon: s.indicator,
                label: s.label,
            }}
            style={{
                backgroundColor: color,
            }}
        />
    );
};

Tag.defaultProps = {
    color: 'default',
    indicatorColor: 'grey',
};

export default Tag;
