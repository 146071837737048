import {
    JsonHubProtocol,
    HubConnectionState,
    HubConnectionBuilder,
    LogLevel,
    HttpTransportType,
} from '@microsoft/signalr';

import { chatHub } from 'config';
// import { isProd } from 'utils/utils';

const startSignalRConnection = async connection => {
    try {
        await connection.start();
        if (process.env.NODE_ENV === 'development') {
            console.assert(connection.state === HubConnectionState.Connected);
            console.log('SignalR connection established');
        }
    } catch (err) {
        if (process.env.NODE_ENV === 'production') {
            console.warn('SignalR Connection Error: ', err);
        } else {
            console.assert(connection.state === HubConnectionState.Disconnected);
            console.error('SignalR Connection Error: ', err);
        }
        setTimeout(() => startSignalRConnection(connection), 5000);
    }
};

/**
 * Connect to signalR.
 * @param {Array} handlers - Array of {event: "eventName", handler: function}.
 * @param {string} token - Session token.
 */
const subscribeToSignalR = async (handlers, token) => {
    if (!token) {
        if (process.env.NODE_ENV === 'production') {
            console.warn('Token not provided to signalR');
        } else {
            console.error('Token not provided to signalR');
        }
        return null;
    }
    const options = {
        logMessageContent: process.env.NODE_ENV === 'development',
        logger: process.env.NODE_ENV === 'production' ? LogLevel.None : LogLevel.Error,
        transport: HttpTransportType.WebSockets,
        skipNegotiation: true,
    };
    // create the connection instance
    // withAutomaticReconnect will automatically try to reconnect
    // and generate a new socket connection if needed
    const connection = new HubConnectionBuilder()
        .withUrl(chatHub, options)
        .withAutomaticReconnect()
        .withHubProtocol(new JsonHubProtocol())
        .configureLogging(LogLevel.Information)
        .build();

    // Note: to keep the connection open the serverTimeout should be
    // larger than the KeepAlive value that is set on the server
    // keepAliveIntervalInMilliseconds default is 15000 and we are using default
    // serverTimeoutInMilliseconds default is 30000 and we are using 60000 set below
    // connection.serverTimeoutInMilliseconds = 60000;
    // re-establish the connection if connection dropped
    connection.onclose(error => {
        console.assert(connection.state === HubConnectionState.Disconnected);
        console.log(
            'Connection closed due to error. Try refreshing this page to restart the connection',
            error
        );
    });

    connection.onreconnecting(error => {
        console.assert(connection.state === HubConnectionState.Reconnecting);
        console.log('Connection lost due to error. Reconnecting.', error);
    });

    connection.onreconnected(connectionId => {
        console.assert(connection.state === HubConnectionState.Connected);
        console.log(
            'Connection reestablished. Connected with connectionId',
            connectionId
        );
    });

    handlers.forEach(h => {
        connection.on(h.event, h.handler);
    });

    startSignalRConnection(connection);

    return connection;
};

export default subscribeToSignalR;
