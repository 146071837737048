import React from 'react';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { ApplicationField } from 'types/Application';
import * as Fields from '../../../molecules/DynamicFields';
import { useFormContext } from 'react-hook-form';
import { useQuery } from 'hooks/useQuery';
import { camelCase } from 'lodash';

const useStylesSubmitField = makeStyles((theme: Theme) => ({
    field: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
        },
        width: 270,
        marginRight: 16,
    },
    fieldFull: {
        width: '100%',
    },
    fieldFullCheckBox: {
        width: '100%',
        //  marginTop: '20px',
    },
    fieldFullFilesBox: {
        width: '100%',
        marginTop: '20px',
        color: '#878B97',
    },
    testSpan: {
        color: '#fff',
        width: '100%',
        display: 'block',
        padding: '2px 10px',
        fontSize: '13px',
        background: '#00800a',
        fontWeight: 100,
        overflowWrap: 'break-word',
    },
    break: {
        flexBasis: '100%',
        height: '0',
    },
}));

export const DynamicField: React.FC<{
    field: ApplicationField;
    fieldName: string;
    isRequired: boolean;
    error: boolean;
}> = ({ field, fieldName, isRequired, error }) => {
    const theme = useTheme();
    const classes = useStylesSubmitField();
    const fType = field.fieldType;
    const formProps = useFormContext();
    const { watch } = formProps;
    let query = useQuery();

    //Стили
    let customClassField;
    if (fType === 'files') customClassField = 'fieldFullFilesBox';
    if (fType === 'checkbox') customClassField = 'fieldFullCheckBox';
    if (fType === 'comment') customClassField = 'fieldFull';
    const comName = fType[0].toUpperCase() + camelCase(fType).slice(1);

    //Компонент
    let Component = Fields[comName];

    if (!Component) {
        console.log(fType[0].toUpperCase() + fType.slice(1) + ' Не найден');
        return null;
    }

    return (
        <>
            <Box
                m={theme.spacing(2, 0)}
                className={customClassField ? classes[customClassField] : classes.field}
            >
                {process.env.NODE_ENV === 'development' && query.get('t') === '1' && (
                    <span className={classes.testSpan}>{fieldName}</span>
                )}
                <Component
                    field={field}
                    fieldName={fieldName}
                    error={error}
                    isRequired={isRequired}
                />
            </Box>
            {field.style?.line_break && <div className={classes.break}></div>}
        </>
    );
};
