import React, { FC } from 'react';
import Box from '@material-ui/core/Box';
import Typography from '@material-ui/core/Typography';
import cx from 'classnames';

import makeStyles from '@material-ui/core/styles/makeStyles';

const useStyles = makeStyles(theme => ({
    root: {
        backgroundColor: theme.palette.grey[100],
        border: '1px solid',
        borderColor: theme.palette.grey[200],
        borderRadius: theme.spacing(1),
        '&$white': {
            backgroundColor: theme.palette.background.paper,
        },
    },
    title: {
        fontWeight: 500,
    },
    white: {},
}));

interface NoticeProps {
    preTitleBlock: string | React.ReactNode;
    title: string | React.ReactNode;
    content: string | React.ReactNode;
    variant?: 'white';
}

export const Notice: FC<NoticeProps> = ({ preTitleBlock, title, content, variant }) => {
    const s = useStyles();

    return (
        <Box
            className={cx(s.root, {
                [s.white]: variant === 'white',
            })}
            padding={4}
        >
            {preTitleBlock ? <Box marginBottom={2}>{preTitleBlock}</Box> : null}
            <Typography variant="body1" className={s.title}>
                {title}
            </Typography>
            <Box paddingBottom={1}></Box>
            {content}
        </Box>
    );
};
