import React from 'react';

import { TextField as AbdtTextField } from '@abdt/ornament';

import { TextFieldProps } from '@material-ui/core/TextField';

const TextField: React.FC<TextFieldProps> = ({ ...other }) => {
    return <AbdtTextField {...other} />;
};

export type { TextFieldProps };

export default TextField;
