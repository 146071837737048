import React from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import stringToRegEx from 'utils/stringToRegEx';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import {
    SelectFormField,
    DatePickerFormFIeld,
    MaskedTextField,
    CheckboxFormField,
    AddressAutocompleteFormField,
    UploaderFormField,
    TextField,
} from 'components/atoms';
import { FormFieldProps } from 'types/FormField';
import { ApplicationField } from 'types/Application';
import useMediaQuery from '@material-ui/core/useMediaQuery';
import some from 'lodash/some';
import get from 'lodash/get';
import {
    getMaskByType,
    getRegExByType,
    parseDateString,
    transformDateToServerString,
} from 'utils/utils';
import { AddressDadata } from 'types/Address';
import { useFormContext } from 'react-hook-form';

export const Combobox: React.FC<{
    field: ApplicationField;
    formProps: FormFieldProps;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, formProps, fieldName, error, isRequired }) => {
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const fieldItems = map(field.fieldItems, ({ key, value }) => ({
        key,
        name: value,
        value: key,
    }));

    return (
        <SelectFormField
            {...formProps}
            key={fieldName}
            label={field.fieldTitle}
            name={fieldName}
            options={fieldItems}
            readOnly={!field.canModify}
            // readOnly
            autoComplete="off"
            defaultValue={field.currentValue}
            fullWidth
            validationRules={{ required: isRequired }}
            variant="outlined"
            error={error}
            // size={isViewXS ? 's' : 'l'}
            // helperText={Boolean(errors[field.fieldId]) && field.errorText}
            formControlProps={{
                size: isViewXS ? 'small' : 'medium',
            }}
        />
    );
};
