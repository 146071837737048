import {
    SIGN_IN_ESIA_REQUESTING,
    SIGN_IN_ESIA_SUCCEED,
    SIGN_IN_ESIA_FAILED,
} from '../../constants';

//signin
const signinInitState = {
    redirectLink: null,
    confirmRequestId: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const signinEsia = (state = signinInitState, action) => {
    switch (action.type) {
        case SIGN_IN_ESIA_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case SIGN_IN_ESIA_SUCCEED:
            return {
                ...state,
                redirectLink: action.redirectLink,
                confirmRequestId: action.confirmRequestId,
                request: {
                    ...state.request,
                    status: action.requestStatus,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };

        case SIGN_IN_ESIA_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };

        default:
            return state;
    }
};
