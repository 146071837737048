import React, { useState } from 'react';
import cx from 'classnames';
import { useDropzone } from 'react-dropzone';

import { Button } from '@abdt/ornament';

import useStyles from './Uploader.style';
import { AcceptedFile, RejectedFile } from './components';
import {
    UploaderFile,
    AcceptedFiles,
    RejectedFiles,
    UploaderFiles,
    UploaderProps,
} from './Uploader.types';
import {
    convertAcceptedFiles,
    convertInitialFiles,
    convertRejectedFiles,
} from './utils/convert-files';
import { defineErrorsByMaxFiles } from './utils/define-errors-by-max-files';
import { useTranslation } from 'react-i18next';

export const Uploader: React.FC<UploaderProps> = ({
    text,
    initialFiles = [],
    submitComponent: SubmitComponent,
    error,
    onDrop,
    onSubmit,
    onReloadFile,
    onDeleteFile,
    textFieldProps,
    buttonProps,
    ...dropzoneProps
}) => {
    const { t } = useTranslation();
    const { submitComponentText = t('actions.send') } = dropzoneProps;
    const [filesData, setFilesData] = useState<UploaderFiles>(
        convertInitialFiles(initialFiles)
    );

    const { maxFiles, disabled } = dropzoneProps;
    const classes = useStyles();
    const acceptedFilesData = filesData.filter(file => !file.errors);
    const rejectedFilesData = filesData.filter(file => file.errors);
    const isFilesDataNotEmpty = filesData.length > 0;
    const isSubmitBehavior = !!onSubmit;

    const setFileStatusLoaded = (dropId: string) => {
        setFilesData((prevFiles: UploaderFiles) => {
            return prevFiles.map((file: UploaderFile) => {
                if (file.dropId === dropId) {
                    return Object.assign(file, { status: 'loaded' });
                }

                return file;
            });
        });
    };

    const setFileErrorNotLoaded = (dropId: string, errorMessage?: string) => {
        setFilesData((prevFiles: UploaderFiles) => {
            return prevFiles.map((file: UploaderFile) => {
                if (file.dropId === dropId) {
                    return Object.assign(file, {
                        errors: [
                            {
                                message: errorMessage || 'File not loaded',
                                code: 'file-not-loaded',
                            },
                        ],
                    });
                }

                return file;
            });
        });
    };

    const deleteFile = (deletedFile: UploaderFile) => {
        setFilesData(prevFiles => {
            return prevFiles.filter((stateFile: UploaderFile) => {
                return stateFile.dropId !== deletedFile.dropId;
            });
        });

        if (onDeleteFile && !deletedFile.errors) {
            onDeleteFile(deletedFile);
        }
    };

    const reloadFile = (reloadedFile: UploaderFile) => {
        let newFile: UploaderFile = Object.assign(reloadedFile, {
            status: 'loading',
        });

        if (maxFiles && acceptedFilesData.length >= maxFiles) {
            newFile = Object.assign(newFile, {
                errors: [
                    {
                        message: 'Too many files',
                        code: 'too-many-files',
                    },
                ],
            });
        } else {
            delete newFile.errors;
        }

        setFilesData(prevFiles => {
            return [
                ...prevFiles.filter(file => file.dropId !== reloadedFile.dropId),
                newFile,
            ];
        });

        if (onReloadFile) {
            onReloadFile(setFileStatusLoaded, setFileErrorNotLoaded, newFile || null);
        }
    };

    const handleDrop = (
        dropzoneAcceptedFiles: AcceptedFiles,
        dropzoneRejectedFiles: RejectedFiles
    ) => {
        const { acceptedFiles, rejectedFiles } = defineErrorsByMaxFiles(
            dropzoneAcceptedFiles,
            acceptedFilesData.length,
            maxFiles
        );

        const acceptedFilesWithDropMeta = convertAcceptedFiles(
            acceptedFiles,
            isSubmitBehavior
        );

        const rejectedFilesWithDropMeta = convertRejectedFiles([
            ...dropzoneRejectedFiles,
            ...rejectedFiles,
        ]);

        setFilesData((prevFiles: UploaderFiles) => {
            return [
                ...prevFiles,
                ...acceptedFilesWithDropMeta,
                ...rejectedFilesWithDropMeta,
            ];
        });

        if (onDrop) {
            onDrop(setFileStatusLoaded, setFileErrorNotLoaded, acceptedFilesWithDropMeta);
        }
    };

    const { getRootProps, getInputProps, open } = useDropzone({
        ...dropzoneProps,
        onDrop: handleDrop,
        noClick: true,
        noKeyboard: true,
        noDrag: true,
    });

    // TODO new Проверить дроп файлов
    const rootProps = getRootProps();

    return (
        <div
            className={cx(classes.root, disabled && 'disabled', error && 'error')}
            {...rootProps}
        >
            <input {...getInputProps()} />

            {isFilesDataNotEmpty && (
                <>
                    {acceptedFilesData.map((file: UploaderFile) => {
                        return (
                            <AcceptedFile
                                key={file.dropId}
                                file={file}
                                deleteFile={deleteFile}
                                textFieldProps={textFieldProps}
                            />
                        );
                    })}
                    {rejectedFilesData.map((file: UploaderFile) => {
                        return (
                            <RejectedFile
                                key={file.dropId}
                                file={file}
                                deleteFile={deleteFile}
                                reloadFile={reloadFile}
                                isSubmitBehavior={isSubmitBehavior}
                                textFieldProps={textFieldProps}
                            />
                        );
                    })}
                </>
            )}
            <Button variant="outlined" onClick={open} {...buttonProps}>
                {t('com.Uploader.addFile')}
            </Button>
        </div>
    );
};

export default Uploader;
