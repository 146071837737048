import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/find';
import cx from 'classnames';
import { Box, makeStyles, Theme, Typography, Grid, Button } from '@material-ui/core';
import { ApplicationGroupHeader } from 'components/organisms';
import { useTranslation } from 'react-i18next';
import { RelativeMember } from './RelativeMember';
import { useDispatch, useSelector } from 'react-redux';
import { addRelativeMember } from 'store/actions/application';
import isEmpty from 'lodash/isEmpty';

const useStyles = makeStyles((theme: Theme) => ({
    selector: {
        /*'& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },*/
    },
    relatives: { marginBottom: 20 },
    buttonContainer: {},
    btn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

interface Props {
    relatives: any;
    familyMemberIndex: any;
    familyMemberId: any;
    setSelectedFamilyMemberIndex: any;
    blockIndex: any;
}

export const Relatives: React.FC<Props> = ({
    relatives,
    familyMemberIndex,
    setSelectedFamilyMemberIndex,
    blockIndex,
}) => {
    const classes = useStyles();
    const dispatch = useDispatch();

    const handleAddRelativeMember = useCallback(() => {
        dispatch(addRelativeMember(familyMemberIndex));
    }, []);

    return (
        <>
            <ApplicationGroupHeader
                categoryName="Состав семьи"
                categoryType={null}
                isSuperCategory={false}
            />
            <Grid container direction="column" className={classes.relatives} spacing={3}>
                {!isEmpty(relatives) && (
                    <Grid item>
                        {relatives.map((rel, relIndex) => {
                            return (
                                <RelativeMember
                                    key={`RelativeMember-${relIndex}-${rel.relativeId}`}
                                    relMember={rel}
                                    relIndex={relIndex}
                                    familyMemberIndex={familyMemberIndex}
                                    relativesLength={relatives.length}
                                    setSelectedFamilyMemberIndex={
                                        setSelectedFamilyMemberIndex
                                    }
                                    blockIndex={blockIndex}
                                />
                            );
                        })}
                    </Grid>
                )}
                <Grid item className={classes.buttonContainer}>
                    <Button
                        variant="outlined"
                        onClick={handleAddRelativeMember}
                        className={classes.btn}
                    >
                        Добавить родственника
                    </Button>
                </Grid>
            </Grid>
        </>
    );
};
