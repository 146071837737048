import React, { useMemo } from 'react';
import { Dialog, IconButton, makeStyles, Theme } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useSnackbar } from 'notistack';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';
import replace from 'lodash/replace';
import { useTranslation } from 'react-i18next';
import { RootState } from 'store/store';
import { getCardInfo, initCheckCard, initCheckCardReset } from 'store/actions';
import { ConfirmDialog } from 'components/molecules/ConfirmDialog';
import { Card } from 'types/Card';
import { AlertDialog } from 'components/molecules/AlertDialog';
import { msp_select_cards_18_05_2021 } from 'store/features.constants';
import { useFeatures } from './useFeatures';

const useStyles = makeStyles((theme: Theme) => ({
    dialog: {
        maxWidth: 480,
        padding: 40,
        backgroundColor: theme.palette.common.white,
    },
    closeButton: {
        position: 'absolute',
        right: 60 - 12,
        top: 60 - 12,
        color: theme.palette.grey[500],
    },
}));
interface AddCardDialogProps {
    card?: Card;
    open: boolean;
    onClickOpen: VoidFunction;
    onClose: VoidFunction;
    onAddCard: VoidFunction;
    step: string;
    src?: string;
    height: string;
    isLoading: boolean;
}
const AddCardDialog: React.FC<AddCardDialogProps> = ({
    card,
    open,
    onClickOpen,
    onClose,
    onAddCard,
    step,
    src,
    height,
    isLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    if (step === 'init') {
        if (card && card?.isRequested) {
            return (
                <AlertDialog
                    open={open}
                    onClickOpen={onClickOpen}
                    onClose={onClose}
                    dialogText={t('pages.AddCard.alertDelPreviousFirst')}
                />
            );
        }

        if (card) {
            return (
                <ConfirmDialog
                    open={open}
                    onClickOpen={onClickOpen}
                    onClose={onClose}
                    onConfirm={onAddCard}
                    dialogText={t('pages.AddCard.alertAddOnlyOneCard')}
                    isLoading={isLoading}
                />
            );
        }
    }

    if (step === 'confirm') {
        return (
            <Dialog
                classes={{
                    paper: classes.dialog,
                }}
                open={open}
                onClose={onClose}
                fullWidth
                aria-labelledby={t('com.AddCard.addModalWindow')}
                aria-describedby="alert-dialog-description"
            >
                <IconButton
                    aria-label={t('actions.closeModal')}
                    className={classes.closeButton}
                    onClick={onClose}
                >
                    <CloseIcon />
                </IconButton>
                {src && (
                    <iframe
                        name="addCard"
                        id="addCard"
                        title={t('pages.AddCard.addCard')}
                        src={src}
                        height={height}
                        width="100%"
                    ></iframe>
                )}
            </Dialog>
        );
    }
    return null;
};

const AddCardDialodContext = React.createContext({
    openCardDialog: () => {},
    closeCardDialog: () => {},
    initAddCard: () => {},
    addCardIsLoading: false,
    addCardError: null,
    dialogIsOpen: false,
});

export const useAddCardDialog = () => {
    return React.useContext(AddCardDialodContext);
};

export const AddCardDialodProvider = React.memo(props => {
    const checkCardState = useSelector((state: RootState) => state.wallet.initCheckCard);
    const cardInfoState = useSelector((state: RootState) => state.wallet.cardInfo);

    const [step, setStep] = React.useState('');
    const [src, setsrc] = React.useState('');
    const [height, setheight] = React.useState('');
    const [open, setOpen] = React.useState(false);
    const hasCard = !isEmpty(cardInfoState.cardInfo);

    const findContext = useFeatures();
    const mspSelectCardsFeatureAvailable = useMemo(
        () => findContext(msp_select_cards_18_05_2021),
        [findContext]
    );

    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const handleOpenDialog = () => {
        setOpen(true);
    };

    const handleCloseDialog = () => {
        setOpen(false);
    };

    const onClose = () => {
        handleCloseDialog();
        setsrc('');
        setStep('');
        dispatch(initCheckCardReset());
    };

    const onOpen = () => {
        setsrc('');
        if (!mspSelectCardsFeatureAvailable && hasCard) {
            setStep('init');
            handleOpenDialog();
        } else {
            initAddCard();
        }
    };

    const initAddCard = () => {
        dispatch(
            initCheckCard(
                result => {
                    // const path = replace(
                    //     result.viewUrl,
                    //     /^(?:\/\/|[^/]+)*\//,
                    //     'http://localhost:3001/'
                    // );
                    // setsrc(path);
                    setsrc(result.viewUrl);
                    setStep('confirm');
                    handleOpenDialog();
                },
                error => {
                    enqueueSnackbar(error, {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            )
        );
    };

    React.useEffect(() => {
        function receiveMessage(event) {
            if (
                (event.data.event === 'check_card' || event.data.event === 'add_card') &&
                event.data.type === 'size'
            ) {
                setheight(event.data.message?.height);
            }
            if (event.data.event === 'add_card' && event.data.type === 'complete') {
                dispatch(getCardInfo());
                onClose();
            }
            if (event.data.event === 'add_card' && event.data.type === 'link_to_faq') {
                onClose();
                history.push('/faq?faqQuestionId=' + event.data.message.faqQuestionId);
            }
        }
        window.addEventListener('message', receiveMessage, false);

        return () => {
            window.removeEventListener('message', receiveMessage);
            dispatch(initCheckCardReset());
        };
    }, []);

    return (
        <>
            <AddCardDialog
                height={height}
                open={open}
                src={src}
                step={step}
                card={cardInfoState.cardInfo && cardInfoState.cardInfo[0]}
                onClickOpen={handleOpenDialog}
                onClose={handleCloseDialog}
                onAddCard={initAddCard}
                isLoading={checkCardState.request.isLoading}
            />
            <AddCardDialodContext.Provider
                value={{
                    openCardDialog: handleOpenDialog,
                    closeCardDialog: onClose,
                    initAddCard: onOpen,
                    dialogIsOpen: open,
                    addCardError: checkCardState.request.error,
                    addCardIsLoading: checkCardState.request.isLoading,
                }}
            >
                {props.children}
            </AddCardDialodContext.Provider>
        </>
    );
});
