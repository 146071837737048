import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box, IconButton, useTheme, useMediaQuery } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) => ({
    listItem: {
        marginTop: 8,
    },
    link: {
        textDecoration: 'unset',
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '140%',
        color: '#5780EA',
    },
    linkBlock: {
        color: '#333B51',
        fontSize: '16px',
        fontStyle: 'normal',
        fontWeight: 500,
        lineHeight: '140%',
        width: '100%',
        display: 'block',
        textDecoration: 'unset',
        background: '#fff',
        borderRadius: '8px',
        padding: theme.spacing(4),
    },
}));

interface Props {
    item: any;
    itemIndex: number;
}

export const SubsidyLi: FC<Props> = ({ item, itemIndex }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    return (
        <li key={'services.subsidyListItem-' + itemIndex} className={classes.listItem}>
            <Link
                to={'/subsidies/' + item.id}
                className={isViewSM ? classes.linkBlock : classes.link}
                key={item.id}
            >
                {!isViewSM && '• '}
                {item.name}
            </Link>
        </li>
    );
};
