import React, { useState } from 'react';

import { makeStyles } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    uploaderNotification: {
        marginBottom: 16,
    },
}));

type UploaderNotificationProps = {
    fieldId?: string;
};

export const UploaderNotification: React.FC<UploaderNotificationProps> = ({
    fieldId,
}) => {
    const classes = useStyles();
    let subtitle;

    switch (fieldId) {
        case 'document_on_nonreceipt_of_pregnancy_benefits_files':
        case 'document_on_recognition_of_unemployed_files':
            break;
    }
    return subtitle ? <p className={classes.uploaderNotification}>{subtitle}</p> : null;
};
