import React, { useCallback, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { useHistory, useParams } from 'react-router-dom';
import Button from '@material-ui/core/Button';
import findIndex from 'lodash/findIndex';
import isEmpty from 'lodash/isEmpty';
import { RootState } from 'store/store';
import { getCardInfo } from 'store/actions';
import { setActiveStep } from 'store/slices/application.slice';
import { addChild } from 'store/actions/application';
import {
    getApplications,
    getApplicationById,
    getProfileChildren,
    clearApplication,
} from 'store/actions/application';
import { ErrorMessage, Loading } from 'components/atoms';
import { getCardOrders } from 'store/slices/cardOrder';
import { useCardOrderDialog } from 'hooks/useCardOrderDialog';
import { ApplicationForm } from './ApplicationForm';
import { Empty } from './Empty';
import { useTranslation } from 'react-i18next';
import { ApplicationOrderSuccess } from './ApplicationOrderSuccess';

export const ApplicationContainer: React.FC = () => {
    const params: { id: string } = useParams();
    const currentApplicationId = params.id;
    const { onOpen: onOpenCardOrderDialog } = useCardOrderDialog();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();

    const applicationsIsLoading = useSelector(
        (state: RootState) => state.application.getApplications.loading
    );
    const applicationsError = useSelector(
        (state: RootState) => state.application.getApplications.error
    );
    const applicationIsLoading = useSelector(
        (state: RootState) => state.application.getApplicationById.loading
    );
    const applicationError = useSelector(
        (state: RootState) => state.application.getApplicationById.error
    );
    const availableApplications = useSelector(
        (state: RootState) => state.application.availableApplications
    );
    const profileChildren = useSelector(
        (state: RootState) => state.application.profileChildren.data
    );

    const applicationSubmitSuccess = useSelector(
        (state: RootState) => state.application.applicationSent
    );

    /* const currentApplicationId = useSelector(
        (state: RootState) => state.application.currentApplicationId
    );*/
    const currentApplication = useSelector(
        (state: RootState) => state.application.application
    );

    const error = applicationsError || applicationError;
    const isLoading = applicationsIsLoading || applicationIsLoading;

    const currentIndex = findIndex(availableApplications, [
        'requestId',
        currentApplicationId,
    ]);

    let nextApplicationId = null;
    if (availableApplications?.length > 1) {
        const nextIndex = (currentIndex + 1) % availableApplications.length;
        nextApplicationId = availableApplications[nextIndex].requestId;
    }

    useEffect(() => {
        // грузим доступные для оформления МСП
        dispatch(getApplicationById(currentApplicationId));
        dispatch(getProfileChildren(currentApplicationId));
        dispatch(getApplications());
        // грузим данные карт
        dispatch(getCardInfo());
        // грузим заявления на карту
        dispatch(getCardOrders());
        // грузим поля выбраной МСП
    }, [currentApplicationId]);

    useEffect(() => {
        if (!isEmpty(currentApplication)) {
            const childDataBlockIndex = findIndex(currentApplication.blocks, {
                blockType: 'child_data',
            });
            const childrenBlock =
                currentApplication.blocks[childDataBlockIndex]?.childrenBlock;

            if (childDataBlockIndex) {
                if (isEmpty(childrenBlock)) {
                    dispatch(addChild());
                }
            }
        }
    }, [currentApplication]);

    /*const handleNextApplication = useCallback(() => {
        dispatch(getNextApplication(deleteCurentApplication));
    }, []);*/

    const handleSetActiveStep = useCallback(step => {
        dispatch(setActiveStep({ step }));
    }, []);

    const handleOrderCard = useCallback(() => {
        onOpenCardOrderDialog({ text: t('actions.return'), url: '/application' });
    }, [onOpenCardOrderDialog]);

    if (isLoading) {
        return <Loading />;
    }

    if (error) {
        return (
            <>
                <ErrorMessage error={error} />
                <Button
                    style={{ marginTop: 30 }}
                    component={Link}
                    to="/home"
                    variant="contained"
                >
                    {t('common.toHome')}
                </Button>
            </>
        );
    }

    if (!availableApplications && !isLoading) {
        return <Empty />;
    }

    if (currentApplication) {
        return (
            <>
                <ApplicationOrderSuccess
                    applicationSubmitSuccess={applicationSubmitSuccess}
                    currentApplicationTitle={currentApplication.title}
                />
                <ApplicationForm
                    currentApplicationId={currentApplicationId}
                    nextApplicationId={nextApplicationId}
                    currentApplication={currentApplication}
                    handleOrderCard={handleOrderCard}
                    handleSetActiveStep={handleSetActiveStep}
                    profileChildren={profileChildren}
                />
            </>
        );
    }
    return null;
};
