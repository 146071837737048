import React, { useRef } from 'react';
import get from 'lodash/get';

import {
    FormControl,
    FormControlLabel,
    FormControlLabelProps,
    FormControlProps,
    FormHelperText,
} from '@material-ui/core';

import { FormFieldProps, SubmitFieldValidationRules } from 'types/FormField';

import Checkbox, { CheckboxProps } from './Checkbox';
import { Controller } from 'react-hook-form';

type Props = {
    label: string | React.ReactNode;
    validationRules?: SubmitFieldValidationRules;
    formControlProps?: FormControlProps;
    error?: boolean;
    formControlLabelProps?: Omit<FormControlLabelProps, 'control' | 'label' | 'name'>;
    helperText?: string;
} & FormFieldProps &
    CheckboxProps;
export const CheckboxFormField: React.FC<Props> = React.memo(props => {
    const {
        control,
        errors,
        error,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        label,
        defaultValue,
        value,
        formControlProps,
        formControlLabelProps,
        helperText,
        ...other
    } = props;

    const checkboxRef = useRef<HTMLInputElement>(null);
    /**     <Controller
                defaultValue={defaultValue}
                control={control}
                name={name}
                rules={validationRules}
                render={({ value, onChange }) => (
                    <FormControl
                        {...formControlProps}
                        error={Boolean(get(errors, name, false))}
                    >
                        <FormControlLabel
                            {...formControlLabelProps}
                            control={<Checkbox {...other} />}
                            name={name}
                            value={value}
                            onChange={onChange}
                            label={label}
                            inputRef={checkboxRef}
                        />
                        <FormHelperText>{helperText}</FormHelperText>
                    </FormControl>
                )}
                onFocus={() => {
                    checkboxRef.current?.focus();
                }}
            /> */

    let errorText;
    if (error) errorText = 'Обязательное поле';

    return (
        <Controller
            defaultValue={Boolean(defaultValue)}
            name={name}
            control={control}
            rules={validationRules}
            render={props => (
                <FormControl {...formControlProps} error={error}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                {...props}
                                checked={props.value}
                                onChange={e => props.onChange(e.target.checked)}
                            />
                        }
                        label={label}
                        inputRef={checkboxRef}
                    />
                    {(helperText || errorText) && (
                        <FormHelperText>
                            {helperText} {errorText}
                        </FormHelperText>
                    )}
                </FormControl>
            )}
            onFocus={() => {
                checkboxRef.current?.focus();
            }}
        />
    );
});
