import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { Application } from 'types/Application';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';

interface getApplicationByIdSuccess {
    application: Application;
    currentApplicationId: string;
}
interface getApplicationsSuccess {
    allApplications: SubsidyManagementModel[];
    availableApplications: SubsidyManagementModel[];
    currentApplicationId?: string;
}
interface getNextApplicationSuccess {
    currentApplicationId: string;
    availableApplications?: SubsidyManagementModel[];
}
interface saveApplicationSuccess {
    applicationSent?: boolean;
    submitData: any;
}

interface setActiveStep {
    step: number;
}

interface setCurrentApplicationId {
    currentApplicationId: string;
}

export interface ApplicationState {
    step: number;

    /**
     * Используется для получения application
     * @action getApplications
     */
    currentApplicationId: string;

    /**
     * @action saveApplication
     */
    submitData: any;

    /**
     * все Заявления
     * @action getApplications
     */
    allApplications: SubsidyManagementModel[];

    /**
     * заявления, но с application.statusId === 0
     * @action getApplications
     */
    availableApplications?: SubsidyManagementModel[];

    /**
     * Получается с помощью currentApplicationId
     * @action getApplicationsById
     */
    application?: Application;

    profileChildren: {
        data: Array<any>;
        success: boolean;
        error?: string;
        loading?: boolean;
    };

    //Контролленры реквестов
    getApplications: {
        success: boolean;
        error?: string;
        loading?: boolean;
    };
    getApplicationById: {
        success: boolean;
        error?: string;
        loading?: boolean;
    };

    submitLoading?: boolean;
    submitError?: string;
    applicationSent: boolean;
}

const initialState: ApplicationState = {
    step: 0,
    submitData: null,
    currentApplicationId: null,
    allApplications: null,
    availableApplications: null,
    application: null,
    profileChildren: {
        data: null,
        loading: false,
        error: null,
        success: false,
    },
    getApplications: {
        loading: false,
        error: null,
        success: false,
    },
    getApplicationById: {
        loading: false,
        error: null,
        success: false,
    },
    applicationSent: false,
    submitError: null,
    submitLoading: false,
};

const application = createSlice({
    name: 'application',
    initialState,
    reducers: {
        getApplicationsStart(state, action: PayloadAction<string>) {
            state.getApplications.error = null;
            state.getApplications.loading = true;
        },
        getApplicationsSuccess(state, action: PayloadAction<getApplicationsSuccess>) {
            state.getApplications.error = null;
            state.getApplications.loading = false;
            state.getApplications.success = true;
            state.availableApplications = action.payload.availableApplications;
            state.allApplications = action.payload.allApplications;
        },
        getApplicationsFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.getApplications.error = action.payload.error;
            state.getApplications.loading = false;
            state.getApplications.success = false;
        },
        getApplicationByIdStart(state, action: PayloadAction<string>) {
            state.getApplicationById.error = null;
            state.getApplicationById.loading = true;
        },
        getApplicationByIdSuccess(
            state,
            action: PayloadAction<getApplicationByIdSuccess>
        ) {
            state.getApplicationById.error = null;
            state.getApplicationById.loading = false;
            state.getApplicationById.success = true;
            state.application = action.payload.application;
            state.currentApplicationId = action.payload.currentApplicationId;
            state.applicationSent = false;
            state.submitData = null;
            state.step = 0;
        },
        getApplicationByIdFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.getApplicationById.error = action.payload.error;
            state.getApplicationById.loading = false;
            state.getApplicationById.success = false;
        },
        saveApplicationStart(state, action: PayloadAction<string>) {
            state.submitLoading = true;
            state.submitError = null;
        },
        saveApplicationSuccess(state, action: PayloadAction<saveApplicationSuccess>) {
            state.submitLoading = false;
            state.submitError = null;
            state.applicationSent = action.payload.applicationSent;
            state.submitData = action.payload.submitData;
        },
        getProfileChildrenStart(state, action: PayloadAction<string>) {
            state.profileChildren.loading = true;
            state.profileChildren.error = null;
            state.profileChildren.success = false;
        },
        getProfileChildrenSuccess(state, action) {
            state.profileChildren.loading = false;
            state.profileChildren.error = null;
            state.profileChildren.data = action.payload.profileChildren;
            state.profileChildren.success = true;
        },
        getProfileChildrenFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.profileChildren.loading = false;
            state.profileChildren.error = action.payload.error;
            state.profileChildren.success = false;
        },
        saveApplicationBlockSuccess(
            state,
            action: PayloadAction<saveApplicationSuccess>
        ) {
            state.submitLoading = false;
            state.submitError = null;
        },
        saveApplicationFailure(state, action: PayloadAction<ApiErrorConstruction>) {
            state.submitLoading = false;
            state.submitError = action.payload.error;
        },
        nextApplication(state, action: PayloadAction<getNextApplicationSuccess>) {
            // state.availableApplications = action.payload.availableApplications;
            state.step = 0;
            state.currentApplicationId = action.payload.currentApplicationId;
            state.application = null;
            state.applicationSent = false;
            state.submitData = null;
        },
        setActiveStep(state, action: PayloadAction<setActiveStep>) {
            state.step = action.payload.step;
        },
        setCurrentApplicationId(state, action: PayloadAction<setCurrentApplicationId>) {
            state.step = 0;
            state.currentApplicationId = action.payload.currentApplicationId;
            state.application = null;
            state.applicationSent = false;
            state.submitData = null;
        },
        setApplication(state, { payload }) {
            state.application = payload.application;
        },
        addChild(state, { payload }) {
            state.application.blocks[payload.childDataBlockIndex].childrenBlock.push({
                childId: null,
                childFields: payload.childFieldsTemplate,
            });
        },
        delChild(state, { payload }) {
            state.application.blocks[payload.childDataBlockIndex].childrenBlock.splice(
                payload.childIndex,
                1
            );
        },
        replaceChild(state, { payload }) {
            state.application.blocks[payload.childDataBlockIndex].childrenBlock[
                payload.childIndex
            ] = payload.selectedChild;
        },
        addFamilyMember(state, { payload }) {
            state.application.blocks[payload.familyDataBlockIndex].familyMembers.push({
                memberId: null,
                memberFields: payload.familyMembersTemplate,
                relatives: [],
            });
        },
        delFamilyMember(state, { payload }) {
            state.application.blocks[payload.familyDataBlockIndex].familyMembers.splice(
                payload.familyMemberIndex,
                1
            );
        },
        addRelativeMember(state, { payload }) {
            const { familyDataBlockIndex, familyMemberIndex, relativeTemplate } = payload;

            state.application.blocks[familyDataBlockIndex].familyMembers[
                familyMemberIndex
            ].relatives.push({
                relativeId: null,
                relativeFields: relativeTemplate,
            });
        },
        delRelativeMember(state, { payload }) {
            const {
                familyDataBlockIndex,
                familyMemberIndex,
                relativeMemberIndex,
            } = payload;

            state.application.blocks[familyDataBlockIndex].familyMembers[
                familyMemberIndex
            ].relatives.splice(relativeMemberIndex, 1);
        },
        setBlock(state, { payload }) {
            state.application.blocks[payload.blockIndex] = payload.block;
        },
        clearApplication(state) {
            return initialState;
        },
    },
});

export const {
    getApplicationsFailure,
    getApplicationsStart,
    getApplicationsSuccess,
    saveApplicationFailure,
    saveApplicationStart,
    saveApplicationSuccess,
    saveApplicationBlockSuccess,
    getApplicationByIdFailure,
    getApplicationByIdStart,
    getApplicationByIdSuccess,
    nextApplication,
    setActiveStep,
    setCurrentApplicationId,
    setApplication,
    addChild,
    delChild,
    replaceChild,
    addFamilyMember,
    delFamilyMember,
    addRelativeMember,
    delRelativeMember,
    setBlock,
    getProfileChildrenStart,
    getProfileChildrenSuccess,
    getProfileChildrenFailure,
    clearApplication,
} = application.actions;
export default application.reducer;
