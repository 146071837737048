import React, { FC, useCallback } from 'react';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    makeStyles,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { useForm, FormProvider } from 'react-hook-form';
import { DynamicField } from './DynamicField';
import {
    checkBindingVisibleValues,
    checkBindingRequiredValues,
} from 'utils/checkFieldBinding';
import get from 'lodash/get';
import { CircularProgress } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
    block: {},
    boxBlockTitle: {
        marginBottom: theme.spacing(4),
    },
    blockTitle: {},
    boxBlocks: {
        marginBottom: theme.spacing(4),
    },
    displayName: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '140%',
        color: '#474D5F',
        marginBottom: theme.spacing(4),
    },
    boxBtn: {},
    btn: {
        width: '100%',
    },
}));

interface Props {
    preconditionData: any;
    createApplication: (jsonPreconditions: string) => void;
    isLoading: boolean;
}

export const MakeApplicationForm: FC<Props> = ({
    preconditionData,
    createApplication,
    isLoading,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const { t } = useTranslation();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();

    const formProps = useForm();

    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = formProps;

    const onSubmit = useCallback(
        () => blockData => {
            console.log(blockData);
            const jsonPreconditions = blockData;
            createApplication(jsonPreconditions);
        },
        []
    );
    return (
        <FormProvider {...formProps}>
            <form onSubmit={handleSubmit(onSubmit())}>
                <Box className={classes.boxBlockTitle}>
                    <Typography variant="h3" className={classes.blockTitle}>
                        {preconditionData.title}
                    </Typography>
                </Box>
                <Box className={classes.boxBlocks}>
                    {preconditionData?.blocks.map((block, blockIndex) => (
                        <Box
                            className={classes.block}
                            key={`MakeDialogBlock-${blockIndex}`}
                        >
                            <Typography variant="h5" className={classes.displayName}>
                                {block.displayName}
                            </Typography>
                            {block?.fields.map((field, fieldIndex) => {
                                let filedsPath = `data`;
                                let fieldName = `${filedsPath}.${field.fieldId}`;
                                if (checkBindingVisibleValues(field, watch, filedsPath))
                                    return null;

                                const isRequired =
                                    checkBindingRequiredValues(
                                        field,
                                        watch,
                                        filedsPath
                                    ) || field.isRequired;

                                const error = Boolean(get(errors, fieldName, false));
                                return (
                                    <DynamicField
                                        key={`MakeDialogField-${fieldIndex}`}
                                        field={field}
                                        isRequired={isRequired}
                                        error={error}
                                        fieldName={fieldName}
                                    />
                                );
                            })}
                        </Box>
                    ))}
                </Box>
                <Box className={classes.boxBtn}>
                    <Button
                        variant="contained"
                        color="primary"
                        type="submit"
                        className={classes.btn}
                        startIcon={isLoading && <CircularProgress size={24} />}
                        disabled={isLoading}
                    >
                        Продолжить
                    </Button>
                </Box>
            </form>
        </FormProvider>
    );
};
