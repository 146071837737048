import { createSlice } from '@reduxjs/toolkit';
import { Service, ServiceSubsidy, ServiceDetail, Calculation } from 'types/Services';

export enum OptionsType {
    Select = 'Select',
    Input = 'Input',
    NumericInput = 'NumericInput',
    Calculation = 'Calculation',
    Radio = 'Radio',
    Link = 'Link',
}
export enum TypeVariant {
    Value = 1,
    Answers = 0,
    Options = 2,
}

export interface OptionsFields {
    name: string;
    optionsType: OptionsType;
    optionsValueses?: {
        name: string;
        value: string;
    }[];
    id: string | number;
    description?: string;
    descriptionLink?: {
        text: string;
        link: string;
    };
    nameLinks?:
        | {
              mask: string;
              link: string;
          }[]
        | null;
}

export interface CalcData {
    typeSubsidy: string | number;
    typeVariant: TypeVariant;
    value?: string;
    question?: string;
    answers?: {
        name: string;
        value: string;
        id: string;
    }[];
    name: string;
    description?: string;
    options?: OptionsFields[];
}

export interface CalcDataResult {
    isFinish: boolean;
    result: string;
    resultDescription: string; // Новое поле для подписи к ответу
    additionalResults: {
        resultDescription: string; // Количество месяцев на период
        result: string;
    }[];
}

interface State {
    calculator: {
        data: CalcData;
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
    calculatorResult: {
        data: CalcDataResult;
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
}

const initialState: State = {
    calculator: {
        data: null,
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
    calculatorResult: {
        data: null,
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
};

//TODO: сделать конструкторы, чтобы уменьшить код
const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        //Калькулятор Опции
        getServiceCalcStart(state) {
            state.calculator.loading = true;
            state.calculator.error = null;
        },
        getServiceCalcSuccess(state, { payload }) {
            state.calculator.loading = false;
            state.calculator.error = null;
            state.calculator.success = true;
            state.calculator.loaded = true;
            state.calculator.data = payload;
        },
        getServiceCalcFailure(state, action) {
            state.calculator.loading = false;
            state.calculator.loaded = true;
            state.calculator.error = action.payload.error.error;
            state.calculator.errorCode = action.payload.errorCode;
        },
        getServiceCalcClearErrorCode(state) {
            state.calculator.errorCode = null;
        },
        getServiceCalcClearAll(state) {
            state.calculator.errorCode = null;
            state.calculator.data = null;
        },

        //Калькулятор Результ
        getServiceCalcResultStart(state) {
            state.calculatorResult.loading = true;
            state.calculatorResult.error = null;
        },
        getServiceCalcResultSuccess(state, { payload }) {
            state.calculatorResult.loading = false;
            state.calculatorResult.error = null;
            state.calculatorResult.success = true;
            state.calculatorResult.loaded = true;
            state.calculatorResult.data = payload;
        },
        getServiceCalcResultFailure(state, action) {
            state.calculatorResult.loading = false;
            state.calculatorResult.loaded = true;
            state.calculatorResult.error = action.payload.error.error;
            state.calculatorResult.errorCode = action.payload.errorCode;
        },
        getServiceCalcResultClearErrorCode(state) {
            state.calculatorResult.errorCode = null;
        },
        getServiceCalcResultClearAll(state) {
            state.calculatorResult.errorCode = null;
            state.calculatorResult.data = null;
        },
    },
});

export const {
    //Калькулятор
    getServiceCalcStart,
    getServiceCalcSuccess,
    getServiceCalcFailure,
    getServiceCalcClearErrorCode,
    getServiceCalcClearAll,
    //Результат
    getServiceCalcResultStart,
    getServiceCalcResultSuccess,
    getServiceCalcResultFailure,
    getServiceCalcResultClearErrorCode,
    getServiceCalcResultClearAll,
} = services.actions;

export default services.reducer;
