import { axiosInstanse, getCommonHeaders } from 'infrastructure/RestClient';
import axios from 'axios';

import {
    WALLET_INIT_CHECK_CARD_REQUESTING,
    WALLET_INIT_CHECK_CARD_SUCCEED,
    WALLET_INIT_CHECK_CARD_FAILED,
    WALLET_INIT_CHECK_CARD_RESET,
} from '../../constants';
import { errorHandle } from 'utils/utils';

const ERROR = 'Не удалось инициализировать форму добавления карты, попробуйте позже';

export const initCheckCard = (cb?: Function, enqueueSnackbar?: Function) => (
    dispatch,
    getState
) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_INIT_CHECK_CARD_REQUESTING, null, null, null);

    return axios
        .get(`/api/Requisites/input`, {
            headers: getCommonHeaders(getState()),
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const result = response.data?.result;
            if (isSuccess === true) {
                cb && cb(result);
                dispatchData(WALLET_INIT_CHECK_CARD_SUCCEED, result, requestStatus, null);
            }

            if (isSuccess === false) {
                const error = errorHandle(response.data, ERROR);
                enqueueSnackbar && enqueueSnackbar(error.error);
                dispatchData(
                    WALLET_INIT_CHECK_CARD_FAILED,
                    null,
                    requestStatus,
                    error.error
                );
            }
            return result;
        })
        .catch(rawError => {
            const error = errorHandle(rawError.response?.data || rawError, ERROR);
            enqueueSnackbar && enqueueSnackbar(error.error);
            dispatchData(WALLET_INIT_CHECK_CARD_FAILED, null, null, error.error);
        });
};

export const initCheckCardReset = () => dispatch => {
    dispatch({
        type: WALLET_INIT_CHECK_CARD_RESET,
    });
};
