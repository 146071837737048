import React, { FC, ReactNode } from 'react';
import { Link } from 'react-router-dom';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import Grid from '@material-ui/core/Grid';

import ExpandLess from '@material-ui/icons/ExpandLess';
import ExpandMore from '@material-ui/icons/ExpandMore';
import { Menu, MenuItem } from '@material-ui/core';

import { Icon } from 'components/atoms';
import { ProfileShort } from 'types/Profile';
import { EsiaLoginContainer } from 'components/molecules';
import { useTranslation } from 'react-i18next';
import logo from 'assets/images/logo2.svg';
import { IconLink } from 'components/atoms/IconLink';
import gosuslugi from 'assets/images/gosuslugi.svg';
import socProtection from 'assets/images/socProtection.svg';
import { gosuslugi_link_06_05_2021 } from 'store/features.constants';
import { soc_protection_link_06_05_2021 } from 'store/features.constants';
import { FeatureToggle } from 'components/common/FeatureToggle';

const useStyles = makeStyles(theme => ({
    logo: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '20px',
        lineHeight: '140%',
        display: 'flex',
        alignItems: 'center',
        color: '#000A26',
    },
    logoImage: {
        marginRight: 10,
    },
    menuContainer: {
        flexWrap: 'nowrap',
        alignItems: 'center',
        height: 'inherit',
    },
    menuLogo: {},
    button: {
        padding: '12px',
        whiteSpace: 'nowrap',
    },
    buttonContainer: {
        // marginLeft: 'auto',
    },
    menuAppbar: {
        backgroundColor: theme.palette.common.white,
        boxShadow: '0px 8px 32px rgba(0, 0, 0, 0.08)',
        borderRadius: 8,
        marginTop: 6,
        width: 160,
    },
    actionButton: {
        maxWidth: 205,
    },
    iconLinksContainer: {
        marginLeft: 'auto',
        marginRight: 16,
    },
    iconLink: {
        marginRight: 32,
    },
    iconLogout: {
        marginLeft: 13,
    },
}));

interface MenuProps {
    isAuthorized?: boolean;
    userName?: ProfileShort;
    logout: VoidFunction;
    actionButton?: ReactNode;
}

export const DesktopMenu: FC<MenuProps> = ({
    userName,
    isAuthorized,
    logout,
    actionButton,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();

    const renderActionButton = () => {
        if (actionButton) {
            return (
                <Grid item className={classes.buttonContainer}>
                    {actionButton}
                </Grid>
            );
        }

        if (isAuthorized) {
            return (
                <Grid item className={classes.buttonContainer}>
                    <Button
                        size="medium"
                        color="default"
                        variant="text"
                        endIcon={<Icon name="logout2" className={classes.iconLogout} />}
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={logout}
                    >
                        {userName?.firstName} {userName?.lastName}
                    </Button>
                </Grid>
            );
        }

        return (
            <Grid item className={classes.buttonContainer}>
                <EsiaLoginContainer
                    mode="gosuslugi"
                    className={cx(classes.button)}
                    buttonProps={{
                        size: 'medium',
                    }}
                />
            </Grid>
        );
    };

    return (
        <Grid container className={classes.menuContainer}>
            <Grid item className={classes.menuLogo}>
                <Link className={classes.logo} to="/home">
                    <img
                        src={logo}
                        alt="logo"
                        width="40px"
                        height="40px"
                        className={classes.logoImage}
                    />{' '}
                    {t('zabota')}
                </Link>
            </Grid>
            <Grid item className={classes.iconLinksContainer}>
                <FeatureToggle name={gosuslugi_link_06_05_2021}>
                    <IconLink
                        to="https://uslugi.tatarstan.ru/"
                        className={classes.iconLink}
                        img={gosuslugi}
                        target="_blank"
                        title="Госуслуги"
                    />
                </FeatureToggle>
                <FeatureToggle name={soc_protection_link_06_05_2021}>
                    <IconLink
                        to="https://uslugi.tatarstan.ru/mintrud/service"
                        className={classes.iconLink}
                        img={socProtection}
                        target="_blank"
                        title="Социальная защита"
                    />
                </FeatureToggle>
            </Grid>
            {renderActionButton()}
        </Grid>
    );
};
