import React, { FC, useRef } from 'react';
import { useForm, Controller } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import map from 'lodash/map';
import toString from 'lodash/toString';
import get from 'lodash/get';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    CircularProgress,
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Grid,
    makeStyles,
    Radio,
    RadioGroup,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import reactStringReplace from 'react-string-replace';
import { getCalculateResult, resetCalculateSubsidy } from 'store/actions';
import { CalculateSubsidyState, OptionsType, TypeVariant } from 'types/Subsidy';
import { FormFieldProps, SubmitField } from 'types/FormField';
import { NumberFormatCustom, SelectFormField, TextField } from 'components/atoms';

const useStyles = makeStyles(theme => ({
    grid: {
        [theme.breakpoints.down('sm')]: {
            // marginTop: 24,
        },
        // marginTop: 40,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            marginRight: 0,
            maxWidth: '100%',
        },
        maxWidth: 313,
        marginRight: 0,
    },
    input: {
        '& + &': {
            marginTop: 32,
        },
        width: '100%',
    },
    textField: {
        '& .MuiOutlinedInput-inputMarginDense': {
            paddingTop: 10,
            paddingBottom: 10,
        },
        [theme.breakpoints.up('sm')]: {
            '& .MuiOutlinedInput-input': {
                paddingTop: 18,
                paddingBottom: 18,
            },
        },
    },
    hint: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
        },
        fontSize: 18,
        lineHeight: 1.4,
        color: theme.palette.grey[400],
    },
    fieldsContainer: {
        marginTop: 32,
    },
    answerContainer: {
        marginTop: 40,
        display: 'flex',
        flexDirection: 'column',
    },
    answer: {
        [theme.breakpoints.down('sm')]: {
            marginTop: 26,
        },
        color: '#25BE4C',
        marginTop: 16,
    },
    listItem: {
        marginBottom: 10,
        '&:last-child': {
            marginBottom: 0,
        },
    },
    title: {
        [theme.breakpoints.down('sm')]: {
            fontWeight: 500,
            fontSize: 18,
        },
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: 1.4,
        marginBottom: 8,
    },
    label: {
        [theme.breakpoints.down('sm')]: {
            fontSize: 15,
            lineHeight: 2.13,
        },
        fontWeight: 500,
        fontSize: 20,
        lineHeight: 1.4,
        color: theme.palette.common.black,
        marginBottom: 8,
    },
    formControlLabel: {
        '& + &': {
            marginTop: 8,
        },
        '& .MuiRadio-root': {
            padding: '0 8px 0px 8px',
        },
        whiteSpace: 'pre-line',
        alignItems: 'end',
    },
    nameLink: {
        color: '#5780EA',
        textDecoration: 'none',
    },
}));

interface FieldProps {
    formProps: FormFieldProps;
    field: SubmitField;
}
const RenderRadioGroup: FC<FieldProps> = ({ field, formProps }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [value, setValue] = React.useState('');
    const { errors, register } = formProps;

    const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
        setValue((event.target as HTMLInputElement).value);
    };

    return (
        <FormControl
            error={Boolean(get(errors, field.name, false))}
            component="fieldset"
            className={classes.input}
        >
            <FormLabel component="legend" className={classes.label}>
                {field.label}
            </FormLabel>
            <RadioGroup
                aria-label={t('actions.selectValue')}
                name={field.name}
                value={value}
                onChange={handleChange}
            >
                {map(field.options, ({ value, name }, index) => (
                    <FormControlLabel
                        key={`${value}_${index}`}
                        value={`${value}_${index}`}
                        control={
                            <Radio
                                color="primary"
                                inputRef={register({ required: true })}
                            />
                        }
                        label={name}
                        className={classes.formControlLabel}
                    />
                ))}
            </RadioGroup>
        </FormControl>
    );
};

const RenderTextField: FC<FieldProps> = ({ formProps, field }) => {
    const { errors, control } = formProps;
    const classes = useStyles();
    const { t } = useTranslation();
    const amountRef = useRef<HTMLInputElement>(null);
    const helperText = Boolean(get(errors, field.name, false))
        ? t('actions.InvalidValue')
        : field.helperText || '';
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    let InpProps, InpType;
    switch (field.type) {
        case 'numeric':
            InpProps = null;
            InpType = 'number';
            break;
        default:
            InpProps = { inputComponent: NumberFormatCustom as any };
            break;
    }
    return (
        <Controller
            control={control}
            name={field.name}
            rules={{ required: true }}
            defaultValue=""
            render={({ onChange, value }) => {
                return (
                    <>
                        <FormControl
                            error={Boolean(get(errors, field.name, false))}
                            className={classes.input}
                        >
                            <FormLabel className={classes.label}>{field.label}</FormLabel>
                            <TextField
                                className={classes.textField}
                                onChange={event => {
                                    onChange(event.target.value);
                                }}
                                value={value}
                                inputRef={amountRef}
                                autoComplete="off"
                                InputProps={InpProps}
                                type={InpType}
                                variant="outlined"
                                size={isViewXS ? 'small' : 'medium'}
                            />
                            <FormHelperText>{helperText}</FormHelperText>
                        </FormControl>
                    </>
                );
            }}
            onFocus={() => amountRef.current?.focus()}
        />
    );
};

const RenderSelect: FC<FieldProps> = ({ formProps, field }) => {
    const { errors } = formProps;
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <>
            <SelectFormField
                {...formProps}
                name={field.name}
                label={field.label}
                className={classes.input}
                error={Boolean(get(errors, field.name, false))}
                helperText={
                    Boolean(get(errors, field.name, false)) && t('actions.InvalidValue')
                }
                variant="outlined"
                options={field.options}
                formControlProps={{
                    size: 'medium',
                    fullWidth: true,
                }}
                autoComplete="off"
                defaultValue={''}
                fullWidth
                validationRules={{ required: true }}
            />
        </>
    );
};

interface SubsidyCalculatorFieldGroupProps {
    calculateSubsidyState: CalculateSubsidyState;
    formProps: FormFieldProps;
}

const SubsidyCalculatorFieldGroup: FC<SubsidyCalculatorFieldGroupProps> = ({
    calculateSubsidyState,
    formProps,
}) => {
    const classes = useStyles();
    return (
        <>
            {map(calculateSubsidyState.result?.options, (field, index) => {
                let fieldName: any = field.name;
                if (field.nameLinks) {
                    field.nameLinks.forEach(item => {
                        fieldName = reactStringReplace(
                            fieldName,
                            item.mask,
                            (match, i) => (
                                <a
                                    className={classes.nameLink}
                                    href={item.link}
                                    target="_blank"
                                >
                                    {item.mask}
                                </a>
                            )
                        );
                    });
                }

                if (field.optionsType === OptionsType.Input) {
                    return (
                        <RenderTextField
                            key={`${field.id}_${index}`}
                            field={{
                                name: toString(field.id),
                                label: fieldName,
                                helperText: field.description,
                            }}
                            formProps={formProps}
                        />
                    );
                }
                if (field.optionsType === OptionsType.NumericInput) {
                    return (
                        <RenderTextField
                            key={`${field.id}_${index}`}
                            field={{
                                name: toString(field.id),
                                label: fieldName,
                                helperText: field.description,
                                type: 'numeric',
                            }}
                            formProps={formProps}
                        />
                    );
                }
                if (
                    field.optionsType === OptionsType.Radio ||
                    field.optionsType === OptionsType.Select
                ) {
                    return (
                        <RenderRadioGroup
                            key={`${field.id}_${index}`}
                            field={{
                                name: toString(field.id),
                                label: fieldName,
                                options: field.optionsValueses,
                            }}
                            formProps={formProps}
                        />
                    );
                }
                return null;
            })}
        </>
    );
};

interface SubsidyCalculatorFieldsProps {
    calculateSubsidyState: CalculateSubsidyState;
    formProps: FormFieldProps;
}

const SubsidyCalculatorFields: FC<SubsidyCalculatorFieldsProps> = ({
    calculateSubsidyState,
    formProps,
}) => {
    if (calculateSubsidyState.result?.typeVariant === TypeVariant.Value) {
        return null;
    }

    if (calculateSubsidyState.result?.typeVariant === TypeVariant.Answers) {
        return (
            <RenderRadioGroup
                formProps={formProps}
                field={{
                    name: calculateSubsidyState.result.question,
                    label: calculateSubsidyState.result.question,
                    options: calculateSubsidyState.result.answers,
                }}
            />
        );
    }

    if (calculateSubsidyState.result?.typeVariant === TypeVariant.Options) {
        return (
            <SubsidyCalculatorFieldGroup
                calculateSubsidyState={calculateSubsidyState}
                formProps={formProps}
            />
        );
    }

    return null;
};

interface SubsidyCalculatorProps {
    calculateSubsidyState: CalculateSubsidyState;
    subsidyId: string;
}

const SubsidyCalculator: FC<SubsidyCalculatorProps> = ({
    calculateSubsidyState,
    subsidyId,
}) => {
    const classes = useStyles();
    const [calcResult, setCalcResult] = React.useState('');
    const [tempValue, settempValue] = React.useState('');
    const dispatch = useDispatch();
    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
        reset,
        formState: { isDirty, isSubmitted },
    } = useForm<any>();
    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
    };
    const watchAllFields = watch();
    const { t } = useTranslation();
    const onChange = value => {
        setCalcResult(value);
    };

    const submit = React.useCallback(
        formData => {
            if (calculateSubsidyState.result?.typeVariant === TypeVariant.Options) {
                const preparedFormData = [];
                const fields = calculateSubsidyState.result?.options;
                Object.keys(formData).forEach(formName => {
                    const field = fields.find(field => field.id === +formName);
                    if (field) {
                        let value = formData[formName];
                        if (
                            field.optionsType === OptionsType.Radio ||
                            field.optionsType === OptionsType.Select
                        ) {
                            value = value.split('_')[0];
                        }
                        preparedFormData.push({
                            id: field.id,
                            value: value,
                        });
                    }
                });
                dispatch(getCalculateResult(subsidyId, preparedFormData));
            }
            if (calculateSubsidyState.result?.typeVariant === TypeVariant.Answers) {
                const value = formData[calculateSubsidyState.result.question].split(
                    '_'
                )[0];
                settempValue(value);
            }
        },
        [dispatch, calculateSubsidyState, subsidyId]
    );

    React.useEffect(() => {
        if (isSubmitted) {
            if (
                calculateSubsidyState.result?.typeVariant === TypeVariant.Options &&
                !calculateSubsidyState.request.isLoading &&
                calculateSubsidyState.calculateResult?.result
            ) {
                onChange(calculateSubsidyState.calculateResult.result);
                reset(watchAllFields);
            } else if (
                calculateSubsidyState.result?.typeVariant === TypeVariant.Answers
            ) {
                onChange(tempValue);
                reset(watchAllFields);
            }
        }
    }, [isSubmitted, calculateSubsidyState, watchAllFields]);

    React.useEffect(() => {
        if (isDirty && calcResult) {
            onChange('');
        }
    }, [calcResult, isDirty]);

    React.useEffect(() => {
        if (calculateSubsidyState.result?.typeVariant === TypeVariant.Value) {
            onChange(calculateSubsidyState.result.value);
        }
    }, [calculateSubsidyState]);

    React.useEffect(() => {
        return () => {
            dispatch(resetCalculateSubsidy());
        };
    }, []);

    return (
        <form onSubmit={handleSubmit(submit)}>
            <Grid container direction="column" className={classes.grid}>
                <Grid item>
                    <Typography className={classes.title}>
                        {calculateSubsidyState.result?.name}
                    </Typography>
                </Grid>
                <Grid item>
                    <Typography className={classes.hint}>
                        {calculateSubsidyState.result?.description}
                    </Typography>
                </Grid>
                <Grid item className={classes.fieldsContainer}>
                    <SubsidyCalculatorFields
                        formProps={formProps}
                        calculateSubsidyState={calculateSubsidyState}
                    />
                </Grid>
                <Grid item className={classes.answerContainer}>
                    <Button
                        type="submit"
                        startIcon={
                            calculateSubsidyState.calculateRequest.isLoading && (
                                <CircularProgress size={24} />
                            )
                        }
                        disabled={
                            calculateSubsidyState.calculateRequest.isLoading ||
                            !!calcResult
                        }
                        variant="contained"
                        className={classes.button}
                    >
                        {t('actions.calculate')}
                    </Button>
                    {calcResult && (
                        <Box>
                            <Typography
                                variant="h4"
                                component="p"
                                className={classes.answer}
                            >
                                {calcResult}
                            </Typography>
                            <Typography variant="body1" className={classes.hint}>
                                {t('actions.approximateCalculation')}
                            </Typography>
                        </Box>
                    )}
                </Grid>
            </Grid>
        </form>
    );
};

export default SubsidyCalculator;
