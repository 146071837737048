import React, { useEffect, FC } from 'react';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Typography, Box } from '@material-ui/core';
import { Bubble } from 'components/atoms';
import { CheckListItem } from 'types/Chat';
import { useSelectedMessages } from '../.././useSelectedMessageContext';
import { getColorBySubsidyCategory } from 'utils/utils';
import { useCommonStyles } from '../.././Chat.styles';
import { Link } from 'react-router-dom';

const useStylesCheckListMessage = makeStyles((theme: Theme) => ({
    checkListMessageContainer: {
        [theme.breakpoints.down('xs')]: {
            // marginLeft: -30,
        },
        display: 'flex',
        flexDirection: 'column',
        marginBottom: 20,
    },
    checkListMessageContentContainer: {
        display: 'flex',
        alignItems: 'flex-start',
    },
    checkListMessageCheckboxControlLabel: {
        marginLeft: 0,
        marginRight: 0,
        alignItems: 'flex-start',
    },
    checkListMessageDescriptionContaiener: {
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
        display: 'flex',
        flexDirection: 'column',
        padding: 16,
        // marginLeft: 10,
        width: 400,
        background: '#FFFFFF',
        borderRadius: '0px 16px 16px 16px',
    },
    checkListMessageItem: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'normal',
            fontSize: 15,
            lineHeight: 1.4,
        },
        fontWeight: 500,
        fontSize: 16,
        lineHeight: 1.5,
    },
    checkListMessageCheckbox: {
        padding: 0,
    },
}));

interface CheckListMessageProps {
    item: CheckListItem;
    disabled: boolean;
}

const CheckListMessage: FC<CheckListMessageProps> = ({ item, disabled }) => {
    const classes = useStylesCheckListMessage();
    const classesCommon = useCommonStyles();

    const { onChangeSelectedMessages } = useSelectedMessages();
    const [checked, setChecked] = React.useState(true);

    useEffect(() => {
        if (disabled) {
            onChangeSelectedMessages(item.Id, false);
            setChecked(false);
        } else {
            onChangeSelectedMessages(item.Id, true);
            setChecked(true);
        }
    }, [disabled]);

    return (
        <>
            <Box className={classes.checkListMessageContainer}>
                {item.Description && (
                    <Bubble
                        variant="incoming"
                        className={classesCommon.checkListMessageChildTag}
                    >
                        {item.Description}
                    </Bubble>
                )}
                <Box className={classes.checkListMessageContentContainer}>
                    <Box
                        className={classes.checkListMessageDescriptionContaiener}
                        style={{
                            backgroundColor: getColorBySubsidyCategory(item.Category)
                                .backgroundColor,
                            border: `1px solid ${
                                getColorBySubsidyCategory(item.Category).borderColor
                            }`,
                        }}
                    >
                        <Typography className={classes.checkListMessageItem}>
                            {item.Title}
                        </Typography>
                        {item.ActionLink && (
                            <Box className={classesCommon.detailsLink}>
                                <a href={item.ActionLink} className={classesCommon.link}>
                                    {item.ActionLinkTitle}
                                </a>
                            </Box>
                        )}
                    </Box>
                </Box>
            </Box>
        </>
    );
};

export default CheckListMessage;
