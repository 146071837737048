import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { Box, makeStyles, Grid } from '@material-ui/core';

import { RootState } from 'store/store';
import { PageHeader } from '../PageHeader';
import { getApplications } from 'store/actions/application';
import { CardStatement } from './CardStatement';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    container: {
        [theme.breakpoints.down('sm')]: {
            height: 'auto',
            minHeight: 'unset',
            marginTop: 20,
            marginBottom: 40,
            marginRight: 0,
            paddingRight: 0,
        },
        flexDirection: 'column',
        overflow: 'hidden',
        // TODO. Высота должна зависеть от центральной колонки
        // контейнер  = высота экрана - шапка сайта(72) - отступ от шапки(56)- футер страницы(104) - отступ от футера(32)
        height: 'calc(100vh - 72px - 56px - 104px - 32px)',
        minHeight: 350,
        overflowY: 'auto',
        marginTop: 32,
    },
    '@global': {
        '*::-webkit-scrollbar': {
            width: '0.4em',
        },
        '*::-webkit-scrollbar-track': {
            '-webkit-box-shadow': 'inset 0 0 6px rgba(0,0,0,0.00)',
        },
        '*::-webkit-scrollbar-thumb': {
            backgroundColor: 'rgba(0,0,0,.1)',
        },
    },
    list: {
        [theme.breakpoints.down('sm')]: {
            flexDirection: 'row',
            flexWrap: 'wrap',
            // marginRight: -16,
            height: 'auto',
            minHeight: 'unset',
        },
        flexShrink: 0,
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column',
    },
}));

export const StatementList: React.FC = () => {
    const classes = useStyles();
    const dispatch = useDispatch();
    const applicationsState = useSelector((state: RootState) => state.application);
    const isAuthorized = !!useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );
    const { t } = useTranslation();
    useEffect(() => {
        if (
            isAuthorized &&
            !applicationsState.getApplications.success &&
            !applicationsState.getApplications.loading
        ) {
            dispatch(getApplications());
        }
    }, [isAuthorized]);

    if (!isAuthorized) {
        return null;
    }

    if (!applicationsState.allApplications) {
        return null;
    }

    return (
        <>
            <PageHeader title={'com.StatementList.header'} />
            <Grid className={classes.container}>
                <Box className={classes.list}>
                    {applicationsState.allApplications.map(subsidy => (
                        <CardStatement
                            subsidy={subsidy}
                            key={`CardStatement-${subsidy.requestId}-${subsidy.statusId}`}
                            detailUrl={`/statement/${subsidy.requestId}`}
                        />
                    ))}
                </Box>
            </Grid>
        </>
    );
};
