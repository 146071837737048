import React from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/find';
import cx from 'classnames';
import { Box, makeStyles, Theme, Typography, Grid, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Icon } from 'components/atoms';
import { useMediaQuery, useTheme } from '@material-ui/core';

const useStyles = makeStyles((theme: Theme) => ({
    selector: {
        /*'& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },*/
        marginBottom: 6,
    },
    infoField: {
        [theme.breakpoints.down('xs')]: {
            height: 48,
            width: 'auto',
        },

        width: '240px',
        border: '1px solid #D5D9DE',
        cursor: 'pointer',
        height: '56px',
        display: 'flex',
        padding: '18px 16px',
        background: '#FAFAFB',
        boxSizing: 'border-box',
        alignItems: 'center',
        borderRadius: '4px',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
    },
    errorField: {
        background: '#ff473314',
        border: '1px solid #ff473352',
    },
    inner: {
        display: 'inline-flex',
        alignItems: 'center',
        whiteSpace: 'nowrap',
        textOverflow: 'ellipsis',
        width: '175px',
    },
    label: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '14px',
        lineHeight: '140%',
        color: '#747E89',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap',
        overflow: 'hidden',
    },
    iconNumber: {
        width: '20px',
        height: '20px',
        display: 'flex',
        background: '#747E89',
        alignItems: 'center',
        marginRight: '8px',
        borderRadius: '40px',
        justifyContent: 'center',
    },
    number: {
        flex: 'none',
        color: '#fff',
        order: 0,
        margin: '0px 0px 0px 1px',
        flexGrow: 0,
        fontSize: '9px',
        textAlign: 'center',
        fontWeight: 500,
        lineHeight: '16px',
        width: '20px',
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            height: 48,
            padding: 0,
            minWidth: 40,
        },
    },
    firstGrid: {
        [theme.breakpoints.down('xs')]: {
            flex: 1,
        },
    },
    error: {
        width: '100%',
        fontSize: '12px',
        lineHeight: '16px',
        color: '#FF4733',
        fontStyle: 'normal',
        fontWeight: 'normal',
    },
}));

interface Props {
    label: any;
    index: any;
    onEdit: any;
    error: boolean;
    onDelete: any;
}

export const Selector: React.FC<Props> = ({ label, error, onEdit, onDelete, index }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <Grid className={classes.selector} spacing={isViewXS ? 2 : 3} container>
            <Grid item className={classes.firstGrid}>
                <Box
                    className={cx(classes.infoField, { [classes.errorField]: error })}
                    onClick={onEdit}
                >
                    <Box className={classes.inner}>
                        <div className={classes.iconNumber}>
                            <span className={classes.number}>{index + 1}</span>
                        </div>
                        <Typography className={classes.label}>{label}</Typography>
                    </Box>
                </Box>
                {error && (
                    <div className={classes.error}>Не заполнены обязательные поля.</div>
                )}
            </Grid>
            <Grid item>
                <Button onClick={onEdit} className={classes.btn}>
                    {isViewXS ? <Icon name="editPancil" /> : 'Редактировать'}
                </Button>
            </Grid>
            <Grid item>
                <Button variant="outlined" onClick={onDelete} className={classes.btn}>
                    {isViewXS ? <Icon name="crossDel" /> : 'Удалить'}
                </Button>
            </Grid>
        </Grid>
    );
};
