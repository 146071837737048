import React from 'react';

import { NumberFormatCustom } from '..//NumberFormatCustom';

import TextField, { TextFieldProps } from '../TextField';

export const AmountField: React.FC<TextFieldProps> = props => {
    return (
        <TextField
            {...props}
            InputProps={{
                inputComponent: NumberFormatCustom as any,
                ...props.InputProps,
            }}
        />
    );
};
