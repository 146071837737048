import React, { FC, useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { canCardOrder } from 'utils/utils';
import { getCardOrders } from 'store/slices/cardOrder';
import { useTranslation } from 'react-i18next';

import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    makeStyles,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import { MainLayout } from 'components/layouts';
import {
    CardOrderFieldCell,
    CardOrderGroupCell,
    CardOrderGroupHeader,
    CardOrderGroupsField,
    cardOrderPersonalFields,
    cardOrderPlaceFields,
    CardOrderSubmitField,
    CARD_ORDER_ACTUAL_ADDRESS_CATEGORY,
    CARD_ORDER_CODE_WORD_CATEGORY,
    CARD_ORDER_DELIVERY_CATEGORY,
    CARD_ORDER_PASSPORT_CATEGORY,
    CARD_ORDER_REGISTATION_ADDRESS_CATEGORY,
} from 'components/organisms';
import {
    AutocompleteFormField,
    Checkbox,
    CheckboxFormField,
    ErrorMessage,
    Loading,
} from 'components/atoms';
import { getBranches, getLocalities, setSelectedCity } from 'store/slices/atm';
import { getDraftFields, sendCardOrder } from 'store/slices/cardOrder';
import { RootState } from 'store/store';
import { DataAsOptions, LocalityModel } from 'types/Atm';
import {
    CardOrderDraftFields,
    CardOrderSubmitFields,
    ChannelType,
} from 'types/CardOrder';
import { transformDateToServerString, unmaskedPhoneNumber } from 'utils/utils';
import { PageHeader } from 'components/molecules';

const useStyles = makeStyles(theme => ({
    dialogButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        marginTop: 56,
        marginRight: 16,
    },
    submitButton: {
        marginTop: 16,
    },
    stepper: {
        backgroundColor: 'transparent',
        padding: 0,
    },
    stepLabel: {
        '& .MuiStepLabel-label, & .MuiStepLabel-active, & .MuiStepLabel-completed': {
            [theme.breakpoints.down('xs')]: {
                fontWeight: 500,
                fontSize: 18,
                lineHeight: 1.4,
            },
            fontWeight: 'bold',
            color: theme.palette.grey[900],
            fontSize: 24,
            lineHeight: 1.33,
        },
    },
    stepContent: {
        marginTop: 16,
    },
    stepDecription: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            lineHeight: 1.4,
        },
        fontSize: 18,
        lineHeight: 1.56,
        color: theme.palette.grey[700],
    },
    checkboxFormField: {
        paddingTop: 0,
    },
    checkboxFormControl: {
        alignItems: 'flex-start',
    },
    groupHeader: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 44,
        },
        marginTop: 56,
    },
    fieldCell: {
        marginTop: 44,
    },
    fieldCell2: {
        [theme.breakpoints.down('xs')]: {
            marginTop: '16px !important',
        },
        marginTop: '24px !important',
    },
    fieldCell3: {
        marginBottom: '40px !important',
    },
    cardOrderGroupCell: {
        '& .field': {
            width: 360,
        },
    },
    cardOrderGroupsField: {
        '& .field': {
            width: 360,
        },
    },
    link: {
        color: theme.palette.primary.main,
    },
    error: {
        [theme.breakpoints.down('xs')]: {
            fontWeight: 'normal',
            fontSize: 13,
            lineHeight: 1.4,
        },
        fontWeight: 'normal',
        marginBottom: 0,
        marginTop: 0,
    },
}));

interface Props {
    getCities: () => void;
    setSelectedCity: (data: LocalityModel) => void;
    citiesAsOptions?: DataAsOptions[];
    selectedCity?: string;
    citiesIsLoading?: boolean;
    citiesError?: string;
    fetchBranches: (city: string) => void;
    branchesAsOptions?: DataAsOptions[];
    cardOrderError?: string;
    cardOrderSuccess?: boolean;
    cardOrderIsLoading?: boolean;
    newCardOrder: (formData: CardOrderSubmitFields, cb?: Function) => void;
    cardOrderDefaultValues?: CardOrderDraftFields;
}

export const CardOrder: FC<Props> = ({
    citiesAsOptions,
    branchesAsOptions,
    selectedCity,
    cardOrderDefaultValues,
    fetchBranches,
    getCities,
    citiesIsLoading,
    citiesError,
    setSelectedCity,
    newCardOrder,
    cardOrderError,
    cardOrderSuccess,
    cardOrderIsLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [activeStep, setActiveStep] = useState(0);
    const [submitData, setSubmitData] = useState(null);
    const [
        liveEqualRegistrationAddress,
        setLiveEqualRegistrationAddress,
    ] = React.useState(false);
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const history = useHistory();
    const {
        handleSubmit,
        register,
        errors,
        control,
        unregister,
        setValue,
        watch,
        getValues,
    } = useForm<any>({
        defaultValues: cardOrderDefaultValues,
    });

    const formProps = {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        defaultValues: cardOrderDefaultValues,
    };

    const watchCity = watch(cardOrderPlaceFields.city.name);

    useEffect(() => {
        // грузим города
        if (!citiesAsOptions || !citiesAsOptions.length) {
            getCities();
        }
    }, []);

    useEffect(() => {
        if (selectedCity) {
            fetchBranches(selectedCity);
        }
    }, [selectedCity]);

    useEffect(() => {
        if (cardOrderSuccess) {
            history.push('/cardOrder/success');
        }
    }, [cardOrderSuccess]);

    const onSubmit = formData => {
        let tempSubmitData = {
            ...submitData,
            ...formData,
        };
        // Шаг Контакты
        if (activeStep === 0) {
            handleNext();
        }
        // Шаг О заявителе
        if (activeStep === 1) {
            handleNext();
        }
        // Шаг Выдача карты
        if (activeStep === 2) {
            const data = {
                ...tempSubmitData,
                phone: unmaskedPhoneNumber(tempSubmitData.phone),
                channelTypeId: ChannelType.ZabotaWeb,
            };
            delete data[cardOrderPlaceFields.terms.name];
            delete data[cardOrderPlaceFields.city.name];
            if (liveEqualRegistrationAddress) {
                data.liveAddress = {
                    ...data.registrationAddress,
                };
            }
            newCardOrder(data, handleNext);
        }
        setSubmitData(prevFieldsData => ({
            ...prevFieldsData,
            ...tempSubmitData,
        }));
    };

    const handleChangeLiveAddress = (): void => {
        setLiveEqualRegistrationAddress(!liveEqualRegistrationAddress);
    };

    const handleCityChange = (event, option): void => {
        // изменение города
        setSelectedCity({ name: option?.value || null });
    };

    const handleNext = (): void => {
        setActiveStep(prevActiveStep => prevActiveStep + 1);
    };

    const handleBack = (): void => {
        setActiveStep(prevActiveStep => prevActiveStep - 1);
    };

    const handleReset = (): void => {
        setActiveStep(0);
    };

    return (
        <Stepper
            orientation="vertical"
            activeStep={activeStep}
            className={classes.stepper}
        >
            <Step key="contacts">
                <StepLabel className={classes.stepLabel}>Контакты</StepLabel>
                <StepContent className={classes.stepContent}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <Typography className={classes.stepDecription}>
                            {t('pages.CardOrder.toIssueMIRCardFillInformation')}
                        </Typography>
                        <CardOrderGroupCell>
                            {map(cardOrderPersonalFields, field => (
                                <CardOrderSubmitField
                                    field={field}
                                    formProps={formProps}
                                    key={field.name}
                                />
                            ))}
                        </CardOrderGroupCell>

                        <Button
                            variant="contained"
                            className={classes.dialogButton}
                            type="submit"
                        >
                            {t('actions.dalee')}
                        </Button>
                        <Button
                            variant="outlined"
                            className={classes.dialogButton}
                            component="a"
                            href="https://www.akbars.ru/individuals/cards/docs-rates/"
                            target="_blank"
                        >
                            {t('common.tariffs')}
                        </Button>
                    </form>
                </StepContent>
            </Step>
            <Step key="personalData">
                <StepLabel className={classes.stepLabel}>О заявителе</StepLabel>
                <StepContent className={classes.stepContent}>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardOrderGroupsField
                            groupName={CARD_ORDER_PASSPORT_CATEGORY.groupName}
                            groupId={CARD_ORDER_PASSPORT_CATEGORY.groupId}
                            formProps={formProps}
                            fields={CARD_ORDER_PASSPORT_CATEGORY.fields}
                        />
                        <CardOrderGroupsField
                            groupName={CARD_ORDER_REGISTATION_ADDRESS_CATEGORY.groupName}
                            groupId={CARD_ORDER_REGISTATION_ADDRESS_CATEGORY.groupId}
                            formProps={formProps}
                            fields={CARD_ORDER_REGISTATION_ADDRESS_CATEGORY.fields}
                            className={classes.cardOrderGroupsField}
                        />
                        <CardOrderGroupHeader
                            className={classes.groupHeader}
                            groupName={CARD_ORDER_ACTUAL_ADDRESS_CATEGORY.groupName}
                        />
                        <Box>
                            {/* Адрес фактического проживания совпадает с адресом регистрации */}
                            <FormControlLabel
                                control={
                                    <Checkbox
                                        value={liveEqualRegistrationAddress}
                                        checked={liveEqualRegistrationAddress}
                                        onChange={handleChangeLiveAddress}
                                        color="primary"
                                        inputProps={{
                                            'aria-label': t(
                                                'pages.CardOrder.matchesTheRegistrationAddress'
                                            ),
                                        }}
                                    />
                                }
                                labelPlacement="end"
                                label={
                                    <Typography variant="body1" component="p">
                                        {t(
                                            'pages.CardOrder.matchesTheRegistrationAddress'
                                        )}
                                    </Typography>
                                }
                            />
                        </Box>

                        {!liveEqualRegistrationAddress && (
                            <CardOrderGroupCell className={classes.cardOrderGroupCell}>
                                {map(CARD_ORDER_ACTUAL_ADDRESS_CATEGORY.fields, field => (
                                    <CardOrderSubmitField
                                        field={field}
                                        formProps={formProps}
                                        key={field.name}
                                    />
                                ))}
                            </CardOrderGroupCell>
                        )}

                        <Button
                            variant="contained"
                            className={classes.dialogButton}
                            type="submit"
                        >
                            {t('actions.dalee')}
                        </Button>
                    </form>
                </StepContent>
            </Step>
            <Step key="cardOrderPlace">
                <StepLabel className={classes.stepLabel}>
                    {t('pages.CardOrder.delivery')}
                </StepLabel>
                <StepContent>
                    <form onSubmit={handleSubmit(onSubmit)}>
                        <CardOrderGroupHeader
                            className={classes.groupHeader}
                            groupName={CARD_ORDER_CODE_WORD_CATEGORY.groupName}
                        />
                        <CardOrderFieldCell>
                            <CardOrderSubmitField
                                field={cardOrderPlaceFields.codeWord}
                                formProps={formProps}
                                key={cardOrderPlaceFields.codeWord.name}
                            />
                        </CardOrderFieldCell>

                        <CardOrderGroupHeader
                            className={classes.groupHeader}
                            groupName={CARD_ORDER_DELIVERY_CATEGORY.groupName}
                        />
                        <CardOrderFieldCell className={classes.fieldCell2}>
                            <AutocompleteFormField
                                {...formProps}
                                name={cardOrderPlaceFields.city.name}
                                label={t(cardOrderPlaceFields.city.label)}
                                validationRules={
                                    cardOrderPlaceFields.city.validationRules
                                }
                                // {...cardOrderPlaceFields.deliveryBranchOfBank}
                                defaultValue={null}
                                variant="outlined"
                                getOptionLabel={option => option.name || ''}
                                options={citiesAsOptions}
                                onChange={handleCityChange}
                                size={isViewXS ? 'small' : 'medium'}
                            />
                        </CardOrderFieldCell>

                        {!!watchCity && (
                            <CardOrderFieldCell>
                                <AutocompleteFormField
                                    {...formProps}
                                    name={cardOrderPlaceFields.deliveryBranchOfBank.name}
                                    label={t(
                                        cardOrderPlaceFields.deliveryBranchOfBank.label
                                    )}
                                    validationRules={
                                        cardOrderPlaceFields.deliveryBranchOfBank
                                            .validationRules
                                    }
                                    // {...cardOrderPlaceFields.deliveryBranchOfBank}
                                    loading={citiesIsLoading}
                                    defaultValue={null}
                                    variant="outlined"
                                    getOptionLabel={option => option.name || ''}
                                    options={branchesAsOptions}
                                    size={isViewXS ? 'small' : 'medium'}
                                />
                            </CardOrderFieldCell>
                        )}

                        <CardOrderFieldCell className={classes.fieldCell}>
                            <CheckboxFormField
                                {...formProps}
                                control={control}
                                className={classes.checkboxFormField}
                                formControlLabelProps={{
                                    className: classes.checkboxFormControl,
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                name={cardOrderPlaceFields.terms.name}
                                label={t(cardOrderPlaceFields.terms.label)}
                                validationRules={
                                    cardOrderPlaceFields.terms.validationRules
                                }
                                color="primary"
                                inputProps={{
                                    'aria-label': t(cardOrderPlaceFields.terms.label),
                                }}
                            />
                        </CardOrderFieldCell>
                        <CardOrderFieldCell className={classes.fieldCell3}>
                            <CheckboxFormField
                                {...formProps}
                                control={control}
                                className={classes.checkboxFormField}
                                formControlLabelProps={{
                                    className: classes.checkboxFormControl,
                                }}
                                formControlProps={{
                                    fullWidth: true,
                                }}
                                name={cardOrderPlaceFields.cardConditions.name}
                                label={
                                    <>
                                        {t('pages.CardOrder.imAgree')}{' '}
                                        <a
                                            href={`${process.env.PUBLIC_URL}/docs/Conditions_for_ordering_card_in_Zabota.pdf`}
                                            className={classes.link}
                                            target="_blank"
                                        >
                                            {t('pages.CardOrder.conditionsForSubmitting')}
                                        </a>
                                    </>
                                }
                                validationRules={
                                    cardOrderPlaceFields.cardConditions.validationRules
                                }
                                color="primary"
                                inputProps={{
                                    'aria-label': t(
                                        cardOrderPlaceFields.cardConditions.label
                                    ),
                                }}
                            />
                        </CardOrderFieldCell>

                        {!!(
                            get(
                                errors,
                                cardOrderPlaceFields.cardConditions.name,
                                false
                            ) || get(errors, cardOrderPlaceFields.terms.name, false)
                        ) && (
                            <ErrorMessage
                                className={classes.error}
                                error={t('pages.CardOrder.youMustAccept')}
                            />
                        )}
                        {!!cardOrderError && (
                            <ErrorMessage
                                className={classes.error}
                                error={cardOrderError}
                            />
                        )}
                        <Button
                            disabled={cardOrderIsLoading}
                            startIcon={
                                cardOrderIsLoading && <CircularProgress size={24} />
                            }
                            variant="contained"
                            className={classNames(
                                classes.dialogButton,
                                classes.submitButton
                            )}
                            type="submit"
                        >
                            {t('pages.CardOrder.sendToBank')}
                        </Button>
                    </form>
                </StepContent>
            </Step>
        </Stepper>
    );
};
