import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_FAQS_CATEGORIES_REQUESTING,
    GET_FAQS_CATEGORIES_SUCCEED,
    GET_FAQS_CATEGORIES_FAILED,
    SET_FITER_CATEGORY_IDS,
} from '../../constants';

export const getFaqCategories = () => (dispatch, getState) => {
    const dispatchData = (actionType, faqs, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                faqs: faqs,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(GET_FAQS_CATEGORIES_REQUESTING, null, null, null);

    axios({
        method: 'GET',
        url: '/api/Faq/category',
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const error = response.data?.error;

            if (isSuccess === true) {
                dispatchData(
                    GET_FAQS_CATEGORIES_SUCCEED,
                    response.data?.result,
                    requestStatus,
                    error
                );
            }
            if (isSuccess === false) {
                dispatchData(GET_FAQS_CATEGORIES_FAILED, null, requestStatus, error);
            }
        })
        .catch(response => {
            dispatchData(GET_FAQS_CATEGORIES_FAILED, null, null, response?.data?.error);
        });
};

export const setFilterCategoryIds = ids => (dispatch, getState) => {
    dispatch({
        type: SET_FITER_CATEGORY_IDS,
        payload: { ids },
    });
};
