import React, { useEffect } from 'react';
import { makeStyles } from '@material-ui/core';
import { Container as MaterialUiContainer } from '@material-ui/core';
import { IconButton } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { MessageType } from 'types/Messages.type';

interface Props {
    data: MessageType;
}

const useStyles = makeStyles(theme => ({
    root: {
        [theme.breakpoints.down('sm')]: {
            padding: '20px',
            fontSize: '11px',
        },
        bottom: 0,
        width: '100%',
        background: '#878B97',
        color: 'white',
        padding: '24px',
        cursor: 'pointer',
        fontSize: '16px',
    },
    closeBtn: {
        color: 'white',
        padding: 0,
    },
    span: {
        fontStyle: 'normal',
        fontWeight: 500,
        fontSize: '16px',
        lineHeight: '140%',
    },
    container: {
        display: 'flex',
        justifyContent: 'space-between',
    },
}));

export const Message: React.FC<Props> = ({ data }) => {
    const classes = useStyles();
    const [mess, setMess] = React.useState(true);

    let storageMess = localStorage.getItem('message_' + data.messageType);

    const handleClick = () => {
        localStorage.setItem('message_' + data.messageType, 'true');
        setMess(false);
    };

    if (!mess || storageMess !== null) return null;
    return (
        <div className={classes.root} onClick={handleClick}>
            <MaterialUiContainer className={`OfferMess ${classes.container}`}>
                <span className={classes.span}>{data.text}</span>
                <IconButton
                    className={`OfferMess__close-btn ${classes.closeBtn}`}
                    edge="end"
                >
                    <CloseIcon />
                </IconButton>
            </MaterialUiContainer>
        </div>
    );
};
