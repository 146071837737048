import React from 'react';

import { Box, CircularProgress } from '@material-ui/core';

export const LoadingElement: React.ReactElement = (
    <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        minHeight="inherit"
    >
        <CircularProgress size={40} color="primary" />
    </Box>
);

export const Loading: React.FC = () => (
    <Box
        display="flex"
        width="100%"
        height="100%"
        justifyContent="center"
        alignItems="center"
        minHeight="inherit"
    >
        <CircularProgress size={40} />
    </Box>
);
