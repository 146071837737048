import map from 'lodash/map';
import { ConfigurationSubmitFields } from 'types/FormField';
import { getMaskByType } from 'utils/utils';

export const cardOrderPersonalFields: ConfigurationSubmitFields = {
    lastName: {
        component: 'textfield',
        label: 'fields.lastName',
        mask: '',
        type: 'text',
        name: 'lastName',
        // readOnly: true,
        validationRules: { required: true },
    },
    firstName: {
        component: 'textfield',
        label: 'fields.firstName',
        mask: '',
        type: 'text',
        name: 'firstName',
        // readOnly: true,
        validationRules: { required: true },
    },
    middleName: {
        component: 'textfield',
        label: 'fields.middleName',
        mask: '',
        type: 'text',
        name: 'middleName',
        // readOnly: true,
        validationRules: { required: true },
    },
    phone: {
        component: 'textfield',
        label: 'fields.phone',
        mask: getMaskByType('mobilePhone'),
        type: 'text',
        name: 'phone',
        validationRules: { required: true },
    },
};

export const cardOrderPassportFields: ConfigurationSubmitFields = {
    passportSeries: {
        component: 'textfield',
        label: 'fields.passportSeries',
        type: '',
        // readOnly: true,
        name: 'passportSeries',
        mask: getMaskByType('passportSeries'),
        placeholder: '0000',
        validationRules: { required: true },
    },
    passportNumber: {
        component: 'textfield',
        label: 'fields.passportNumber',
        type: '',
        // readOnly: true,
        name: 'passportNumber',
        mask: getMaskByType('passportNumber'),
        placeholder: '000000',
        validationRules: { required: true },
    },
    passportDate: {
        component: 'datepicker',
        label: 'fields.passportDate',
        mask: '',
        type: 'date',
        name: 'passportDate',
        // readOnly: true,
        validationRules: { required: true },
    },
    passportIssuedBy: {
        component: 'textfield',
        label: 'fields.passportIssuedBy',
        mask: '',
        type: '',
        name: 'passportIssuedBy',
        // readOnly: true,
        validationRules: { required: true },
    },
    passportDepartmentCode: {
        component: 'textfield',
        label: 'fields.passportDepartmentCode',
        type: '',
        // readOnly: true,
        mask: getMaskByType('passportCode'),
        placeholder: '000-000',
        name: 'passportDepartmentCode',
        validationRules: { required: true },
    },
    birthPlace: {
        component: 'textfield',
        label: 'fields.birthPlace',
        type: '',
        // readOnly: true,
        mask: '',
        placeholder: '',
        name: 'birthPlace',
        validationRules: { required: true },
    },
    birthdayDate: {
        component: 'datepicker',
        label: 'fields.birthdayDate',
        type: '',
        // readOnly: true,
        mask: '',
        placeholder: '',
        name: 'birthdayDate',
        validationRules: { required: true },
    },
};

export const cardOrderRegistrationAddressFields: ConfigurationSubmitFields = {
    registrationAddress: {
        component: 'address',
        label: 'fields.registrationAddress',
        mask: '',
        type: 'text',
        name: 'registrationAddress',
        validationRules: { required: true },
    },
};

export const cardOrderLiveAddressFields: ConfigurationSubmitFields = {
    liveAddress: {
        component: 'address',
        label: 'fields.liveAddress',
        mask: '',
        type: 'text',
        name: 'liveAddress',
        validationRules: { required: true },
    },
};

export const cardOrderPlaceFields: ConfigurationSubmitFields = {
    codeWord: {
        component: 'textfield',
        label: 'fields.codeWord',
        mask: '',
        type: 'text',
        name: 'codeWord',
        validationRules: { required: true },
    },
    city: {
        component: 'select',
        label: 'fields.city',
        mask: '',
        // type: 'text',
        name: 'city',
        validationRules: { required: true },
    },
    deliveryBranchOfBank: {
        component: 'autoComplete',
        label: 'fields.deliveryBranchOfBank',
        mask: '',
        name: 'deliveryBranchOfBank',
        validationRules: { required: true },
    },
    terms: {
        component: 'checkbox',
        label: 'fields.terms',
        mask: '',
        name: 'terms',
        validationRules: { required: true },
    },
    cardConditions: {
        component: 'checkbox',
        label: 'fields.cardConditions',
        mask: '',
        name: 'cardConditions',
        validationRules: { required: true },
    },
};
export const CARD_ORDER_PASSPORT_CATEGORY = {
    groupName: 'groups.passport',
    groupId: 'passport',
    fields: map(cardOrderPassportFields, field => field),
};

export const CARD_ORDER_REGISTATION_ADDRESS_CATEGORY = {
    groupName: 'groups.registationAddress',
    groupId: 'registationAddress',
    fields: map(cardOrderRegistrationAddressFields, field => field),
};

export const CARD_ORDER_ACTUAL_ADDRESS_CATEGORY = {
    groupName: 'groups.liveAddress',
    groupId: 'liveAddress',
    fields: map(cardOrderLiveAddressFields, field => field),
};

export const CARD_ORDER_CODE_WORD_CATEGORY = {
    groupName: 'groups.codeWord',
    groupId: 'codeWord',
    fields: [],
};

export const CARD_ORDER_DELIVERY_CATEGORY = {
    groupName: 'groups.delivery',
    groupId: 'delivery',
    fields: [],
};
