import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    GET_CALCULATE_RESULT_REQUESTING,
    GET_CALCULATE_RESULT_FAILED,
    GET_CALCULATE_RESULT_SUCCEED,
} from '../../constants';

export const getCalculateResult = (id: string, formData: any) => (dispatch, getState) => {
    const dispatchData = (actionType, calculateResult, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                calculateResult,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(GET_CALCULATE_RESULT_REQUESTING, null, null, null);

    axios
        .post(
            `/api/Calculation/result/${id}`,
            {
                result: formData,
            },
            {
                headers: getCommonHeaders(getState()),
            }
        )
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;

            if (isSuccess === true) {
                dispatchData(
                    GET_CALCULATE_RESULT_SUCCEED,
                    response.data?.result,
                    requestStatus,
                    null
                );
            }
            if (isSuccess === false) {
                const error = errorHandle(response.data);
                dispatchData(GET_CALCULATE_RESULT_FAILED, null, requestStatus, error);
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);

            dispatchData(GET_CALCULATE_RESULT_FAILED, null, null, error.error);
        });
};
