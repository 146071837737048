import React from 'react';
import { Controller } from 'react-hook-form';

import { FormControl, InputLabel } from '@abdt/ornament';
import { FormControlProps, FormHelperText, makeStyles, Theme } from '@material-ui/core';

import { FormFieldProps, SubmitField } from 'types/FormField';

import Select, { SelectProps } from './Select';

const useStyles = makeStyles((theme: Theme) => ({
    selectPaper: {
        borderRadius: '4px',
        maxHeight: 300,
        '& li': {
            padding: '12px 16px',
            fontSize: '16px',
        },
    },
}));

type Props = {
    formControlProps: FormControlProps;
} & FormFieldProps &
    SubmitField &
    SelectProps;

const EmptyEl: React.FC = () => null;

export const SelectFormField: React.FC<Props> = React.memo(props => {
    const {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        defaultValue,
        disabled,
        error,
        fullWidth,
        required,
        variant,
        label,
        readOnly,
        helperText,
        formControlProps,
        defaultValues,
        ...other
    } = props;
    const classes = useStyles();
    const getVerticalValue = () => {
        if (variant === 'outlined') {
            if (formControlProps.size === 'small') {
                return 48;
            }
            return 65;
        }
        return undefined;
    };

    return (
        <Controller
            defaultValue={defaultValue}
            control={control}
            name={name}
            rules={validationRules}
            render={({ value, onChange }) => (
                <FormControl
                    {...formControlProps}
                    disabled={disabled}
                    error={error}
                    fullWidth={fullWidth}
                    variant={variant}
                    required={required}
                >
                    <InputLabel>{label}</InputLabel>
                    <Select
                        {...other}
                        value={value}
                        onChange={onChange}
                        readOnly={readOnly}
                        MenuProps={{
                            getContentAnchorEl: null,
                            anchorOrigin: {
                                vertical: getVerticalValue(),
                                horizontal: 'left',
                            },
                            classes: {
                                paper: classes.selectPaper,
                            },
                        }}
                        IconComponent={readOnly ? EmptyEl : undefined}
                    />
                    <FormHelperText>{helperText}</FormHelperText>
                </FormControl>
            )}
        />
    );
});
