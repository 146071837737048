import React, { FC, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import classNames from 'classnames';
import {
    Grid,
    Typography,
    Box,
    Avatar,
    Collapse,
    IconButton,
    useTheme,
    useMediaQuery,
} from '@material-ui/core';
import ExpandMoreIcon from '@material-ui/icons/ExpandMore';

import { Icon } from 'components/atoms';
import { MainLayout } from 'components/layouts';
import { useDispatch, useSelector } from 'react-redux';
import { getSubsidy, resetSubsidy } from 'store/actions';
import { RootState } from 'store/store';
import { useCollapse } from 'hooks/useCollapse';
import { useHistory, useParams } from 'react-router-dom';
import { useSubsidyCalculatorDrawer } from 'hooks/useSubsidyCalculatorDrawer';
import { PageHeader } from 'components/molecules';
import { Loading } from 'components/atoms';
import { useStyles } from './Subsidy.styles';
import { SubsidyAmount } from './SubsidyAmount';
import { SubsidyGranted } from './SubsidyGranted';
import MakeApplication from './MakeApplication';
import { allowance_request_18_05_2021 } from 'store/features.constants';
import { FeatureToggle } from 'components/common/FeatureToggle';

interface SubsidyProps {
    // id?: string;
}

export const Subsidy: FC<SubsidyProps> = () => {
    const params: { id?: string } = useParams();
    const history = useHistory();
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const dispatch = useDispatch();
    const isAuthorized = !!useSelector(
        (state: RootState) => state.account.signInEsiaConfirm.token
    );
    const currentSubsidyState = useSelector(
        (state: RootState) => state.subsidies.currentSubsidy
    );

    const { initCalculateSubsidy } = useSubsidyCalculatorDrawer();

    const handleClickCalculator = () => {
        initCalculateSubsidy(params.id);
    };

    useEffect(() => {
        if (params.id) {
            dispatch(getSubsidy(params.id));
        }
    }, [params.id, dispatch]);
    useEffect(() => {
        window.scrollTo(0, 0);
        return () => {
            dispatch(resetSubsidy());
        };
    }, []);

    const userCollapse = useCollapse();
    const documnetCollapse = useCollapse();
    const whoIsGranted = currentSubsidyState.subsidy?.whoIsGranted.split('●');
    const whoIsNotGranted = currentSubsidyState.subsidy?.whoIsNotGranted?.split('●');
    const actionLink = currentSubsidyState.subsidy?.actionLink;
    const isLoading = currentSubsidyState.request.isLoading;

    if (isLoading) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={
                    <PageHeader
                        title={currentSubsidyState.subsidy?.description}
                        hasBackLink={!isViewXS}
                    />
                }
            >
                <Grid container className={classes.grid}>
                    <Loading />
                </Grid>
            </MainLayout>
        );
    }

    return (
        <MainLayout
            contentClassName={classes.root}
            pageHeader={
                <PageHeader
                    title={currentSubsidyState.subsidy?.description}
                    hasBackLink={!isViewXS}
                />
            }
        >
            <Grid container className={classes.grid}>
                <Typography className={classes.subsidyHint}>
                    <span style={{ color: theme.palette.grey[600] }}>
                        {t('pages.SubsidyDetail.periodicity')}:{' '}
                    </span>
                    {currentSubsidyState.subsidy?.periodicity}
                </Typography>
                <Typography className={classes.subsidyHint}>
                    <span style={{ color: theme.palette.grey[600] }}>
                        {t('pages.SubsidyDetail.methodOfAddress')}:
                    </span>{' '}
                    {currentSubsidyState.subsidy?.isOnline
                        ? t('pages.SubsidyDetail.onlineAndPersonal')
                        : t('pages.SubsidyDetail.personal')}
                </Typography>

                <Grid container spacing={3} className={classes.sybsidyButtonContainer}>
                    {/*Сообщение в неавторизованной зоне*/}
                    {currentSubsidyState.subsidy?.canOrderManually && !isAuthorized && (
                        <FeatureToggle name={allowance_request_18_05_2021}>
                            <Grid item xs={12}>
                                <Typography
                                    variant="body1"
                                    className={classes.nonAuthorizedInfo}
                                >
                                    Для получения субсидии необходимо авторизоваться
                                </Typography>
                            </Grid>
                        </FeatureToggle>
                    )}
                    {/*Кнопка "Оформить заявление"*/}
                    {currentSubsidyState.subsidy?.canOrderManually && (
                        <FeatureToggle name={allowance_request_18_05_2021}>
                            <MakeApplication
                                subsidyId={params.id}
                                actionLink={actionLink}
                            />
                        </FeatureToggle>
                    )}
                    <Grid item xs={12} sm={'auto'}>
                        {/*Кнопка "Рассчитать сумму"*/}
                        <SubsidyAmount
                            subsidy={currentSubsidyState.subsidy}
                            handleClickCalculator={handleClickCalculator}
                        />
                    </Grid>
                </Grid>
                <Grid item className={classes.subsidyInformationContainer}>
                    <Box className={classes.subsidyInformation}>
                        <Avatar
                            variant="square"
                            className={classes.subsidyInformationImage}
                        >
                            <Icon name="calendar" />
                        </Avatar>
                        <Typography variant="body1">
                            {currentSubsidyState.subsidy?.termDescription ||
                                t('common.noData')}
                        </Typography>
                    </Box>
                    <Box className={classes.subsidyInformation}>
                        <Avatar
                            variant="square"
                            className={classes.subsidyInformationImage}
                        >
                            <Icon name="bank" />
                        </Avatar>
                        <Typography variant="body1">
                            {currentSubsidyState.subsidy?.placeGet || t('common.noData')}
                        </Typography>
                    </Box>
                </Grid>
                <Grid item className={classes.subsidyGrantedContainer}>
                    <Typography className={classes.subsidyGrantedTitle}>
                        {currentSubsidyState.subsidy?.isOnline
                            ? t('pages.SubsidyDetail.whoCanElectronicForm')
                            : t('pages.SubsidyDetail.whoCanReceive')}
                    </Typography>
                    <SubsidyGranted list={whoIsGranted} />
                </Grid>
                {currentSubsidyState.subsidy?.whoIsNotGranted ? (
                    <Grid item className={classes.subsidyGrantedContainer}>
                        <Box>
                            <Typography className={classes.subsidyGrantedTitle}>
                                {t('pages.SubsidyDetail.whoDontElectronicForm')}
                            </Typography>
                            <Typography
                                component={'span'}
                                className={classes.subsidyGranted}
                                variant="body1"
                            >
                                <SubsidyGranted list={whoIsNotGranted} />
                            </Typography>
                        </Box>
                    </Grid>
                ) : null}
                <Grid container className={classes.collapseContainers}>
                    <Grid item className={classes.collapseContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={documnetCollapse.handleExpandClick}
                            className={classNames(classes.collapse, {
                                [classes.collapseOpen]: documnetCollapse.expanded,
                            })}
                        >
                            <Avatar variant="rounded" className={classes.collapseImage}>
                                <Icon
                                    name={
                                        isViewXS
                                            ? 'subsidyDetailDocument1'
                                            : 'subsidyDetailDocument'
                                    }
                                />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography>{t('common.docs')}</Typography>
                            </Box>
                            <IconButton
                                className={classNames(classes.expand, {
                                    [classes.expandOpen]: documnetCollapse.expanded,
                                })}
                                onClick={documnetCollapse.handleExpandClick}
                                aria-expanded={documnetCollapse.expanded}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                        <Collapse
                            in={documnetCollapse.expanded}
                            className={classes.collapseContent}
                        >
                            <Box padding="20px">
                                <Typography variant="body1" className={classes.input}>
                                    {currentSubsidyState.subsidy?.requiredDocuments ||
                                        t('common.noData')}
                                </Typography>
                            </Box>
                        </Collapse>
                    </Grid>
                    <Grid item className={classes.collapseContainer}>
                        <Box
                            display="flex"
                            alignItems="center"
                            onClick={userCollapse.handleExpandClick}
                            className={classNames(classes.collapse, {
                                [classes.collapseOpen]: userCollapse.expanded,
                            })}
                        >
                            <Avatar variant="rounded" className={classes.collapseImage}>
                                <Icon
                                    name={
                                        isViewXS
                                            ? 'subsidyDetailInfo1'
                                            : 'subsidyDetailInfo'
                                    }
                                />
                            </Avatar>
                            <Box className={classNames(classes.collapseTitleContainer)}>
                                <Typography>{t('common.legislation')}</Typography>
                            </Box>
                            <IconButton
                                className={classNames(classes.expand, {
                                    [classes.expandOpen]: userCollapse.expanded,
                                })}
                                onClick={userCollapse.handleExpandClick}
                                aria-expanded={userCollapse.expanded}
                            >
                                <ExpandMoreIcon />
                            </IconButton>
                        </Box>
                        <Collapse
                            in={userCollapse.expanded}
                            className={classes.collapseContent}
                        >
                            <Box padding="20px">
                                <Typography variant="body1" className={classes.input}>
                                    {currentSubsidyState.subsidy?.legislation ||
                                        t('common.noData')}
                                </Typography>
                            </Box>
                        </Collapse>
                    </Grid>
                </Grid>
            </Grid>
        </MainLayout>
    );
};
