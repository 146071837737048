import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';

export default makeStyles((theme: Theme) =>
    createStyles({
        root: {
            [theme.breakpoints.down('xs')]: {
                width: '100%',
            },
            width: '320px',
            outline: 'none',
            marginTop: 'auto',
            '&.disabled': {
                opacity: 0.5,
                cursor: 'default',
                '& $rootText': {
                    color: `${theme.palette.action.disabled} !important`,
                },
                '& $filesContainer': {
                    borderColor: `${theme.palette.action.disabled} !important`,
                },
                borderColor: `${theme.palette.action.disabled} !important`,
            },
            '&.error:not(:hover):not(.drag-active)': {
                '& $rootText': {
                    color: theme.palette.error.main,
                },
                '& $filesContainer': {
                    borderColor: theme.palette.error.main,
                },
                borderColor: theme.palette.error.main,
            },
        },
    })
);
