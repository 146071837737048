import { GET_FAQS_SUCCEED, GET_FAQS_REQUESTING, GET_FAQS_FAILED } from '../../constants';

const initialState = {
    data: null,
    selectedFaq: {},
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const allFaqs = (state = initialState, action) => {
    switch (action.type) {
        case GET_FAQS_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case GET_FAQS_SUCCEED:
            return {
                ...state,
                data: action.payload.faqs,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_FAQS_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};
