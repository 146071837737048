import { ProfileContactsState } from 'types/Profile';
import {
    GET_CONTACTS_REQUESTING,
    GET_CONTACTS_SUCCEED,
    GET_CONTACTS_FAILED,
    UPDATE_CONTACTS_SUCCEED,
    UPDATE_CONTACTS_FAILED,
    UPDATE_CONTACTS_REQUESTING,
} from '../../constants';

const initialState: ProfileContactsState = {
    contacts: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const contacts = (state = initialState, action) => {
    switch (action.type) {
        case GET_CONTACTS_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case GET_CONTACTS_SUCCEED:
            return {
                ...state,
                contacts: action.payload.contacts,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.payload.errors,
                },
            };
        case GET_CONTACTS_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload.errors,
                },
            };
        case UPDATE_CONTACTS_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case UPDATE_CONTACTS_SUCCEED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.payload.errors,
                },
            };
        case UPDATE_CONTACTS_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload.errors,
                },
            };
        default:
            return state;
    }
};
