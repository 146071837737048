import { CurrentSubsidyState } from 'types/Subsidy';
import {
    GET_SUBSIDY_SUCCEED,
    GET_SUBSIDY_FAILED,
    GET_SUBSIDY_REQUESTING,
    GET_SUBSIDY_RESET,
} from '../../constants';

const initialState: CurrentSubsidyState = {
    subsidy: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const currentSubsidy = (state = initialState, action) => {
    switch (action.type) {
        case GET_SUBSIDY_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case GET_SUBSIDY_SUCCEED:
            return {
                ...state,
                subsidy: action.payload.subsidy,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_SUBSIDY_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case GET_SUBSIDY_RESET:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
