import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldProps, SubmitFieldValidationRules } from 'types/FormField';
import { TextFieldProps } from '../TextField';
import { AmountField } from './AmountField';

type Props = {
    validationRules: SubmitFieldValidationRules;
} & TextFieldProps &
    FormFieldProps;

export const AmountFormField: React.FC<Props> = React.memo(props => {
    const {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        ...other
    } = props;

    const amountRef = useRef<HTMLInputElement>(null);

    return (
        <Controller
            control={control}
            name={name}
            rules={validationRules}
            render={({ onChange, value }) => {
                return (
                    <AmountField
                        onChange={event => {
                            onChange(event.target.value);
                        }}
                        value={value}
                        inputRef={amountRef}
                        {...other}
                    />
                );
            }}
            onFocus={() => amountRef.current?.focus()}
        />
    );
});
