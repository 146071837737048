import { createSlice } from '@reduxjs/toolkit';
import { ServiceDetailDetSad, ChildDetSad } from 'types/Services';

interface State {
    form: {
        data: {
            title: string;
            description: string;
            childList: ChildDetSad[];
        };
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
    result: {
        data: ServiceDetailDetSad;
        error: any;
        errorCode: any;
        loading: boolean;
        success: boolean;
        loaded: boolean;
    };
}

const initialState: State = {
    form: {
        data: null,
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
    result: {
        data: null,
        error: null,
        errorCode: null,
        loading: false,
        success: false,
        loaded: false,
    },
};

//TODO: сделать конструкторы, чтобы уменьшить код
const services = createSlice({
    name: 'services',
    initialState,
    reducers: {
        //Данные для формы
        getServiceChildrenStart(state) {
            state.form.loading = true;
            state.form.error = null;
        },
        getServiceChildrenSuccess(state, { payload }) {
            state.form.loading = false;
            state.form.error = null;
            state.form.success = true;
            state.form.loaded = true;
            state.form.data = payload;
        },
        getServiceChildrenFailure(state, action) {
            state.form.loading = false;
            state.form.loaded = true;
            state.form.error = action.payload.error.error;
            state.form.errorCode = action.payload.errorCode;
        },
        getServiceChildrenClearErrorCode(state) {
            state.form.errorCode = null;
        },
        getServiceChildrenClearAll(state) {
            state.form.errorCode = null;
            state.form.data = null;
            state.form.loading = false;
            state.form.loaded = false;
        },
        //Данные результата, после отправки формы ребенка
        getServiceResultStart(state) {
            state.result.loading = true;
            state.result.error = null;
        },
        getServiceResultSuccess(state, { payload }) {
            state.result.loading = false;
            state.result.error = null;
            state.result.success = true;
            state.result.loaded = true;
            state.result.data = payload;
        },
        getServiceResultFailure(state, action) {
            state.result.loading = false;
            state.result.loaded = true;
            state.result.error = action.payload.error.error;
            state.result.errorCode = action.payload.errorCode;
        },
        getServiceResultClearErrorCode(state) {
            state.result.errorCode = null;
        },
        getServiceResultClearAll(state) {
            state.result.errorCode = null;
            state.result.data = null;
            state.result.loading = false;
            state.result.loaded = false;
        },
    },
});

export const {
    getServiceChildrenStart,
    getServiceChildrenSuccess,
    getServiceChildrenFailure,
    getServiceChildrenClearErrorCode,
    getServiceChildrenClearAll,
    getServiceResultStart,
    getServiceResultSuccess,
    getServiceResultFailure,
    getServiceResultClearErrorCode,
    getServiceResultClearAll,
} = services.actions;

export default services.reducer;
