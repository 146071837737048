import {
    axiosInstanse as axios,
    getAnonimCommonHeaders,
    getCommonHeaders,
} from 'infrastructure/RestClient';
import isArray from 'lodash/isArray';

import {
    startChatBotStart,
    startChatBotSuccess,
    startChatBotFailure,
    getChatHistoryStart,
    getChatHistoryFailure,
    getChatHistorySuccess,
    connectToSignalRStart,
    disconnectSignalRStart,
    clearChatHistorySuccess,
    clearChatHistoryFailure,
    clearChatHistoryStart,
    sendMessageStart,
    newWebSocketMessage,
    transformChatMessageStart,
    transformChatMessageSuccess,
    transformChatMessageFailure,
    sendMessageFailure,
} from './actionCreators';
import { parseMessage, parseMessages } from './chatUtils';

const ERROR = {
    GET_MESSAGES: 'pages.Chat.errorGetMessages',
    GET_CHAT: 'pages.Chat.errorGetChat',
};

export function startChatBot() {
    return (dispatch, getState) => {
        dispatch(startChatBotStart());
        return axios
            .get('/api/Chat/startChatBot', {
                headers: getAnonimCommonHeaders(getState()),
            })
            .then(({ data: { success, error } = {} }) => {
                if (success) {
                    dispatch(startChatBotSuccess());
                } else {
                    dispatch(startChatBotFailure(error));
                }
            })
            .catch(response => {
                dispatch(startChatBotFailure(response?.data?.error || ERROR.GET_CHAT));
            });
    };
}

export function getChatHistory() {
    return (dispatch, getState) => {
        dispatch(getChatHistoryStart());
        return axios
            .get('/api/Chat/history', { headers: getAnonimCommonHeaders(getState()) })
            .then(({ data: { success, result, error } = {} }) => {
                if (success && result) {
                    return Promise.all(parseMessages(result));
                } else {
                    dispatch(getChatHistoryFailure(error));
                }
            })
            .then(messages => {
                if (isArray(messages)) {
                    dispatch(getChatHistorySuccess(messages));
                } else {
                    dispatch(getChatHistoryFailure('actions.error'));
                }
            })
            .catch(response => {
                dispatch(
                    getChatHistoryFailure(response?.data?.error || ERROR.GET_MESSAGES)
                );
            });
    };
}

export function clearChatHistory() {
    return (dispatch, getState) => {
        dispatch(clearChatHistoryStart());
        return axios
            .delete('/api/Chat', { headers: getAnonimCommonHeaders(getState()) })
            .then(response => {
                dispatch(clearChatHistorySuccess());
            })
            .catch(response => {
                dispatch(
                    clearChatHistoryFailure(response?.data?.error || ERROR.GET_MESSAGES)
                );
            });
    };
}

export function connectToSignalR() {
    return (dispatch, getState) => {
        dispatch(connectToSignalRStart());
    };
}

export function disconnectSignalR() {
    return (dispatch, getState) => {
        dispatch(disconnectSignalRStart());
    };
}

export function sendChatMessage(messageObj) {
    return async (dispatch, getState) => {
        // const messages = getState().chat?.messages;
        // const messageId = messages[messages.length - 1]?.id;
        // const messageObj = {
        //     Text: messageText,
        //     SelectedItems: selectedMessages,
        //     MessageId: messageId,
        // };
        try {
            const message = JSON.stringify(messageObj);
            dispatch(sendMessageStart(message, messageObj.Text));
        } catch (error) {
            dispatch(sendMessageFailure(error));
        }
    };
}

export function transformChatMessage(message) {
    return async (dispatch, getState) => {
        dispatch(transformChatMessageStart());
        let tempMessage = message;
        try {
            tempMessage = await parseMessage(tempMessage);
            dispatch(transformChatMessageSuccess());
            dispatch(newWebSocketMessage({ message: tempMessage }));
        } catch (error) {
            dispatch(transformChatMessageFailure(error));
        }
    };
}
