import React from 'react';
import { Controller } from 'react-hook-form';

import { FormFieldProps, SubmitField, SubmitFieldValidationRules } from 'types/FormField';

import AddressAutocomplete, { AddressAutocompleteProps } from './AddressAutocomplete';

export type AddressAutocompleteFormFieldProps = {
    label: string;
    validationRules?: SubmitFieldValidationRules;
    defaultFormValue?: any;
    childId?: string;
    fieldActualId?: string;
    daDataType?: string;
} & FormFieldProps &
    AddressAutocompleteProps &
    Pick<SubmitField, 'transformValueForBackend'>;

const AddressAutocompleteFormField: React.FC<AddressAutocompleteFormFieldProps> = React.memo(
    props => {
        const {
            control,
            errors,
            error,
            register,
            unregister,
            setValue,
            getValues,
            watch,
            name,
            validationRules,
            defaultValue,
            defaultFormValue,
            value,
            childId,
            onChange,
            transformValueForBackend,
            defaultValues,
            fieldActualId,
            daDataType,
            ...other
        } = props;

        return (
            <Controller
                control={control}
                name={name}
                rules={validationRules}
                defaultValue={defaultFormValue}
                render={({ value: RHFValue, onChange: RHFOnChange }) => (
                    <AddressAutocomplete
                        {...other}
                        onChange={(event, data, reason, details) => {
                            if (onChange) {
                                onChange(event, data, reason, details);
                            }
                            //@ts-ignore
                            if (transformValueForBackend) {
                                RHFOnChange(transformValueForBackend(data) || undefined);
                            } else {
                                RHFOnChange(data || undefined);
                            }
                        }}
                        // value={RHFValue}
                        defaultValue={defaultValue}
                        error={Boolean(error)}
                        daDataType={daDataType}
                    />
                )}
            />
        );
    }
);

export default AddressAutocompleteFormField;
