import React, { FC } from 'react';
import cx from 'classnames';

import { makeStyles } from '@material-ui/core';

interface Props {
    error?: string;
    className?: string;
}

const useStyles = makeStyles(theme => ({
    errorMessage: {
        color: theme.palette.error.main,
        marginBottom: 30,
        marginTop: 30,
        width: '100%',
    },
}));

const ErrorMessage: FC<Props> = ({ error, className }) => {
    const classes = useStyles();
    return <div className={cx(classes.errorMessage, className)}>{error}</div>;
};

export default ErrorMessage;
