import React from 'react';

const SelectedMessageContext = React.createContext({
    selectedMessages: {},
    onChangeSelectedMessages: (id, select) => {},
});

export const useSelectedMessages = () => {
    return React.useContext(SelectedMessageContext);
};

export const SelectedMessageProvider = ({ children }) => {
    const [selectedMessages, setselectedMessages] = React.useState({});

    const onChange = (id: string, select: boolean) => {
        setselectedMessages(prev => ({ ...prev, [id]: select }));
    };

    return (
        <SelectedMessageContext.Provider
            value={{
                selectedMessages,
                onChangeSelectedMessages: onChange,
            }}
        >
            {children}
        </SelectedMessageContext.Provider>
    );
};
