import { FileWithPath, FileRejection, DropzoneProps } from 'react-dropzone';

interface DefineFilesByMaxFilesData {
    acceptedFiles: FileWithPath[];
    rejectedFiles: FileRejection[];
}

type DefineErrorByMaxFilesUtil = (
    /**
     * Разрешенные файлы обработанные react-dropzone
     * */
    dropzoneAcceptedFiles: FileWithPath[],
    /**
     * Заруженные файлы обработанные react-dropzone
     * */
    uploadedFilesLength: number,
    /**
     * Максимально допустимое кол-во файлов,
     * */
    maxFiles: DropzoneProps['maxFiles']
) => DefineFilesByMaxFilesData;

/**
 * Проверка разрешенных бибилиотекой файлов с заданным разработчиком макс. кол-вом файлов,
 * дальнейшее распределение на файлы с ошибкой о превышении максимального кол-ва файлов
 * */

// eslint-disable-next-line import/prefer-default-export
export const defineErrorsByMaxFiles: DefineErrorByMaxFilesUtil = (
    dropzoneAcceptedFiles,
    uploadedFilesLength,
    maxFiles
) => {
    const acceptedFiles: FileWithPath[] = [];
    const rejectedFiles: FileRejection[] = [];

    dropzoneAcceptedFiles.forEach((file: FileWithPath, index: number) => {
        if (maxFiles && uploadedFilesLength + index + 1 > maxFiles) {
            const overloadFile = Object.assign(
                {},
                {
                    file,
                    errors: [
                        {
                            message: 'Too many files',
                            code: 'too-many-files',
                        },
                    ],
                }
            ) as FileRejection;

            rejectedFiles.push(overloadFile);
        } else {
            acceptedFiles.push(file);
        }
    });

    return {
        acceptedFiles,
        rejectedFiles,
    };
};
