import React from 'react';
import MaskedInput, { MaskedInputProps } from 'react-text-mask';
import TextField, { TextFieldProps } from './TextField';

type MaskProps = {
    maskProps?: MaskedInputProps;
};

type TextMaskProps = {
    inputRef: (ref: HTMLElement | null) => void;
};

const TextMask: React.FC<TextMaskProps> = ({ inputRef, ...maskOther }) => {
    return (
        <MaskedInput
            ref={(ref: MaskedInput) => {
                inputRef(ref ? ref.inputElement : null);
            }}
            placeholderChar="_"
            {...maskOther}
        />
    );
};

export const MaskedTextField: React.FC<TextFieldProps & MaskProps> = ({
    maskProps,
    inputProps,
    InputProps,
    ...other
}) => {
    return (
        <TextField
            InputProps={
                maskProps
                    ? {
                          ...InputProps,
                          inputComponent: TextMask as React.FC,
                      }
                    : InputProps
            }
            inputProps={
                maskProps
                    ? {
                          ...inputProps,
                          ...maskProps,
                      }
                    : inputProps
            }
            {...other}
        />
    );
};

export default React.memo(MaskedTextField);
