import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { ApiErrorConstruction, ApiResponse } from 'types/ApiResponse';
import { errorHandle } from 'utils/utils';
import { SET_FITER_SUBSIDY_ID, SET_FITER_SUBSIDY_CATEGORY_IDS } from '../../constants';
import { AppThunk } from 'store/store';
import { AxiosResponse } from 'axios';
import {
    getFilterStart,
    getFilterSuccess,
    getFilterFailure,
} from 'store/slices/subsidiesFilter';

export const getFilter = (): AppThunk => async (dispatch, getState) => {
    try {
        dispatch(getFilterStart());
        const res: AxiosResponse = await axios.get('/api/Subsidies/getAllFilters', {
            headers: getCommonHeaders(getState()),
        });

        if (res.data.success) {
            dispatch({
                type: SET_FITER_SUBSIDY_ID,
                payload: res.data.result[0]?.id || 'empty',
            });
            dispatch(getFilterSuccess(res.data.result));
        } else {
            const error = errorHandle(res.data.error);
            dispatch(getFilterFailure(error));
        }
    } catch (rawError) {
        const error = errorHandle(rawError);
        dispatch(getFilterFailure(error));
    }
};

export const setFilterSubsidyId = (id: string) => ({
    type: SET_FITER_SUBSIDY_ID,
    payload: id,
});

export const setFilterSubsidyCategoryIds = ids => (dispatch, getState) => {
    dispatch({
        type: SET_FITER_SUBSIDY_CATEGORY_IDS,
        payload: ids,
    });
};
