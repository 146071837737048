import React, { useState, useEffect } from 'react';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FormControl, FormControlLabel } from '@material-ui/core';
import { ApplicationField } from 'types/Application';
import { useFormContext } from 'react-hook-form';
import Checkbox, { CheckboxProps } from 'components/atoms/Checkbox/Checkbox';
import cloneDeep from 'lodash/cloneDeep';

const useStyles = makeStyles((theme: Theme) => ({
    checkBoxList: {},
    fc: {
        width: '100%',
    },
    checkBox: {
        marginRight: '7px',
    },
}));

const checkValidation = state => {
    let val = 0;
    forEach(state, (item, itemIndex) => {
        forEach(item, (value, valueIndex) => {
            if (value.value) val++;
        });
    });

    return val === 0;
};

export const CheckboxList: React.FC<{
    field: ApplicationField;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, fieldName, isRequired }) => {
    const classes = useStyles();
    const formProps = useFormContext();
    const { watch, errors, register, control } = formProps;
    const [error, setError] = useState(false);

    const [state, setState] = useState(() => {
        let obj = {};
        forEach(field.fieldItems, (item, itemIndex) => {
            const jsonFields = JSON.parse(item.value);
            obj[item.key] = jsonFields.map((valueItem, valueItemIndex) => {
                const value =
                    typeof valueItem.value === 'string'
                        ? valueItem.value.toLowerCase() === 'true'
                        : valueItem.value;
                return { id: valueItem.id, value };
            });
        });
        return obj;
    });

    const onChange = ({ value, keyName, valueIndex, id }) => {
        let cloneState = cloneDeep(state);
        cloneState[keyName][valueIndex].value = value;
        cloneState[keyName][valueIndex].id = id;
        setState(cloneState);
    };

    useEffect(() => {
        control.trigger();
    }, [fieldName, state]);

    if (!isEmpty(state))
        return (
            <Box>
                <input
                    type="hidden"
                    key={`checkBoxList-${fieldName}`}
                    value={JSON.stringify(state)}
                    name={fieldName}
                    ref={register({
                        validate: value => !checkValidation(state),
                    })}
                />
                {field.fieldItems.map((item, itemIndex) => {
                    const jsonFields = JSON.parse(item.value);
                    return jsonFields.map((value, valueIndex) => {
                        const keyName = item.key;
                        // const name = `${fieldName}.${keyName}[${valueIndex}].value`;
                        const checked = state?.[keyName]?.[valueIndex]?.value;
                        return (
                            <FormControl
                                error={error}
                                key={`checkBoxListValue-${itemIndex}-${valueIndex}`}
                                className={classes.fc}
                            >
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={checked}
                                            className={classes.checkBox}
                                            onChange={e =>
                                                onChange({
                                                    value: e.target.checked,
                                                    keyName: keyName,
                                                    valueIndex,
                                                    id: value.id,
                                                })
                                            }
                                        />
                                    }
                                    label={value.title}
                                />
                            </FormControl>
                        );
                    });
                })}
            </Box>
        );

    return null;
};
