import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStylesChatComponent = makeStyles((theme: Theme) => ({
    root: {
        position: 'relative',
    },
    grid: {
        [theme.breakpoints.down('xs')]: {
            width: 'auto',
        },
        position: 'relative',
        // width: 656,
        // margin: '0 auto',
    },
    chatTitleContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'none',
        },
        display: 'flex',
        alignItems: 'center',
        marginTop: 28,
        padding: 20,
        background: theme.palette.background.paper,
        borderRadius: '12px 12px 0px 0px',
    },
    chatTitleAvatar: {
        width: 32,
        height: 32,
        marginRight: 16,
    },
    chatTitle: {
        fontWeight: 'bold',
        fontSize: 24,
        lineHeight: '29px',
        textAlign: 'center',
    },
    chatSubtitle: {
        marginLeft: 'auto',
        fontSize: 14,
        lineHeight: 1.5,
        color: '#878B97',
    },
    chatContainer: {
        [theme.breakpoints.down('xs')]: {
            // контейнер сообщений = высота экрана - шапка сайта(72)
            height: 'calc(100vh - 72px)',
            minHeight: 350,
        },
        flexDirection: 'column',
        overflow: 'hidden',
        // контейнер сообщений = высота экрана - шапка сайта(72) - отступ от шапки(56) - заголовок страницы(31) - отступ от заголовка(32) - футер страницы(104) - отступ от футера(32)
        height: 'calc(100vh - 72px - 56px - 31px - 32px - 104px - 32px)',
        minHeight: 350,
        overflowY: 'scroll',
    },
    messagesContainer: {
        [theme.breakpoints.down('xs')]: {
            padding: '16px 0',
        },
        padding: 16,
        flexShrink: 0,
        display: 'flex',
        minHeight: '100%',
        flexDirection: 'column',
        overflowX: 'hidden',
    },
    messagesContainerWithInput: {
        [theme.breakpoints.down('xs')]: {
            padding: '16px 0 56px',
        },
        padding: '16px 16px 56px',
    },
    chatPage: {
        [theme.breakpoints.down('xs')]: {
            background: 'none',
            border: 'none',
        },
        border: '8px solid #D5EDFF',
        background: '#EEF7FF',
        position: 'relative',
    },
    pageGrid: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 0,
        },
    },
}));

export const useCommonStyles = makeStyles(theme => ({
    checkListMessageChildTag: {
        fontSize: 14,
        lineHeight: 1.43,
        fontWeight: 'normal',
        marginBottom: 2,
    },
    bubble: {
        marginBottom: 20,
    },
    checkboxexMessageContainer: {
        border: `1px solid ${theme.palette.primary.main}`,
        borderRadius: 16,
        padding: '33px 16px',
        marginBottom: 20,
    },
    incomingBubble: {
        width: 'fit-content',
    },
    link: {
        color: theme.palette.primary.main,
        textDecoration: 'none',
        fontWeight: 200,
        '&:visited': {
            color: theme.palette.primary.main,
        },
    },
    detailsLink: {
        marginTop: 8,
        textAlign: 'left',
    },
}));
