import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { AxiosResponse } from 'axios';

import {
    GET_BASIC_REQUESTING,
    GET_BASIC_SUCCEED,
    GET_BASIC_FAILED,
} from '../../../constants';

import { ApiResponse } from 'types/ApiResponse';
import { ProfileShort } from 'types/Profile';

export const getBasic = () => (dispatch, getState) => {
    const dispatchData = (actionType, info, requestStatus, errors) => {
        dispatch({
            type: actionType,
            info: info,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(GET_BASIC_REQUESTING, null, null, null);

    axios
        .get('/api/Profile/short', {
            method: 'GET',
            headers: getCommonHeaders(getState()),
        })
        .then((response: AxiosResponse<ApiResponse<ProfileShort>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                const data = response.data.result;

                dispatchData(GET_BASIC_SUCCEED, data, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_BASIC_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_BASIC_FAILED, null, null, errors);
        });
};
