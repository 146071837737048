export function setJson(key: string, json: any) {
    try {
        localStorage.setItem(key, JSON.stringify(json));
    } catch (err) {
        console.error(err);
    }
}

export function getJson(key: string) {
    try {
        return JSON.parse(localStorage.getItem(key));
    } catch (err) {
        console.error(err);

        return null;
    }
}

export function deleteJson(key: string) {
    try {
        return localStorage.removeItem(key);
    } catch (err) {
        console.error(err);
    }
}
