import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    CALCULATE_SUBSIDY_FAILED,
    CALCULATE_SUBSIDY_REQUESTING,
    CALCULATE_SUBSIDY_SUCCEED,
} from '../../constants';

export const calculateSubsidy = (id: string) => (dispatch, getState) => {
    const dispatchData = (actionType, calculateSubsidy, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                calculateSubsidy,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(CALCULATE_SUBSIDY_REQUESTING, null, null, null);

    axios
        .get(`/api/Calculation/get/${id}`, {
            headers: getCommonHeaders(getState()),
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const error = response.data?.error;

            if (isSuccess === true) {
                dispatchData(
                    CALCULATE_SUBSIDY_SUCCEED,
                    response.data?.result,
                    requestStatus,
                    error
                );
            }
            if (isSuccess === false) {
                dispatchData(CALCULATE_SUBSIDY_FAILED, null, requestStatus, error);
            }
        })
        .catch(response => {
            dispatchData(CALCULATE_SUBSIDY_FAILED, null, null, response?.data?.error);
        });
};
