import React, { FC } from 'react';

import {
    Select as MaterialSelect,
    SelectProps as MaterialSelectProps,
    MenuItem,
} from '@material-ui/core';

/**
 * Вариант выбора для Select
 */
export interface SelectionOption {
    /**
     * Название варианта
     */
    name: string | number;
    /**
     * Что выбираем
     */
    value?: string | number;
}
export interface SelectProps extends MaterialSelectProps {
    options?: SelectionOption[];
}

const Select: React.FC<SelectProps> = ({ options, children, ...other }) => {
    const renderItems = () =>
        options.map((option: SelectionOption) => (
            <MenuItem key={`${option.name}${option.value}`} value={option.value}>
                {option.name}
            </MenuItem>
        ));

    return (
        <MaterialSelect {...other}>{options ? renderItems() : children}</MaterialSelect>
    );
};

export default Select;
