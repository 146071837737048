import { ProfileFamilyState } from 'types/Profile';
import {
    GET_FAMILY_REQUESTING,
    GET_FAMILY_SUCCEED,
    GET_FAMILY_FAILED,
    UPDATE_FAMILY_REQUESTING,
    UPDATE_FAMILY_SUCCEED,
    UPDATE_FAMILY_FAILED,
} from '../../constants';

const familyInitState: ProfileFamilyState = {
    spouse: null,
    updateRequest: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const family = (state = familyInitState, action) => {
    switch (action.type) {
        case GET_FAMILY_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case GET_FAMILY_SUCCEED:
            return {
                ...state,
                spouse: action.payload.spouse,
                request: {
                    ...state.request,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.payload.errors,
                },
            };
        case GET_FAMILY_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload.errors,
                },
            };

        case UPDATE_FAMILY_REQUESTING:
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    isLoading: true,
                    errors: null,
                },
            };
        case UPDATE_FAMILY_SUCCEED:
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.payload.errors,
                },
            };
        case UPDATE_FAMILY_FAILED:
            return {
                ...state,
                updateRequest: {
                    ...state.updateRequest,
                    status: action.payload.requestStatus,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.payload.errors,
                },
            };

        default:
            return state;
    }
};
