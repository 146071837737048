import React, { FC } from 'react';
import { Link, useHistory } from 'react-router-dom';

import classNames from 'classnames';
import map from 'lodash/map';

import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import {
    Button,
    Avatar,
    Box,
    Typography,
    useMediaQuery,
    Hidden,
    IconButton,
    Grid,
} from '@material-ui/core';

import { ProfileChildrens } from 'types/Profile';
import { Icon } from 'components/atoms';
import { getColorByGender } from 'utils/utils';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        [theme.breakpoints.down('xs')]: {
            marginRight: 0,
        },
        height: '100%',
        alignItems: 'center',
        marginRight: -72,
        width: 'auto',
    },
    children: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            padding: 16,
            marginRight: 0,
            marginBottom: 16,
            minHeight: 'auto',
            height: 'auto',
            flexDirection: 'row',
            backgroundColor: `${theme.palette.common.white} !important`,
        },
        width: 197,
        minWidth: 197,
        minHeight: 168,
        marginRight: 24,
        marginBottom: 24,
        padding: 24,
        borderRadius: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
        '&:hover': {
            cursor: 'pointer',
        },
    },
    childrenMale: {
        backgroundColor: '#EEF7FF',
    },
    childrenFemale: {
        backgroundColor: '#FFF2F7',
    },
    collapseTitleContainer: {
        [theme.breakpoints.down('xs')]: {
            display: 'flex',
            justifyContent: 'space-between',
        },
        width: '100%',
    },
    addButton: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 'auto',
            fontWeight: 'normal',
            fontSize: 16,
            lineHeight: 1.4,
        },
        borderRadius: 4,
        marginTop: 46,
        padding: '13px 28px',
    },
    avatar: {
        [theme.breakpoints.down('xs')]: {
            width: 18,
            height: 18,
            marginRight: 12,
        },
    },
    avatarMale: {
        '& circle': {
            stroke: '#8CC9FF',
        },
        '& path': {
            stroke: '#8CC9FF',
        },
        '& rect': {
            stroke: '#8CC9FF',
        },
    },
    avatarFemale: {
        '& circle': {
            stroke: '#FF8AB1',
        },
        '& path': {
            stroke: '#FF8AB1',
        },
        '& rect': {
            stroke: '#FF8AB1',
        },
    },
    cardButton: {
        [theme.breakpoints.down('xs')]: {
            marginLeft: 'auto',
        },
    },
    addCardButtonCircle: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        width: 80,
        height: 80,
        borderRadius: '100%',
        border: '2px solid #95B7D6',
        marginBottom: 24,
    },
}));

interface ChildrensProps {
    preparedChildren: ProfileChildrens;
}

export const Childrens: FC<ChildrensProps> = ({ preparedChildren }) => {
    const classes = useStyles();
    const history = useHistory();
    const theme = useTheme();
    const { t } = useTranslation();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const handleClick = id => {
        history.push(`/profile/child/${id}`);
    };
    return (
        <>
            <Grid container item className={classes.grid}>
                {preparedChildren &&
                    map(preparedChildren, (child, index) => {
                        return (
                            <Box
                                onClick={() => handleClick(child.id)}
                                className={classNames(classes.children, {
                                    [classes.childrenMale]: child.gender === 'Male',
                                    [classes.childrenFemale]: child.gender === 'Female',
                                })}
                                style={{
                                    backgroundColor: getColorByGender(child.gender)
                                        .backgroundColor,
                                }}
                                key={child.id}
                            >
                                <Avatar
                                    className={classNames(classes.avatar, {
                                        [classes.avatarMale]: child.gender === 'Male',
                                        [classes.avatarFemale]: child.gender === 'Female',
                                    })}
                                >
                                    <Icon name="child" />
                                </Avatar>
                                <Typography
                                    variant={isViewXS ? 'subtitle1' : 'h5'}
                                    component="p"
                                >
                                    {child.firstName}
                                </Typography>
                                {isViewXS && (
                                    <Icon
                                        name="arrowRight"
                                        className={classes.cardButton}
                                    />
                                )}
                            </Box>
                        );
                    })}
                <Hidden smDown>
                    <IconButton
                        component={Link}
                        to="/profile/childAdd"
                        className={classes.addCardButtonCircle}
                        aria-label={t('com.Child.add')}
                    >
                        <Icon name="plusFinance" />
                    </IconButton>
                </Hidden>
            </Grid>
            <Hidden smUp>
                <Button
                    className={classes.addButton}
                    fullWidth
                    component={Link}
                    to="/profile/childAdd"
                    disableElevation
                    variant="contained"
                >
                    {t('com.Child.add')}
                </Button>
            </Hidden>
        </>
    );
};
