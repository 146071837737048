import React from 'react';
import { useHistory } from 'react-router-dom';

import { IconButton, makeStyles, Typography } from '@material-ui/core';

import { Icon, PageTitle } from 'components/atoms';
import { getColorBySubsidyStatus } from 'utils/utils';
import { SubsidyStatus } from 'types/SubsidyManagementModel';
import { useTranslation } from 'react-i18next';

interface BackButtonProps {
    onBackClick?: VoidFunction;
}

const BackButton: React.FC<BackButtonProps> = ({ onBackClick }) => {
    const history = useHistory();
    const { t } = useTranslation();
    if (!onBackClick) {
        onBackClick = () => {
            if (history.length === 1) {
                history.push('/home');
            } else {
                history.goBack();
            }
        };
    }
    return (
        <IconButton
            edge="start"
            aria-label={t('actions.return')}
            size="small"
            style={{ marginRight: '24px' }}
            onClick={onBackClick}
        >
            <Icon name="arrowLeft" />
        </IconButton>
    );
};

interface PageHeaderProps {
    title: string;
    hasBackLink?: boolean;
    status?: string;
    statusId?: SubsidyStatus;
    onBackClick?: VoidFunction;
}

const useStyles = makeStyles(theme => ({
    pageHeader: {
        display: 'flex',
        alignItems: 'center',
        // justifyContent: 'space-between'
    },
    status: {
        position: 'absolute',
        top: -25,
        left: 0,
        fontSize: 12,
        lineHeight: 1.42,
        fontWeight: 'bold',
    },
    titleContainer: {
        position: 'relative',
    },
}));

export const PageHeader: React.FC<PageHeaderProps> = ({
    title,
    hasBackLink,
    status,
    statusId,
    onBackClick,
}) => {
    const classes = useStyles();
    return (
        <div className={classes.pageHeader}>
            {hasBackLink && <BackButton onBackClick={onBackClick} />}
            <div className={classes.titleContainer}>
                {status && statusId != null && (
                    <Typography
                        className={classes.status}
                        style={{
                            color: getColorBySubsidyStatus(statusId).tagTextColor,
                        }}
                    >
                        {status}
                    </Typography>
                )}
                <PageTitle title={title} />
            </div>
        </div>
    );
};
