import React from 'react';
import { useHistory } from 'react-router-dom';
import { useQuery } from 'hooks/useQuery';
import { RootState } from 'store/store';
import { useSelector } from 'react-redux';
import { Message } from './Message';

const Messages: React.FC = () => {
    let history = useHistory();
    const data = useSelector((state: RootState) => state.messages.data);

    let query = useQuery();

    if (history.location.pathname.match(/addCard/)) return null;
    if (query.get('webView') === 'true') return null;

    return data.map(d => <Message data={d} />);
};

export default Messages;
