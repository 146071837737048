import * as React from 'react';
import { Box, makeStyles } from '@material-ui/core';
import cx from 'classnames';

export type BubbleVariant = 'incoming' | 'outgoing';

export interface BubbleProps {
    /** Содержание компонента */
    children: React.ReactNode;
    /** Время сообщения */
    time?: string;
    /** Вариант сообщения, где
     *	`"outgoing"` - исходящее сообщение,
     *	`"incoming"` - входящее сообщение.
     */
    variant?: BubbleVariant;
    className?: string;
}

const useStyles = makeStyles(theme => ({
    root: {
        fontWeight: 400,
        display: 'flex',
        flexDirection: 'column',
        // width: 'fit-content'
    },
    bubble: {
        [theme.breakpoints.down('xs')]: {
            maxWidth: 275,
        },
        maxWidth: 400,
        display: 'flex',
        flexDirection: 'column',
        padding: '12px 16px',
        marginBottom: '6px',
    },
    bubbleIncoming: {
        marginRight: 'auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: '0px 16px 16px 16px',
        // color: '#000A26',
    },
    bubbleOutgoing: {
        marginLeft: 'auto',
        backgroundColor: theme.palette.common.white,
        borderRadius: '16px 16px 0 16px',
        // color: '#FFFFFF',
    },
    bubbleInner: {
        fontSize: '14px',
        lineHeight: '20px',
        // textAlign: 'justify',
    },
    bubbleTime: {
        marginRight: '8px',
        color: '#6C6C6C',
        fontSize: '10px',
        height: '16px',
        lineHeight: '16px',
        textAlign: 'right',
    },
    timeIncoming: {
        marginLeft: 'auto',
    },
    timeOutgoing: {
        marginLeft: 'auto',
    },
}));

/** Компонент представления сообщений в чате. */
export const Bubble: React.FC<BubbleProps> = ({ children, time, variant, className }) => {
    const s = useStyles();

    let bubbleVariant;
    let timeVariant;
    let formattedChildren = children;
    let component: React.ElementType = 'div';

    switch (variant) {
        case 'incoming':
            bubbleVariant = s.bubbleIncoming;
            timeVariant = s.timeIncoming;
            break;
        case 'outgoing':
            bubbleVariant = s.bubbleOutgoing;
            timeVariant = s.timeOutgoing;
            break;
        default:
            break;
    }

    if (typeof formattedChildren === 'string') {
        formattedChildren = formattedChildren.trim();
        component = 'p';
    }

    return (
        <Box className={cx(s.root, className)}>
            <Box component="span" className={cx(s.bubble, bubbleVariant)}>
                <Box component={component} className={s.bubbleInner}>
                    {formattedChildren}
                </Box>
            </Box>
            {time && (
                <Box component="span" className={cx(s.bubbleTime, timeVariant)}>
                    {time}
                </Box>
            )}
        </Box>
    );
};

Bubble.defaultProps = {
    variant: 'incoming',
};

export default Bubble;
