import { CalculateSubsidyState } from 'types/Subsidy';
import {
    CALCULATE_SUBSIDY_SUCCEED,
    CALCULATE_SUBSIDY_REQUESTING,
    CALCULATE_SUBSIDY_FAILED,
    GET_CALCULATE_RESULT_FAILED,
    GET_CALCULATE_RESULT_SUCCEED,
    GET_CALCULATE_RESULT_REQUESTING,
    RESET_CALCULATE_SUBSIDY,
} from '../../constants';

const initialState: CalculateSubsidyState = {
    result: null,
    calculateResult: null,
    calculateRequest: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const calculateSubsidy = (state = initialState, action) => {
    switch (action.type) {
        case CALCULATE_SUBSIDY_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case CALCULATE_SUBSIDY_SUCCEED:
            return {
                ...state,
                result: action.payload.calculateSubsidy,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case CALCULATE_SUBSIDY_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case GET_CALCULATE_RESULT_REQUESTING:
            return {
                ...state,
                calculateResult: null,
                calculateRequest: {
                    ...state.calculateRequest,
                    error: null,
                    isLoading: true,
                },
            };
        case GET_CALCULATE_RESULT_SUCCEED:
            return {
                ...state,
                calculateResult: action.payload.calculateResult,
                calculateRequest: {
                    ...state.calculateRequest,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_CALCULATE_RESULT_FAILED:
            return {
                ...state,
                calculateRequest: {
                    ...state.calculateRequest,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case RESET_CALCULATE_SUBSIDY:
            return {
                ...initialState,
            };
        default:
            return state;
    }
};
