import React, { useRef } from 'react';
import { Controller } from 'react-hook-form';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import EventIcon from '@material-ui/icons/Event';
import { FormFieldProps, SubmitField } from 'types/FormField';
import { DATE_PATTERN, parseDateString } from 'utils/utils';
import DatePicker from './DatePicker';

const minDate = new Date('1900-01-01');
const maxDate = new Date();

type Props = FormFieldProps &
    Omit<KeyboardDatePickerProps, 'value' | 'onChange' | 'renderInput'> &
    SubmitField;

function isValidDate(d) {
    return d instanceof Date && !isNaN(d.getTime()) && d >= minDate && d <= maxDate;
}

export const DatePickerFormFIeld: React.FC<Props> = props => {
    const {
        control,
        errors,
        register,
        unregister,
        setValue,
        getValues,
        watch,
        name,
        validationRules,
        label,
        defaultValue,
        defaultFormValue,
        defaultValues,
        transformValueForBackend,
        ...other
    } = props;

    const validate = value => {
        if (
            typeof value === 'string' &&
            isValidDate(new Date(value.replace(DATE_PATTERN, '$3-$2-$1')))
        ) {
            return true;
        }
        return false;
    };

    const dateRef = useRef<HTMLInputElement>(null);

    return (
        <Controller
            control={control}
            name={name}
            rules={{
                ...validationRules,
                validate,
            }}
            label={label}
            defaultValue={defaultFormValue}
            render={({ value: RHFValue, onChange: RHFOnChange }) => (
                <DatePicker
                    {...other}
                    onChange={(date, value) => {
                        if (transformValueForBackend) {
                            RHFOnChange(transformValueForBackend(date) || undefined);
                        } else {
                            RHFOnChange(date || undefined);
                        }
                    }}
                    value={parseDateString(RHFValue)}
                    label={label}
                    inputRef={dateRef}
                    keyboardIcon={other.readOnly ? null : <EventIcon />}
                    InputAdornmentProps={{
                        ...other.InputAdornmentProps,
                        disablePointerEvents: other.readOnly,
                    }}
                    minDate={minDate}
                    maxDate={maxDate}
                />
            )}
            onFocus={() => {
                dateRef.current?.focus();
            }}
        />
    );
};
