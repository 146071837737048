import React, { FC } from 'react';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Grid, Typography, Box, Button } from '@material-ui/core';
import { isEmpty } from 'lodash';
import { Link } from 'react-router-dom';

import { delDraft } from 'store/actions';

import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { useStyles } from './styles';
import { LinkList } from 'components/molecules/LinkList';
import { LinkListFiles } from 'components/molecules/LinkListFiles';

interface StatementFormProps {
    requestId: any;
    application?: SubsidyManagementModel;
    downloadFile: (fileId: string) => void;
    fileIsLoading?: boolean;
    solutionFiles: any;
}

export const StatementForm: FC<StatementFormProps> = ({
    requestId,
    application,
    downloadFile,
    fileIsLoading,
    solutionFiles,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const history = useHistory();
    const { enqueueSnackbar } = useSnackbar();

    const del = React.useCallback(() => {
        dispatch(
            delDraft(
                application?.requestId,
                () => {
                    history.push('/home');
                    enqueueSnackbar(t('pages.Statement.delDraftSuccess'), {
                        variant: 'success',
                        autoHideDuration: 3000,
                    });
                },
                () => {
                    enqueueSnackbar(t('actions.internalError'), {
                        variant: 'error',
                        autoHideDuration: 3000,
                    });
                }
            )
        );
    }, [dispatch]);

    return (
        <Grid container className={classes.grid}>
            <Grid item container className={classes.subsidyInformationContainer}>
                {application?.statusId !== 0 && (
                    <Box className={classes.subsidyInformation}>
                        <Typography
                            className={classes.subsidyInformationTitle}
                            variant="subtitle1"
                        >
                            {t('pages.Statement.applicationNumber')}
                            {':  '}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                            {application?.caseNumber || t('common.noData')}
                        </Typography>
                    </Box>
                )}
                {application?.statusId !== 0 && (
                    <Box className={classes.subsidyInformation}>
                        <Typography
                            className={classes.subsidyInformationTitle}
                            variant="subtitle1"
                        >
                            {t('pages.Statement.submissionDate')}
                            {': '}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                            {application?.sendDate || t('common.noData')}
                        </Typography>
                    </Box>
                )}
                <Box className={classes.subsidyInformation}>
                    <Typography
                        className={classes.subsidyInformationTitle}
                        variant="subtitle1"
                    >
                        {t('pages.Statement.reviewPeriod')}:{' '}
                    </Typography>
                    <Typography component="span" variant="subtitle1">
                        {application?.shortTermDescription || t('common.noData')}
                    </Typography>
                </Box>
                {application?.amount && (
                    <Box className={classes.subsidyInformation}>
                        <Typography
                            className={classes.subsidyInformationTitle}
                            variant="subtitle1"
                        >
                            {t('pages.Statement.amount')}:{' '}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                            {application?.amount} ₽
                        </Typography>
                    </Box>
                )}
                {application?.receiverName && (
                    <Box className={classes.subsidyInformation}>
                        <Typography
                            className={classes.subsidyInformationTitle}
                            variant="subtitle1"
                        >
                            {t('pages.Statement.recipient')}:{' '}
                        </Typography>
                        <Typography component="span" variant="subtitle1">
                            {application?.receiverName}
                        </Typography>
                    </Box>
                )}
            </Grid>
            <Grid item container className={classes.sybsidyButtonContainer}>
                {application?.statusId === 0 && (
                    <>
                        <Link to={'/application/' + requestId}>
                            <Button
                                className={classNames(classes.issueApplicationButton)}
                                variant="contained"
                            >
                                {t('pages.Statement.makeAnApplication')}
                            </Button>
                        </Link>
                        <Button
                            className={classes.delButton}
                            disableElevation
                            variant="outlined"
                            onClick={del}
                        >
                            {t('pages.Statement.dekDraft')}
                        </Button>
                    </>
                )}
            </Grid>
            {!isEmpty(solutionFiles) && (
                <Grid item className={classes.subsidyCommentContainer}>
                    <Box mb={3}>
                        <Typography variant="h5">Документы</Typography>
                    </Box>
                    <Box>
                        <LinkListFiles
                            files={solutionFiles}
                            downloadFile={downloadFile}
                        />
                    </Box>
                </Grid>
            )}
            {application?.statusId !== 0 && (
                <Grid item className={classes.subsidyCommentContainer}>
                    <Box>
                        <Typography variant="h5">{t('common.comment')}</Typography>
                    </Box>
                    <Typography className={classes.subsidyComment} variant="body1">
                        {application?.comment || t('common.noData')}
                    </Typography>
                </Grid>
            )}
            {application?.reportFileUrl && (
                <Grid item className={classes.subsidyCommentContainer}>
                    <a href={application?.reportFileUrl} target="_blank">
                        <Button
                            variant="contained"
                            className={classNames(classes.issueApplicationButton)}
                        >
                            Открыть заявление
                        </Button>
                    </a>
                </Grid>
            )}
            <Grid item className={classes.subsidyCommentContainer}>
                <Box>
                    <Typography variant="h5">{t('pages.Statement.subInfo')}</Typography>
                </Box>
                <LinkList
                    links={[{ id: application.id, name: application.name }]}
                    className={classes.subsidies}
                />
            </Grid>
        </Grid>
    );
};
