import React, { FC, useCallback } from 'react';
import classNames from 'classnames';
import { Link } from 'react-router-dom';
import { Typography, useMediaQuery, useTheme, Box } from '@material-ui/core';

import { Icon, Loading, PageTitle, TextField } from 'components/atoms';
import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { getColorBySubsidyCategory } from 'utils/utils';
import { useSubsidyCalculatorDrawer } from 'hooks/useSubsidyCalculatorDrawer';
import { useStyles } from './Subsidies.styles';
import { SubsidyAmount } from './SubsidyAmount';

interface CardSubsidyProps {
    subsidy?: SubsidyManagementModel;
    withDescription?: boolean;
    withTitle?: boolean;
    withTag?: boolean;
    withArrow?: boolean;
    withCalc?: boolean;
    withIcon?: boolean;
    className?: string;
    detailUrl: string;
}
export const CardSubsidy: FC<CardSubsidyProps> = ({
    subsidy,
    withDescription,
    withTitle,
    withTag,
    withArrow,
    withCalc,
    className,
    withIcon,
    detailUrl,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const { initCalculateSubsidy } = useSubsidyCalculatorDrawer();

    const handleClickCalculator = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.stopPropagation();
        event.preventDefault();
        initCalculateSubsidy(subsidy.id);
    };

    return (
        <Box
            className={classNames(classes.subsidyCard, className)}
            style={{
                backgroundColor: getColorBySubsidyCategory(subsidy.category)
                    .backgroundColor,
            }}
        >
            <Link to={detailUrl} className={classes.linkTitle}>
                <Typography className={classes.subsidyCardTitle}>
                    {subsidy.name}
                </Typography>
            </Link>
            <div className={classes.subsidyCardSubtitleContainer}>
                {isViewXS && subsidy.shortTermDescription && (
                    <Typography
                        variant="body1"
                        className={classNames(classes.subsidyAmount, 'subsidyAmount')}
                    >
                        <Icon name="subsidyCalendar" /> {subsidy.shortTermDescription}
                    </Typography>
                )}
                <SubsidyAmount
                    subsidy={subsidy}
                    handleClickCalculator={handleClickCalculator}
                />
            </div>
        </Box>
    );
};
