import { FileWithPath, FileRejection } from 'react-dropzone';
import { v4 as uuidv4 } from 'uuid';
import {
    AcceptedFiles,
    RejectedFiles,
    UploaderFiles,
    UploaderInitialFile,
    UploaderInitialFiles,
} from '../Uploader.types';

/** Конвертация файлов библиотеки react-dropzone под общий тип File */
export const convertAcceptedFiles = (
    acceptedFiles: AcceptedFiles,
    isSubmitBehavior: boolean
): UploaderFiles =>
    acceptedFiles.map((acceptedFile: FileWithPath) => {
        const acceptedFileWithDropMeta = Object.assign(acceptedFile, {
            dropId: uuidv4(),
            status: isSubmitBehavior ? 'dropped' : 'loading',
        });

        return acceptedFileWithDropMeta;
    });

export const convertRejectedFiles = (rejectedFiles: RejectedFiles): UploaderFiles =>
    rejectedFiles.map((rejectedFile: FileRejection) => {
        return Object.assign(rejectedFile.file, {
            dropId: uuidv4(),
            status: 'error',
            errors: rejectedFile.errors,
        });
    });

export const convertInitialFiles = (initialFiles: UploaderInitialFiles): UploaderFiles =>
    initialFiles.map((file: UploaderInitialFile) => {
        return Object.assign(
            {
                dropId: uuidv4(),
            },
            file
        );
    });
