import React, { useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import sortBy from 'lodash/sortBy';
import filter from 'lodash/find';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';
import cx from 'classnames';
import {
    Box,
    makeStyles,
    Theme,
    Typography,
    Grid,
    Button,
    Dialog,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { useFormContext } from 'react-hook-form';
import { Selector } from 'components/organisms/Application/ApplicationForm/Steps/StepBlocks/Selector';
import { ApplicationGroupsField } from 'components/organisms';
import { TransitionProps } from '@material-ui/core/transitions';
import DialogTitle from '@material-ui/core/DialogTitle';
import DialogContent from '@material-ui/core/DialogContent';
import DialogActions from '@material-ui/core/DialogActions';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import { useDispatch, useSelector } from 'react-redux';
import { delRelativeMember } from 'store/actions/application';
import { useTheme, useMediaQuery } from '@material-ui/core';
import { RootState } from 'store/store';

const useStyles = makeStyles((theme: Theme) => ({
    family: {
        /*'& + &': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 44,
            },
            marginTop: 56,
        },*/
    },
    familyMembers: {},
    dialogBox: {
        [theme.breakpoints.down('xs')]: {
            padding: '20px',
        },
        padding: '42px 42px',
    },
    dialogTitle: {
        marginBottom: 16,
        padding: 0,
        display: 'flex',
        justifyContent: 'space-between',
        alignItems: 'center',
    },
    closeButton: {
        position: 'absolute',
        [theme.breakpoints.down('xs')]: {
            right: '5px',
        },
        right: '30px',
        color: theme.palette.grey[500],
    },
    relatives: {},
    buttons: {},
    iconNumber: {
        marginRight: '16px',
        background: '#DFE1E4',
        width: '24px',
        height: '24px',
        borderRadius: '40px',
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
    },
    number: {
        color: 'white',
        fontWeight: 500,
        fontSize: '12px',
        lineHeight: '16px',
        textAlign: 'center',
        flex: 'none',
        order: 0,
        flexGrow: 0,
        margin: '0px 0px 0px 1px',
    },
    iconTitle: {
        display: 'flex',
        alignItems: 'center',
    },
    title: {
        color: '#DFE1E4',
    },
    subTitle: {
        lineHeight: '1',
        marginBottom: '32px',
    },
    btn: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
        },
    },
}));

interface Props {
    relIndex: any;
    relMember: any;
    familyMemberIndex: any;
    relativesLength: any;
    setSelectedFamilyMemberIndex: any;
    blockIndex: any;
}

export const RelativeMember: React.FC<Props> = ({
    relMember,
    relIndex,
    relativesLength,
    familyMemberIndex,
    setSelectedFamilyMemberIndex,
    blockIndex,
}) => {
    const classes = useStyles();
    const [selectedRelIndex, setSelectedRelIndex] = useState(null);
    const dispatch = useDispatch();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));
    const formProps = useFormContext();
    const { watch, trigger, register, errors } = formProps;

    const handleClose = () => {
        setSelectedRelIndex(null);
        setSelectedFamilyMemberIndex(familyMemberIndex);
        // trigger(`familyMembers[${familyMemberIndex}].relatives[${relIndex}]`);
    };
    const handleOpen = () => {
        setSelectedRelIndex(relIndex);
        setSelectedFamilyMemberIndex(null);
    };

    const handleDel = useCallback(() => {
        dispatch(delRelativeMember(relIndex, familyMemberIndex));
    }, []);

    const application = useSelector((state: RootState) => state.application.application);
    const categories = application.blocks[blockIndex].categories;

    //Имя
    const first_name = watch(
        `familyMembers[${familyMemberIndex}].relatives[${relIndex}].data.relative_first_name`
    );
    //Отчество
    const middle_name = watch(
        `familyMembers[${familyMemberIndex}].relatives[${relIndex}].data.relative_middle_name`
    );
    //Степень родства
    const relation_degree_items = find(relMember.relativeFields, {
        fieldId: 'relative_relation_degree',
    })?.fieldItems;

    //Степень родства (value)
    let relation_degree;
    if (!isEmpty(relation_degree_items)) {
        let relation_degreeWatch = watch(
            `familyMembers[${familyMemberIndex}].relatives[${relIndex}].data.relative_relation_degree`
        );
        relation_degree = find(relation_degree_items, {
            key: String(relation_degreeWatch),
        })?.value;
    }

    //Имя + Отчество + (Степень родства)
    let label = first_name;
    label = middle_name ? label + ' ' + middle_name : label;
    label = relation_degree ? label + ' (' + relation_degree + ')' : label;
    if (!label) label = 'Новый родственник';

    //Селектор и Диалог родственника
    return (
        <>
            <Selector
                label={label}
                onEdit={handleOpen}
                error={Boolean(
                    errors?.familyMembers?.[familyMemberIndex]?.relatives?.[relIndex]
                )}
                onDelete={handleDel}
                index={relIndex}
                key={`selectorRel-${relIndex}`}
            />
            <Dialog
                open={selectedRelIndex === relIndex}
                onClose={handleClose}
                keepMounted
                fullWidth={true}
                maxWidth={'md'}
                scroll="body"
                fullScreen={isViewXS}
            >
                <Box className={classes.dialogBox}>
                    <DialogTitle disableTypography className={classes.dialogTitle}>
                        <Box className={classes.iconTitle}>
                            <div className={classes.iconNumber}>
                                <span className={classes.number}>{relIndex + 1}</span>
                            </div>
                            <Typography variant="h3" className={classes.title}>
                                Член семьи
                            </Typography>
                        </Box>
                        <IconButton
                            aria-label="close"
                            className={classes.closeButton}
                            onClick={handleClose}
                        >
                            <CloseIcon />
                        </IconButton>
                    </DialogTitle>
                    <Typography variant="h3" className={classes.subTitle}>
                        Родственник
                    </Typography>
                    {/*id*/}
                    <input
                        type="hidden"
                        value={relMember.relativeId}
                        name={`familyMembers[${familyMemberIndex}].relatives[${relIndex}.relativeId`}
                        ref={register}
                    />

                    <ApplicationGroupsField
                        categoryName={null}
                        categoryId={null}
                        fields={relMember.relativeFields}
                        isSuperCategory={false}
                        filedsPath={`familyMembers[${familyMemberIndex}].relatives[${relIndex}].data`}
                        key={`RelativeGroup-${relIndex}`}
                    />
                    {categories.map((category, categoryIndex) => (
                        <ApplicationGroupsField
                            categoryName={category.displayName}
                            categoryType={category.categoryType}
                            categoryId={category.id}
                            fields={relMember.relativeFields}
                            isSuperCategory={false}
                            filedsPath={`familyMembers[${familyMemberIndex}].relatives[${relIndex}].data`}
                            key={`RelativeGroup-${categoryIndex}`}
                        />
                    ))}
                    <Grid container className={classes.buttons} spacing={3}>
                        <Grid item xs={12}>
                            <Button
                                variant="contained"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Сохранить данные
                            </Button>
                        </Grid>
                        <Grid item xs={12}>
                            <Button
                                variant="outlined"
                                onClick={handleClose}
                                className={classes.btn}
                            >
                                Отмена
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Dialog>
        </>
    );
};
