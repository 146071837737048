import { Box, makeStyles, Typography } from '@material-ui/core';
import React from 'react';

const NEWS = [
    {
        title: '',
        img: '',
    },
];

const useStyles = makeStyles(theme => ({}));

export const NewsBanner: React.FC = () => {
    const classes = useStyles();
    return (
        <div>
            <Box>
                <img />
                <Typography />
            </Box>
        </div>
    );
};
