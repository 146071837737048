const tokenKey = 'token';
const anonimToken = 'anonimToken';

export function setAuthToken(token: string) {
    try {
        localStorage.setItem(tokenKey, token);
    } catch (err) {
        console.error(err);
    }
}

export function getAuthToken() {
    try {
        return localStorage.getItem(tokenKey);
    } catch (err) {
        console.error(err);
    }
}

export function deleteAuthToken() {
    try {
        return localStorage.removeItem(tokenKey);
    } catch (err) {
        console.error(err);
    }
}

export function setAnonimToken(token: string) {
    try {
        localStorage.setItem(anonimToken, token);
    } catch (err) {
        console.error(err);
    }
}

export function getAnonimToken() {
    try {
        return localStorage.getItem(anonimToken);
    } catch (err) {
        console.error(err);
    }
}

export function deleteAnonimToken() {
    try {
        return localStorage.removeItem(anonimToken);
    } catch (err) {
        console.error(err);
    }
}
