import React, { useCallback, useEffect, useState } from 'react';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { ApplicationGroupsField } from 'components/organisms';
import { ErrorMessage } from 'components/atoms';
import { CircularProgress, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { ApplicationGroupSubtitle } from 'components/organisms';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Child } from './Child';
import { useDispatch, useSelector } from 'react-redux';
import { addChild } from 'store/actions/application';

export const useStyles = makeStyles((theme: Theme) => ({
    children: {},
}));

interface Props {
    categories: any;
    childFieldsTemplate: any;
    childrenBlock: any;
    canAppendChildData: boolean;
    profileChildren: any;
    applicationSubmitIsLoading: boolean;
}
//TODO: оптимизация детей. Когда детей много приложение тормозит (при удалении и добавлении)
export const Children: React.FC<Props> = ({
    categories,
    childFieldsTemplate,
    canAppendChildData,
    childrenBlock,
    profileChildren,
    applicationSubmitIsLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const [selectedChildIndex, setSelectedChildIndex] = useState(0);
    const childrenBlockLastIndex = childrenBlock.length - 1;

    const handleNextChild = (e, currentChildIndex) => {
        //TODO: Оставил для сворачивающихся детей
        /*  if (selectedChildIndex < childrenBlockLastIndex) {
            //Изменяем ребенка на следущего
            e.preventDefault();
            setSelectedChildIndex(currentChildIndex + 1);
        }*/
    };

    const handleAddChild = useCallback(() => {
        dispatch(addChild());
    }, []);

    return (
        <>
            {childrenBlock &&
                !isEmpty(childrenBlock) &&
                map(childrenBlock, (child, key) => {
                    return (
                        <Child
                            key={'child' + key + child.childId}
                            categories={categories}
                            profileChildren={profileChildren}
                            child={child}
                            canAppendChildData={canAppendChildData}
                            childIndex={key}
                            childrenBlock={childrenBlock}
                            childrenBlockLastIndex={childrenBlockLastIndex}
                            handleNextChild={handleNextChild}
                            handleAddChild={handleAddChild}
                            applicationSubmitIsLoading={applicationSubmitIsLoading}
                        />
                    );
                })}
        </>
    );
};
