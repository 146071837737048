import React from 'react';
import NumberFormat from 'react-number-format';

interface NumberFormatCustomProps {
    inputRef: (instance: NumberFormat<any> | null) => void;
    onChange: (event: { target: { name: string; value: string } }) => void;
    name: string;
}

export function NumberFormatCustom(props: NumberFormatCustomProps) {
    const { inputRef, onChange, ...other } = props;

    return (
        <NumberFormat
            inputMode="decimal"
            {...other}
            allowNegative={false}
            getInputRef={inputRef}
            onValueChange={values => {
                onChange({
                    target: {
                        name: props.name,
                        value: values.value,
                    },
                });
            }}
            thousandSeparator=" "
            decimalScale={2}
            isNumericString
            suffix=" ₽"
            decimalSeparator="."
            allowedDecimalSeparators={['.', ',']}
        />
    );
}
