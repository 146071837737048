import React from 'react';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';
import { Box, Grid, List, ListItem, ListItemIcon, ListItemText } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { CustomStep } from 'components/organisms/Application/ApplicationForm/Steps/CustomStep';
import { Link } from 'react-router-dom';
import { Application } from 'types/Application';
import { makeStyles, Theme } from '@material-ui/core/styles';

export const useStyles = makeStyles((theme: Theme) => ({
    dialogButton: {
        [theme.breakpoints.down('xs')]: {
            width: '100%',
            marginRight: 0,
            '& + &': {
                marginTop: 16,
            },
        },
        marginTop: 56,
        marginRight: 16,
    },
    stepDecription: {
        [theme.breakpoints.down('xs')]: {
            fontSize: 15,
            lineHeight: 1.4,
        },
        fontSize: 18,
        lineHeight: 1.56,
        color: theme.palette.grey[700],
    },
    term: {
        fontSize: 18,
        fontWeight: 'normal',
    },
    cardIcon: {
        backgroundColor: 'transparent',
        [theme.breakpoints.down('xs')]: {
            width: 40,
            height: 40,
        },
        width: 62,
        height: 40,
    },
    dot: {
        width: 8,
        height: 8,
        display: 'inline-block',
        backgroundColor: theme.palette.grey[100],
        borderRadius: '100%',
        marginRight: 16,
    },
    listItemIconRoot: {
        minWidth: 'unset',
    },
}));

interface StepSubsidyNameProps {
    open: () => void;
    currentApplication: Application;
    handleSubmit: any;
    nextApplicationId: string;
    onSubmit: any;
    handleBack: Function;
}

const StepSubsidyName: React.FC<StepSubsidyNameProps> = ({
    handleSubmit,
    open,
    currentApplication,
    nextApplicationId,
    onSubmit,
    handleBack,
    ...props
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    return (
        <CustomStep
            key="subsidyName"
            stepLabel={currentApplication.title}
            handleBack={handleBack}
            {...props}
        >
            <form onSubmit={handleSubmit(onSubmit('subsidyName'))}>
                <Typography className={classes.stepDecription}>
                    {t('pages.Application.forSendingConfirm')}
                </Typography>
                <Grid item>
                    {currentApplication.termsPoints && (
                        <List dense disablePadding>
                            {currentApplication.termsPoints.map((term, index) => (
                                <ListItem
                                    key={`term_${index}`}
                                    alignItems="flex-start"
                                    dense
                                    disableGutters
                                >
                                    <ListItemIcon
                                        classes={{
                                            root: classes.listItemIconRoot,
                                        }}
                                    >
                                        <span className={classes.dot} />
                                    </ListItemIcon>
                                    <ListItemText
                                        className={classes.term}
                                        disableTypography
                                    >
                                        <Typography variant="body1" component="p">
                                            {term}
                                        </Typography>
                                    </ListItemText>
                                </ListItem>
                            ))}
                        </List>
                    )}
                </Grid>
                <Box>
                    <Button
                        type="submit"
                        variant="contained"
                        className={classes.dialogButton}
                    >
                        {t('pages.Application.informationIsCorrect')}
                    </Button>
                    <Button
                        variant="outlined"
                        className={classes.dialogButton}
                        onClick={open}
                    >
                        {t('pages.Application.notCorrect')}
                    </Button>
                    {nextApplicationId && (
                        <Link to={'/application/' + nextApplicationId}>
                            <Button
                                variant="text"
                                color="primary"
                                className={classes.dialogButton}
                            >
                                {t('pages.Application.nextSubsidy')}
                            </Button>
                        </Link>
                    )}
                </Box>
            </form>
        </CustomStep>
    );
};

export default StepSubsidyName;
