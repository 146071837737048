import {
    GET_MY_SUBSIDIES_SUCCEED,
    GET_MY_SUBSIDIES_REQUESTING,
    GET_MY_SUBSIDIES_FAILED,
    GET_MY_SUBSIDY_FAILED,
    GET_MY_SUBSIDY_SUCCEED,
    GET_MY_SUBSIDY_REQUESTING,
    GET_APPLICATION_FILE_SUCCEED,
    GET_APPLICATION_FILE_FAILED,
    GET_APPLICATION_FILE_REQUESTING,
} from '../../constants';

const initialState = {
    data: [],
    selectedSubsidy: null,
    file: {
        isLoading: false,
        isSuccess: true,
        error: null,
    },
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const mySubsidies = (state = initialState, action) => {
    switch (action.type) {
        case GET_MY_SUBSIDIES_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };

        case GET_MY_SUBSIDIES_SUCCEED:
            return {
                ...state,
                data: action.payload.subsidies,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_MY_SUBSIDIES_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case GET_MY_SUBSIDY_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };
        case GET_MY_SUBSIDY_SUCCEED:
            return {
                ...state,
                selectedSubsidy: action.payload.subsidy,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_MY_SUBSIDY_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    // status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        case GET_APPLICATION_FILE_REQUESTING:
            return {
                ...state,
                file: {
                    ...state.file,
                    isLoading: true,
                },
            };
        case GET_APPLICATION_FILE_SUCCEED:
            return {
                ...state,
                file: {
                    ...state.file,
                    isLoading: false,
                    isSuccess: true,
                    error: action.payload.error,
                },
            };
        case GET_APPLICATION_FILE_FAILED:
            return {
                ...state,
                file: {
                    ...state.file,
                    isLoading: false,
                    isSuccess: false,
                    error: action.payload.error,
                },
            };
        default:
            return state;
    }
};
