import React, { FC } from 'react';
import CssBaseline from '@material-ui/core/CssBaseline';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';

import { theme, GlobalCss } from '../utils/theme';

export const ThemeLayout: FC = ({ children }) => {
    return (
        <>
            <ThemeProvider theme={theme}>
                <CssBaseline />
                <GlobalCss />
                <StylesProvider injectFirst>{children}</StylesProvider>
            </ThemeProvider>
        </>
    );
};
