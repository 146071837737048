import React, { FC } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';
import cx from 'classnames';
import { makeStyles, Theme } from '@material-ui/core/styles';
import {
    Typography,
    Box,
    IconButton,
    useTheme,
    useMediaQuery,
    Button,
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Trans } from 'react-i18next';
import { Link } from 'react-router-dom';
import { ListItem } from './ListItem';

const useStyles = makeStyles((theme: Theme) => ({
    ul: {},
}));

interface Props {
    files: any[];
    className?: any;
    downloadFile: (fileId: string) => void;
}

export const LinkListFiles: FC<Props> = ({ files, className, downloadFile }) => {
    const classes = useStyles();
    const theme = useTheme();
    const isViewSM = useMediaQuery(theme.breakpoints.down('sm'));
    const { t } = useTranslation();

    const handleClickDownloadAll = () => {
        files.forEach((item, i) => {
            downloadFile(item.fileId);
        });
    };

    return (
        <>
            <Box mb={5}>
                <ul className={className ? className : classes.ul}>
                    {files.map((item, itemIndex) => (
                        <ListItem
                            item={item}
                            itemIndex={itemIndex}
                            downloadFile={downloadFile}
                        />
                    ))}
                </ul>
            </Box>
            <Box>
                <Button variant="contained" onClick={handleClickDownloadAll}>
                    {files.length > 1 ? 'Скачать все' : 'Скачать'}
                </Button>
            </Box>
        </>
    );
};
