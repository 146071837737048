import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { ProfileRelations } from 'types/Profile';
import { errorHandle, transformDateToServerString } from 'utils/utils';
import {
    UPDATE_FAMILY_REQUESTING,
    UPDATE_FAMILY_SUCCEED,
    UPDATE_FAMILY_FAILED,
} from '../../../constants';
import { getFamily } from './getFamily';

export const updateFamily = (spouse: ProfileRelations, cb?: Function) => (
    dispatch,
    getState
) => {
    const dispatchData = (actionType, requestStatus, errors) => {
        dispatch({
            type: actionType,
            payload: {
                requestStatus: requestStatus,
                errors: errors,
            },
        });
    };

    let data = spouse;

    if (data.maritalStatus === 1 || data.maritalStatus === 5) {
        data = {
            ...data,
            birthOfDate: transformDateToServerString(spouse.birthOfDate),
            marriageOfDate: transformDateToServerString(spouse.marriageOfDate),
        };
    }

    dispatchData(UPDATE_FAMILY_REQUESTING, null, null);
    return axios({
        method: 'POST',
        url: '/api/Profile/maritalstatus/update',
        headers: getCommonHeaders(getState()),
        data: data,
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;

            if (isSuccess === true) {
                dispatch(getFamily());
                cb && cb();
                dispatchData(UPDATE_FAMILY_SUCCEED, requestStatus, null);
            }
            if (isSuccess === false) {
                const error = errorHandle(response.data);

                dispatchData(UPDATE_FAMILY_FAILED, requestStatus, error.error);
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError);
            dispatchData(UPDATE_FAMILY_FAILED, null, error.error);
        });
};
