import { ProfileChildrenState } from 'types/Profile';
import {
    GET_CHILD_REQUESTING,
    GET_CHILD_SUCCEED,
    GET_CHILD_FAILED,
    CREATE_CHILD_REQUESTING,
    CREATE_CHILD_SUCCEED,
    CREATE_CHILD_FAILED,
    SELECT_CHILD,
    DESELECT_CHILD,
    UPDATE_CHILD_REQUESTING,
    UPDATE_CHILD_SUCCEED,
    UPDATE_CHILD_FAILED,
    GET_CHILDRENS_REQUESTING,
    GET_CHILDRENS_SUCCEED,
    GET_CHILDRENS_FAILED,
    DEL_CHILD_REQUESTING,
    DEL_CHILD_SUCCEED,
    DEL_CHILD_FAILED,
} from '../../constants';

const childrenInitState: ProfileChildrenState = {
    data: [],
    selectedChild: null,
    request: {
        status: null,
        isLoading: false,
        isSuccess: false,
        errors: null,
    },
};

export const childrenList = (state = childrenInitState, action) => {
    switch (action.type) {
        case GET_CHILDRENS_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case GET_CHILDRENS_SUCCEED:
            return {
                ...state,
                data: action.childrens,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case GET_CHILDRENS_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };

        case GET_CHILD_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case GET_CHILD_SUCCEED:
            return {
                ...state,
                selectedChild: action.children,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case GET_CHILD_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };

        case CREATE_CHILD_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                    errors: null,
                },
            };
        case CREATE_CHILD_SUCCEED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case CREATE_CHILD_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };

        case SELECT_CHILD:
            return {
                ...state,
                selectedChild: state.data.filter(child => child.id === action.id)[0],
            };

        case DESELECT_CHILD:
            return {
                ...state,
                selectedChild: null,
            };
        //UPDATE
        case UPDATE_CHILD_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };
        case UPDATE_CHILD_SUCCEED:
            return {
                ...state,
                // selectedChild: {},
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case UPDATE_CHILD_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };
        //DEL
        case DEL_CHILD_REQUESTING:
            return {
                ...state,
                request: {
                    ...state.request,
                    isLoading: true,
                },
            };
        case DEL_CHILD_SUCCEED:
            return {
                ...state,
                // selectedChild: {},
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: true,
                    errors: action.errors,
                },
            };
        case DEL_CHILD_FAILED:
            return {
                ...state,
                request: {
                    ...state.request,
                    status: action.status,
                    isLoading: false,
                    isSuccess: false,
                    errors: action.errors,
                },
            };
        default:
            return state;
    }
};
