import {
    axiosInstanse as axios,
    getCommonHeaders,
    ResponseFileData,
} from 'infrastructure/RestClient';
import { saveAs } from 'utils/utils';
import {
    GET_APPLICATION_FILE_REQUESTING,
    GET_APPLICATION_FILE_FAILED,
    GET_APPLICATION_FILE_SUCCEED,
} from '../../constants';

export const downloadStatementFile = fileId => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(GET_APPLICATION_FILE_REQUESTING, null, null, null);

    axios
        .get(`/api/files/${fileId}`, {
            headers: { ...getCommonHeaders(getState()), Accept: '*/*' },
            responseType: 'blob',
        })
        .then(response => {
            const requestStatus = response.status;

            const headers = response.headers;
            const contentType = headers && headers['content-type'];

            if (
                /application\/octet-stream/.exec(contentType) ||
                response.data instanceof Blob
            ) {
                const contentDisposition = headers['content-disposition'],
                    match = /filename=*(.*)?;/.exec(contentDisposition),
                    fileName =
                        match && match.length === 2 && match[1]
                            ? match[1].split('"').join('')
                            : '';
                let file;
                if (fileName) {
                    file = new ResponseFileData(response.data, fileName);
                }
                if (file) {
                    saveAs(file.content, file.fileName);
                }
            }
            dispatchData(GET_APPLICATION_FILE_SUCCEED, null, requestStatus, null);
        })
        .catch(response => {
            dispatchData(
                GET_APPLICATION_FILE_FAILED,
                null,
                null,
                response?.data?.error || 'error'
            );
        });
};
