import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import Typography from '@material-ui/core/Typography';
import { Box, Dialog, Button } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { useQuery } from 'hooks/useQuery';
import { isEmpty } from 'lodash';
import merge from 'lodash/merge';

export const useStyles = makeStyles((theme: Theme) => ({
    fixedBox: {
        position: 'fixed',
        left: '0px',
        width: '500px',
        padding: '2px 10px',
        top: '0px',
        fontSize: '13px',
        fontWeight: 100,
        background: '#fff',
        overflowWrap: 'break-word',
        zIndex: 10000,
        whiteSpace: 'break-spaces',
        height: '100%',
        overflow: 'auto',
    },
    icons: {
        position: 'fixed',
        right: '0',
        display: 'grid',
    },
    btn: {
        fontSize: '10px',
        marginBottom: '10px',
    },
}));

interface Props {
    formProps: any;
    submitData: any;
}

export const TestPanel: React.FC<Props> = ({ formProps, submitData }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const [state, setState] = useState(0);
    let query = useQuery();
    if (query.get('t') !== '1') return null;
    const { watch, errors } = formProps;
    if (state === 3) console.log(errors);
    return (
        <Box>
            <div className={classes.icons}>
                <Button
                    variant={state === 0 ? 'contained' : 'outlined'}
                    className={classes.btn}
                    onClick={() => setState(0)}
                >
                    watch
                </Button>
                <Button
                    variant={state === 1 ? 'contained' : 'outlined'}
                    className={classes.btn}
                    onClick={() => setState(1)}
                >
                    submitData
                </Button>
                <Button
                    variant={state === 2 ? 'contained' : 'outlined'}
                    className={classes.btn}
                    onClick={() => setState(2)}
                >
                    Merge
                </Button>
                <Button
                    variant={state === 3 ? 'contained' : 'outlined'}
                    className={classes.btn}
                    onClick={() => setState(3)}
                >
                    errors
                </Button>
            </div>
            <div className={classes.fixedBox}>
                {state === 0 && JSON.stringify(watch(), null, '\t')}
                {state === 1 &&
                    !isEmpty(submitData) &&
                    JSON.stringify(submitData, null, '\t')}
                {state === 2 && JSON.stringify(merge(watch(), submitData), null, '\t')}
                {state === 3 &&
                    'errors отображаются в консоле, т.к. не могут быть преобразованы в json'}
            </div>
        </Box>
    );
};

export default TestPanel;
