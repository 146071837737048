/** Colors */
const reds = {
    red50: '#fff9f8',
    red100: '#fef3ef',
    red150: '#fee7e0',
    red200: '#fdcfc2',
    red250: '#fcb7a5',
    red300: '#fb9c87',
    red350: '#fa826a',
    red400: '#f8654c',
    red450: '#f64930',
    red500: '#f52b18',
    red550: '#e02514',
    red600: '#cd1f13',
    red650: '#bb1912',
    red700: '#a81411',
    red750: '#961010',
    red800: '#840c0f',
};

const ambers = {
    amber100: '#fff8ee',
    amber150: '#fff1df',
    amber200: '#ffe3bf',
    amber250: '#ffd39f',
    amber300: '#ffc27f',
    amber350: '#ffb160',
    amber400: '#ff9e40',
    amber450: '#ff8920',
    amber500: '#ff7200',
    amber550: '#f86c00',
    amber600: '#f26400',
    amber650: '#ec5e00',
    amber700: '#e55700',
    amber750: '#df5000',
    amber800: '#d84a00',
};

const oranges = {
    orange100: '#fffdef',
    orange150: '#fffadf',
    orange200: '#fff4bf',
    orange250: '#ffec9f',
    orange300: '#ffe27f',
    orange350: '#ffd65f',
    orange400: '#ffc940',
    orange450: '#ffba20',
    orange500: '#ffa600',
    orange550: '#fb9f00',
    orange600: '#f89500',
    orange650: '#f58d00',
    orange700: '#f28300',
    orange750: '#ee7b00',
    orange800: '#eb7300',
};

const yellows = {
    yellow100: '#fffeef',
    yellow150: '#fffcdf',
    yellow200: '#fff9bf',
    yellow250: '#fff69f',
    yellow300: '#fff180',
    yellow350: '#ffec5f',
    yellow400: '#ffe540',
    yellow450: '#ffde20',
    yellow500: '#ffd500',
    yellow550: '#ffcd00',
    yellow600: '#ffc300',
    yellow650: '#ffb900',
    yellow700: '#ffac00',
    yellow750: '#ffa000',
    yellow800: '#ff9200',
};

const lightGreens = {
    lightGreen100: '#fcfdef',
    lightGreen150: '#fafcdf',
    lightGreen200: '#f2f8bf',
    lightGreen250: '#e9f4a0',
    lightGreen300: '#ddee7f',
    lightGreen350: '#cfe860',
    lightGreen400: '#bee03f',
    lightGreen450: '#abd720',
    lightGreen500: '#99cc00',
    lightGreen550: '#82b900',
    lightGreen600: '#6fa700',
    lightGreen650: '#5f9500',
    lightGreen700: '#4f8300',
    lightGreen750: '#407300',
    lightGreen800: '#346200',
};

const grasses = {
    grass100: '#f0fdf2',
    grass150: '#dffbe4',
    grass200: '#bff7ca',
    grass250: '#a0f1b1',
    grass300: '#7fea98',
    grass350: '#60e381',
    grass400: '#3fd969',
    grass450: '#20d054',
    grass500: '#00c150',
    grass550: '#00b13c',
    grass600: '#009b3a',
    grass650: '#008e37',
    grass700: '#007c34',
    grass750: '#006d30',
    grass800: '#005d2d',
};

const mints = {
    mint100: '#f0fbf7',
    mint150: '#e2f8f0',
    mint200: '#c6f1e2',
    mint250: '#aaead6',
    mint300: '#8ee2c8',
    mint350: '#71dabb',
    mint400: '#54d2ae',
    mint450: '#37caa2',
    mint500: '#1bc298',
    mint550: '#17ae87',
    mint600: '#16a280',
    mint650: '#139476',
    mint700: '#10846b',
    mint750: '#0f7660',
    mint800: '#0c6755',
};

const neons = {
    neon100: '#effcfa',
    neon150: '#defaf5',
    neon200: '#bff6ec',
    neon250: '#a0f1e4',
    neon300: '#7febdc',
    neon350: '#60e5d5',
    neon400: '#40decd',
    neon450: '#20d7c7',
    neon500: '#00cfc4',
    neon550: '#00beb3',
    neon600: '#00b2a9',
    neon650: '#009c98',
    neon700: '#008b8a',
    neon750: '#007a7c',
    neon800: '#006b6e',
};

const lightBlues = {
    lightBlue100: '#eefdff',
    lightBlue150: '#dffaff',
    lightBlue200: '#bff4ff',
    lightBlue250: '#a0edff',
    lightBlue300: '#7fe3ff',
    lightBlue350: '#60d9ff',
    lightBlue400: '#40ccff',
    lightBlue450: '#20bdff',
    lightBlue500: '#00aaff',
    lightBlue550: '#0096f2',
    lightBlue600: '#0081e6',
    lightBlue650: '#006cd9',
    lightBlue700: '#0058cc',
    lightBlue750: '#0045bf',
    lightBlue800: '#0032b2',
};

const blues = {
    blue100: '#eef8ff',
    blue150: '#def1ff',
    blue200: '#bfe3ff',
    blue250: '#a0d3ff',
    blue300: '#7fbfff',
    blue350: '#60abff',
    blue400: '#3f93ff',
    blue450: '#207bff',
    blue500: '#005eff',
    blue550: '#0052ec',
    blue600: '#0044d8',
    blue650: '#0039c6',
    blue700: '#002eb2',
    blue750: '#00249f',
    blue800: '#001b8c',
};

const deepPurples = {
    deepPurple100: '#f8efff',
    deepPurple150: '#f2e1ff',
    deepPurple200: '#e5c6ff',
    deepPurple250: '#d5a8ff',
    deepPurple300: '#c48aff',
    deepPurple350: '#b26dff',
    deepPurple400: '#9f50ff',
    deepPurple450: '#8a32ff',
    deepPurple500: '#7614ff',
    deepPurple550: '#6611ec',
    deepPurple600: '#5a0fd9',
    deepPurple650: '#4e0dc6',
    deepPurple700: '#420bb2',
    deepPurple750: '#380aa0',
    deepPurple800: '#2e088c',
};

const purples = {
    purple100: '#fbf0fe',
    purple150: '#f7e1fe',
    purple200: '#efc4fe',
    purple250: '#e6a6fe',
    purple300: '#dc88fd',
    purple350: '#d16bfd',
    purple400: '#c54cfd',
    purple450: '#b82efd',
    purple500: '#ab10fe',
    purple550: '#990de9',
    purple600: '#890bd7',
    purple650: '#7b0ac5',
    purple700: '#6c09b1',
    purple750: '#5e079e',
    purple800: '#50068b',
};

const pinks = {
    pink100: '#fef0f1',
    pink150: '#fee3e6',
    pink200: '#fcc7cf',
    pink250: '#fbabb9',
    pink300: '#fa8ea4',
    pink350: '#f8708f',
    pink400: '#f5537d',
    pink450: '#f2356c',
    pink500: '#f11767',
    pink550: '#dd1459',
    pink600: '#ca1155',
    pink650: '#b80d53',
    pink700: '#a40b4d',
    pink750: '#920947',
    pink800: '#800742',
};

const neonGrays = {
    neonGray100: '#fafafc',
    neonGray150: '#f5f5f7',
    neonGray200: '#ebecef',
    neonGray250: '#e2e3e6',
    neonGray300: '#d9dbe0',
    neonGray350: '#ced0d7',
    neonGray400: '#c5c7cf',
    neonGray450: '#bbbec7',
    neonGray500: '#b2b5bf',
    neonGray550: '#9fa3af',
    neonGray600: '#8c909f',
    neonGray650: '#757a8c',
    neonGray700: '#5c6378',
    neonGray750: '#434b63',
    neonGray800: '#5c6378',
    neonGray850: '#202232',
    neonGray900: '#212329',
};

const grays = {
    gray0: '#ffffff',
    gray50: '#fbfbfb',
    gray100: '#f7f7f7',
    gray150: '#f0f0f0',
    gray200: '#e1e1e1',
    gray250: '#d3d3d3',
    gray300: '#c4c4c4',
    gray350: '#b6b6b6',
    gray400: '#a6a6a6',
    gray450: '#989898',
    gray500: '#8a8a8a',
    gray550: '#7b7b7b',
    gray600: '#6c6c6c',
    gray650: '#5e5e5e',
    gray700: '#4f4f4f',
    gray750: '#414141',
    gray800: '#323232',
    gray850: '#232323',
    gray900: '#151515',
    gray950: '#000000',
};

const nightSkies = {
    nightSky100: '#f1eff6',
    nightSky150: '#e4dfed',
    nightSky200: '#c9bfdc',
    nightSky250: '#b0a0cb',
    nightSky300: '#947fb9',
    nightSky350: '#7a60a8',
    nightSky400: '#604097',
    nightSky450: '#452086',
    nightSky500: '#2b0075',
    nightSky550: '#250066',
    nightSky600: '#200058',
    nightSky650: '#1a0049',
    nightSky700: '#15003a',
    nightSky750: '#10002b',
    nightSky800: '#0a001d',
};

const misc = {
    neon: neons.neon600,
    grass: grasses.grass600,
    mint: mints.mint550,
    nightSky: nightSkies.nightSky750,
    black: grays.gray950,
    white: grays.gray0,
    error: reds.red500,
};

export const colors = {
    ...reds,
    ...ambers,
    ...oranges,
    ...yellows,
    ...lightGreens,
    ...grasses,
    ...mints,
    ...neons,
    ...lightBlues,
    ...blues,
    ...deepPurples,
    ...purples,
    ...pinks,
    ...neonGrays,
    ...grays,
    ...nightSkies,
    ...misc,
};
