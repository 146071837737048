import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_FEATURES_LIST_START,
    GET_FEATURES_LIST_FAILURE,
    GET_FEATURES_LIST_SUCCESS,
} from '../../constants';

export const getAllFeatures = () => (dispatch, getState) => {
    dispatch({ type: GET_FEATURES_LIST_START });
    axios({
        method: 'GET',
        params: {
            platform: 'web',
        },
        url: '/api/FeatureToggle/getAllFeatures',
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const error = response.data?.error;

            if (isSuccess === true) {
                dispatch({
                    type: GET_FEATURES_LIST_SUCCESS,
                    payload: { list: response.data?.result },
                });
            }
            if (isSuccess === false) {
                dispatch({ type: GET_FEATURES_LIST_FAILURE, payload: { error } });
            }
        })
        .catch(response => {
            dispatch({
                type: GET_FEATURES_LIST_FAILURE,
                payload: { error: response.data?.error },
            });
        });
};
