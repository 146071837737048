import React, { useState, useCallback, useRef, useEffect, FC } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import { Grid } from '@material-ui/core';
import { Message, MessagesGroupByDay } from 'types/Chat';

import { useSelectedMessages } from '.././useSelectedMessageContext';

import { useStylesChatComponent } from '.././Chat.styles';
import { ChatContent } from './ChatContent';
import { ChatInput } from './ChatContent';

interface ChatComponentProps {
    handleMessage?: (message: string, selectedMessages: { [key: string]: any }) => void;
    messagesByDay?: MessagesGroupByDay;
    lastMessage?: Message;
}

const ChatComponent: FC<ChatComponentProps> = ({
    handleMessage,
    messagesByDay,
    lastMessage,
}) => {
    const classes = useStylesChatComponent();
    const [scrollToBottom, setScrollToBottom] = useState(false);
    const [withInput, setWithInput] = useState(false);
    const chatContainer = useRef(null);
    const fakeMessageRef = useRef(null);
    const { selectedMessages } = useSelectedMessages();

    useEffect(() => {
        // if (!messages || !messages.length || !chatContainer.current) return;
        if (fakeMessageRef.current) {
            fakeMessageRef.current.scrollIntoView();
        }
    }, [scrollToBottom, messagesByDay, lastMessage]);

    const onMessage = useCallback(
        message => {
            handleMessage(message, selectedMessages);

            setScrollToBottom(true);
        },
        [setScrollToBottom, handleMessage, selectedMessages]
    );

    useEffect(() => {
        if (
            isEmpty(lastMessage?.message.Buttons) &&
            !lastMessage?.isClientMessage &&
            !isEmpty(messagesByDay)
        ) {
            setWithInput(true);
        } else {
            setWithInput(false);
        }
    }, [lastMessage]);

    return (
        <>
            <Grid className={classes.chatContainer}>
                <Grid
                    item
                    className={cx(classes.messagesContainer, {
                        [classes.messagesContainerWithInput]: withInput,
                    })}
                    ref={chatContainer}
                >
                    <ChatContent
                        lastMessage={lastMessage}
                        messagesByDay={messagesByDay}
                        onMessage={onMessage}
                        fakeMessageRef={fakeMessageRef}
                    />
                </Grid>
            </Grid>

            {withInput && (
                <ChatInput onMessage={onMessage} lastMessage={lastMessage}></ChatInput>
            )}
        </>
    );
};

export default ChatComponent;
