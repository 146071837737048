import React, { FC } from 'react';
import { useTranslation } from 'react-i18next';
import {
    Box,
    Button,
    Dialog,
    IconButton,
    makeStyles,
    SwipeableDrawer,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { CircularProgress } from '@abdt/ornament';

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            textTransform: 'uppercase',
        },
        padding: '13px 28px 15px 27px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        flexDirection: 'column',
        padding: 60,
    },
    dialogPaper: {
        backgroundColor: theme.palette.common.white,
    },
}));

interface ConfirmDialogProps {
    open: boolean;
    onClickOpen: VoidFunction;
    onClose: VoidFunction;
    onConfirm: VoidFunction;
    dialogText: string;
    isLoading?: boolean;
    okLabel?: string;
    cancelLabel?: string;
}

export const ConfirmDialog: FC<ConfirmDialogProps> = props => {
    const { t } = useTranslation();
    const {
        open,
        onClickOpen,
        onClose,
        onConfirm,
        dialogText,
        isLoading,
        okLabel = t('actions.yes'),
        cancelLabel = t('actions.no'),
    } = props;
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));
    if (matches) {
        return (
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onOpen={onClickOpen}
                disableDiscovery
                onClose={onClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Box className={classes.dialogActions}>
                    <Box marginBottom="40px">
                        <Typography variant="h1" component="h3">
                            {dialogText}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Box marginBottom="30px">
                            <Button
                                className={classes.button}
                                onClick={onConfirm}
                                startIcon={isLoading && <CircularProgress size={24} />}
                                disabled={isLoading}
                                color="primary"
                                variant="contained"
                                fullWidth
                            >
                                {okLabel}
                            </Button>
                        </Box>
                        <Button
                            className={classes.button}
                            onClick={onClose}
                            disabled={isLoading}
                            color="primary"
                            variant="outlined"
                            fullWidth
                        >
                            {cancelLabel}
                        </Button>
                    </Box>
                </Box>
            </SwipeableDrawer>
        );
    }
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            scroll="body"
            onClose={onClose}
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <IconButton
                size="small"
                aria-label={t('actions.closeModal')}
                className={classes.closeButton}
                onClick={onClose}
            >
                <CloseIcon />
            </IconButton>
            <Box className={classes.dialogActions}>
                <Box marginBottom="40px">
                    <Typography variant="h3">{dialogText}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Box marginBottom="30px">
                        <Button
                            className={classes.button}
                            onClick={onConfirm}
                            startIcon={isLoading && <CircularProgress size={24} />}
                            disabled={isLoading}
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            {okLabel}
                        </Button>
                    </Box>
                    <Button
                        className={classes.button}
                        onClick={onClose}
                        disabled={isLoading}
                        color="primary"
                        variant="outlined"
                        fullWidth
                    >
                        {cancelLabel}
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
