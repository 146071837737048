import React, { FC } from 'react';
import { makeStyles } from '@material-ui/core/styles';
import { Typography } from '@material-ui/core';
import map from 'lodash/map';
import isEmpty from 'lodash/isEmpty';
import { useTranslation } from 'react-i18next';
import { Message } from 'types/Chat';
import MessageComponent from './MessageComponent';

const useStylesMessagesGroupByDayComponent = makeStyles(() => ({
    day: {
        fontWeight: 'bold',
        fontSize: 10,
        lineHeight: 1.2,
        textAlign: 'center',
        margin: '16px 0',
    },
}));

interface MessagesGroupByDayComponentProps {
    dayMessages: Array<Message>;
    day: string;
    lastMessage?: Message;
}

const MessagesGroupByDayComponent: FC<MessagesGroupByDayComponentProps> = ({
    dayMessages,
    day,
    lastMessage,
}) => {
    const classes = useStylesMessagesGroupByDayComponent();
    const { t } = useTranslation();

    if (!dayMessages || isEmpty(dayMessages)) {
        return null;
    }

    return (
        <>
            <Typography className={classes.day}>{t(day)}</Typography>
            {map(dayMessages, (message: Message, index, array) => {
                return (
                    <MessageComponent
                        key={`message_container_${message.id}`}
                        message={message}
                        lastMessage={lastMessage}
                    />
                );
            })}
        </>
    );
};

export default MessagesGroupByDayComponent;
