import { createSlice } from '@reduxjs/toolkit';
import { SubsidiesFilter } from 'types/SubsidiesFilter';

interface SubsidiesFilterState {
    filter: SubsidiesFilter[];
    error: any;
    loading: boolean;
    success: boolean;
}

const initialState: SubsidiesFilterState = {
    filter: [],
    error: null,
    loading: false,
    success: false,
};

const subsidiesFilter = createSlice({
    name: 'subsidiesFilter',
    initialState,
    reducers: {
        getFilterStart(state) {
            state.loading = true;
            state.error = null;
        },
        getFilterSuccess(state, action) {
            state.loading = false;
            state.error = null;
            state.success = true;
            state.filter = action.payload;
        },
        getFilterFailure(state, action) {
            state.loading = false;
            state.error = action.payload.error;
        },
    },
});

export const {
    getFilterStart,
    getFilterSuccess,
    getFilterFailure,
} = subsidiesFilter.actions;

export default subsidiesFilter.reducer;
