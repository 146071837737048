import React, { useEffect } from 'react';
import { Grid, InputAdornment } from '@material-ui/core';
import { useTranslation } from 'react-i18next';

import { Icon, PageTitle, TextField } from 'components/atoms';
import { useInput } from 'hooks/useInput';
import { useStyles } from './Subsidies.styles';
import { Filter } from './Filter';

interface AdaptedPageHeaderProps {
    title: string;
    setSearch: (string: string) => void;
}

export const AdaptedPageHeader: React.FC<AdaptedPageHeaderProps> = ({
    title,
    setSearch,
}) => {
    const classes = useStyles();
    const searchInput = useInput('');
    const { t } = useTranslation();

    useEffect(() => {
        setSearch(searchInput.bind.value);
    }, [searchInput.bind.value]);

    return (
        <Grid className={classes.pageTitle}>
            <PageTitle title={title} />
            <Filter />
        </Grid>
    );
};
