import React from 'react';

import { Box, Divider, Grid, IconButton, InputAdornment } from '@abdt/ornament';
import { TextFieldProps } from 'components/atoms/TextField';

import { Icon, TextField } from 'components/atoms';
import useStyles from './RejectedFile.style';
import sliceFileName from '../../utils/slice-file-name';
import { UploaderFile, UploaderFileError } from '../../Uploader.types';
import { useTranslation } from 'react-i18next';
interface RejectedFileProps {
    file: UploaderFile;
    deleteFile: (file: UploaderFile) => void;
    reloadFile: (file: UploaderFile) => void;
    isSubmitBehavior: boolean;
    textFieldProps: TextFieldProps;
}

const errorTextByCode = {
    'file-too-large': 'com.Uploader.fileTooLarge',
    'file-too-small': 'com.Uploader.fileTooSmall',
    'too-many-files': 'com.Uploader.tooManyFiles',
    'file-invalid-type': 'com.Uploader.fileInvalidType',
    'file-not-loaded': 'com.Uploader.fileNotLoaded',
};

const getErrorTextByCode = (error: UploaderFileError, t) => {
    if (error.code === 'file-not-loaded' && error.message) {
        return error.message;
    }
    return t(errorTextByCode[error.code]);
};

const RejectedFile: React.FC<RejectedFileProps> = ({
    file,
    deleteFile,
    reloadFile,
    isSubmitBehavior,
    textFieldProps,
}) => {
    const { errors } = file;
    const classes = useStyles();
    const fileName = sliceFileName(file.name);
    const { t } = useTranslation();
    const errorMessage = errors
        ? getErrorTextByCode(errors[0], t)
        : t('actions.unknownError');

    const closeHandler = () => deleteFile(file);
    const reloadHandler = () => reloadFile(file);

    return (
        <Grid
            container
            wrap="nowrap"
            direction="row"
            alignItems="center"
            className={classes.rejectedFileItem}
        >
            <TextField
                {...textFieldProps}
                className={classes.rejectedFileInput}
                value={fileName}
                error
                helperText={errorMessage}
                InputProps={{
                    endAdornment: (
                        <Box
                            display="flex"
                            alignItems="center"
                            flexWrap="nowrap"
                            position="relative"
                            className={classes.rejectedFileControls}
                        >
                            <Icon
                                name="fileLoadError"
                                className={classes.rejectedFileIcon}
                            />
                            <Divider
                                orientation="vertical"
                                variant="fullWidth"
                                className={classes.rejectedFileDivider}
                            />
                            {errors && errors[0].code === 'file-not-loaded' ? (
                                <Grid container justify="flex-end" wrap="nowrap">
                                    {!isSubmitBehavior && (
                                        <IconButton
                                            onClick={reloadHandler}
                                            className={classes.reloadButton}
                                        >
                                            <Icon name="reload" />
                                        </IconButton>
                                    )}
                                    <IconButton onClick={closeHandler}>
                                        <Icon name="close" />
                                    </IconButton>
                                </Grid>
                            ) : (
                                <Grid container justify="flex-end" wrap="nowrap">
                                    <IconButton
                                        onClick={closeHandler}
                                        className={classes.closeButton}
                                    >
                                        <Icon name="close" />
                                    </IconButton>
                                </Grid>
                            )}
                        </Box>
                    ),
                }}
            />
        </Grid>
    );
};

export default RejectedFile;
