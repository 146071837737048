import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_CHILDRENS_REQUESTING,
    GET_CHILDRENS_FAILED,
    GET_CHILDRENS_SUCCEED,
} from '../../../constants';
import { ApiResponse } from 'types/ApiResponse';
import { ProfileChildrens } from 'types/Profile';

export const getChildrens = () => (dispatch, getState) => {
    const dispatchData = (actionType, childrens, requestStatus, errors) => {
        dispatch({
            type: actionType,
            childrens: childrens,
            requestStatus: requestStatus,
            errors: errors,
        });
    };

    dispatchData(GET_CHILDRENS_REQUESTING, null, null, null);

    axios
        .get('/api/Profile/childs/short', {
            method: 'GET',
            url: '/api/Profile/get',
            headers: getCommonHeaders(getState()),
        })
        .then((response: AxiosResponse<ApiResponse<ProfileChildrens>>) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                const data = response.data?.result;

                dispatchData(GET_CHILDRENS_SUCCEED, data, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(GET_CHILDRENS_FAILED, null, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(GET_CHILDRENS_FAILED, null, null, errors);
        });
};
