import * as React from 'react';
import { FC } from 'react';
import { KeyboardDatePickerProps } from '@material-ui/pickers';
import { KeyboardDatePicker as MUIKeyboardDatePicker } from '@material-ui/pickers';
import TextField from '../TextField';

const renderInput = props => <TextField {...props} helperText={null} />;

const DatePicker: FC<KeyboardDatePickerProps> = other => {
    return <MUIKeyboardDatePicker {...other} TextFieldComponent={renderInput} />;
};

DatePicker.defaultProps = {
    format: 'DD.MM.YYYY',
    inputVariant: 'outlined',
    autoOk: true,
    cancelLabel: 'Отмена',
    okLabel: 'ОК',
    KeyboardButtonProps: {
        edge: 'end',
    },
};

export default DatePicker;
