import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_SAVE_REQUISITES_REQUESTING,
    WALLET_SAVE_REQUISITES_SUCCEED,
    WALLET_SAVE_REQUISITES_FAILED,
} from '../../constants';

export const saveRequsites = (formData, maskPan, operationId) => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_SAVE_REQUISITES_REQUESTING, null, null, null);

    axios
        .post(
            `/api/Card/info/save/${operationId}`,
            {
                ...formData,
                maskPan: maskPan,
            },
            {
                headers: getCommonHeaders(getState()),
            }
        )
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const result = response.data?.result;

            if (isSuccess === true) {
                dispatchData(WALLET_SAVE_REQUISITES_SUCCEED, result, requestStatus, null);
            }

            if (isSuccess === false) {
                const error = errorHandle(response.data);

                let errorString = [error.error, ...result].join('.\r\n');
                dispatchData(
                    WALLET_SAVE_REQUISITES_FAILED,
                    null,
                    requestStatus,
                    errorString
                );
            }
        })
        .catch(rawError => {
            const error = errorHandle(rawError.response?.data || rawError);

            dispatchData(WALLET_SAVE_REQUISITES_FAILED, null, null, error.error);
        });
};
