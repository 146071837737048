import { AxiosResponse } from 'axios';

import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    UPDATE_CONTACTS_REQUESTING,
    UPDATE_CONTACTS_FAILED,
    UPDATE_CONTACTS_SUCCEED,
} from '../../../constants';
import { getContacts } from './getContacts';

export const updateContacts = contacts => (dispatch, getState) => {
    const dispatchData = (actionType, requestStatus, errors) => {
        dispatch({
            type: actionType,
            payload: {
                status: requestStatus,
                errors: errors,
            },
        });
    };

    dispatchData(UPDATE_CONTACTS_REQUESTING, null, null);
    axios({
        method: 'POST',
        url: '/api/Profile/contact/update',
        headers: getCommonHeaders(getState()),
        data: contacts,
    })
        .then((response: AxiosResponse) => {
            const requestStatus = response.status;
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                dispatch(getContacts());
                dispatchData(UPDATE_CONTACTS_SUCCEED, requestStatus, error);
            }
            if (isSuccess === false) {
                dispatchData(UPDATE_CONTACTS_FAILED, requestStatus, error);
            }
        })
        .catch(errors => {
            dispatchData(UPDATE_CONTACTS_FAILED, null, errors);
        });
};
