import React, { FC } from 'react';
import { useDispatch } from 'react-redux';

import {
    Autocomplete as MUIAutocomplete,
    AutocompleteProps as MUIAutocompleteProps,
} from '@material-ui/lab';

import TextField from '../TextField';
import { AddressDadata } from 'types/Address';
import { findAddress } from 'store/actions';
import debounce from 'lodash/debounce';
import { useTranslation } from 'react-i18next';

export interface AddressAutocompleteProps
    extends Omit<
        MUIAutocompleteProps<AddressDadata, true, true, true>,
        'renderInput' | 'options'
    > {
    /**
     * Подпись/метка к элементу
     */
    label?: string;
    /**
     * Имена классов для возможности дополнительной стилизации
     */
    classes?: object;
    /**
     * Визуализация элемента ввода
     */
    renderInput?: (params: object) => React.ReactNode;
    /**
     * Ссылка ref
     */
    ref?: ((instance: unknown) => void) | React.RefObject<unknown> | null;
    helperText?: string;
    variant?: 'outlined' | 'standard';
    error?: boolean;
    required?: boolean;
    readOnly?: boolean;
    daDataType?: string;
}

const AddressAutocomplete: FC<AddressAutocompleteProps> = ({
    label,
    placeholder,
    variant,
    size,
    className,
    error,
    helperText,
    required,
    readOnly,
    daDataType,
    ...other
}) => {
    const [inputValue, setInputValue] = React.useState('');
    const [options, setOptions] = React.useState<AddressDadata[]>([]);
    const [isLoading, setIsLoading] = React.useState<boolean>(false);
    const { t } = useTranslation();
    const dispatch = useDispatch();

    const fetch = React.useMemo(
        () =>
            debounce((input: string, callback: (results?: AddressDadata[]) => void) => {
                dispatch(findAddress(input, callback, daDataType));
            }, 500),
        []
    );

    React.useEffect(() => {
        let active = true;

        if (inputValue === '') {
            // setOptions([]);
            return undefined;
        }
        setIsLoading(true);
        setOptions([]);
        fetch(inputValue, (results?: AddressDadata[]) => {
            setIsLoading(false);

            if (active) {
                let newOptions = [] as AddressDadata[];

                if (results) {
                    newOptions = results;
                }
                setOptions(newOptions);
            }
        });

        return () => {
            active = false;
        };
    }, [inputValue]);

    return (
        <MUIAutocomplete
            renderInput={params => (
                <TextField
                    {...params}
                    error={error}
                    required={required}
                    inputProps={{
                        ...params.inputProps,
                        autoComplete: 'new-password', // disable autocomplete and autofill
                    }}
                    InputProps={{
                        ...params.InputProps,
                        readOnly: readOnly,
                    }}
                    variant={variant}
                    size={size === 'small' ? 'small' : 'medium'}
                    label={label}
                    helperText={helperText}
                    placeholder={placeholder}
                />
            )}
            {...other}
            options={options}
            // autoComplete
            filterOptions={x => x}
            loading={isLoading}
            noOptionsText={t('actions.noSearchResult')}
            loadingText={t('actions.loadingDots')}
            // @ts-ignore
            getOptionLabel={option => option.displayValue || ''}
            onInputChange={(event, newInputValue) => {
                setInputValue(newInputValue);
            }}
        />
    );
};

export default AddressAutocomplete;
