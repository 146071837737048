import axios from 'axios';

import { baseUrl } from '../config';
import store from 'store/store';
import { signinEsiaResetToken } from 'store/actions/account';
import {
    deleteAuthToken,
    deleteAnonimToken,
    getAuthToken,
    getAnonimToken,
} from 'utils/token';
import { deleteJson } from 'utils/localStorage';

export class ResponseFileData {
    fileName;
    content;
    constructor(content, fileName) {
        this.content = content instanceof Blob ? content : new Blob([content]);
        this.fileName = fileName;
    }
}

export const axiosInstanse = axios.create({
    baseURL: baseUrl,
});

axiosInstanse.interceptors.response.use(
    response => {
        // Response interceptor
        return response;
    },
    error => {
        // Errors handle
        if (error && error.response && error.response.status === 401) {
            deleteJson('userID');
            deleteAuthToken();
            deleteAnonimToken();
            store.dispatch(signinEsiaResetToken());
        }

        return Promise.reject(error);
    }
);

/**
 * Возвращает распространенный вариант конфигурации заголовков запроса.
 */
export const getCommonHeaders = (state: any = {}) => {
    let headers: any = {
        'Content-Type': 'application/json',
    };

    const { account } = state;
    const token = account?.signInEsiaConfirm?.token || getAuthToken();
    headers.Authorization = token ? `Bearer ${token}` : undefined;

    return headers;
};

/**
 * Возвращает распространенный вариант конфигурации заголовков запроса если пользователь не авторизован.
 */
export const getAnonimCommonHeaders = (state: any = {}) => {
    let headers: any = {
        'Content-Type': 'application/json',
    };

    const { account } = state;
    const token =
        account?.signInEsiaConfirm?.token ||
        getAuthToken() ||
        account?.signinAnonim?.anonimToken ||
        getAnonimToken();
    headers.Authorization = token ? `Bearer ${token}` : undefined;

    return headers;
};
