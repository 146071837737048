import React, { lazy } from 'react';
import { Route, Switch } from 'react-router-dom';

import {
    Subsidies,
    Profile,
    Chat,
    SubsidyDetail,
    Statement,
    NotFoundPage,
    ApplicationPage,
    CardOrder,
    CardOrderSuccess,
} from '../components/pages';
import { PublicRoute, ProtecedRoute, PrivateRoute } from './utils';
import { OfferMess } from 'components/common/OfferMess';
import { get_services_07_04_2021 } from 'store/features.constants';

const Landing = lazy(() => import('../components/pages/Landing'));
const Faq = lazy(() => import('../components/pages/Faq'));

const Personal = lazy(() => import('../components/pages/Profile/Personal'));
const Contacts = lazy(() => import('../components/pages/Profile/Contacts'));
const Childrens = lazy(() => import('../components/pages/Profile/Childrens'));
const Child = lazy(() => import('../components/pages/Profile/Child'));
const ChildAdd = lazy(() => import('../components/pages/Profile/ChildAdd'));
const Family = lazy(() => import('../components/pages/Profile/Family'));

const Calculator = lazy(() => import('../components/pages/Calculator'));

const Finance = lazy(() => import('../components/pages/Finance'));
const CardInfo = lazy(() => import('../components/pages/Finance/Card'));
const AddCard = lazy(() => import('../components/pages/AddCard'));
const AddCardExit = lazy(() => import('../components/pages/AddCard/AddCardExit'));

const AuthError = lazy(() => import('../components/pages/AuthError'));
const Services = lazy(() => import('../components/pages/Services'));
const Service = lazy(() => import('../components/pages/Service'));

export const Routes = () => {
    return (
        <>
            <Switch>
                {/*# Лендинг*/}
                <PublicRoute exact path="/" component={Landing} />

                {/*# Чат*/}
                <PrivateRoute exact path="/bot" component={Chat} />

                {/*# Субсидии*/}
                <PrivateRoute exact path="/home" component={Subsidies} />
                <PrivateRoute exact path="/subsidies/:id" component={SubsidyDetail} />
                {/*Калькулятор субсидии для мобилок*/}
                <PublicRoute exact path="/calculator/:id" component={Calculator} />

                {/*Черновик/Заявление*/}
                <ProtecedRoute exact path="/statement/:id" component={Statement} />

                {/*Анкета МСП*/}
                <ProtecedRoute
                    exact
                    path="/application/:id"
                    component={ApplicationPage}
                />

                {/*# Услуги*/}
                <PublicRoute
                    exact
                    path="/services"
                    component={Services}
                    featureToggle={get_services_07_04_2021}
                />
                <PublicRoute
                    exact
                    strict
                    path="/services/:id"
                    component={Service}
                    featureToggle={get_services_07_04_2021}
                />

                {/*# Финансы*/}
                <ProtecedRoute exact path="/finance" component={Finance} />
                {/*Информация о карте*/}
                <ProtecedRoute exact path="/finance/card/:id" component={CardInfo} />
                {/*Добавление карты*/}
                <PublicRoute exact path="/addCard/:operationId" component={AddCard} />
                {/*Завершение операции добавления карты*/}
                <PublicRoute
                    exact
                    path="/addCard/:operationId/exit"
                    component={AddCardExit}
                />
                {/*Заказ карты*/}
                <ProtecedRoute exact path="/cardOrder" component={CardOrder} />
                {/*Статус заказа карты*/}
                <ProtecedRoute
                    exact
                    path="/cardOrder/success"
                    component={CardOrderSuccess}
                />

                {/*# Профиль*/}
                <ProtecedRoute exact path="/profile" component={Profile} />
                <ProtecedRoute exact path="/profile/personal" component={Personal} />
                <ProtecedRoute exact path="/profile/contacts" component={Contacts} />
                <ProtecedRoute exact path="/profile/child" component={Childrens} />
                <ProtecedRoute exact strict path="/profile/child/:id" component={Child} />
                <ProtecedRoute
                    exact
                    strict
                    path="/profile/childAdd"
                    component={ChildAdd}
                />
                {/*Семейный статус*/}
                <ProtecedRoute exact path="/profile/family" component={Family} />
                {/*Faq*/}
                <PrivateRoute exact strict path="/faq" component={Faq} />
                <PrivateRoute exact strict path="/faq/:id" component={Faq} />

                <PublicRoute exact path="/authError" component={AuthError} />

                <Route path="*">
                    <NotFoundPage />
                </Route>
            </Switch>
            <OfferMess />
        </>
    );
};
