import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { errorHandle } from 'utils/utils';
import {
    WALLET_DELETE_CARD_FAILED,
    WALLET_DELETE_CARD_REQUESTING,
    WALLET_DELETE_CARD_SUCCEED,
    WALLET_DELETE_CARD_RESET,
} from '../../constants';

export const clearUserCard = (
    cardId: string,
    cbSuccess?: Function,
    cbError?: Function
) => (dispatch, getState) => {
    const dispatchData = (actionType, result, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                result: result,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(WALLET_DELETE_CARD_REQUESTING, null, null, null);
    //TODO: Поменять метод на тот, что с cardID
    axios
        .delete(`/api/Card/remove`, {
            headers: getCommonHeaders(getState()),
            params: {
                cardId,
            },
        })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;

            if (isSuccess === true) {
                dispatchData(WALLET_DELETE_CARD_SUCCEED, null, requestStatus, null);
                cbSuccess && cbSuccess();
            }

            if (isSuccess === false) {
                const error = errorHandle(response.data);
                cbError && cbError(error.error);
                dispatchData(WALLET_DELETE_CARD_FAILED, null, requestStatus, error);
            }
        })
        .catch(rawError => {
            let error = rawError?.response?.data || rawError;
            error = errorHandle(error);
            cbError && cbError(error.error);
            dispatchData(WALLET_DELETE_CARD_FAILED, null, null, error.error);
        });
};

export const clearUserCardReset = () => dispatch => {
    dispatch({
        type: WALLET_DELETE_CARD_RESET,
    });
};
