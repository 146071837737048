import React from 'react';
import { useSelector } from 'react-redux';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { Box, Button, Grid, makeStyles, Typography } from '@material-ui/core';

import successCardOrder from 'assets/images/successCardOrder.png';

import { MainLayout } from 'components/layouts';
import { RootState } from 'store/store';
import { ErrorMessage, Loading } from 'components/atoms';
import { CardOrderSuccesConfig } from 'types/CardOrder';
import { PageHeader } from 'components/molecules';
import { CardOrderSuccess } from 'components/organisms/CardOrderSuccess';

const usePageStyles = makeStyles(theme => ({
    root: {},
    pageContent: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        padding: 24,
    },
    pageGrid: {},
}));

export const CardOrderSuccessPage: React.FC = () => {
    const classes = usePageStyles();
    const { t } = useTranslation();
    const cardOrderError = useSelector((state: RootState) => state.cardOrder.submitError);
    const cardOrderSuccess = useSelector(
        (state: RootState) => state.cardOrder.submitSuccess
    );
    const cardOrderIsLoading = useSelector(
        (state: RootState) => state.cardOrder.submitLoading
    );
    const cardOrderSuccesConfig = useSelector(
        (state: RootState) => state.cardOrder.cardOrderSuccesConfig
    );

    if (cardOrderIsLoading) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={<PageHeader title={'pages.CardOrder.mirOrder'} />}
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
            >
                <Loading />
            </MainLayout>
        );
    }

    if (cardOrderError || !cardOrderSuccess) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={
                    <PageHeader title={'pages.CardOrder.applicationIsNotIssued'} />
                }
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
            >
                <ErrorMessage error={cardOrderError || t('actions.error')} />
                <Button variant="contained" component={Link} to="/home">
                    На главную
                </Button>
            </MainLayout>
        );
    }
    if (cardOrderSuccess) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={<PageHeader title={'pages.CardOrder.applicationIssued'} />}
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
            >
                <CardOrderSuccess cardOrderSuccesConfig={cardOrderSuccesConfig} />
            </MainLayout>
        );
    }
    return null;
};
