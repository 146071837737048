import React, { FC, useCallback, useEffect, useState } from 'react';
import map from 'lodash/map';
import get from 'lodash/get';
import { useForm } from 'react-hook-form';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useHistory } from 'react-router-dom';
import classNames from 'classnames';
import { canCardOrder } from 'utils/utils';
import { getCardOrders } from 'store/slices/cardOrder';
import { useTranslation } from 'react-i18next';
import { CardOrder } from 'components/organisms/CardOrder';

import {
    Box,
    Button,
    CircularProgress,
    FormControlLabel,
    makeStyles,
    Step,
    StepContent,
    StepLabel,
    Stepper,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';

import { MainLayout } from 'components/layouts';
import {
    CardOrderFieldCell,
    CardOrderGroupCell,
    CardOrderGroupHeader,
    CardOrderGroupsField,
    cardOrderPersonalFields,
    cardOrderPlaceFields,
    CardOrderSubmitField,
    CARD_ORDER_ACTUAL_ADDRESS_CATEGORY,
    CARD_ORDER_CODE_WORD_CATEGORY,
    CARD_ORDER_DELIVERY_CATEGORY,
    CARD_ORDER_PASSPORT_CATEGORY,
    CARD_ORDER_REGISTATION_ADDRESS_CATEGORY,
} from 'components/organisms';
import {
    AutocompleteFormField,
    Checkbox,
    CheckboxFormField,
    ErrorMessage,
    Loading,
} from 'components/atoms';
import { getBranches, getLocalities, setSelectedCity } from 'store/slices/atm';
import { getDraftFields, sendCardOrder } from 'store/slices/cardOrder';
import { RootState } from 'store/store';
import { DataAsOptions, LocalityModel } from 'types/Atm';
import {
    CardOrderDraftFields,
    CardOrderSubmitFields,
    ChannelType,
} from 'types/CardOrder';
import { transformDateToServerString, unmaskedPhoneNumber } from 'utils/utils';
import { PageHeader } from 'components/molecules';

const usePageStyles = makeStyles(theme => ({
    root: {},
    pageContent: {
        [theme.breakpoints.down('xs')]: {
            padding: 0,
        },
        padding: 24,
    },
    contentColumn: {
        width: '100%',
        marginRight: 0,
    },
    pageGrid: {},
}));

export const CardOrderPage = () => {
    const classes = usePageStyles();
    const { t } = useTranslation();
    const citiesAsOptions = useSelector(
        (state: RootState) => state.atm.localitiesAsOptions
    );
    const citiesIsLoading = useSelector(
        (state: RootState) => state.atm.localitiesLoading
    );
    const citiesError = useSelector((state: RootState) => state.atm.localitiesError);
    const selectedCity = useSelector((state: RootState) => state.atm.selectedCity);
    const branchesAsOptions = useSelector(
        (state: RootState) => state.atm.branchesAsOptions
    );

    const cardOrderError = useSelector((state: RootState) => state.cardOrder.submitError);
    const cardOrderSuccess = useSelector(
        (state: RootState) => state.cardOrder.submitSuccess
    );
    const cardOrderIsLoading = useSelector(
        (state: RootState) => state.cardOrder.submitLoading
    );

    const defaultValues = useSelector((state: RootState) => state.cardOrder.draftFields);
    const defaultValuesIsLoading = useSelector(
        (state: RootState) => state.cardOrder.loading
    );
    const defaultValuesError = useSelector((state: RootState) => state.cardOrder.error);

    const dispatch = useDispatch();
    const getCities = useCallback(() => {
        dispatch(getLocalities());
    }, []);
    const fetchBranches = useCallback(selectedCity => {
        dispatch(getBranches(selectedCity));
    }, []);
    const _setSelectedCity = useCallback(selectedCity => {
        dispatch(setSelectedCity(selectedCity));
    }, []);
    const getCardOrderDefaultValues = useCallback(() => {
        dispatch(getDraftFields());
    }, []);
    const newCardOrder = useCallback((formData, cb) => {
        dispatch(sendCardOrder(formData, cb));
    }, []);

    const history = useHistory();
    const cardOrdersIsLoading = useSelector(
        (state: RootState) => state.cardOrder.cardOrdersLoading
    );
    const cardOrders = useSelector((state: RootState) => state.cardOrder.cardOrders);
    const possibleCardOrder = canCardOrder(cardOrders, false);

    useEffect(() => {
        // грузим данные заявки
        if (!defaultValues) {
            getCardOrderDefaultValues();
        }
        // грузим заявления на карту
        dispatch(getCardOrders());
    }, []);

    if (defaultValuesIsLoading || cardOrdersIsLoading) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={<PageHeader title={'pages.CardOrder.mirOrder'} />}
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
            >
                <Loading />
            </MainLayout>
        );
    }

    if (!possibleCardOrder) {
        history.push('/home');
    }

    if (defaultValuesError) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={<PageHeader title={'pages.CardOrder.mirOrder'} />}
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
            >
                <ErrorMessage error={defaultValuesError} />
                <Button
                    style={{ marginTop: 30 }}
                    component={Link}
                    to="/home"
                    variant="contained"
                >
                    На главную
                </Button>
            </MainLayout>
        );
    }
    if (defaultValues) {
        return (
            <MainLayout
                contentClassName={classes.root}
                pageHeader={<PageHeader title={'pages.CardOrder.mirOrder'} />}
                pageContentClassName={classes.pageContent}
                withFooter
                withRightColumn={false}
                gridClassName={classes.pageGrid}
                contentColumnClassName={classes.contentColumn}
            >
                <CardOrder
                    citiesAsOptions={citiesAsOptions}
                    branchesAsOptions={branchesAsOptions}
                    citiesError={citiesError}
                    citiesIsLoading={citiesIsLoading}
                    cardOrderError={cardOrderError}
                    cardOrderSuccess={cardOrderSuccess}
                    cardOrderIsLoading={cardOrderIsLoading}
                    cardOrderDefaultValues={defaultValues}
                    selectedCity={selectedCity}
                    getCities={getCities}
                    fetchBranches={fetchBranches}
                    setSelectedCity={_setSelectedCity}
                    newCardOrder={newCardOrder}
                />
            </MainLayout>
        );
    }
    return null;
};
