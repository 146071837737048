import React from 'react';
import { Link } from 'react-router-dom';

import { Grid } from '@abdt/ornament';
import { makeStyles, Typography } from '@material-ui/core';

import authErrorImage from 'assets/images/authError.png';
import { EsiaLoginContainer } from '../EsiaLoginContainer';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    image: {},
    title: {
        [theme.breakpoints.down('xs')]: {
            marginTop: 40,
            fontSize: 22,
        },
        textAlign: 'center',
        marginTop: 32,
        fontWeight: 500,
        fontSize: 28,
        lineHeight: 1.14,
    },
    linkToFaq: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            marginTop: 16,
            fontSize: 14,
        },
        marginTop: 32,
        fontSize: 13,
        lineHeight: 1.4,
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    linkToHome: {
        [theme.breakpoints.down('xs')]: {
            textAlign: 'center',
            lineHeight: 1.4,
            fontSize: 16,
        },
        marginTop: 32,
        textDecoration: 'none',
        color: theme.palette.primary.main,
    },
    button: {
        marginTop: 32,
        width: '100%',
    },
}));

function getMessageByErrorType(errorType: string, t) {
    let message = t('com.AuthError.internalError');
    switch (errorType) {
        case 'access_denied':
            message = t('com.AuthError.accessDenied');
            break;
        case 'internal_error':
            break;

        default:
            break;
    }
    return message;
}

interface AuthErrorProps {
    authError: boolean;
    errorType: string;
    closeDialog: VoidFunction;
}

const AuthError: React.FC<AuthErrorProps> = ({ authError, errorType, closeDialog }) => {
    const classes = useStyles();
    const { t } = useTranslation();
    if (authError) {
        return (
            <Grid container direction="column" alignItems="center" justify="center">
                <img
                    src={authErrorImage}
                    alt=""
                    width="200px"
                    height="170px"
                    className={classes.image}
                />
                <Typography className={classes.title}>
                    {getMessageByErrorType(errorType, t)}
                </Typography>
                <Typography
                    component="a"
                    href="https://www.gosuslugi.ru/help/faq/c-1/2"
                    rel="noopener noreferrer"
                    variant="h5"
                    className={classes.linkToFaq}
                >
                    {t('com.AuthError.howToConfirm')}
                </Typography>
                <Typography
                    component={Link}
                    onClick={closeDialog}
                    to="/home"
                    variant="h5"
                    className={classes.linkToHome}
                >
                    {t('com.AuthError.subsidiesCatalog')}
                </Typography>
                <EsiaLoginContainer
                    mode="gosuslugi"
                    text={t('com.AuthError.reLogin')}
                    className={classes.button}
                />
            </Grid>
        );
    }

    return null;
};

export default AuthError;
