import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import {
    GET_ALL_SUBSIDIES_REQUESTING,
    GET_ALL_SUBSIDIES_FAILED,
    GET_ALL_SUBSIDIES_SUCCEED,
} from '../../constants';

export const getAllSubsidies = () => (dispatch, getState) => {
    const dispatchData = (actionType, subsidies, requestStatus, error) => {
        dispatch({
            type: actionType,
            payload: {
                subsidies: subsidies,
                requestStatus: requestStatus,
                error: error,
            },
        });
    };

    dispatchData(GET_ALL_SUBSIDIES_REQUESTING, null, null, null);

    axios({
        method: 'GET',
        url: '/api/Subsidies',
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const requestStatus = response.status;
            const isSuccess = response.data?.success;
            const error = response.data?.error;

            if (isSuccess === true) {
                dispatchData(
                    GET_ALL_SUBSIDIES_SUCCEED,
                    response.data?.result,
                    requestStatus,
                    error
                );
            }
            if (isSuccess === false) {
                dispatchData(GET_ALL_SUBSIDIES_FAILED, null, requestStatus, error);
            }
        })
        .catch(response => {
            dispatchData(GET_ALL_SUBSIDIES_FAILED, null, null, response?.data?.error);
        });
};
