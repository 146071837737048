import React, { FC } from 'react';

import {
    Box,
    Button,
    Dialog,
    IconButton,
    makeStyles,
    SwipeableDrawer,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';

const useStyles = makeStyles(theme => ({
    drawerPaper: {
        backgroundColor: theme.palette.common.white,
    },
    button: {
        [theme.breakpoints.down('sm')]: {
            textTransform: 'uppercase',
        },
        padding: '13px 28px 15px 27px',
    },
    closeButton: {
        position: 'absolute',
        right: theme.spacing(2),
        top: theme.spacing(2),
        color: theme.palette.grey[500],
    },
    dialogActions: {
        [theme.breakpoints.down('sm')]: {
            padding: 20,
        },
        flexDirection: 'column',
        padding: 60,
    },
    dialogPaper: {
        backgroundColor: theme.palette.common.white,
    },
}));

interface AlertDialogProps {
    open: boolean;
    onClickOpen: VoidFunction;
    onClose: VoidFunction;
    dialogText: string;
}

export const AlertDialog: FC<AlertDialogProps> = ({
    open,
    onClickOpen,
    onClose,
    dialogText,
}) => {
    const classes = useStyles();
    const theme = useTheme();
    const matches = useMediaQuery(theme.breakpoints.down('xs'));

    if (matches) {
        return (
            <SwipeableDrawer
                anchor="bottom"
                open={open}
                onOpen={onClickOpen}
                disableDiscovery
                onClose={onClose}
                classes={{
                    paper: classes.drawerPaper,
                }}
            >
                <Box className={classes.dialogActions}>
                    <Box marginBottom="40px">
                        <Typography variant="h1" component="h3">
                            {dialogText}
                        </Typography>
                    </Box>
                    <Box display="flex" flexDirection="column">
                        <Button
                            className={classes.button}
                            onClick={onClose}
                            color="primary"
                            variant="contained"
                            fullWidth
                        >
                            Ок
                        </Button>
                    </Box>
                </Box>
            </SwipeableDrawer>
        );
    }
    return (
        <Dialog
            maxWidth="xs"
            open={open}
            onClose={onClose}
            scroll="body"
            classes={{
                paper: classes.dialogPaper,
            }}
        >
            <IconButton size="small" className={classes.closeButton} onClick={onClose}>
                <CloseIcon />
            </IconButton>
            <Box className={classes.dialogActions}>
                <Box marginBottom="40px">
                    <Typography variant="h3">{dialogText}</Typography>
                </Box>
                <Box display="flex" flexDirection="column">
                    <Button
                        className={classes.button}
                        onClick={onClose}
                        color="primary"
                        variant="contained"
                        fullWidth
                    >
                        Ок
                    </Button>
                </Box>
            </Box>
        </Dialog>
    );
};
