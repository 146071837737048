import React, { useState, useEffect } from 'react';
import cx from 'classnames';
import isEmpty from 'lodash/isEmpty';
import forEach from 'lodash/forEach';
import { makeStyles, Theme, useTheme } from '@material-ui/core/styles';
import { Box } from '@material-ui/core';
import { FormControl, FormControlLabel, Radio } from '@material-ui/core';
import { ApplicationField } from 'types/Application';
import { useFormContext } from 'react-hook-form';
import cloneDeep from 'lodash/cloneDeep';

const useStylesRadio = makeStyles({
    root: {
        '&:hover': {
            backgroundColor: 'transparent',
        },
        marginRight: '7px',
    },
    icon: {
        borderRadius: '50%',
        width: 20,
        height: 20,
        boxShadow: 'inset 0 0 0 1px rgba(16,22,26,.2), inset 0 -1px 0 rgba(16,22,26,.1)',
        backgroundColor: '#f5f8fa',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.8),hsla(0,0%,100%,0))',
        '$root.Mui-focusVisible &': {
            outline: '2px auto rgba(19,124,189,.6)',
            outlineOffset: 2,
        },
        'input:disabled ~ &': {
            boxShadow: 'none',
            background: 'rgba(206,217,224,.5)',
        },
    },
    checkedIcon: {
        backgroundColor: '#5780ea',
        backgroundImage: 'linear-gradient(180deg,hsla(0,0%,100%,.1),hsla(0,0%,100%,0))',
        boxShadow: 'none',
        '&:before': {
            display: 'block',
            width: 20,
            height: 20,
            backgroundImage: 'radial-gradient(#fff,#fff 28%,transparent 40%)',
            content: '""',
        },
    },
});

// Inspired by blueprintjs
function StyledRadio(props) {
    const classes = useStylesRadio();

    return (
        <Radio
            className={classes.root}
            disableRipple
            color="default"
            checkedIcon={<span className={cx(classes.icon, classes.checkedIcon)} />}
            icon={<span className={classes.icon} />}
            {...props}
        />
    );
}

const useStyles = makeStyles((theme: Theme) => ({
    checkBoxList: {},
    fc: {
        width: '100%',
    },
}));

const checkValidation = state => {
    let val = 0;
    forEach(state, (item, itemIndex) => {
        forEach(item, (value, valueIndex) => {
            if (value.value) val++;
        });
    });

    return val === 0;
};

export const RadioList: React.FC<{
    field: ApplicationField;
    fieldName: string;
    error: boolean;
    isRequired: boolean;
}> = ({ field, fieldName, isRequired }) => {
    const classes = useStyles();
    const formProps = useFormContext();
    const { watch, errors, register, control } = formProps;
    const [error, setError] = useState(false);

    const [state, setState] = useState(() => {
        let obj = {};
        forEach(field.fieldItems, (item, itemIndex) => {
            const jsonFields = JSON.parse(item.value);
            obj[item.key] = jsonFields.map((valueItem, valueItemIndex) => {
                const value =
                    typeof valueItem.value === 'string'
                        ? valueItem.value.toLowerCase() === 'true'
                        : valueItem.value;
                return { id: valueItem.id, value };
            });
        });
        return obj;
    });

    const onChange = ({ value, keyName, valueIndex, id }) => {
        let cloneState = cloneDeep(state);

        //Сброс значений
        forEach(cloneState, (item, index1) => {
            forEach(item, (value, index2) => {
                cloneState[index1][index2].value = false;
            });
        });

        cloneState[keyName][valueIndex].value = value;
        cloneState[keyName][valueIndex].id = id;
        setState(cloneState);
    };

    useEffect(() => {
        control.trigger();
    }, [fieldName, state]);

    if (!isEmpty(state))
        return (
            <Box>
                <input
                    type="hidden"
                    key={`checkBoxList-${fieldName}`}
                    value={JSON.stringify(state)}
                    name={fieldName}
                    ref={register({
                        validate: value => !checkValidation(state),
                    })}
                />
                {field.fieldItems.map((item, itemIndex) => {
                    const jsonFields = JSON.parse(item.value);
                    return jsonFields.map((value, valueIndex) => {
                        const keyName = item.key;
                        // const name = `${fieldName}.${keyName}[${valueIndex}].value`;
                        const checked = state?.[keyName]?.[valueIndex]?.value;
                        return (
                            <FormControl
                                error={error}
                                key={`checkBoxListValue-${itemIndex}-${valueIndex}`}
                                className={classes.fc}
                            >
                                <FormControlLabel
                                    control={
                                        <StyledRadio
                                            checked={checked}
                                            onChange={e =>
                                                onChange({
                                                    value: e.target.checked,
                                                    keyName: keyName,
                                                    valueIndex,
                                                    id: value.id,
                                                })
                                            }
                                        />
                                    }
                                    label={value.title}
                                />
                            </FormControl>
                        );
                    });
                })}
            </Box>
        );

    return null;
};
