import React from 'react';
import classNames from 'classnames';
import { useHistory } from 'react-router-dom';
import { Link } from 'react-router-dom';
import { Box, Typography, makeStyles } from '@material-ui/core';

import { SubsidyManagementModel } from 'types/SubsidyManagementModel';
import { Icon, Tag } from 'components/atoms';
import { useSubsidyCalculatorDrawer } from 'hooks/useSubsidyCalculatorDrawer';
import { RootState } from 'store/store';
import { getColorBySubsidyStatus } from 'utils/utils';
import { PageHeader } from '../PageHeader';
import { getApplications } from 'store/actions/application';
import { useTranslation } from 'react-i18next';

const useStyles = makeStyles(theme => ({
    subsidyCard: {
        [theme.breakpoints.down('sm')]: {
            width: 168,
            minWidth: 168,
            padding: 16,
            marginRight: 8,
            // marginBottom: 16,
            minHeight: 181,
            // height: 'auto',
            marginTop: 0,
        },
        '&:nth-child(n+5)': {
            [theme.breakpoints.down('sm')]: {
                marginTop: 16,
            },
        },
        '&:nth-child(n+3)': {
            [theme.breakpoints.down('xs')]: {
                marginTop: 16,
            },
        },
        '& + &': {
            [theme.breakpoints.down('sm')]: {
                marginTop: 0,
            },
            marginTop: 24,
        },
        '&:hover': {
            cursor: 'pointer',
        },
        textDecoration: 'none',
        color: 'inherit',
        width: 232,
        minWidth: 232,
        minHeight: 172,
        // marginBottom: 24,
        padding: 16,
        borderRadius: 8,
        position: 'relative',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'space-between',
        background: (props: any) =>
            getColorBySubsidyStatus(props.statusId).backgroundColor,
    },
    subsidyCardSubtitleContainer: {
        [theme.breakpoints.down('xs')]: {
            justifyContent: 'space-between',
            alignItems: 'center',
        },
        display: 'flex',
        alignItems: 'flex-start',
        marginTop: 16,
        '& .icon-user2 path': {
            fill: (props: any) => getColorBySubsidyStatus(props.statusId).iconUser2Color,
        },
    },
    subsidyCardTitle: {
        fontWeight: 500,
        fontSize: 13,
        lineHeight: 1.4,
        marginTop: 16,
        color: (props: any) => getColorBySubsidyStatus(props.statusId).textColor,
    },
    subsidyAmount: {
        fontWeight: 'normal',
        //color: theme.palette.grey[600],
        color: (props: any) => getColorBySubsidyStatus(props.statusId).amountColor,
        fontSize: 13,
        lineHeight: 1.62,
    },
    subsidyCardStatusDescription: {
        color: (props: any) => getColorBySubsidyStatus(props.statusId).tagTextColor,
        fontSize: 12,
        lineHeight: 1.42,
    },
    subsidyCardTag: {
        padding: '4px 10px',
        height: 'auto',
        width: 'fit-content',
    },
    iconButton: {
        [theme.breakpoints.down('xs')]: {
            textDecoration: 'none',
        },
        textDecoration: 'underline',
        color: theme.palette.primary.main,
        '&:hover': {
            textDecoration: 'underline',
            backgroundColor: 'unset',
        },
        borderRadius: 0,
        padding: 0,
        fontSize: 14,
        lineHeight: 1.43,
    },
}));

const subsidyIcon = (classes, amount, receiverName) => {
    let icon;
    if (receiverName) {
        icon = (
            <>
                <Icon className="icon-user2" name="user2" /> {receiverName}
            </>
        );
    } else if (amount) {
        icon = (
            <>
                <Icon className="icon-ruble" name="subsidyRuble" /> {amount}
            </>
        );
    }
    return (
        <Typography
            variant="body1"
            className={classNames(classes.subsidyAmount, 'subsidyAmount')}
        >
            {icon}
        </Typography>
    );
};

interface CardStatementProps {
    subsidy?: SubsidyManagementModel;
    className?: string;
    detailUrl: string;
}
export const CardStatement: React.FC<CardStatementProps> = ({
    subsidy,
    className,
    detailUrl,
}) => {
    const { statusId, status, name, amount, receiverName } = subsidy;
    const classes = useStyles({ statusId });
    const history = useHistory();

    const handleClick = () => {
        history.push(detailUrl);
    };
    const { t } = useTranslation();
    return (
        <Link className={classNames(classes.subsidyCard, className)} to={detailUrl}>
            <Tag
                className={classes.subsidyCardTag}
                color="#FFFFFF"
                label={
                    <Typography className={classes.subsidyCardStatusDescription}>
                        {status || t('com.StatementList.IncompleteData')}
                    </Typography>
                }
            />
            <Typography className={classes.subsidyCardTitle}>{name}</Typography>
            <Box className={classes.subsidyCardSubtitleContainer}>
                {subsidyIcon(classes, amount, receiverName)}
            </Box>
        </Link>
    );
};
