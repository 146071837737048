export const RESET = 'chat/RESET';

export const NEW_WEBSOCKET_MESSAGE = 'chat/NEW_WEBSOCKET_MESSAGE';

export const SEND_MESSAGE_START = 'chat/SEND_MESSAGE_START';
export const SEND_MESSAGE_FAILURE = 'chat/SEND_MESSAGE_FAILURE';
export const SEND_MESSAGE_SUCCESS = 'chat/SEND_MESSAGE_SUCCESS';

export const STOP_SIGNALR = 'chat/STOP_SIGNALR';

export const CLEAR_SEND_MESSAGE_ERROR = 'chat/CLEAR_SEND_MESSAGE_ERROR';

export const START_BOT_START = 'chat/START_BOT_START';
export const START_BOT_FAILURE = 'chat/START_BOT_FAILURE';
export const START_BOT_SUCCESS = 'chat/START_BOT_SUCCESS';

export const GET_CHAT_HISTORY_START = 'chat/GET_CHAT_HISTORY_START';
export const GET_CHAT_HISTORY_FAILURE = 'chat/GET_CHAT_HISTORY_FAILURE';
export const GET_CHAT_HISTORY_SUCCESS = 'chat/GET_CHAT_HISTORY_SUCCESS';

export const CLEAR_CHAT_HISTORY_START = 'chat/CLEAR_ CHAT_HISTORY_START';
export const CLEAR_CHAT_HISTORY_FAILURE = 'chat/CLEAR_CHAT_HISTORY_FAILURE';
export const CLEAR_CHAT_HISTORY_SUCCESS = 'chat/CLEAR_CHAT_HISTORY_SUCCESS';

export const CONNECT_TO_SIGNALR = 'chat/CONNECT_TO_SIGNALR';

export const TRANSFORM_CHAT_MESSAGE_START = 'chat/TRANSFORM_CHAT_MESSAGE_START';
export const TRANSFORM_CHAT_MESSAGE_FAILURE = 'chat/TRANSFORM_CHAT_MESSAGE_FAILURE';
export const TRANSFORM_CHAT_MESSAGE_SUCCESS = 'chat/TRANSFORM_CHAT_MESSAGE_SUCCESS';
