import React from 'react';
import {
    Box,
    Grid,
    makeStyles,
    Theme,
    Typography,
    useMediaQuery,
    useTheme,
} from '@material-ui/core';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { MainLayout } from 'components/layouts';

const useStyles = makeStyles((theme: Theme) => ({
    grid: {
        alignItems: 'center',
        justifyContent: 'center',
        flexDirection: 'column',
        height: 'calc(100vh - 104px - 120px)',
    },
}));

interface Props {}

export const NotFoundPage: React.FC<Props> = () => {
    const classes = useStyles();
    const { t } = useTranslation();
    const theme = useTheme();
    const isViewXS = useMediaQuery(theme.breakpoints.down('xs'));

    return (
        <MainLayout withRightColumn={!isViewXS}>
            <Grid container className={classes.grid}>
                <Grid item>
                    <Box textAlign="center">
                        <Typography variant="h4">{t('actions.error')}</Typography>
                        <Typography variant="h4">404</Typography>
                    </Box>
                </Grid>
                <Grid item>
                    <Typography variant="h3" to="/home" component={Link}>
                        {t('common.toHome')}
                    </Typography>
                </Grid>
            </Grid>
        </MainLayout>
    );
};
