import {
    GET_FEATURES_LIST_SUCCESS,
    GET_FEATURES_LIST_FAILURE,
    GET_FEATURES_LIST_START,
} from '../constants';

import { FeatureTogglesState } from 'types/FeatureToggles';

const INITIAL_STATE: FeatureTogglesState = {
    featuresLoading: false,
    featuresAreLoaded: false,
    featuresError: undefined,
    list: [],
};

export default function (state = INITIAL_STATE, { type, payload }) {
    let nextState;
    switch (type) {
        case GET_FEATURES_LIST_START: {
            nextState = {
                ...state,
                featuresLoading: true,
                featuresError: undefined,
            };
            break;
        }
        case GET_FEATURES_LIST_FAILURE: {
            nextState = {
                ...state,
                featuresLoading: false,
                featuresError: payload.error,
                featuresAreLoaded: true,
            };
            break;
        }
        case GET_FEATURES_LIST_SUCCESS: {
            nextState = {
                ...state,
                featuresLoading: false,
                list: payload.list,
                featuresAreLoaded: true,
            };
            break;
        }

        default:
            nextState = state;
    }

    return nextState;
}
