import React from 'react';
import Button from '@material-ui/core/Button';
import isEmpty from 'lodash/isEmpty';
import map from 'lodash/map';
import { ApplicationGroupsField } from 'components/organisms';
import { ErrorMessage } from 'components/atoms';
import { CircularProgress, Typography, Grid } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import Step from '@material-ui/core/Step';
import StepLabel from '@material-ui/core/StepLabel';
import StepContent from '@material-ui/core/StepContent';
import { ApplicationGroupSubtitle } from 'components/organisms';
import { makeStyles, Theme } from '@material-ui/core/styles';
import { Icon } from 'components/atoms';
import { useDispatch, useSelector } from 'react-redux';
import { ChildSelectField } from './ChildSelectField';
import { delChild } from 'store/actions/application';
import { useFormContext } from 'react-hook-form';

export const useStyles = makeStyles((theme: Theme) => ({
    child: {
        position: 'relative',
        marginBottom: 32,
        marginTop: 32,
    },
    title: {
        color: '#10002B',
        fontSize: '20px',
        fontWeight: 'bold',
        lineHeight: 1.33,
    },
    anketaChildCircleBtn: {
        position: 'absolute',
        left: '-30px',
        top: '6px',
    },
    buttonsContainer: {
        marginTop: 10,
    },
    topInfo: {
        fontStyle: 'normal',
        fontWeight: 'normal',
        fontSize: '14px',
        lineHeight: '20px',
        color: '#474D5F',
        marginTop: 24,
        marginBottom: 16,
    },
}));

interface Props {
    categories: any;
    child: any;
    childIndex: number | string;
    canAppendChildData: boolean;
    profileChildren: any;
    handleNextChild: any;
    handleAddChild: any;
    childrenBlock: any;
    childrenBlockLastIndex: any;
    applicationSubmitIsLoading: boolean;
}

export const Child: React.FC<Props> = ({
    categories,
    child,
    childIndex,
    canAppendChildData,
    profileChildren,
    handleNextChild,
    handleAddChild,
    childrenBlock,
    childrenBlockLastIndex,
    applicationSubmitIsLoading,
}) => {
    const classes = useStyles();
    const { t } = useTranslation();
    const dispatch = useDispatch();
    const childFields = child.childFields;
    const formProps = useFormContext();
    const { errors, register, watch, setValue } = formProps;

    const handleDelChild = () => {
        dispatch(delChild(childIndex));
    };

    return (
        <div className={classes.child}>
            <Icon name="anketaChildCircleBtn" className={classes.anketaChildCircleBtn} />
            <Typography className={classes.title}>{+childIndex + 1} Ребенок</Typography>
            {childIndex !== 0 && (
                <Grid container spacing={4} className={classes.buttonsContainer}>
                    <Grid item>
                        <Button variant="outlined" onClick={handleDelChild}>
                            Удалить
                        </Button>
                    </Grid>
                </Grid>
            )}
            <Typography className={classes.topInfo}>
                Чтобы получить субсидию заполните информацию о ребенке
            </Typography>

            {/*Выбор детей*/}
            {canAppendChildData && (
                <ChildSelectField
                    childIndex={childIndex}
                    childrenBlock={childrenBlock}
                    currentChildId={child.childId}
                    profileChildren={profileChildren}
                />
            )}

            {/*Пустая категория. Когда criterionFieldsCategoryId == null*/}
            <ApplicationGroupsField
                categoryName={null}
                categoryId={null}
                fields={childFields}
                isSuperCategory={false}
                filedsPath={`childrenData[${childIndex}]`}
                key={`childrenBlock_category__common-${child.childId}`}
            />

            {map(categories, (category, key) => {
                return (
                    <ApplicationGroupsField
                        categoryName={category.displayName}
                        categoryType={category.categoryType}
                        categoryId={category.id}
                        isSuperCategory={category.isSuperCategory}
                        fields={childFields}
                        filedsPath={`childrenData[${childIndex}]`}
                        key={`childrenBlock_category__${key}-${child.childId}`}
                    />
                );
            })}

            <input
                type="hidden"
                value={child.childId}
                name={`childrenData[${childIndex}].kid_id`}
                ref={register}
            />

            <Grid container spacing={4} className={classes.buttonsContainer}>
                {childrenBlockLastIndex === childIndex && (
                    <>
                        {canAppendChildData && (
                            <Grid item>
                                <Button variant="outlined" onClick={handleAddChild}>
                                    Добавить ребенка
                                </Button>
                            </Grid>
                        )}
                        <Grid item>
                            <Button
                                variant="contained"
                                type="submit"
                                onClick={e => handleNextChild(e, childIndex)}
                                startIcon={
                                    applicationSubmitIsLoading && (
                                        <CircularProgress size={24} />
                                    )
                                }
                                disabled={applicationSubmitIsLoading}
                            >
                                Далее
                            </Button>
                        </Grid>
                    </>
                )}
            </Grid>
        </div>
    );
};
