import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import { iconSizes, typography } from '@abdt/design-tokens';

export default makeStyles((theme: Theme) =>
    createStyles({
        acceptedFileItem: {
            marginBottom: 16,
        },
        acceptedFileInput: {
            flex: 1,
            '&.MuiOutlinedInput-root': {},
            '& .MuiOutlinedInput-input': {
                padding: '10px 0 10px 16px',
                fontSize: 16,
                color: '#747E89',
            },
            '& .MuiOutlinedInput-adornedEnd': {
                paddingRight: 0,
            },
            '& .MuiOutlinedInput-adornedEnd.MuiOutlinedInput-marginDense': {
                paddingRight: 0,
            },
        },
        acceptedFileDivider: {
            // position: 'absolute',
            // right: 48,
            height: 48,
        },
        acceptedFileIcon: {
            flex: 'none',
            marginLeft: 12,
            marginRight: 12,
        },
        acceptedFileProgress: {
            marginRight: 12,
        },
        acceptedFileDescription: {},
        acceptedFileName: {},
        acceptedFileSize: {},
        acceptedFileControls: {},
        downloadButton: {},
        binButton: {},
    })
);
