import { HubConnectionState, HubConnection } from '@microsoft/signalr';

import {
    CONNECT_TO_SIGNALR,
    SEND_MESSAGE_START,
    STOP_SIGNALR,
} from 'constants/chat.constants';
import {
    initializeChat,
    sendMessageFailure,
    sendMessageSuccess,
    subscribeToChatEvents,
} from '../actions/chat';
import { SIGN_IN_ESIA_CONFIRM_SUCCEED, SIGN_IN_ESIA_RESET_TOKEN } from '../constants';

let connection: HubConnection;
let connectionInProccess = false;

const stopSignalRConnection = async (_connection, cb?: Function) => {
    try {
        if (connection) {
            await _connection.stop();
            connection = null;
        }
        connectionInProccess = false;
        if (cb && typeof cb === 'function') {
            cb();
        }
    } catch (err) {
        setTimeout(() => stopSignalRConnection(_connection, cb), 5000);
    }
};

const signalRMiddleware = ({ getState, dispatch }) => next => async action => {
    if (action.type === SIGN_IN_ESIA_CONFIRM_SUCCEED) {
        stopSignalRConnection(connection, () => dispatch(initializeChat()));
    }
    if (action.type === CONNECT_TO_SIGNALR && !connection && !connectionInProccess) {
        connectionInProccess = true;
        connection = await dispatch(subscribeToChatEvents());
    }
    if (
        action.type === SIGN_IN_ESIA_RESET_TOKEN &&
        connection &&
        connection.state === HubConnectionState.Connected
    ) {
        stopSignalRConnection(connection);
    }
    if (
        action.type === STOP_SIGNALR &&
        connection &&
        connection.state === HubConnectionState.Connected
    ) {
        stopSignalRConnection(connection);
    }
    if (
        action.type === SEND_MESSAGE_START &&
        connection &&
        connection.state === HubConnectionState.Connected
    ) {
        connection
            .invoke('send', action.payload.message)
            .then(e => {
                dispatch(sendMessageSuccess());
            })
            .catch(error => {
                dispatch(sendMessageFailure(error));
            });
    }

    return next(action);
};

export default signalRMiddleware;
