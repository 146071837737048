import { axiosInstanse as axios, getCommonHeaders } from 'infrastructure/RestClient';
import { getApplications } from '../../actions/application/application.actions';

//Удалить черновик
export const delDraft = (requestId?: string, cb?: Function, errorCb?: Function) => (
    dispatch,
    getState
) => {
    return axios({
        method: 'DELETE',
        url: '/api/Allowance/' + requestId,
        headers: getCommonHeaders(getState()),
    })
        .then(response => {
            const isSuccess = response.data.success;
            const error = response.data.error;

            if (isSuccess === true) {
                dispatch(getApplications());
                cb();
            }
            if (isSuccess === false || error) {
                errorCb();
            }
        })
        .catch(rawError => {
            errorCb();
        });
};
