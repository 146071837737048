import map from 'lodash/map';
import { ConfigurationSubmitFields } from 'types/FormField';
import { ProfilePassportFields, ProfileContactFields } from 'types/Profile';
import { getMaskByType } from 'utils/utils';
const REGISTRATION_ADDRESS = 'registrationAddress';
export const EMPTY = 'empty';
const CHILD_INFO = 'childInfo';
const CHILD_BIRTH_PLACE = 'childBirthPlace';
const CHILD_BIRTH_CERTIFICATE = 'childBirthCertificate';
const CHILD_REGISTRATION_ADDRESS = 'childRegistrationAddress';

export const GROUPS = {
    [EMPTY]: '',
    [REGISTRATION_ADDRESS]: 'groups.registationAddress',
    [CHILD_INFO]: 'groups.childInfo',
    [CHILD_BIRTH_PLACE]: 'groups.childBirthPlace',
    [CHILD_BIRTH_CERTIFICATE]: 'groups.childBirthCertificate',
    [CHILD_REGISTRATION_ADDRESS]: 'groups.childRegistrationAddress',
    ['familyStatus']: 'groups.familyStatus',
    ['spouse']: 'groups.spouse',
};

export const personalFields: ProfilePassportFields = {
    lastName: {
        component: 'textfield',
        label: 'fields.lastName',
        mask: '',
        type: 'text',
        name: '',
        disabled: true,
        readOnly: true,
    },
    firstName: {
        component: 'textfield',
        label: 'fields.firstName',
        mask: '',
        type: 'text',
        name: '',
        disabled: true,
        readOnly: true,
    },
    middleName: {
        component: 'textfield',
        label: 'fields.middleName',
        mask: '',
        type: 'text',
        name: '',
        disabled: true,
        readOnly: true,
    },
    birthOfDate: {
        component: 'datepicker',
        label: 'fields.birthdayDate',
        mask: '',
        type: 'date',
        disabled: true,
        readOnly: true,
    },
    gender: {
        component: 'textfield',
        label: 'fields.gender',
        mask: '',
        type: 'text',
        name: '',
        disabled: true,
        readOnly: true,
    },
    snils: {
        component: 'textfield',
        label: 'fields.snils',
        type: 'text',
        name: '',
        disabled: true,
        readOnly: true,
        mask: getMaskByType('snils'),
        placeholder: '000-000-000 00',
    },
    passportSeries: {
        component: 'textfield',
        label: 'fields.passportSeries',
        type: '',
        disabled: true,
        readOnly: true,
        mask: getMaskByType('passportSeries'),
        placeholder: '0000',
    },
    passportNumber: {
        component: 'textfield',
        label: 'fields.passportNumber',
        type: '',
        disabled: true,
        readOnly: true,
        mask: getMaskByType('passportNumber'),
        placeholder: '000000',
    },
    passportDate: {
        component: 'datepicker',
        label: 'fields.passportDate',
        mask: '',
        type: 'date',
        disabled: true,
        readOnly: true,
    },
    passportIssuedBy: {
        component: 'textfield',
        label: 'fields.passportIssuedBy',
        mask: '',
        type: '',
        disabled: true,
        readOnly: true,
    },
    passportDepartmentCode: {
        component: 'textfield',
        label: 'fields.passportDepartmentCode',
        type: '',
        disabled: true,
        readOnly: true,
        mask: getMaskByType('passportCode'),
        placeholder: '000-000',
    },
    citizenship: {
        component: 'textfield',
        label: 'fields.citizenship',
        mask: '',
        type: '',
        disabled: true,
        readOnly: true,
    },
};

export const contactsFields: ProfileContactFields = {
    registrationAddress: {
        component: 'address',
        label: 'fields.registrationAddress',
        mask: '',
        // type: 'text',
        name: 'registrationAddress',
        validationRules: { required: true },
        group: REGISTRATION_ADDRESS,
    },
    mobilePhone: {
        component: 'textfield',
        label: 'fields.mobilePhone',
        mask: getMaskByType('mobilePhone'),
        type: 'text',
        name: '',
        validationRules: { required: true },
        group: EMPTY,
    },
    email: {
        component: 'textfield',
        label: 'fields.email',
        mask: '',
        type: 'text',
        name: '',
        group: EMPTY,
    },
};

export const childrenInfoFields: ConfigurationSubmitFields = {
    lastName: {
        component: 'textfield',
        label: 'fields.lastName',
        mask: '',
        type: 'text',
        name: 'lastName',
        validationRules: { required: true },
    },
    firstName: {
        component: 'textfield',
        label: 'fields.firstName',
        mask: '',
        type: 'text',
        name: 'firstName',
        validationRules: { required: true },
    },
    middleName: {
        component: 'textfield',
        label: 'fields.middleName',
        type: 'text',
        name: 'middleName',
        validationRules: { required: true },
    },
    gender: {
        component: 'select',
        label: 'fields.gender',
        mask: '',
        type: '',
        options: [
            { name: 'fields.male', value: 'Male' },
            { name: 'fields.female', value: 'Female' },
        ],
        name: 'gender',
        validationRules: { required: true },
    },
    birthOfDate: {
        component: 'datepicker',
        label: 'fields.birthdayDate',
        type: 'date',
        name: 'birthOfDate',
        validationRules: { required: true },
    },
    snils: {
        component: 'textfield',
        label: 'fields.snils',
        type: 'text',
        name: 'snils',
        mask: getMaskByType('snils'),
        placeholder: '000-000-000 00',
        validationRules: { required: true },
    },
};

export const childrenBirthPlaceFields: ConfigurationSubmitFields = {
    birthOfCountry: {
        component: 'textfield',
        label: 'fields.country',
        mask: '',
        type: 'text',
        name: 'birthOfCountry',
        validationRules: { required: true },
    },
    birthOfRegion: {
        component: 'textfield',
        label: 'fields.region',
        mask: '',
        type: 'text',
        name: 'birthOfRegion',
        validationRules: { required: true },
    },
    birthOfCity: {
        component: 'textfield',
        label: 'fields.district',
        mask: '',
        type: 'text',
        name: 'birthOfCity',
    },
    birthOfTown: {
        component: 'textfield',
        label: 'fields.town',
        mask: '',
        type: 'text',
        name: 'birthOfTown',
        validationRules: { required: true },
    },
};

export const childBirthCertificateFields: ConfigurationSubmitFields = {
    certificateSeries: {
        component: 'textfield',
        label: 'fields.series',
        mask: getMaskByType('kidCertificateSeries'),
        placeholder: 'XX-ЩЩ',
        type: 'text',
        name: 'certificateSeries',
        validationRules: {
            pattern: /^M{0,3}(D?C{0,3}|C[DM])(L?X{0,3}|X[LC])(V?I{0,3}|I[VX])[-][А-Я]{2}$/i,
            required: true,
        },
        toUpperCase: true,
    },
    certificateNumber: {
        component: 'textfield',
        label: 'fields.number',
        type: 'text',
        name: 'certificateNumber',
        mask: getMaskByType('kidCertificateNumber'),
        placeholder: '000000',
        validationRules: { required: true },
    },
    certificateIssueDate: {
        component: 'datepicker',
        label: 'fields.issueDate',
        validationRules: { required: true },
        mask: '',
        type: 'date',
        name: 'certificateIssueDate',
    },
    certificateIssueBy: {
        component: 'textfield',
        label: 'fields.passportIssuedBy',
        mask: '',
        type: 'text',
        name: 'certificateIssueBy',
        validationRules: { required: true },
    },
};

export const childRegistrationAddressFields: ConfigurationSubmitFields = {
    registrationAddress: {
        component: 'address',
        label: 'fields.registrationAddress',
        mask: '',
        type: 'text',
        name: 'registrationAddress',
        validationRules: { required: true },
    },
};

export const familyMaritalStatusFields: ConfigurationSubmitFields = {
    maritalStatus: {
        component: 'select',
        label: 'actions.status',
        mask: '',
        type: 'select',
        options: [
            { name: 'fields.maritalStatus.neverMarried', value: 0 },
            { name: 'fields.maritalStatus.marriedOne', value: 1 },
            { name: 'fields.maritalStatus.divorced', value: 2 },
            { name: 'fields.maritalStatus.singleMother', value: 3 },
            { name: 'fields.maritalStatus.widower', value: 4 },
            { name: 'fields.maritalStatus.marriedTwo', value: 5 },
        ],
        name: 'maritalStatus',
    },
};

export const familySpouseFields: ConfigurationSubmitFields = {
    lastName: {
        component: 'textfield',
        label: 'fields.lastName',
        mask: '',
        type: 'text',
        name: 'lastName',
        validationRules: { required: true },
    },
    firstName: {
        component: 'textfield',
        label: 'fields.firstName',
        mask: '',
        type: 'text',
        name: 'firstName',
        validationRules: { required: true },
    },
    middleName: {
        component: 'textfield',
        label: 'fields.middleName',
        mask: '',
        type: 'text',
        name: 'middleName',
        validationRules: { required: true },
    },
    birthOfDate: {
        component: 'datepicker',
        label: 'fields.birthdayDate',
        mask: '',
        type: 'date',
        name: 'birthOfDate',
        validationRules: { required: true },
    },
    marriageOfDate: {
        component: 'datepicker',
        label: 'fields.marriageOfDate',
        mask: '',
        type: 'date',
        name: 'marriageOfDate',
        validationRules: { required: true },
    },
};

export const FAMILY_MARITAL_STATUS_CATEGORY = {
    groupName: 'groups.familyStatus',
    groupId: 'familyStatus',
    fields: map(familyMaritalStatusFields, field => field),
};

export const FAMILY_SPOUSE_CATEGORY = {
    groupName: 'groups.spouse',
    groupId: 'spouse',
    fields: map(familySpouseFields, field => field),
};
export const CHILDREN_INFO_CATEGORY = {
    groupName: 'groups.childInfo',
    groupId: 'childInfo',
    fields: map(childrenInfoFields, field => field),
};

export const CHILDREN_BIRTH_PLACE_CATEGORY = {
    groupName: 'groups.childBirthPlace',
    groupId: 'childBirthPlace',
    fields: map(childrenBirthPlaceFields, field => field),
};

export const CHILDREN_REGISTRATION_ADDRESS_CATEGORY = {
    groupName: 'groups.childRegistrationAddress',
    groupId: 'childRegistrationAddress',
    fields: map(childRegistrationAddressFields, field => field),
};

export const CHILDREN_BIRTH_CERTIFICATE_CATEGORY = {
    groupName: 'groups.childBirthCertificate',
    groupId: 'childBirthCertificate',
    fields: map(childBirthCertificateFields, field => field),
};
