import * as constants from 'constants/chat.constants';

export const startChatBotStart = () => ({
    type: constants.START_BOT_START,
});
export const startChatBotSuccess = () => ({
    type: constants.START_BOT_SUCCESS,
});
export const startChatBotFailure = error => ({
    type: constants.START_BOT_FAILURE,
    payload: {
        error,
    },
});

export const getChatHistoryStart = () => ({
    type: constants.GET_CHAT_HISTORY_START,
});
export const getChatHistorySuccess = messages => ({
    type: constants.GET_CHAT_HISTORY_SUCCESS,
    payload: {
        messages,
    },
});
export const getChatHistoryFailure = error => ({
    type: constants.GET_CHAT_HISTORY_FAILURE,
    payload: {
        error,
    },
});

export const clearChatHistoryStart = () => ({
    type: constants.CLEAR_CHAT_HISTORY_START,
});
export const clearChatHistorySuccess = () => ({
    type: constants.CLEAR_CHAT_HISTORY_SUCCESS,
});
export const clearChatHistoryFailure = error => ({
    type: constants.CLEAR_CHAT_HISTORY_FAILURE,
    payload: {
        error,
    },
});

export const connectToSignalRStart = () => ({
    type: constants.CONNECT_TO_SIGNALR,
});

export const disconnectSignalRStart = () => ({
    type: constants.STOP_SIGNALR,
});
export const reset = () => ({ type: constants.RESET });
export const newWebSocketMessage = data => ({
    type: constants.NEW_WEBSOCKET_MESSAGE,
    payload: { data },
});

export const sendMessageStart = (message, sendMessageText) => ({
    type: constants.SEND_MESSAGE_START,
    payload: {
        message,
        sendMessageText,
    },
});
export const sendMessageFailure = error => ({
    type: constants.SEND_MESSAGE_FAILURE,
    payload: { error },
});
export const sendMessageSuccess = () => ({
    type: constants.SEND_MESSAGE_SUCCESS,
});

export const transformChatMessageStart = () => ({
    type: constants.TRANSFORM_CHAT_MESSAGE_START,
});
export const transformChatMessageFailure = error => ({
    type: constants.TRANSFORM_CHAT_MESSAGE_FAILURE,
    payload: { error },
});
export const transformChatMessageSuccess = () => ({
    type: constants.TRANSFORM_CHAT_MESSAGE_SUCCESS,
});
